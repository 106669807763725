import { DatePicker, Input, InputNumber, Select } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

export const InputElementProvider = (props: any) => {
	const { value, onChange, inputType, list, loading, width, min, max } = props;
	const {Option} = Select;
	switch(inputType) {
		case 1: {
			return (
				<InputNumber min={min} max={max} value={value} onChange={onChange} style={{width}} />
			);
		}
		case 2:{
			return (
				<Input type='text' value={value} onChange={(ev)=>onChange(ev.target.value)} style={{width}}/>
			);
		}
		case 3:{
			return (
				<Select 
					loading={loading}
					value={value}
					onChange={onChange}
					style={{width}}
					showSearch
					filterOption={(inputValue: any, option: any) =>
						option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
					}
				>
					{ Object.keys(list ?? {}).map((element: any) => {
						return (
							<Option key={list[element].label} value={list[element].value}>
								{list[element].label}
							</Option>			
						);
					})}
				</Select>
			);
		}
		case 4:{
			return (
				<DatePicker showTime style={{width}}
					onChange={onChange}
					// value={(value)}
					defaultValue={ value && dayjs(value ?? '')} 
					format="DD-MM-YYYY HH:mm"
				/>
			);
		}
		default :{
			return (
				<></>
			);
		}
	}
};