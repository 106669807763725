import React, { useEffect } from 'react';
import { Button, Modal, message } from 'antd';

import styles from './authModal.module.css';
import { APP_CONFIG } from '../../config';

// interface AuthModalProps {
	
// }

export const AuthModal: React.FunctionComponent<any> = (props) => {

	const receiveMessage = (event:any) => {
		if(event?.data && event.data.token){
			const token = event.data.token;

			// STORE TOKEN IN LOCAL STORAGE
			localStorage.setItem('token',token);
			window.location.reload();
		}else if(event?.data?.error){
			message.error(event.data.error);		
		}else if(event?.data?.msg){
			message.info(event.data.msg);
		}
	};

	useEffect(() => {
		window.addEventListener('message', receiveMessage);

		return () => {
			window.removeEventListener('message', receiveMessage);
		};
	},[]);

	return (
		<div>
			<Modal
				title="Authenticate" open={true}
				footer={[]}
				closable={false}
			>
				<div className={styles.iframeContainer}>
					<iframe src={`${APP_CONFIG.AuthServerURL}/auth/login?url=${window.location.href}`} title="Authenticate"></iframe>
				</div>
			</Modal>
		</div>
	);
};
