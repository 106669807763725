import React, { useEffect, useState, useRef, MutableRefObject, useCallback } from 'react';
import { LeadStatusGrap, LeadStatusType, LocalLeadStatusGraphInterface } from '../../@types/report';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';
import { ChartRefConfig } from '@ant-design/charts';
import { ReportState } from '../../@types/store/report';
import { useReportStore } from '../../stores';
import { ReportGraphConstants } from '../../config/report';
interface callerStatusBarGraphProp{
	data: LeadStatusType;
	labelSize?: number;
	setSelectedGraph: any;
}

export const CallerStatusBarGraph = (props: callerStatusBarGraphProp) =>{
	const { data, labelSize, setSelectedGraph } = props;

	const [localData, setLocalData] = useState<Array<LocalLeadStatusGraphInterface>>([]);
	const [maxValue, setMaxValue] = useState<number>(0);

	const chartRef = useRef<any>();


	useEffect(() => {
		const handleClick = (ev: any) => {
			setSelectedGraph({
				type: ReportGraphConstants.leadStatus.value,
				value: ev?.data?.data,
				title: ReportGraphConstants.leadStatus.label,
			});
		};
		chartRef.current.on('element:click',handleClick);

		return () => {
			chartRef.current.off('element:click',handleClick);
		};
	},[]);

	useEffect(()=>{
		if(data){
			let max = 0;
			setLocalData((data ?? []).map( (element) => {
				max = Math.max(max, parseInt(element.count as string));
				return {
					count: parseInt(element.count as string),
					label: `${element.statusLabel} (${element.stageLabel})`,
					status: element.status,
					statusLabel: element.statusLabel,
					stageLabel: element.stageLabel,
				};
			}));
			setMaxValue(max);
		}
	},[data]);


	const config = {
		minBarWidth: 30,
		maxBarWidth: 30,
		xAxis:{
			tickMethod: (()=>{
				if(maxValue <= 5){
					return 'cat';
				}else {
					return 'wilkinson-extended';
				}
			})(),
		},
		yAxis:{
			label:{
				style:{fontSize: labelSize}
			}
		}
	  };
	return <Bar chartRef={chartRef} loading={data===undefined} data={localData} xField='count' yField='label' barWidthRatio={0.5} scrollbar={{type:'vertical'}} {...config} />;
};