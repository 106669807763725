import React, { useEffect, useRef, useState } from 'react';
import { Select, Segmented,theme, Button } from 'antd';
import styles from './ruleEngineIfModel.module.css';
const { useToken } = theme;
import { v4 as uuid } from 'uuid';

import { DisplayElements } from './subModule-sectionDisplayElement';

export const RuleEngineIfModel = (props: any) => {
	const { token } = useToken();
	const { values, reRender, renderElement, options } = props;
	const [condition, setCondition] = useState<string>((Object.keys(values)[0])==='any'?'OR':'AND');
	const [initalLoad, setInitalLoad] = useState<boolean>(true);
	useEffect(()=>{
		if(initalLoad){
			setInitalLoad(false);
			return ;
		}
		if(condition==='AND'){
			values.all = values.any;
			delete values.any;
		} else {
			values.any = values.all;
			delete values.all;
		}
		reRender();
	},[condition]);

	const children = useRef<Array<any>>(values.all??values.any);

	const addNewCondition = ()=>{
		children.current?.push({
			all:[
				{
					fact: undefined,
					operator: undefined,
					value: undefined,
					valueLabel: undefined,
				}
			]
		});
		reRender();
	};

	const removeElement = (index: any) => {
		  if (children.current && children.current.length > 0) {
			children.current.splice(index, 1);
			reRender();
		  }
	};
	  

	return (
		<div className={styles.tabChild}>
			<div className={styles.mainBody}>
				<div className={styles.segementContainer}>
					<Button type="primary" onClick={addNewCondition}>Add Condition</Button>
					<Segmented options={['AND','OR']} value={condition} onChange={(ev)=>setCondition(ev as string)} style={{background:token.colorPrimary}}/>
				</div>
				{children.current && children.current?.map((element,index)=>{
					return (
						<>
							{(index !== 0 ) && <div className={styles.tabOuter}><Button className={styles.tab} type='primary'>{condition.toUpperCase()}</Button></div>}
							<DisplayElements key={uuid()} values={children.current && children.current[index]} reRender={reRender} 
								removeFunction={()=>removeElement(index)} options={options}
							/>
						</>
					);
				})}
			</div>
		</div>
	);
};
