import React, { useEffect, useState,createContext, useRef, useCallback, ChangeEvent } from 'react';
import { Header } from '../../components/header';
import { useAppStore } from '../../stores';
import styles from './rule-engine.module.css';
import { Switch, Table, FloatButton, message, Button, Space, Tooltip, Popconfirm } from 'antd';
import { CommentOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined, InfoOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { RuleEngineModel } from '../../components/ruleEngineModel';
import { ruleInterface } from '../../@types/ruleEngine';
import { Dayjs } from 'dayjs';

import dayjs from 'dayjs';

import { useModelConstructor } from '../../hook/useEngineModel';
import { v4 as uuid } from 'uuid';
import { SearchField } from '../../components/searchField';


const appStoreSelector = (state: AppState) => ({
	sessionData: state.sessionData,
	logoutUser: state.logoutUser,
	getRuleList: state.getRuleList,
	postRule: state.postRule,
	changeRuleVisibility: state.changeRuleState,
	getRuleEngineConditions: state.getRuleEngineConditions,
	getSingleRule: state.getSingleRule,
	deleteRuleCall: state.deleteRule,
	editRuleCall: state.editRule,
	getDataFromDynamicLink: state.getDataFromDynamicLink,
	setAppLoading: state.setAppLoading,
	getUserDetails: state.getUserDetails,
	checkEditRulePermission: state.checkEditRulePermission,
	runRuleEngine: state.runRuleEngine,
});

interface ContextInterface{
	conditions: any,
	reverseConditions: any,
	operations: any,
	refData: any,
	fetchDataForDynamicLink: any,
}

interface fetchCacheInterface{
	[keys: string]: any
}

interface userDetails {
	[key: string]: {
		name: string,
		email: string,
		id: string,
	}
}

//CONTEXTPROVIDE FOR CONDITIONS
export const ConditionContext = createContext<ContextInterface>({conditions: null, reverseConditions: null, operations: null, refData: null,fetchDataForDynamicLink: null});

export const RuleEngine = () => {

	const { sessionData, logoutUser, getRuleList, getRuleEngineConditions,
		changeRuleVisibility, postRule,setAppLoading,
		deleteRuleCall, editRuleCall, getDataFromDynamicLink, getUserDetails,
		checkEditRulePermission,runRuleEngine
	 } = useAppStore(appStoreSelector);

	useEffect(() => {
		document.title = 'BusinessRule | CodeQuotient';
	},[]);

	//ModelState
	const [data, setData] = useState<Array<ruleInterface>>([]);
	const [previewShow, setPreviewShow ] = useState<boolean>(false);
	const [selectedId, setSelectedId] = useState<string | null>(null);

	const [conditions, setConditions] = useState<any>(null);
	const [reverseConditions, setReverseConditions] = useState<any>(null);
	const [operations, setOperations] = useState<any>(null);
	const [reverseOperations, setReverseOperation] = useState<any>(null);
	const [refData, setRefData] = useState<any>(null);
	const [userList, setUserList] = useState<any>({});
	const [searchValue, setSearchValue] = useState<string>('');
	const [dataToShowOnTable, setDataToShowOnTable] = useState<any>([]);

	const fetchCache = useRef<fetchCacheInterface>({});

	useEffect(() => {
		console.log(data, searchValue);
		setDataToShowOnTable(data.filter((element: any) => {
			return ((element?.name ?? '') as string).toLowerCase().includes(searchValue.toLowerCase());
		}));
	}, [searchValue, data]);

	const fetchDataForDynamicLink = useCallback(
		async (url: string) => {
			if(fetchCache.current[url]){
				return (fetchCache.current[url]);
			} else {
				const data = await getDataFromDynamicLink(url);
				if(data.error){
					throw data.error;
				}
				fetchCache.current[url] = data;
				return data;
			}
		}
		,[]);

	//MODEL
	const [ model, setModel, error, showModel, setShowModel ] = useModelConstructor(conditions,
		reverseConditions,
		refData,
		fetchDataForDynamicLink
	);

	useEffect(()=>{
		if(error){
			message.error(error);
		}
	},[error]);

	useEffect(() => {
		if(!showModel) {
			setPreviewShow(false);
		}
	},[showModel]);

	const onSearchInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(ev.target.value);
	};

	const validateobject = useCallback((object: any) => {
		if(!object || !object.conditions || !object.event?.params) {
			throw new Error('Invalid Object.');
		}
		if(!object.name) {
			throw new Error('Name is invalid.');
		}
		// Validate Conditions
		const conditions = object.conditions;
		if( !(conditions.all || conditions.any) && (conditions.length !== 1) ){
			throw new Error('Invalid Conditions');
		}
		const innerArray = conditions.any ?? conditions.all;

		innerArray.forEach((element:any) => {
			console.log(element);
			if (!(element.all || element.any)) {
				throw new Error('Invalid rule.');
			}
			const singleCondition = element.all ?? element.any;
			singleCondition.forEach((element: any) => {
				if(!element.fact || !element.operator || !element.value){
					throw new Error('Incomplete Condition.');
				}
			});
		});

		//Validate Then Condition
		const thenCondition = object.event.params;
		if( !thenCondition || Object.keys(thenCondition).length === 0 ){
			throw new Error('Then condition is not valid.');
		}
	},[]);
	
	const handlePreviewShow = useCallback((toShow: boolean, object: any) => {
		try{
			if(toShow) {
				validateobject(object);
				setPreviewShow(true);
				return ;
			}
			setPreviewShow(false);
		} catch (error: any) {
			message.error(error?.message ?? error);
		}
	},[validateobject,setPreviewShow ]);

	useEffect(()=>{
		(async ()=>{
			try{
				const data = await getRuleList();
				if(!data){
					return ;
				}
				if(data?.error){
					throw data?.error;
				}
				const idsToGet = (data ?? []).map((element: any) => element.created_by);
				const userData = await getUserDetails(idsToGet);
				if(data.error || userData.error) {
					throw data?.error ?? userData?.error;
				} else {
					console.log(data);
					if(data.length){
						setData(data);
					}
					setUserList(userData);
				}
			} catch (error: any) {
				message.error(error?.message ?? error);
			}
		})();
		(async ()=>{
			try{
				const data = await getRuleEngineConditions();
				if(!data) {
					return ;
				}
				if(data.error){
					throw data.error;
				}
				const reverseOperation = Object.values(data?.operations?.operators).reduce((result: any, element: any) => {
					result[element.ruleEngineString] = element;
					return result;
				},{});
				if(data.operations)	data.operations.reverseOperation = reverseOperation;
				setOperations(data?.operations);
				setConditions(data?.triggers?.leads?.triggers);
				setReverseOperation(reverseOperation);
				const conditions = data?.triggers?.leads?.triggers;
				const reverseConditions = Object.keys(conditions).reduce((result: any,element: any) => {
					result[conditions[element].dataIndex] = {
						fact: element,
						label: conditions[element].label,
						type: conditions[element].type
					};
					return result;
				},{});
				setReverseConditions(reverseConditions);
				setRefData(data);
			} catch (error: any) {
				message.error(error?.message ?? error);
			}
		})();
	},[]);

	const changeRuleState = useCallback((checked: boolean, ev: any, value: any, index: number) => {
		(async () => {
			try{
				const result = await changeRuleVisibility({ruleId:value, state: checked});
				if(result.error){
					throw result.error;
				}
				setData(data.map((element: any, localIndex: number) => {
					if (localIndex === index) {
						element.loading = false;
						element.status = !element.status;
					}
					return element;
				}));
			} catch (error: any) {
				setData(data.map((element: any, localIndex: number) => {
					if (localIndex === index) {
						element.loading = false;
					}
					return element;
				}));
				message.error(error?.message ?? error);
			}
		})();
		setData(data.map((element: any,localIndex: number)=>{
			if (localIndex === index) {
				element.loading = true;
			}
			return element;
		}));
	},[data, changeRuleVisibility]);

	const editRule = async (record: any) => {
		try{
			setAppLoading(true);
			const response = await checkEditRulePermission(record.id);
			if(response.error){
				throw response.error;
			}
			setSelectedId(record.id);
			setModel(record);
			setShowModel(true);
			setAppLoading(false);
		} catch (error: any) {
			message.error(error?.message ?? error);
			setAppLoading(false);
		}
	};

	const previewRule = async (record: any) => {
		try{
			setAppLoading(true);
			const response = await checkEditRulePermission(record.id);
			if(response.error){
				throw response.error;
			}
			setSelectedId(record.id);
			setModel(record);
			setPreviewShow(true);
			setShowModel(true);
			setAppLoading(false);
		} catch (error: any) {
			message.error(error?.message ?? error);
			setAppLoading(false);
		}
	};

	const processData = (object: any) => {
		const name = object.name;
		delete object.name;
		let date: Dayjs | null = dayjs(object.date);
		if(date && !date.isValid()){
			date = null;
		}
		delete object.date;
		delete object.communicationModel;
		
		const ruleConditions = object.conditions;
		const innerArray = ruleConditions.any ?? ruleConditions.all;

		innerArray.forEach((element:any) => {
			if (!(element.all || element.any)) {
				throw new Error('Invalid rule.');
			}
			const singleCondition = element.all ?? element.any;
			singleCondition.forEach((element: any) => {
				element.fact = conditions[element.fact].dataIndex;
				delete element.contentType;
				delete element.key;
				delete element.valueLabel;
			});
		});
		
		const thenCondition = object.event.params;

		
		Object.keys(thenCondition).forEach((element: string)=>{
			console.log(thenCondition[element]);
			thenCondition[conditions[element].dataIndex] = thenCondition[element].value;
			if(element !== conditions[element].dataIndex){
				delete thenCondition[element];
			}
		});

		object.event.type = name.replace(/(\S)\s+(\S)/g, '$1-$2') ?? uuid();
		return [name, date, object];
	};

	const saveRule = async (object: any, id: any) => {
		try{
			validateobject(object);
			let result: any;
			const [name, date, rule] = processData(structuredClone(object));

			if(id) {
				result = await editRuleCall({
					ruleId: selectedId,
					name: name,
					rule: rule,
					time: date,

				});
				if(result.error){
					throw result.error;
				}
				setData(data.map((element:any) => {
					if(element.id === id){
						return result;
					}
					return element;
				}));
			} else {
				result = await postRule({name,rule, id, time: date });
				if(result.error){
					throw result.error;
				}
				setData([...data, result]);
			}

			if(!userList[result.created_by]) {
				const data = await getUserDetails([result.created_by]);
				setUserList({...userList, ...data});
			}
			
			message.success('Rule Created Successfully');
			setModel(null);
			setShowModel(false);
			setSelectedId(null);
		} catch (error: any) {
			message.error(error?.message ?? error);
		}
	};

	const deleteRule = async (id:string)=> {
		try{
			const response = await deleteRuleCall(id);
			if(response.error) {
				throw new response.error;
			}
			setData(data.filter((element)=>{
				if (element.id === id){
					return false;
				}
				return true;
			}));
			message.success('Rule Deleted Successfully');
		} catch (error: any) {
			message.error(error?.message ?? error);
		}
	};

	const handleRunEngine = async () => {
		try {
			const response = await runRuleEngine();
			if(response.msg) {
				message.success(response.msg);
			}else if(response.error) {
				message.error(response.error);
			}
		} catch (error: any) {
			message.error(error?.message ?? error);
		}
	};

	const Columns: any = [
		{
			title: 'Description',
			dataIndex: 'name',
			key: 'name',
		},
		{
			title: 'Created By',
			dataIndex: 'created_by',
			key: 'created_by',
			render: (created_by: string) => {
				if(!userList[created_by]){
					return <p>Loading</p>;
				} else {
					return <p>{userList[created_by].email}</p>;
				}
			}
		},
		{
			title: 'Created On',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (created_at: string) => {
				return <p>{dayjs(created_at).format('YYYY-MM-DD HH:mm:ss')}</p>;
			}
		},
		{
			title: 'Start Time',
			dataIndex: 'time',
			key: 'time',
			render: (start_date: string) => {
				return <p>{ start_date && dayjs(start_date).format('YYYY-MM-DD HH:mm:ss')}</p>;
			}
		},
		{
			title:'Active',
			dataIndex: 'status',
			key:'status',
			render: (status: boolean, value: ruleInterface,index: number) => {
				return (
					<Switch loading={value?.loading ?? false} onClick={(state,ev)=>changeRuleState(state,ev,value.id,index)} checked={status}/>
				);
			}
		},
		{
			dataIndex: -1,
	  		title: 'Actions',
			key: 'actions',
			fixed: 'right',
			width: 100,
	    	render: ( _: any, record: any ) => { 
				return (
					<Space size="small">
						<Tooltip title='Edit'>
							<div className={styles.usersListingAction} onClick={()=>editRule(record)}>
								<EditOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
						<Tooltip title='Preview'>
							<div className={styles.usersListingAction} onClick={()=>previewRule(record)}>
								<InfoOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
						<Tooltip title="Delete">
							<Popconfirm
								title="Delete the rule"
								description="Are you sure to delete this rule?"
								okText="Yes"
								cancelText="No"
								onConfirm={()=>deleteRule(record.id)}
  							>
								<div className={styles.usersListingAction} >
									<DeleteOutlined style={{opacity: '0.7'}}/>
								</div>
							</Popconfirm>
						</Tooltip>
					</Space>
				);
			}
	  	}
	];
	
	return (
		<>
			<Header
				sessionData={sessionData}
				logoutUser={logoutUser}
			/>
			{showModel &&
				<ConditionContext.Provider value={{conditions,reverseConditions,operations,refData, fetchDataForDynamicLink}}>
					<RuleEngineModel
						previewShow={previewShow}
						setPreviewShow={handlePreviewShow}
						value={model}
						save={saveRule}
						closeModel={()=>{ 
							setShowModel(false);
							setModel(null);
							setSelectedId(null);
						}}
						id={selectedId}
					/>
				</ConditionContext.Provider>
			}
			<div className={styles.mainBody}>
				<div className={styles.headingHolder}>
					<h1 className={styles.mainHeading} >BUSINESS RULE</h1>
					<SearchField 
						onSearchInputChange={onSearchInputChange} 
						isAddLeadModal={false}
						placeholder='Search in rule'
					/>
					{sessionData?.isAdmin &&
						<Button onClick={handleRunEngine} type='primary'>Run Rule</Button>
					}
				</div>
				<Table
					dataSource={dataToShowOnTable}
					scroll={{x: 1000, y: 100} }
					columns={Columns}
					size="small" 
					bordered
					pagination={{ 
						defaultPageSize: 20,
						showSizeChanger: true, 
						pageSizeOptions: ['20', '50', '100'],
						position: ['bottomCenter'],
					}}
				/>
			</div>
			{showModel
				?
				<FloatButton onClick={()=>{ 
					setSelectedId(null);
					setShowModel(false);
					setModel(null);
				}} type='primary' className='floatButton' icon={ <MinusOutlined style={{ pointerEvents: 'none'}}/> }/>
				:
				<FloatButton onClick={()=>setShowModel(true)} type='primary' className='floatButton' icon={ <PlusOutlined style={{ pointerEvents: 'none' }} />}/>
			}
		</>
	);
};