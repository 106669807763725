import React, { useState, Dispatch, SetStateAction, useEffect,useRef } from 'react';
import type { InputRef } from 'antd';
import { 
	Button, 
	Drawer, 
	Input,  
	message,
	Form,
	Select,
	Space,
	Switch,
} from 'antd';
import styles from './leadDetailsModal.module.css';
import { formFieldTypes } from '../../config';
import { LeadSeekers } from '../../config/constants';
import { convertSnakeToCamelCase } from '../../libs/utils';

const {Option} =Select;

interface LeadDetalisModalprops{
	leadDetailModalOpen: boolean,
	setLeadDetailModalOpen: Dispatch<SetStateAction<boolean>>,
	onleadDetailModalClose: () => void,
	handleCreateOneLead: ( data: any ) => Promise<void>,
	isEdit: boolean,
	oneLeadData: any,
	handleUpdateOneLead: ( data: any ) => Promise<void>
	leadListData: any;
	setDetailModalBtnLoading: any
	detailModalBtnLoading: boolean
	leadDetailModalSubmitBtnRef: any
	sourceOptions: any
}

export const LeadDetalisModal: React.FunctionComponent<LeadDetalisModalprops> = ( props ) => {
	const {
		leadDetailModalOpen, 
		setLeadDetailModalOpen, 
		onleadDetailModalClose,
		handleCreateOneLead,
		isEdit,
		oneLeadData,
		handleUpdateOneLead,
		setDetailModalBtnLoading,
		detailModalBtnLoading,
		leadDetailModalSubmitBtnRef,
		sourceOptions
	} = props;

	const [createNewLeadForm] = Form.useForm();
	const leadNameFieldRef = useRef<InputRef>(null);
	const leadEmailFieldRef = useRef<InputRef>(null);
	const leadNumberFieldRef = useRef<InputRef>(null);
	const dynamicFieldTypeRef = useRef<InputRef>(null);

	// for now by default we need only college fields in drawer
	const [defaultFields, setDefaultFields] = useState<any>( LeadSeekers.College.fields );  
	const handleDefaultFields = ( value: string ) => {
		setDefaultFields( LeadSeekers[value].fields );
	};

	const [dynamicFields, setDynamicFields] = useState<any>( {} );
	const [dynamicFieldTitle, setDynamicFieldTitle] = useState<string>('');
	const [dynamicFieldType, setDynamicFieldType] = useState<any>(null);
	const [showAddOptionBox, setShowAddOptionBox] = useState<boolean>(false);
	const [dynamicOptions, setDynamicOptions] = useState<string[]>([]);
	const [showMandatoryFields, setShowMandatoryFields] = useState<boolean>(false);

	// useEffect(() => {
	// 	console.log(leadListData);
	//  }, [leadListData]);

	// setting the type of the dynamic field 
	const onFieldTypeChange = (type: any) => {
		// checking if type is dropdown then shown options box
		if(type === 3 || type === 6) {
			setShowAddOptionBox(true);
		} else {
			setShowAddOptionBox(false);
		}
		setDynamicFieldType(type);
	};

	// setting the options for dynamic dropdown field
	const onDynamicOptChange = (value: string) =>{
		setDynamicOptions(value.toString().split(','));
	};

	// adding the dynamic fields
	const addDynamicField = () => {
		if(!dynamicFieldTitle){
			message.error('Please Enter Dynamic Fields Title');
			dynamicFieldTypeRef.current?.focus();
			return;
		}

		if(!dynamicFieldType){
			message.error('Please Select Dynamic Fields Type');
			return;
		}

		const newDefaultFields: any = {};
		const newDefaultFieldsKey = dynamicFieldTitle.replace( / /g,'' );

		// if dynamic field type is dropdown then set its option
		if(dynamicFieldType === 3 || dynamicFieldType === 6) {
			const options: any = {};
			dynamicOptions.forEach((option) => {
				options[option] = {
					value: option, label: option
				};
			});
			// add in the newDefaultFields as same as in contants file
			newDefaultFields[newDefaultFieldsKey] = {
				title: dynamicFieldTitle,
				type: dynamicFieldType,
				values: { ...options }
			};
		} else {
			newDefaultFields[newDefaultFieldsKey] = {
				title: dynamicFieldTitle,
				type: dynamicFieldType
			};
		}

		setDynamicFields({ ...dynamicFields, ...newDefaultFields });
		setDefaultFields({ ...defaultFields, ...newDefaultFields});
		setDynamicFieldTitle('');
	};

	// reset the drawer while closing
	const resetDrawerOnClose = () => { 
		createNewLeadForm.resetFields();
		onFieldTypeChange(null);
		setDynamicFieldTitle('');
		document.body.querySelector('.createNewLeadDrawer .ant-drawer-body')?.scrollTo(9999, 0);
		setDefaultFields( LeadSeekers.College.fields );
	};

	// reset the drawer after lead created or edited
	const resetDrawer = () => { 
		resetDrawerOnClose(); 
		setDynamicFields({});
		setLeadDetailModalOpen( false );
	};

	// set the create new lead data as per the requirement of backend and then make request
	const addNewLead = ( values: any, dynamicFields: any ) => {
		// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		leadDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		
		let data: any = {
			email: values.email,
			number: values.number,
			name: values.name,
		};

		const dynamicFieldsVals: any = {};
		Object.keys( defaultFields ).map( ( field:any ) => {
			data[field] = values[field] ? values[field] : null;
		} );

		Object.entries(dynamicFields).forEach( (field: any) => {
			dynamicFieldsVals[field[0]] = field[1];
			dynamicFieldsVals[field[0]].response = values[field[0]];
		});

		data = { 
			...data,
			dynamicFields: {
				...dynamicFieldsVals
			}
		};

		// resetDrawer();
		handleCreateOneLead( data );
	};

	// updating dynamic fields responses
	const editLeadSubmit = (values: any, dynamicFields: any) => {
		// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		leadDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');

		Object.entries(values).forEach((value) => {
			if(!value[1]) {
				values[value[0]] = null;
			}
			if(dynamicFields[value[0]] !== undefined) {
				dynamicFields[value[0]].response = value[1];
			}
		});

		const data: any = { leadId: oneLeadData.id, ...values, dynamicFields};

		// resetDrawer();
		handleUpdateOneLead(data);
	};

	// Edit lead functionality, 
	// when user click on edit icon that particular lead data map into the drawer
	const setFieldsAndVals = () => {
		setDefaultFields({ ...defaultFields, ...oneLeadData.dynamic_fields});

		const remainingFieldsVals: any = {};

		Object.entries(oneLeadData).forEach((leadData)=> {
			// removing underScore and capital next char
			const newleadData = convertSnakeToCamelCase(leadData[0]);

			// while editing the one lead, 
			// for the dropdowns fields we are getting the labels(string) instead of value(int) from the oneLeadData
			// but we need the value(int) so that dropdowns get filled with data as of is in the table 

			// so for that we have made the new key value pair 
			// for eg for state dropdown field we make 'stateValue: value' in setLeadList function in listLead-screen 
			// and set these values in original keys which matches with formItem name
			if(newleadData.toLocaleLowerCase() === 'state'){
				remainingFieldsVals[newleadData] = oneLeadData?.stateValue;
			} else if(newleadData.toLocaleLowerCase() === 'prefstate'){
				remainingFieldsVals[newleadData] = oneLeadData?.prefStateValue ? oneLeadData?.prefStateValue : [];
			} else if(newleadData.toLocaleLowerCase() === 'source'){
				remainingFieldsVals[newleadData] = oneLeadData?.sourceValue;
			} else if(newleadData.toLocaleLowerCase() === 'testplanmonth'){
				remainingFieldsVals[newleadData] = oneLeadData?.testPlanMonthValue;
			} else{
				// for the all the fields instead of dropdown
				remainingFieldsVals[newleadData] = leadData[1];
			}

		});

		createNewLeadForm.setFieldsValue({
			...remainingFieldsVals
		});
	}; 

	const handleFocusOnError = () => {
		const errorFields = createNewLeadForm.getFieldsError();
		const firstErrorField = errorFields.find((field) => field.errors.length > 0);
		// console.log(errorFields);
	
		if (firstErrorField) {
		  const inputNode = createNewLeadForm.getFieldInstance(firstErrorField.name);
		  inputNode.focus();
		}
	};

	useEffect(() => {
		if( isEdit ){
			setFieldsAndVals();
		}

		if(!leadDetailModalOpen) {
			resetDrawer();
		}
	}, [isEdit, leadDetailModalOpen]);

	return (
		<div className={styles.createNewLeadDrawerContainer}>
			<Drawer
				title={(()=>{
					if(isEdit) return 'Edit Lead';
					else return 'Create New Lead';
				})()}
				width={450}
				onClose={() => {
					resetDrawerOnClose();
					onleadDetailModalClose();
				}}
				className="createNewLeadDrawer"
				open={leadDetailModalOpen}
				bodyStyle={{ paddingBottom: 80 }}
				extra={
					<Space>
						<Button 
							ref={leadDetailModalSubmitBtnRef}
							type="primary" 
							onClick={() =>{
								handleFocusOnError ();
								createNewLeadForm.submit();
							}}
							loading={detailModalBtnLoading}
						>
							{ isEdit ? 'Edit' : 'Create' }
					  	</Button>
					</Space>
				}
			>
				<div style={{display: 'flex', justifyContent: 'end', position: 'relative', bottom: '10px'}}>
					<div style={{display: 'flex', gap: '10px'}}><Switch onChange={(checked: boolean) => setShowMandatoryFields(checked)}/> Show mandatory fields</div>
				</div>
				<div className={styles.createNewLeadBody}>
					<Form
						form={createNewLeadForm}
						onFinish={
							(values) => {
								if(isEdit)	
									editLeadSubmit(
										values, { ...oneLeadData.dynamic_fields, ...dynamicFields }
									);
								else {
									addNewLead(values, dynamicFields);
								}
							}
						}
						onFinishFailed={
							({ values, errorFields, outOfDate }) => {
								errorFields.every((field) => {
									if(field.name[0] === 'name') {
										leadNameFieldRef.current?.focus();
										return false;
									} 

									if(field.name[0] === 'email') {
										leadEmailFieldRef.current?.focus();
										return false;
									} 

									if(field.name[0] === 'number') {
										leadNumberFieldRef.current?.focus();
										return false;
									} 
										
									return true;
								});
							}
						}
						layout="vertical"
					>
						<Form.Item 
							label='Default Fields' 
							name='Default_Fields' 
							style={{display: 'none'}}
						>
							<Select  
								// defaultValue={Object.keys(LeadSeekers)[0]}
								onChange={handleDefaultFields}
								options={ Object.entries( LeadSeekers ).map( ( key: any, index: number ) => (
									{ value: key[0], label: key[1].title, key: index }
								) ) }
							/>
						</Form.Item>
						<Form.Item
							label="Name"
							name="name"
							rules={
								[
									{ 
										required: true, 
										message: 'Please input your username!' 
									},
									{
										pattern: /^(?!\s)[a-zA-Z0-9-_. ]*$/,
										message: 'Not a valid name,remove special symbols or any initial spaces!',
									},
								]}
						>
							<Input 
								ref={leadNameFieldRef} 
								showCount maxLength={50} 
								placeholder={`Enter Lead's Name`} 
								disabled={isEdit ? true : false}
							/>
						</Form.Item>
						<Form.Item
							label="Number"
							name="number"
							rules={[
								{ 
									required: true, 
									message: 'Please input your number!' 
								},
								{
									pattern: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[7896]\d{9}$/,
									message: 'The input is not valid Number',
								},
							]}
						>
							<Input ref={leadNumberFieldRef} placeholder={`Enter Lead's Number`} disabled={isEdit ? true : false} />
						</Form.Item>
						{!showMandatoryFields ? 
							<>
								<Form.Item
									label="E-mail"
									name="email"
									rules={[
										{
											type: 'email',
											message: 'The input is not valid E-mail!',
										},
									]}
								>
									<Input ref={leadEmailFieldRef} placeholder={`Enter Lead's E-mail`} disabled={isEdit ? true : false} />
								</Form.Item>
								{
									Object.entries( defaultFields ).map( ( field: any ) => (
										(field[0] === 'currentStage' || field[0] === 'comment' || field[0] === 'currentlyAssignTo' || field[0] === 'currentStatus'
										|| field[0] === 'currentFollowUpDate' || field[0] === 'currentLeadAssignToDate' || field[0] === 'currentLastCalled') ? '' :
											<Form.Item 
												key={field[0]}
												label={field[1].title}
												name={field[0]}
												rules={[
													{
														pattern: ( ()=> {
															if( field[1].type === formFieldTypes['Year'] ){
																return new RegExp( /^\d{4}$/ );
															} else if(
																field[1].type === formFieldTypes['Phone Number']
															) {
																return new RegExp( 
																	/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im 
																);
															} else if (
																field[1].type === formFieldTypes['Number'] 
															) {
																return new RegExp( /^(?:\d{1,3}(?:\.\d{1,2})?)$/ );
															} else {
																return new RegExp( /.*/ );
															} 
														} )(),
														  message: `The input is not valid ${field[1].title}`,
													}
												]}
											>
												{( ()=> {
													if( field[1].type === formFieldTypes['Dropdown'] || field[1].type === formFieldTypes['MultiSelectDropdown']){
														return (
															<Select 
																mode={(field[1].type === formFieldTypes['MultiSelectDropdown']) ? 'multiple' : undefined }
																allowClear
																showSearch
																filterOption={(inputValue: any, option: any) =>
																	option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
																}
																placeholder={`Select ${field[1].title}`}>
																{ Object.entries( field[1].title ==='Source' ? sourceOptions : field[1].values ).map( 
																	( val: any ) => (
																		<Option 
																			key={val[0]}
																			value={!isNaN(val[0]) ? Number(val[0]) : val[0]} 
																		>
																			{val[1].label}
																		</Option>
																	) )}
															</Select>
														);
													} else if( field[1].type === formFieldTypes['Phone Number']){
														return ( <Input placeholder={`Enter Lead's ${field[1].title}`} /> );
													} else if( field[1].type === formFieldTypes['Number']){
														return (
															<Input placeholder={`Enter Lead's ${field[1].title}`} />
														);
													} else{
														return (
															<Input 
																showCount
																maxLength={
																	(field[1].type === formFieldTypes['Year']) ? 4 : 50
																}
																placeholder={`Enter Lead's ${field[1].title}`} 
															/>
														);
													}
												} )() }
											</Form.Item>
									) )
								}
							</> : ''
						}
						{/* <Form.Item 
							label = 'Add fields dynamically'
						>
							<div style={{display: 'grid', gap: '13px'}}>
								<div style={{display: 'flex', gap: '10px'}}>
									<Input
										showCount maxLength={50}
										placeholder='Enter Field Title'
										onChange={(e) => setDynamicFieldTitle(e.target.value)}
										value={dynamicFieldTitle}
										ref={dynamicFieldTypeRef}
									/>
									<Select 
										allowClear
										placeholder='Select Field Type'
										onChange={onFieldTypeChange}
										value={dynamicFieldType}
										options = {Object.keys(formFieldTypes).map((type: any, index: number) => (
											{
												value: formFieldTypes[type],
												label: type,
												key: index
											}

										))}
									/>
									<Button
										onClick={addDynamicField}
									>
										Add
									</Button>
								</div>
								{showAddOptionBox ? 
									<Select
										mode="tags"
										style={{ width: '100%' }}
										placeholder="Enter the Options for dropdown"
										onChange={onDynamicOptChange}
 								 	/> : ''
								}
							</div>
						</Form.Item> */}
					</Form>
				</div>
			</Drawer>
		</div>
	);
};