import React, { useState, useEffect } from 'react';
import './App.scoped.css';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider, Spin, message } from 'antd';
import { ReactComponent as Loader } from './assets/icons/loader.svg';
import { MainScreen } from './screens';
import { useAppStore } from './stores';
import { AuthModal } from './components/authModal';
import { WelcomeModal } from './components/welcomeModal';
import { PaymentScreen } from './screens/payment-screen';

import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import local from 'dayjs/plugin/updateLocale';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);
dayjs.extend(local);

interface colorsType {
	primaryColor: string
}

const appStoreSelector = (state: AppState) => ({
	initialConnectionEstablished: state.initialConnectionEstablished,
	sessionData: state.sessionData,
	welcome: state.welcome,
	loading: state.loading,
	loadingText: state.loadingText,
	init: state.init,
	getLeadStagesAndStatus: state.getLeadStagesAndStatus,
	setAppLoading: state.setAppLoading,
	listSources: state.listSources
});

export default function App(): JSX.Element {
	const [colors, setColors] = useState<colorsType>( {primaryColor: '#DE6834'} );
	const [isOpenRoute, setIsOpenRoute] = useState<boolean>(false);

	const {
		initialConnectionEstablished,
		sessionData,
		welcome,
		loading,
		loadingText,
		init,
		getLeadStagesAndStatus,
		setAppLoading,
		listSources
	} = useAppStore(appStoreSelector);

	useEffect(() => {
		(async () => {
			try {
				setAppLoading(true);
				await init();
				await getLeadStagesAndStatus();
				await listSources({});
			} catch (e: any) {
				console.log(e.message);
				if(e?.message?.toLowerCase()?.indexOf('token not found') !== -1){
					return;
				}
				message.error(e.message);
			} finally {
				setAppLoading(false);
			}
		})();

		if(window.location.pathname.split('/')[1] === 'paymentsummary') {
			setIsOpenRoute(true);
		}
	}, [init]);

	// console.log(initialConnectionEstablished);

	return (
		<Spin
			wrapperClassName="wrapper"
			spinning={loading}
			indicator={<Loader/>}
			size='large'
			style={{
				maxHeight:'100vh', 
				color:colors.primaryColor,
			}}
			tip={loadingText}
		>
			{/* only for payment */}
			{/* <Routes>
				<Route 
					path='/paymentsummary/:leadId'
					element={<PaymentScreen/>}
				/>
			</Routes> */}
			{/* only for payment */}
			<div>
				{initialConnectionEstablished && (
					sessionData ?
						<ConfigProvider
							theme={{
								token: { colorPrimary: colors.primaryColor },
							}}
						>
							<MainScreen />
						</ConfigProvider> 
						:<>
							{welcome ? (
								<WelcomeModal/>	
							) : (
								!isOpenRoute && (
									<AuthModal/>
								)
							)}
						</>
				)}
			</div>
		</Spin>
	);
}
