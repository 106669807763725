import axios, { AxiosInstance, Method } from 'axios';
import { UUID } from 'crypto';
// import TypedEventEmitter from 'typed-emitter';
import { LeadTrendSelectionType } from '../@types/report';

interface CQLeadsClientOptions {
	cqServerURL: string;
}

interface RequestHandlerParams {
	method:Method;
	url:string, params?:any;
	payload?:any;
	config?: any;
	headers?: any;
}

export class CQLeadsClient{
	private _cqAPI: AxiosInstance;
	private _requestHandler:any;
	private _sessionExpire: boolean;

	constructor( options: CQLeadsClientOptions ) {
		const { cqServerURL } = options;
	
		const cqAPI = axios.create( {
		  baseURL: cqServerURL,
		  responseType: 'json',
		  withCredentials: true,
		  headers: {
				'Authorization': localStorage.getItem('token'),
				'Content-Type': 'application/json' 
			},
		} );

		const requestHandler = async (
			{
				method, url, params, payload, config, headers,
			}:RequestHandlerParams
		// eslint-disable-next-line consistent-return
		):Promise<any> => {
			let isLogout = false;
			try {
				const response = await cqAPI({
					method,
					url,
					data:payload,
					params,
					...config,
					headers:headers,
				});

				if ( response?.data?.error ) {
					throw new Error( response?.data?.error );
				}
				// eslint-disable-next-line consistent-return
				return response;
			} catch (error:any) {
				if(error?.response?.status === 401) {
					isLogout = true;
					if(!this._sessionExpire){
						this._sessionExpire = true;
						setTimeout(() => {
							this._sessionExpire = false;
						}, 2000);
						throw new Error(error?.response?.data?.error || error.message);
					}
					return false;
				} else {
					throw new Error(error?.response?.data?.error || error.message);
				}
			}finally {
				if(isLogout ) {
					setTimeout(() => {
						localStorage.clear();
						if(url!=='/user/getsessiondata'){
							window.location.reload();
						}
					},2000);
				}
			}
		};
	
		this._requestHandler = requestHandler;
		this._cqAPI = cqAPI;
		this._sessionExpire = false;
	}

	getSessionData = async (): Promise<sessionData> => {

		try {
			const response = await this._requestHandler(
				{
					method:'GET',
					url:'/user/getsessiondata',
				}
			);
			return response?.data;
		} catch (error:any) {
			throw error;
		}
	};

	getLeadStagesAndStatus = async (): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'GET',
					url:'/status/getLeadStagesAndStatus',
				}
			);
			// console.log(response?.data);
			return response?.data;
		} catch (error:any) {
			throw error;
		}
	};

	logoutUser = async (): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/user/logoutUser',
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	welcomeUser = async (data:any ): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/welcomeUser',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	createOneLead = async ( data: any ): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/createOne',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	getLeadsList = async (data: paginationData): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/list',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	deleteOneLead = async ( leadId: string ): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/deleteOne',
					payload: {leadId}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	updateOneLead = async ( data: any ): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/updateOne',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getGroupsList = async (data : paginationData): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/listGroups',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	createOneGroup = async (data: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/createOne',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	deleteOneGroup = async (groupId: string): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/deleteOne',
					payload: {groupId}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	updateOneGroup = async ( data: any ): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/updateOne',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getLeadsOfGroup = async (data : grpLeadsPaginateData): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/getLeadsOfGroup',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getLeadsToAddInGroup = async (data : grpLeadsPaginateData): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/getLeadsToAddInGroup',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	addLeadsInGroup = async (data : any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/addLeadsInGroup',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getGroupActivity = async (groupId: string | undefined): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/getGroupActivity',
					payload: {groupId}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getManyLeads = async (leadIds: string[]): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/getManyLeads',
					payload: {leadIds}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	removeLeadFromGroup = async (leadIds: string[] | React.Key[], groupId: string | undefined): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/group/removeLeadFromGroup',
					payload: {leadIds, groupId}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getLeadActivity = async ( leadId: string | undefined ): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/getLeadActivity',
					payload: {leadId}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	bulkUploadLeads = async ( uploadFile: any ): Promise<any> => {
		// try {
		// 	const response = await this._cqAPI.post( `/lead/bulkUploadLeads`,
		// 		uploadFile,
		// 		{
		// 			headers: {
		// 				'content-type': 'multipart/form-data',
		// 			},
		// 		},
		// 	);
		// 	return response?.data;
		// } catch ( error: any ) {
		// 	return {error: error?.message};
		// }

		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/bulkUploadLeads',
					payload: uploadFile,
					headers: {
						'content-type': 'multipart/form-data',
					}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	createActivityBeforeDownload = async (uploadFile: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/download/createActivity',
					payload: uploadFile,
					headers: {
						'content-type': 'multipart/form-data',
					}
				}
			);

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	getBulkActivity = async (): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/getBulkActivity',
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	updateLeadStatus = async (data: UpdateLeadStatus): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/updateLeadStatus',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	updateAssignTo = async (data: UpdateAssignTo): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/updateAssignTo',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	unAssignLeads = async (data: UnassignLeads): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/unAssignLeads',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	updateFollowUpLastCalled = async (data: UpdateFollowUpLastCalled): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/lead/updateFollowUpLastCalled',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	createOneUser = async (data: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/user/create',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	editOneUser = async (data: editUserPayload): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/user/edit',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	deleteUser = async (deleteUserId: string): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/user/deleteUser',
					payload: {deleteUserId}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	getAllowedRolesOfCurrentRole = async (): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'GET',
					url:'/role/getAllowedRolesOfCurrentRole',
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getUserList = async (data : paginationData, filter?:any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/user/listUsers',
					payload: {
						data,
						filter,
					}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getAssignList = async (): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/user/getAssignList',
					payload: {}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getAllReportingManager = async (): Promise<any> => {
		try {
			const response = await this._requestHandler({
				method:'POST',
				url:'/user/getAllReportingManager',
				payload: {}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	getReports = async (): Promise<any> => {
		try {
			const response = await this._requestHandler({
				method:'POST',
				url:'/reports/getMainReports',
				payload: {}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	getPermissionData = async (data:any):Promise<any> => {
		try {
			const response = await this._requestHandler({
				method:'POST',
				url:'/reports/getMainReports',
				payload: {}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	setPermissionData = async (data:any):Promise<any> => {
		try {
			const response = await this._requestHandler({
				method:'POST',
				url:'/reports/getMainReports',
				payload: {}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	// getAllRoles = async ():Promise<any> => {
	// 	try {
	// 		const response = await this._requestHandler({
	// 			method:'GET',
	// 			url:'/role/getAllowedRolesOfCurrentRole',
	// 			payload: {}
	// 		});

	// 		return response?.data;
	// 	} catch (error) {
	// 		throw error;
	// 	}
	// };
	addNewRole = async (data:any):Promise<any> => {
		try {
			const response = await this._requestHandler({
				method:'POST',
				url:'/role/create',
				payload: {'name':data}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	getLeadComments = async (leadId: string | undefined): Promise<any> => {
		try {
			const response = await this._requestHandler({
				method:'POST',
				url:'/lead/getLeadComments',
				payload: {leadId}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	getUserActivity = async (userId: string | undefined, page: number): Promise<any> => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url: `/user/getUserActivity`,
				payload: {userId, page}
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	getCallerData = async (): Promise<any> => {
		try{
			const response = await this._requestHandler({
				method: 'GET',
				url: '/user/getAllCaller',
				payload: {}
			});
			return response?.data?.data;
		} catch (error) {
			throw error;
		}
	};
	getStatusList = async (data : paginationData): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/status/list',
					payload: {}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	createOneStatus = async (data: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/status/create',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	editOneStatus = async (data: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/status/edit',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	getLeadCount = async (startDate: string | undefined, endDate: string | undefined, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/reports/getLeadCount',
					payload: {
						startDate,
						endDate,
						callerId,
					}
				}
			);
			return response?.data;
		} catch (error: any) {
			throw error;
		}
	};
	getCardData = async (startDate: string | undefined, endDate: string | undefined, value: number, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url:'/reports/getCardData',
					payload:{
						startDate,
						endDate,
						status: value,
						callerId,
					}
				}
			);
			return response?.data?.data;
		} catch (error :any){
			throw new Error(error?.message);
		}
	};
	getLeadVsStatusCount = async (startDate: string | undefined, endDate: string | undefined, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/getLeadVsStatusCount',
					payload:{
						startDate,
						endDate,
						callerId,
					}
				}
			);
			return response?.data?.data;
		} catch (error :any){
			throw new Error(error?.message);
		}
	};
	getPaymentRecivedVsWorkedByCallerCount = async (startDate: string | undefined, endDate: string | undefined, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/getPaymentRecivedVsWorkedByCallerCount',
					payload:{
						startDate,
						endDate,
						callerId,
					}
				}
			);
			return response?.data;
		} catch (error :any){
			throw new Error(error?.message);
		}
	};
	getLeadVsSourceCount = async (startDate: string | undefined , endDate: string | undefined, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/getLeadVsSourceCount',
					payload:{
						startDate,
						endDate,
						callerId
					}
				}
			);
			return response?.data;
		} catch (error :any){
			throw new Error(error?.message);
		}
	};
	getUserCreatedDaily = async (startDate: string | undefined, endDate: string | undefined, selectionType: LeadTrendSelectionType, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/getUserCreatedDaily',
					payload:{
						startDate,
						endDate,
						selectionType,
						callerId,
					}
				}
			);
			return response?.data;
		} catch (error: any){
			throw new Error(error?.message);
		}
	};

	getEngagementData = async (startDate: string | undefined, endDate: string | undefined, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url: '/reports/getEngagementData',
				payload:{
					startDate,
					endDate,
					callerId
				}
			});
			return response?.data;
		} catch (error : any){
			throw new Error(error?.message);
		}
	};

	getLeadCountByCounselor = async (startDate: string | undefined, endDate: string | undefined, callerId: string | undefined): Promise<any> => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url: '/reports/getLeadCountByCounselor',
				payload: {
					startDate,
					endDate,
					callerId
				}
			});
			return response?.data;
		} catch (error : any){
			throw new Error(error?.message);
		}
	};
	listSources = async (data : paginationData | any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/source/list',
					payload: {}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	createSource = async (data: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/source/create',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	editSource = async (data: any): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/source/edit',
					payload: data
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};
	deleteSource = async (value: number): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/source/delete',
					payload: {value}
				}
			);
			return response?.data;
		} catch ( error: any ) {
			throw error;
		}
	};

	generatePaymentLink = async (amount:number, leadId: string): Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/payment/generatelink',
					payload: {
						amount,
						leadId,
					}
				}
			);

			return response?.data;
		} catch (error: any) {
			throw error;
		}
	};

	getPaymentDetails = async (leadId: string):Promise<any> => {
		try {
			const response = await this._requestHandler(
				{
					method:'POST',
					url:'/payment/getPaymentDetails',
					payload: {
						leadId,
					}
				}
			);

			return response?.data;
		} catch (error) {
			throw error;
		}
	};

	getDetailedDataForLeadStatus = async ( status: number | undefined, startDate: string | undefined, endDate: string | undefined, callerId: string | undefined ) => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/detailedReport/getDetailedDataForLeadStatus',
					payload:{
						status,
						startDate,
						endDate,
						callerId,
					}
				}
			);
			return response?.data?.data;
		} catch (error: any ){
			throw new Error(error?.message ?? error);
		}
	};
	getDetailedDataForLeadVsCaller = async ( callerId: string, startDate: string | undefined, endDate: string | undefined ) => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: `/reports/detailedReport/getDetailedDataForLeadVsCaller`,
					payload:{
						callerId,
						startDate,
						endDate,
					}
				}
			);
			return response?.data?.data;
		} catch (error: any){
			throw new Error(error?.message ?? error);
		}
	};
	getPymentRecieveVsWorkedOnLead = async ( callerId: string, status: number, startDate: string | undefined, endDate: string | undefined ) => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/detailedReport/getPymentRecieveVsWorkedOnLead',
					payload:{
						callerId,
						status,
						startDate,
						endDate,
					}
				}
			);
			return response?.data?.data;
		} catch (error: any){
			throw new Error(error?.message ?? error);
		}
	};
	getDetailedDataOfDailyLeadTrend = async ( date: string, selectionType: string,callerId: string | undefined ) => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/detailedReport/leadTrend',
					payload: {
						date,
						selectionType,
						callerId,
					}
				}
			);
			return response?.data?.data;
		} catch (error: any){
			throw new Error(error?.message ?? error);
		}
	};
	getDetailedCardData = async (status: number | undefined, startDate: string | undefined, endDate: string | undefined, callerId: string | undefined) => {
		try{
			const response = await this._requestHandler(
				{
					method: 'POST',
					url: '/reports/detailedReport/getCardData',
					payload: {
						status,
						startDate,
						endDate,
						callerId,
					}
				}
			);
			return response?.data?.data;
		} catch (error: any){
			throw new Error(error?.message ?? error);
		}
	};
	getDetailedSourceData = async (source: number | undefined, startDate: string | undefined, endDate: string | undefined, callerId: string | undefined) => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url: '/reports/detailedReport/getDetailedSourceData',
				payload:{
					source,
					startDate,
					endDate,
					callerId
				}
			});
			return response?.data?.data;
		} catch (error: any){
			throw new Error(error?.message ?? error);
		}
	};
	getDetailedEngagementData = async (value: number, date: string , callerId: string | undefined) => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url: '/reports/detailedReport/getEngagementData',
				payload:{
					value,
					date,
					callerId,
				}
			});
			return response?.data?.data;
		} catch (error: any){
			throw new Error(error?.message ?? error);
		}
	};

	getRuleList= async ():Promise<any> => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url: '/rule/list',
			});

			return response?.data?.data;
		} catch (error){
			throw error;
		}
	};

	postRule = async (payload: any ):Promise<any> => {
		try{
			const response = await this._requestHandler({
				method: 'POST',
				url:'/rule/create',
				payload,
			});
			return response?.data?.data;
		} catch (error) {
			throw error;
		}
	};

	changeRuleState = async (payload: any ):Promise<any> => {
		try{
			const response:any = await this._requestHandler({
				method: 'POST',
				url:'/rule/changeStateOfRule',
				payload,
			});
			return response?.data;
		} catch (error) {
			throw error;
		}
	};

	getRuleEngineConditions = async ():Promise<any> => {
		try{
			const response:any = await this._requestHandler({
				method: 'GET',
				url:'/rule/triggers'
			});
			return response?.data;
		} catch (error) {
			throw error;
		}
	};


	getSingleRule = async (id: string): Promise<any> => {
		try{
			const response: any = await this._requestHandler({
				method: 'POST',
				url: '/getSingleRule',
				payload:{
					id
				}
			});
			return response?.data?.data;
		} catch (error) {
			throw error;
		}
	};

	deleteRule = async (id: string): Promise<any> => {
		try{
			const response: any = await this._requestHandler({
				method: 'POST',
				url: '/rule/delete',
				payload: {
					ruleId: id
				}
			});
			return response?.data;
		} catch (error) {
			throw error;
		}
	};
	
	editRule = async (payload: any): Promise<any> => {
		try{
			const response: any = await this._requestHandler({
				method: 'POST',
				url:'/rule/edit',
				payload,
			});
			return response?.data?.data;
		} catch (error) {
			throw error;
		}
	};

	getDataFromDynamicLink = async (url: string): Promise<any> => {
		try{
			const response: any = await this._requestHandler({
				method:'GET',
				url: `${url}?isListing=true`,
			});
			console.log(response,'Assinged List');
			return response?.data?.data;
		} catch (error) {
			throw error;
		}
	};

	getUserDetails = async (ids: Array<UUID>): Promise<any> => {
		try{
			const response: any = await this._requestHandler({
				method: 'POST',
				url: `/user/getUserDetailsFromUserId`,
				payload:{
					ids,
				}
			});
			return response?.data?.data;
		} catch (error) {
			throw error;
		}
	};
	
	checkEditRulePermission = async (id: UUID): Promise<any> => {
		try{
			const response: any = await this._requestHandler({
				method: 'POST',
				url: `/rule/editPermission`,
				payload:{
					id
				}
			});
			return response?.data;
		} catch (error) {
			throw error;
		}
	};

	runRuleEngine = async (): Promise<any> => {
		try {
			const response: any = await this._requestHandler({
				method: 'GET',
				url: `/rule/runengine`,
			});

			return response?.data;
		} catch (error) {
			throw error;
		}
	};
}
