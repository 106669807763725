import React from 'react';
import styles from './leadsContentSubHeader.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import {Button} from 'antd';
import GroupIcon from '../../assets/img/group.png';
import { CaretLeftOutlined } from '@ant-design/icons';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';

interface SubHeaderProps {
	isGroup: boolean,
	isActivityPage: boolean,
	isLeadActivityPage: boolean,
	isBulkAvtivity: boolean,
	isLeadsCommentActivity: boolean
	groupDetails: groupDetails,
	leadDetails: any,
	isUserActivityPage?: boolean,
	userDetails?: any,
}

export const LeadsContentSubHeader: React.FunctionComponent<SubHeaderProps> = (props) => {

	const {
		isGroup,
		isActivityPage,
		isLeadActivityPage,
		isBulkAvtivity,
		isLeadsCommentActivity,
		groupDetails,
		leadDetails,
		isUserActivityPage = false,
		userDetails,
	} = props;

	// eslint-disable-next-line react-hooks/rules-of-hooks
	const navigate = useNavigate();
	const { id, leadId,userId } = useParams();
	return (
		<div className={styles.pageHeader}>
			<Button 
				className={styles.leftBox}
				type="link"
				onClick={() => (
					navigate(
						isGroup ? `/group/list` : (isUserActivityPage? `/users/list`:`/lead/list`),
						{ replace: false })
				)}
				icon={<CaretLeftOutlined style={{color: '#de6834'}}/>}
          	/>
			<div className={styles.middleBox}>
				<div className={styles.topBox}>
					<span className={styles.groupTitle}>
						{isGroup ? `${groupDetails?.title || ''} ` : ''}
						{isBulkAvtivity ? 'Bulk Add Lead ' : ''} 
						{isLeadActivityPage ? `${leadDetails?.name} ` : ''}
						{isLeadsCommentActivity ? `${leadDetails?.name || ''} Comment ` : ''}
						{isUserActivityPage ? `${userDetails[userId ?? 0 ]?.name || ''}  `: '' }
						{isActivityPage ? 'Activity' : 'Report'}
					</span>
				</div>
				<div className={styles.bottomBox}>
					{
						useCheckAttribute(resources.group.value, attributes.activity.value) &&
						(!isBulkAvtivity && !isLeadActivityPage && !isLeadsCommentActivity && !isUserActivityPage) ? 
							<Button 
								className={styles.batchTab}
								type="link"
								onClick={() => 
									navigate(`/group/groupContent/leads/${id}`, {replace: false})
								}
								style={(!isActivityPage) ? {fontWeight: 600, borderBottom: '3px solid #de6834'} : {}}
							> 
								Leads
							</Button> : ''
					}
					<Button 
						className={styles.batchTab}
						type="link"
						onClick={() => {
							if (isBulkAvtivity) return navigate(`/lead/bulkActivity`, {replace: false});
							else if (isLeadActivityPage) return navigate(`/lead/leadActivity/${leadId}`, {replace: false});
							else if (isLeadsCommentActivity) return navigate(`/lead/leadCommentActivity/${leadId}`, {replace: false});
							else if (isUserActivityPage)	return false;
							else return navigate(`/group/groupContent/activity/${id}`, {replace: false});
						}}
						style={isActivityPage ? {fontWeight: 600, borderBottom: '3px solid #de6834'} : {}}
          			> 
						Activity
					</Button>
				</div>
			</div>
			<div className={styles.rightBox}>
				<img src={GroupIcon} />
			</div>
		</div>
  	);
};
