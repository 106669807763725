import React, { useEffect, useState, useRef } from 'react';
import { useAppStore } from '../../stores';
import UserDeleted from '../../assets/img/users_deleted.svg';
import UserAdded from '../../assets/img/users_add.svg';
import StatusUpdated from '../../assets/img/status-update.png';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { LeadsContentSubHeader } from '../leadsContentSubHeader';
import { TimeLineComponent } from '../timeLineComponent';
import { activityType } from '../../config';
import { convertTime } from '../../libs/utils';
import { useOnScreen } from '../../hook';

const appStoreSelector = ( state: AppState ) => ( {
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	logoutUser: state.logoutUser,
	getLeadActivity: state.getLeadActivity,
	getManyLeads: state.getManyLeads,
	setAppLoading: state.setAppLoading,
	getUserActivity: state.getUserActivity,
	loading: state.loading,
} );

interface CallerTimeLineProps {
	userId: string| undefined,
}

export const CallerTimeLine = (props:CallerTimeLineProps) => {

	//PROPS
	const {userId} = props;

	//FROM STORE
	const {
		leadStatus,
		setAppLoading,
		getUserActivity,
		loading
	} = useAppStore(appStoreSelector);

	//THIS IS RESPOSE MAP CONTAINING ALL DATA ACCORDING TO THE ACTIVITY ID
	const responseMap = useRef<Map<string,any>>(new Map());
	const [activityData, setActivityData] = useState<any[]>([]);
	const [particulatActivityData, setParticulatActivityData] = useState<any>({});
	const [activityNumber, setActivityNumber] = useState<string>('');
	const [createdByDetails, setCreatedByDetails] = useState<any>({});
	const [leadDetails, setLeadDetails] = useState<any>({});
	const [page, setPage] = useState<any>(1);
	const [isMoreDataAvailable, setIsMoreDataAvailabe] = useState(false);
	const [isIntersecting, setRef] = useOnScreen();
	const [otherThenFirstLoad, setOtherThenFirstLoad] = useState(false);
	const [userIdChanged, setUserIdChange] = useState(false);
	const firstElement = useRef<HTMLSpanElement>();

	//RESET EVERY THING
	useEffect(()=>{
		setActivityData([]);
		responseMap.current = new Map<string,any>();
		setParticulatActivityData({});
		setActivityNumber('');
		setCreatedByDetails({});
		setLeadDetails({});
		setPage(1);
		setIsMoreDataAvailabe(false);
		setOtherThenFirstLoad(false);
		setUserIdChange(true);
	},[userId]);

	useEffect(() => {
		if(loading){
			return ;
		}
		let interval: string | number | NodeJS.Timeout | undefined;
		if( isIntersecting && isMoreDataAvailable && otherThenFirstLoad ){
			interval = setTimeout(()=>{
				setAppLoading(true);
				setPage(page+1);
			},150);
		}
		// eslint-disable-next-line consistent-return
		return () => {
			if(interval){
				clearInterval(interval);
			}
		};
	},[isIntersecting, isMoreDataAvailable, otherThenFirstLoad, loading]);

	const getLeadActivities = async (page: number) => {
		try{
			if(!userId){
				throw new Error('userId is null');
			}
			setAppLoading(true);

			const response = await getUserActivity(userId, page);

			if( response?.error ){
				message.error( response.error );
				return;
			}

			const leadData = { ...leadDetails, ...response?.data?.leadData };

			// const leadData = response?.data?.leadData;
			const userData = response?.data?.userData;
			setCreatedByDetails(userData);
			setLeadDetails(leadData);

			
			// const LocalResponseMap = structuredClone(responseMap);	

			const finalActivityData: any[] = [];
			let currentMonth = new Date().getMonth()+1;
			let currentYear = new Date().getFullYear();

			response?.data?.data.forEach((val: any) => {

				responseMap.current.set(val.id, val);
				const date = new Date(val.created_at);
				const prevMonth = date.getMonth(); 
				const prevYear = date.getFullYear();

				if(
					(prevMonth!==currentMonth && prevYear===currentYear) || 
					((prevMonth===currentMonth || prevMonth!==currentMonth) && prevYear!==currentYear)
				){
					finalActivityData.push({
						activityMonthYear: `${date.toLocaleString('default', {month: 'short'})} ${prevYear}`,
						dataOfSameMonthYear: []
					});
					currentMonth = prevMonth;
					currentYear = prevYear;
				}

				// eslint-disable-next-line max-len
				const createdAt = `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})}, ${date.getHours()>9 ? date.getHours() : '0'+date.getHours()}:${date.getMinutes()>9 ? date.getMinutes() : '0'+date.getMinutes()}`;
				if(val.activity_type === 2){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[2].label,
						iconType: UserDeleted,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						id: val.id
					});
				} else if(val.activity_type === 3){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[3].label,
						iconType: UserAdded,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						stage: leadStatus[val.status]?.leadActivityStage,
						status: leadStatus[val.status]?.label,
						id: val.id
					});
				} else if(val.activity_type === 4){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[4].label,
						iconType: UserAdded,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						id: val.id
					});
				} else if(val.activity_type === 5){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[5].label,
						groupId: val.group_id,
						iconType: UserAdded,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						groupName: val.title,
						id: val.id
					});
				} else if(val.activity_type === 6){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[6].label,
						groupId: val.group_id,
						iconType: UserDeleted,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						groupName: val.title,
						id: val.id
					});
				} else if(val.activity_type === 8){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[8].label : `${activityType[8].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						id: val.id,
						stage: leadStatus[val.status]?.leadActivityStage,
						status: leadStatus[val.status]?.label,
						comment: val.comment
					});
				} else if(val.activity_type === 9 && val.assign_to !== null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[11].label : `${activityType[11].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						assignToName: val.assign_to,
						comment: val.comment,
						id: val.id
					});
				} else if(val.activity_type === 9 && val.assign_to === null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[9].label : `${activityType[9].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						comment: val.comment,
						id: val.id
					});
				} else if(val.activity_type === 10){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[10].label : `${activityType[10].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						LeadName: [leadData[val.lead_id].name],
						followUpDate: `${convertTime(val.follow_up_date).split(' ')[0]},  ${convertTime(val.follow_up_date).split(' ')[1]}`,
						comment: val.comment,
						id: val.id
					});
				}
			});
			// setResponse(LocalResponseMap);
			const monthAvailable = new Map<string,number>();

			finalActivityData.forEach((element, index)=>{
				monthAvailable.set(element.activityMonthYear,index);
			});

			//ADD OLD DATA BACK
			for( let index = activityData.length-1; index >=0 ; --index){
				const element = activityData[index];
				const indexOfFinalActivityData = monthAvailable.get(element.activityMonthYear) ?? -1;
				if(indexOfFinalActivityData !== -1 ){
					finalActivityData[indexOfFinalActivityData].dataOfSameMonthYear = [ ...element.dataOfSameMonthYear, ...finalActivityData[indexOfFinalActivityData].dataOfSameMonthYear ];
				}else{
					finalActivityData.unshift(element);
				}
			}

			setActivityData(finalActivityData);
			setAppLoading(false);
			setIsMoreDataAvailabe( response?.data?.moreDataPresent );
			if(!otherThenFirstLoad){
				setTimeout(() => {
					if(firstElement.current){
						firstElement.current.click();
					}
					// else{
					// 	const element: HTMLElement = document.getElementsByClassName('timelineTitleBox')[0] as HTMLElement;
					// 	if(element){
					// 		element.click();
					// 	}
					// }
				}, 100);
			}
		} catch(e: any){
			setActivityData([]);
			setAppLoading(false);
		}
	};

	const onParticularActivityClick = async (activityNum: string, leadIds: string[], activityLabel: string, iconType: any, createdAt: string,
		created_by: string, alreadyLeadsPresent: any, duplicateLeads: any, notInsertedLeads: any, groupName: string, stage: string, status: string,
		assignToName: string, followUpDate: string, comment: string, amount: any) => {
		setActivityNumber(activityNum);

		const leadsData: leadsData = {
			name: leadDetails[responseMap.current.get(activityNum).lead_id].name,
			email: leadDetails[responseMap.current.get(activityNum).lead_id].email,
			createdAt: `${new Date(leadDetails[responseMap.current.get(activityNum).lead_id].created_at).toLocaleString('default', {month: 'short'})} 
            ${new Date(leadDetails[responseMap.current.get(activityNum).lead_id].created_at).getFullYear()}`,
			created_by: `${createdByDetails[created_by]?.name} (${createdByDetails[created_by].email})`,
			currentStage: leadStatus[leadDetails[responseMap.current.get(activityNum).lead_id].current_status]?.leadActivityStage,
			currentStatus: leadStatus[leadDetails[responseMap.current.get(activityNum).lead_id].current_status]?.label,
			groupName, stage, status, assignToName: createdByDetails[assignToName]?.name, followUpDate, comment, amount
		};

		setParticulatActivityData({
			leadsData, activityLabel, iconType
		});
	};

	//This is the function which updates all the data of this page.
	useEffect(() => {
		if(userId && !userIdChanged){
			getLeadActivities(page);
			setUserIdChange(false);
		}
		if(userIdChanged){
			setUserIdChange(false);
		}
	}, [page, userIdChanged, leadStatus]);

	//To check if it is first request or not.
	useEffect(()=>{
		if(activityData.length !== 0)	setOtherThenFirstLoad(true);
	},[activityData]);

	return (
		<>
			<LeadsContentSubHeader 
				// groupId= {id}
				isGroup = {false}
				isActivityPage = {true}
				isLeadActivityPage = {false}
				isUserActivityPage = {true}
				isBulkAvtivity = {false}
				isLeadsCommentActivity = {false}
				groupDetails={{}}
				leadDetails={leadDetails}
				userDetails={createdByDetails}
			/>
			<TimeLineComponent 
				activityData={activityData}
				particulatActivityData={particulatActivityData}
				activityNumber={activityNumber}
				onParticularActivityClick={onParticularActivityClick}
				isLeadActivityPage = {false}
				isBulkActivityPage = {false}
				isGroupActivityPage = {false}
				isLeadsCommentActivityPage = {false}
				isUserActivityPage = {true}
				lastElementRef = {setRef}
				firstElementRef = {firstElement}
			/>
		</>
	);		
};