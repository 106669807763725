const states: any = {
	13: { label: 'Haryana', value: 13 },
	29: { label: 'Punjab', value: 29 },
	1: { label: 'Andaman and Nicobar Islands', value: 1 },
	2: { label: 'Andhra Pradesh', value: 2 },
	3: { label: 'Arunachal Pradesh', value: 3 },
	4: { label: 'Assam', value: 4 },
	5: { label: 'Bihar', value: 5 },
	6: { label: 'Chandigarh', value: 6 },
	7: { label: 'Chhattisgarh', value: 7 },
	8: { label: 'Dadra and Nagar Haveli', value: 8 },
	9: { label: 'Daman and Diu', value: 9 },
	10: { label: 'Delhi', value: 10 },
	11: { label: 'Goa', value: 11 },
	12: { label: 'Gujarat', value: 12 },
	14: { label: 'Himachal Pradesh', value: 14 },
	15: { label: 'Jammu and Kashmir', value: 15 },
	16: { label: 'Jharkhand', value: 16 },
	17: { label: 'Karnataka', value: 17 },
	18: { label: 'Kerala', value: 18 },
	19: { label: 'Ladakh', value: 19 },
	20: { label: 'Lakshadweep', value: 20 },
	21: { label: 'Madhya Pradesh', value: 21 },
	22: { label: 'Maharashtra', value: 22 },
	23: { label: 'Manipur', value: 23 },
	24: { label: 'Meghalaya', value: 24 },
	25: { label: 'Mizoram', value: 25 },
	26: { label: 'Nagaland', value: 26 },
	27: { label: 'Odisha', value: 27 },
	28: { label: 'Puducherry', value: 28 },
	30: { label: 'Rajasthan', value: 30 },
	31: { label: 'Sikkim', value: 31 },
	32: { label: 'Tamil Nadu', value: 32 },
	33: { label: 'Telangana', value: 33 },
	34: { label: 'Tripura', value: 34 },
	35: { label: 'Uttar Pradesh', value: 35 },
	36: { label: 'Uttarakhand', value: 36 },
	37: { label: 'West Bengal', value: 37 },
};

export default states;