import { useAppStore } from '../../stores';

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
});

const useCheckAttribute = (resource:number, attribute:number) => {
	const { 
		sessionData, 
	} = useAppStore(appStoreSelector);

	const checkAttribute = () => {
		if(!sessionData?.permissions[resource]?.attributes.includes(attribute)){
			return false;
		}
		return true;
	};

	return checkAttribute();
};

export default useCheckAttribute;