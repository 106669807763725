import React, { useEffect, useState } from 'react';
import { useAppStore } from '../../stores';
import UserDeleted from '../../assets/img/users_deleted.svg';
import UserAdded from '../../assets/img/users_add.svg';
import StatusUpdated from '../../assets/img/status-update.png';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { LeadsContentSubHeader } from '../../components/leadsContentSubHeader';
import { Header } from '../../components/header';
import { TimeLineComponent } from '../../components/timeLineComponent';
import { activityType } from '../../config';
import { convertTime } from '../../libs/utils';

const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	sourceOptions: state.sourceOptions,
	logoutUser: state.logoutUser,
	getLeadActivity: state.getLeadActivity,
	getManyLeads: state.getManyLeads,
	setAppLoading: state.setAppLoading,
	listSources: state.listSources,
} );

export const LeadsActivity: React.FunctionComponent = () => {
	
	const {
		sessionData,
		leadStages,
		leadStatus,
		sourceOptions,
		logoutUser,
		getLeadActivity,
		getManyLeads,
		listSources,
		setAppLoading
	} = useAppStore(appStoreSelector);

	const [activityData, setActivityData] = useState<any[]>([]);
	const [particulatActivityData, setParticulatActivityData] = useState<any>({});
	const [activityNumber, setActivityNumber] = useState<string>('');
	const [createdByDetails, setCreatedByDetails] = useState<any>({});
	const [leadDetails, setLeadDetails] = useState<any>({});

	const { leadId } = useParams();

	const getLeadActivities = async () => {
		try{
			setAppLoading(true);
			const response = await getLeadActivity(leadId);

			if( response?.error ){
				message.error( response.error );
				return;
			}
			document.title = `${response?.data?.leadData?.name} | CodeQuotient`;
			setCreatedByDetails(response?.data?.usersData);
			setLeadDetails(response?.data?.leadData);

			// [
			// 	{
			// 		activityMonthYear: '',
			// 		dataOfSameMonthYear: [{
			// 			createdAt: '',
			// 			activityType: '',
			// 			totalData: '',
			// 			insertedLeadsId: []
			// 		}]
			// 	 }
			// ];

			const finalActivityData: any[] = [];
			let currentMonth = new Date().getMonth()+1;
			let currentYear = new Date().getFullYear();		

			response?.data?.data.forEach((val: any, index:number) => {
				// console.log(val.created_at);
				const date = new Date(val.created_at);
				const prevMonth = date.getMonth(); 
				const prevYear = date.getFullYear();

				if(
					(prevMonth!==currentMonth && prevYear===currentYear) || 
					((prevMonth===currentMonth || prevMonth!==currentMonth) && prevYear!==currentYear)
				){
					finalActivityData.push({
						activityMonthYear: `${date.toLocaleString('default', {month: 'short'})} ${prevYear}`,
						dataOfSameMonthYear: []
					});
					currentMonth = prevMonth;
					currentYear = prevYear;
				}

				// eslint-disable-next-line max-len
				const createdAt = `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})}, ${date.getHours()>9 ? date.getHours() : '0'+date.getHours()}:${date.getMinutes()>9 ? date.getMinutes() : '0'+date.getMinutes()}`;
				if(val.activity_type === 2){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[2].label,
						iconType: UserDeleted,
						LeadId: [val.lead_id],
						id: val.id
					});
				} else if(val.activity_type === 3){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[3].label,
						iconType: UserAdded,
						LeadId: [val.lead_id],
						stage: leadStatus[val.status]?.leadActivityStage,
						status: leadStatus[val.status]?.label,
						id: val.id
					});
				} else if(val.activity_type === 4){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[4].label,
						iconType: UserAdded,
						LeadId: [val.lead_id],
						id: val.id,
						ruleId: val.rule_id,
						updatedColumns: val.updated_columns,
					});
				} else if(val.activity_type === 5){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[5].label,
						groupId: val.group_id,
						iconType: UserAdded,
						LeadId: [val.lead_id],
						groupName: val.title,
						id: val.id
					});
				} else if(val.activity_type === 6){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[6].label,
						groupId: val.group_id,
						iconType: UserDeleted,
						LeadId: [val.lead_id],
						groupName: val.title,
						id: val.id
					});
				} else if(val.activity_type === 8){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[8].label : `${activityType[8].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						id: val.id,
						stage: leadStatus[val.status]?.leadActivityStage,
						status: leadStatus[val.status]?.label,
						comment: val.comment,
						ruleId: val.rule_id,
					});
				} else if(val.activity_type === 9 && val.assign_to !== null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[11].label : `${activityType[11].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						assignToName: val.assign_to,
						comment: val.comment,
						id: val.id,
						ruleId: val.rule_id,
					});
				} else if(val.activity_type === 9 && val.assign_to === null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[9].label : `${activityType[9].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						comment: val.comment,
						id: val.id,
						ruleId: val.rule_id,
					});
				} else if(val.activity_type === 10){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: (val.title === null) ? activityType[10].label : `${activityType[10].label} via ${val.title} group`,
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						followUpDate: val.follow_up_date && `${convertTime(val.follow_up_date).split(' ')[0]},  ${convertTime(val.follow_up_date).split(' ')[1]}`,
						comment: val.comment,
						id: val.id,
						lastCalled: val.last_called && `${convertTime(val.last_called).split(' ')[0]},  ${convertTime(val.last_called).split(' ')[1]}`,
					});
				}else if(val.activity_type === 11){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: '',
						activityType: val.activity_type,
						activityLabel: 'Payment link generated',
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						id: val.id,
						amount:val.amount,
						validity: val.link_validity,
						orderId: val.order_id,
						leadId,
					});
				}else if(val.activity_type === 12){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: '',
						activityType: val.activity_type,
						activityLabel: 'Payment Successful',
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						id: val.id,
						amount:val.amount,
						orderId: val.order_id,
					});
				}else if(val.activity_type === 13){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: '',
						activityType: val.activity_type,
						activityLabel: 'Payment Failed',
						iconType: StatusUpdated,
						LeadId: [val.lead_id],
						id: val.id,
						amount:val.amount,
						orderId: val.order_id,
					});
				}
			});
			setActivityData(finalActivityData);
			setTimeout(() => {
				const element: HTMLElement = document.getElementsByClassName('timelineTitleBox')[0] as HTMLElement;
				if(element){
					element.click();
				}
			}, 100);
		} catch(e: any){
			setActivityData([]);
		} finally {
			setAppLoading(false);
		}
	};

	const onParticularActivityClick = async (activityNum: string, leadIds: string[], activityLabel: string, iconType: any, createdAt: string,
		created_by: string, alreadyLeadsPresent: any, duplicateLeads: any, notInsertesdLeads: any, groupName: string, stage: string, status: string,
		assignToName: string, followUpDate: string, comment: string, amount: any, validity:any, orderId:any, leadId:string, lastCalled: string,
		ruleId: string, updatedColumns: any) => {
		setActivityNumber(activityNum);

		if(updatedColumns) {
			Object.keys(updatedColumns).forEach((element: string) => {
				if (element === 'source') {
					if(!isNaN(updatedColumns[element] )) updatedColumns[element] = sourceOptions[updatedColumns[element]]?.label ?? 'Deleted';
				}
			});
		}	

		const leadsData: leadsData = {
			name: leadDetails.name,
			email: leadDetails.email,
			createdAt: `${new Date(leadDetails.created_at).toLocaleString('default', {month: 'short'})} ${new Date(leadDetails.created_at).getFullYear()}`,
			created_by: createdByDetails[created_by]?.name,
			currentStage: leadStatus[leadDetails.current_status]?.leadActivityStage,
			currentStatus: leadStatus[leadDetails.current_status]?.label,
			groupName, stage, status, assignToName: createdByDetails[assignToName]?.name, followUpDate, comment, amount, validity,orderId,
			leadId,lastCalled,ruleId,updatedColumns,
		};

		// console.log(leadsData);
		setParticulatActivityData({
			leadsData, activityLabel, iconType
		});

	};

	useEffect(() => {
		if(leadStages.length) getLeadActivities();
	}, [leadStages]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<LeadsContentSubHeader 
				// groupId= {id}
				isGroup = {false}
				isActivityPage = {true}
				isLeadActivityPage = {true}
				isBulkAvtivity = {false}
				isLeadsCommentActivity = {false}
				groupDetails={{}}
				leadDetails={leadDetails}
			/>
			<TimeLineComponent 
				activityData={activityData}
				particulatActivityData={particulatActivityData}
				activityNumber={activityNumber}
				onParticularActivityClick={onParticularActivityClick}
				isLeadActivityPage = {true}
				isBulkActivityPage = {false}
				isGroupActivityPage = {false}
				isLeadsCommentActivityPage = {false}
			/>
		</>
	);		
};