import { message } from 'antd';
import React, { useEffect, useState } from 'react';

export const useModelConstructor = ( conditions: any, reverseConditions: any, refData: any, fetchDataForDynamicLink: any): 
[model: any, setModel: any, error: string, showModel: boolean, setShowModel: any] => {
	const [localModel, setLocalModel] = useState<any>();
	const [model, setModel] = useState<any>();
	const [error, setError] = useState<string>('');
	const [showModel, setShowModel] = useState<boolean>(false);
	const [innerModelShow, setInnerModelShow] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(()=>{
		if(showModel && !(loading && localModel) ) {
			setInnerModelShow(true);
			setLoading(true);
		}
		if (!showModel) {
			setInnerModelShow(false);
		}
	}),[showModel, loading];

	useEffect(()=>{
		if(!localModel){
			setModel({
				name:'',
				date:'',
				communicationModel: {},
				conditions:{
					//Here are if conditions 
					/**
                     * all | any : [array containg all|any: [{fact,operator,value}]]
                     */
					all: []
				},
				event:{
					type:'',
					params:{
						//HERE WILL COME THEN
					},
				}
			});
			setInnerModelShow(true);
		} else {
			const ruleClone = structuredClone(localModel.rule);
			ruleClone.name = localModel.name;
			ruleClone.date = localModel.time;
			constructShowableModel(ruleClone);
		}
	}, [localModel]);

	const constructShowableModel = async (ruleClone: any) => {
		try{
			const ruleConditions = ruleClone.conditions?.all ?? ruleClone.conditions?.any;

			//* Constructing CONDITION FOR RULE ENGINE
			for ( let i = 0; i< ruleConditions.length; ++i ) {
				const element = ruleConditions[i].all ?? ruleConditions[i].any;
				for(let j=0; j < element.length; ++j ){
					const innerElement = element[j] ;
					innerElement.fact = reverseConditions[innerElement.fact].fact;
					innerElement.contentType = conditions[innerElement.fact].type.value;
					if(innerElement.contentType === 3 ){
						if(conditions[innerElement.fact].url) {
							const data = await fetchDataForDynamicLink(conditions[innerElement.fact].url);
							innerElement.valueLabel = data[innerElement.value].label; 
						} else if(refData[innerElement.fact]){
							const data = refData[innerElement.fact];
							innerElement.valueLabel = data[innerElement.value].label;
						}
					}
				}
			}

			//* Constructing THEN CONDITION FOR RULE ENGINE
			const thenObject:any = {};
			const thenCondition = ruleClone.event.params;
			const thenConditionKey =Object.keys(thenCondition);

			for(let i = 0 ; i < thenConditionKey.length; ++i ) {
				const contentType = reverseConditions[thenConditionKey[i]].type?.value;
				const value = thenCondition[thenConditionKey[i]];
				const fact = reverseConditions[thenConditionKey[i]].fact;
				console.log(thenObject);
				let valueLabel;
				if(contentType === 3 ){
					if(conditions[fact].url) {
						const data = await fetchDataForDynamicLink(conditions[fact]?.url);
						valueLabel = data[value].label;
					} else if(refData[fact]){
						const data = refData[fact];
						valueLabel = data[value].label;
					}
				}
				thenObject[fact] = {
					contentType: contentType,
					value: value,
					valueLabel,
				};
			}

			ruleClone.event.params = thenObject;
			setModel(ruleClone);
			setLoading(false);
		} catch (error: any) {
			setError(error?.message ?? error);
		}
	};

	return [model, setLocalModel, error, innerModelShow, setShowModel];
};
