import React, { useCallback, useEffect } from 'react';
import { useAppStore, useReportStore } from '../../stores';

import { Header } from '../../components/header';

import {
	LeadTrendSelectionType
} from '../../@types/report';

const appStoreSelector = (state: AppState) => ({
	sessionData: state.sessionData,
	logoutUser: state.logoutUser,
	getLeadVsStatusCount: state.getLeadVsStatusCount,
	getPaymentRecivedVsWorkedByCallerCount: state.getPaymentRecivedVsWorkedByCallerCount,
	getLeadVsSourceCount: state.getLeadVsSourceCount,
	getUserCreatedDaily: state.getUserCreatedDaily,
	getEngagementData: state.getEngagementData,
	getLeadCountByCounselor: state.getLeadCountByCounselor,
	getAllCaller: state.getCallerData,
	getDetailedDataForLeadStatus: state.getDetailedDataForLeadStatus,
	getDetailedDataForLeadVsCaller: state.getDetailedDataForLeadVsCaller,
	getPymentRecieveVsWorkedOnLead: state.getPymentRecieveVsWorkedOnLead,
	getDetailedDataOfDailyLeadTrend: state.getDetailedDataOfDailyLeadTrend,
	getDetailedCardData: state.getDetailedCardData,
	getDetailedSourceData: state.getDetailedSourceData,
	getDetailedEngagementData: state.getDetailedEngagementData,

});

const reportStoreSelector = (state: ReportState) => ({
	callerList: state.callerList,
	selectedCaller: state.selectedCaller,
	fullScreen: state.fullScreen,
	selectedGraph: state.selectedGraph,
	selectedGraphData: state.selectedGraphData,
	fullScreenData: state.fullScreenData,

	globaTime: state.globalTime,
	leadVsStatusTime: state.leadVsStatusTime,
	leadVsSourceTime: state.leadVsSourceTime,
	leadVsCallerTime : state.leadVsCallerTime,
	callerPerformanceTime: state.callerPerformanceTime,
	leadTrendTime: state.leadTrendTime,
	engagementTrendTime : state.engagementTrendTime,

	
	leadTrendDateType : state.leadTrendDateType,

	leadVsStatus: state.leadVsStatus,
	leadVsSource: state.leadVsSources,
	leadVsCaller : state.leadVsCaller,
	callerPerformace : state.callerPerformace,
	leadTrend: state.leadTrend,
	engagementTrend : state.engagementTrend,
	
	cards: state.cards,

	setSelectedCaller: state.setSelectedCaller,
	setFullScreen: state.setFullScreen,
	download: state.download,

	setLeadTrendSelectionType: state.setLeadTrendSelectionType,
	setGlobalTime: state.setGlobalTime,
	setLeadVsStatusTime: state.setLeadVsStatusTime,
	setLeadVsSourceTime: state.setLeadVsSourceTime,
	setLeadVsCallerTime: state.setLeadVsCallerTime,
	setCallerPerformaceTime: state.setCallerPerformaceTime,
	setLeadTrendTime: state.setLeadTrendTime,
	setEngagementTrendTime: state.setEngagementTrendTime,
	setSelectedGraph: state.setSelectedGraph,
	setFullScreenData: state.setFullScreenData,

	getCallerList: state.getCallerList,
	getLeadStatus: state.getLeadStatus,
	getLeadSource: state.getLeadSource,
	getLeadCountPerCaller : state.getLeadCountPerCaller,
	getCallerPerformace : state.getCallerPerformace,
	getLeadTrend: state.getLeadTrend,
	getEngagementTrend : state.getEngagementTrend,


	refreshAll : state.refreshAll,
});

import styles from './report.module.css';

//Graph Components
import { CallerStatusBarGraph } from '../../components/callerStatus-bargraph/callerStatus-bargaph';
import { LeadVsSource } from '../../components/leadSource-report/LeadVsSource';
import { LeadWorkedVsCaller } from '../../components/leadWorkedVsCallerGraph-report';
import { DailyLeadTrendGraph } from '../../components/dailyLeadTrendGraph-report';
import { EngagementChart } from '../../components/engagementChart-report';
import { SubHeaderReport } from '../../components/subHeader-report';
import { LeadVsCallerCount } from '../../components/leadvscallercount-report';

//REPORT MODEL
import { ReportModel } from '../../components/reportModel';

//TO Handle DatePicker
import { DatePicker, Select, theme } from 'antd';

import dayjs, { Dayjs } from 'dayjs';

//TO Handle Download
import { exportToCSV } from '../../libs/utils';
import { ExportCSVName, ReportGraphConstants } from '../../config/report';
import { RangePickerProps } from 'antd/es/date-picker';
import { ReportState } from '../../@types/store/report';
import { CardComponent } from '../../components/cardComponent';
import { FullScreenModelReport } from '../../components/fullScreenModel-report';
const { Option } = Select;

const { useToken } = theme;

export const Report = () =>{
	const {
		sessionData,
		logoutUser
	} = useAppStore(appStoreSelector);

	const {
		//States
		callerList,
		selectedCaller,
		leadTrendDateType,
		fullScreen,
		selectedGraph,
		selectedGraphData,
		fullScreenData,

		//Time
		globaTime,
		leadVsStatusTime,
		leadVsSourceTime,
		leadVsCallerTime,
		callerPerformanceTime,
		leadTrendTime,
		engagementTrendTime,

		//States
		cards,
		leadVsStatus,
		leadVsSource,
		leadVsCaller,
		callerPerformace,
		leadTrend,
		engagementTrend,

		//Setter Function
		setSelectedCaller,
		setLeadTrendSelectionType,
		setFullScreen,
		setSelectedGraph,
		setFullScreenData,

		download,

		setGlobalTime,
		setLeadVsStatusTime,
		setLeadVsSourceTime,
		setLeadVsCallerTime,
		setCallerPerformaceTime,
		setLeadTrendTime,
		setEngagementTrendTime,

		//API Function
		getCallerList,
		getLeadStatus,
		getLeadSource,
		getLeadCountPerCaller,
		getCallerPerformace,
		getEngagementTrend,
		getLeadTrend,

		refreshAll
	} = useReportStore(reportStoreSelector);

	useEffect(() => {
		document.title = 'Dashboard | CodeQuotient';
	},[]);

	useEffect(() => {
		setGlobalTime([null,null]);
	},[]);

	useEffect(() => {
		getCallerList();
	},[getCallerList]);

	useEffect(() => {
		refreshAll();
	},[refreshAll]);

	const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
		// Can not select days before today and today
		return current && current < dayjs('2023-01-01');
	  };

	const handleCallerSelection = useCallback(
		(value: string)=>{
			callerList && setSelectedCaller(callerList[value]);
		}	
		,[selectedCaller,callerList]);

	const onRangeChangeGlobal = useCallback( (
		dates: any,
		dateStrings: string[]
	  ) => {
		if(dates){
			setGlobalTime(dates as [Dayjs, Dayjs]);
		}else{
			setGlobalTime([null, null]);
		}
	  },[setGlobalTime]);

	return (
		<>
			<Header
				sessionData={sessionData}
				logoutUser={logoutUser}
			/>
			<div className={styles.mainPage}>
				<div className={styles.pageContainer}>
					<div className={styles.globalSelectors}>
						<div className={styles.globalCallerSelector}>
							{fullScreen &&
								<FullScreenModelReport 
									fullScreenData={fullScreenData} 
									setFullScreen={setFullScreen} 
									setFullScreenData={setFullScreenData} fullScreen={fullScreen} download={download} setSelectedGraph={setSelectedGraph}/>
							}
							{selectedGraph &&
								<ReportModel sessionData={sessionData} logoutUser={logoutUser} data={selectedGraphData} closeFunction={()=>setSelectedGraph(null)}/>
							}
							<Select
								style={{width:'100%',height:'100%'}}
								value={selectedCaller?.id}
								onChange={handleCallerSelection}
								size='large'
								allowClear={true}
								placeholder={'Select Caller'}
								showSearch={true}
								filterOption={(inputValue: any, option: any) =>
									option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
								}
							>
								{callerList
								&& Object.keys(callerList).map( (element: string) => {
									return (
										<Option key={element} value={element} label={callerList[element].name}>
											{callerList[element].name}
										</Option>
									);
								})
								}
							</Select>
						</div>
						<div className={styles.globalDatePicker}>
							<DatePicker.RangePicker
								value={globaTime}
								size='large'
								format="DD-MM-YYYY"
								onChange={onRangeChangeGlobal}
								disabledDate={disabledDate}
							/>
						
						</div>
					</div>
					<div className={styles.firstPage}>
						<div className={styles.cardHolder}>
							{cards
								?
								Object.values(cards).map((element) => {
									return <CardComponent key={element.value} label={element.label} value={element.value} color={element.color}/>;
								})
								:
								<div>Loading</div>
							}
						</div>
						<div className={`${styles.graphContainer}`}>
							<SubHeaderReport title={'Lead Status'} 
								download={()=>download(ReportGraphConstants.leadStatus.value)}
								dateRage={leadVsStatusTime} 
								setDateRange={setLeadVsStatusTime}
								setFullScreen={()=>setFullScreen(ReportGraphConstants.leadStatus.value)}
								refreshFunction={getLeadStatus}
								callerList={callerList}
								callerSelected={selectedCaller}
								handleCallerSelection={handleCallerSelection}
							/>
							<div className={styles.graphHolder}>
								<CallerStatusBarGraph data={leadVsStatus} setSelectedGraph={setSelectedGraph}/>
							</div>
						</div>
						<div className={styles.graphContainer}>
							<SubHeaderReport title={'Source Performance'} 
								download={()=>download(ReportGraphConstants.leadSource.value)}
								dateRage={leadVsSourceTime} 
								setDateRange={setLeadVsSourceTime}
								setFullScreen={()=>setFullScreen(ReportGraphConstants.leadSource.value)}
								refreshFunction={getLeadSource}
								callerList={callerList}
								callerSelected={selectedCaller}
								handleCallerSelection={handleCallerSelection}
							/>
							<div className={styles.tableHolder}>
								<LeadVsSource
									data = {leadVsSource}
									pageSize={5}
									pageSizeOptions={['5','10']}
									setSelectedGraph={setSelectedGraph}
								/>
							</div>
						</div>
						{/* Lead Vs caller */}
						<div className={styles.graphContainer}>
							<SubHeaderReport title={'Lead Count by Caller'} 
								download={()=>download(ReportGraphConstants.leadVsCaller.value)}
								dateRage={leadVsCallerTime} 
								setDateRange={setLeadVsCallerTime}
								setFullScreen={()=>setFullScreen(ReportGraphConstants.leadVsCaller.value)}
								refreshFunction={getLeadCountPerCaller}
								handleCallerSelection={handleCallerSelection}
							/>
							<div className={styles.graphHolder}>
								<LeadVsCallerCount data={leadVsCaller} setSelectedGraph={setSelectedGraph}/>
							</div>
						</div>
						<div className={styles.graphContainer}>
							<SubHeaderReport title={'Caller Performence'} 
								download={()=>download(ReportGraphConstants.callerPerformace.value)}
								dateRage={callerPerformanceTime} 
								setDateRange={setCallerPerformaceTime}
								setFullScreen={ () => setFullScreen(ReportGraphConstants.callerPerformace.value)}
								refreshFunction={getCallerPerformace}
								callerList={callerList}
								callerSelected={selectedCaller}
								handleCallerSelection={handleCallerSelection}
							/>
							<div className={styles.graphHolder}>
								<LeadWorkedVsCaller data={callerPerformace} setSelectedGraph={setSelectedGraph}/>
							</div>
						</div>
						<div className={styles.graphContainer}>
							<SubHeaderReport title={'Lead Trend'} 
								download={()=>download(ReportGraphConstants.leadTrend.value)}
								dateRage={leadTrendTime} 
								// format={`YYYY/MM/DD HH:mm`}
								setDateRange={setLeadTrendTime}
								setFullScreen={()=>setFullScreen(ReportGraphConstants.leadTrend.value)}
								refreshFunction={getLeadTrend}
								callerList={callerList}
								callerSelected={selectedCaller}
								handleCallerSelection={handleCallerSelection}
								pickerRange={leadTrendDateType}
								extraElementToShow={
									<Select
										defaultValue = {'day'}
										options={[
											{label:'Day',value:'day'},
											{label:'Week', value:'week'},
											{label:'Year', value:'year'}
										]}
										onChange={(value: string)=> {
											setLeadTrendSelectionType(value as LeadTrendSelectionType); setLeadTrendTime([null,null]);
										}}
									/>
								}
							/>
							<div className={styles.graphHolder} >
								<DailyLeadTrendGraph data={leadTrend} setSelectedGraph={setSelectedGraph}/>
							</div>
						</div>
						<div className={styles.graphContainer}>
							<SubHeaderReport title={'Engagement Chart'} 
								download={()=>download(ReportGraphConstants.engagementTrend.value)}
								dateRage={engagementTrendTime}
								// format={`YYYY/MM/DD`}
								setDateRange={setEngagementTrendTime}
								setFullScreen={()=>setFullScreen(ReportGraphConstants.engagementTrend.value)}
								refreshFunction={getEngagementTrend}
								callerList={callerList}
								callerSelected={selectedCaller}
								handleCallerSelection={handleCallerSelection}
								placeHolderForCalander = {[dayjs().subtract(1,'month').format('DD-MM-YYYY HH:mm'),dayjs().format('DD-MM-YYYY HH:mm')]}
							/>
							<div className={styles.graphHolder}>
								<EngagementChart data={engagementTrend} setSelectedGraph={setSelectedGraph}/>
							</div>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};
