export const attributes = {
	add : { 'label' : 'Add', value : 1 },
	edit : { 'label' : 'Edit', value : 2 },
	delete : { 'label' : 'Delete', value : 3 },
	list : { 'label' : 'List', value : 4 },
	assign : { 'label' : 'Assign', value : 5 },
	activity : { 'label' : 'Assign', value : 6 },
	bulkUpload : { 'label' : 'Bulk Upload', value : 7 },
	download : { 'label' : 'Download', value : 8 },
	view : { 'label': 'View', value: 9 },
	statusUpdate : { 'label': 'Status update', value: 10 },
	lastCalledFollowUp : { 'label':'Last called & follow up', value: 11 }
};
export const resources = {
	lead : { 'label' : 'Lead', value : 1 },
	group : { 'label' : 'Group', value : 2 },
	role : { 'label' : 'Role', value : 3 },
	user : { 'label' : 'User', value : 4 },
	reports : { 'label' : 'Reports', value : 5 },
};