import React,{ useCallback, useContext, useEffect, useState } from 'react';
import { ConditionContext } from '../../screens/rule-engine';
import { Select, message } from 'antd';
import { InputElementProvider } from '../inputElementProvider';
import styles from './ruleEngineIfModel.module.css';
import dayjs, { Dayjs } from 'dayjs';

export const SingleIfBlock = (props: any) => {
	const {conditions, reverseConditions, operations, refData, fetchDataForDynamicLink} = useContext(ConditionContext);
	const {values, reRender, options} = props;

	const [fact, setFact] = useState(values.fact);
	const [operation, setOperation] = useState(values.operator);
	const [value, setValue] = useState(values.value);

	const [optionList, setOptionList] = useState<any>();
	const [valueList, setValueList] = useState<any>();
	const [valueInputType, setValueIntputType] = useState<number>(values.contentType);

	const [loadingValues, setLoadingValues] = useState(false);

	const {Option} = Select;

	const getValueFromUrl = useCallback(async (url: string) => {
		try{
			const data = await fetchDataForDynamicLink(url);
			setValueList(data);
		} catch (error: any) {
			setLoadingValues(false);
			message.error(error?.message ?? error);
		}
	},[setValueList, setLoadingValues, fetchDataForDynamicLink]);

	useEffect(() => {
		if(fact){
			const type = conditions[fact].type.value;
			// Operation List
			switch(type) {
				case operations.dataTypes.string.value :{
					setOptionList({
						[operations.operators.equal.ruleEngineString]:operations.operators.equal,
						[operations.operators.notEqual.ruleEngineString]:operations.operators.notEqual,
						[operations.operators.contain.ruleEngineString]:operations.operators.contain,
					});
					break;
				}
				case operations.dataTypes.array.value : {
					setOptionList({
						[operations.operators.equal.ruleEngineString]:operations.operators.equal,
						[operations.operators.notEqual.ruleEngineString]:operations.operators.notEqual
					});
					break;
				}
				default :{
					{
						setOptionList({
							[operations.operators.equal.ruleEngineString]:operations.operators.equal,
							[operations.operators.notEqual.ruleEngineString]:operations.operators.notEqual,
							[operations.operators.greaterThan.ruleEngineString]: operations.operators.greaterThan,
							[operations.operators.greaterThanInclusive.ruleEngineString]: operations.operators.greaterThanInclusive,
							[operations.operators.lessThan.ruleEngineString]: operations.operators.lessThan,
							[operations.operators.lessThanInclusive.ruleEngineString]: operations.operators.lessThanInclusive,
						});
					}}
			}

			//Value List
			setValueList([]);
			if(conditions[fact].url){
				getValueFromUrl(conditions[fact].url);
				setValueIntputType(3);
			} else if(refData[fact]){
				setValueList(refData[fact]);
				setValueIntputType(3);
			} else {
				setValueIntputType(conditions[fact].type.value);
			}
			
		} else {
			setOperation([]);
		}

	},[fact]);

	
	useEffect(()=>{
		values.contentType = valueInputType;
	},[valueInputType]);

	useEffect(() => {
		values.fact = fact;
	},[fact]);

	useEffect(() => {
		values.operator = operation;
		values.value = '';
	},[operation]);

	useEffect(() => {
		values.value = value;
	},[value]);

	const handleOnChangeForFact = (value:string) => {
		console.log(value);
		setFact(value);
		values.fact = value;
		values.operator = '';
		values.value = '';
		values.valueLabel = '';
		setOperation('');
		setValue('');
	};

	const handleOnChangeForOperations = (value: string) => {
		console.log(value);
		setOperation('');
		setTimeout(()=>{
			setOperation(value);
			values.operator = value; 
			values.value = '';
			values.valueLabel = '';
			setValue('');
		},10);
		
	};

	const handleOnChangeForValues = (value: any) => {

		if(valueInputType === 3) {
			values.valueLabel = valueList[value].label;
		}
		if(valueInputType === 4){
			console.log(value);
			if(value)	value = (value as Dayjs).millisecond(0).second(0).toISOString();
		}
		setValue(value);
		values.value = value;
	};

	return (
		<div className={styles.singleIfConditionBlock}>
			<Select 
				value={fact}
				style={{width:'150px'}}
				onChange={handleOnChangeForFact}
				showSearch
				filterOption={(inputValue: any, option: any) =>
					option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
				}
			>
				{(Object.keys(conditions ?? {})).map((element: any) => {
					return (
						<Option key={element} value={element}>
							{conditions[element].label}
						</Option>
					);
				})
				}
			</Select>
			{fact &&
				<Select value={operation} style={{width:'150px'}} onChange={handleOnChangeForOperations}>
					{(Object.keys(optionList ?? {})).map((element: any) => {
						return (
							<Option key={optionList[element].label} value={optionList[element].ruleEngineString}>
								{optionList[element].label}
							</Option>
						);
					})}
				</Select>
			}

			{operation &&
				<InputElementProvider
					min={conditions[fact]?.min} 
					max={conditions[fact]?.max} 
					loading={loadingValues}
					value={value}
					onChange={handleOnChangeForValues}
					inputType={valueInputType}
					list={valueList}
					width={'200px'}
				/>
			}
		</div>
	);
};