import React, { ReactElement, useState, MutableRefObject, RefObject } from 'react';
import styles from './timeLineComponent.module.css';
import Calender from '../../assets/img/calendar.svg';
import BasicInfo from '../../assets/img/basicInfo.png';
import Polygon from '../../assets/img/Polygon 62.svg';
import rulesIcon from '../../assets/img/rules-icon.svg';
import { CommentOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { APP_CONFIG } from '../../config';

interface TimeLineComponentProps {
	activityData: any,
	particulatActivityData: any,
	activityNumber: string,
	onParticularActivityClick: (activityNum: string, leadIds: string[], activityLabel: string, iconType: any, createdAt: string,
		created_by: string, alreadyLeadsPresent: any, duplicateLeads: any, notInsertedLeads: any, groupName: string, stage: string, 
		status: string, assignToName: string, followUpDate: string, comment: string, amount: any, validity:any, orderId:any, leadId:string,
		lastCalled: string, ruleId: string, updatedColumns:any) => void
	isLeadActivityPage: boolean
	isBulkActivityPage: boolean
	isGroupActivityPage: boolean
	isLeadsCommentActivityPage: boolean,
	isUserActivityPage?: boolean,
	lastElementRef?: any,
	firstElementRef?: any,
}

export const TimeLineComponent: React.FunctionComponent<TimeLineComponentProps> = (props) => {

	const {
		activityData,
		particulatActivityData,
		activityNumber,
		onParticularActivityClick,
		isLeadActivityPage,
		isBulkActivityPage,
		isGroupActivityPage,
		isLeadsCommentActivityPage,
		isUserActivityPage = false,
		lastElementRef,
		firstElementRef,
	} = props;

	const [showInsertedLeads, setShowInsertedLeads] = useState<boolean>(true);
	const [showAlreadyPresentLeads, setShowAlreadyPresentLeads] = useState<boolean>(true);
	const [showDuplicateLeads, setShowDuplicateLeads] = useState<boolean>(true);
	const [showNotInsertedLeads, setShowNotInsertedLeads] = useState<boolean>(true);
	const [showGroupInsertedLeads, setShowGroupInsertedLeads] = useState<boolean>(true);

	const nextSlide = (num: number) => {
		const el = document.getElementById(`m${num}`);
		if (el && el.style.display === 'none') {
			el.style.display = 'block';
		} else if(el) {
			el.style.display = 'none';
		}
	};

	const copyText = (textValue:string,messageToShow:string) => {
		// navigator.clipboard.writeText(textValue); 

		const tempInput = document.createElement('textarea');
		tempInput.value = textValue;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);

		message.success(messageToShow);
	};

	const resetRightPart = () => {
		document.getElementById('rightPart')?.scrollTo(9999, 0);
		setShowAlreadyPresentLeads(true);
		setShowDuplicateLeads(true);
		setShowGroupInsertedLeads(true);
		setShowInsertedLeads(true);
		setShowNotInsertedLeads(true);
	};

	const leadsStatusCheck = ( particulatActivityData?.leadsData?.groupName || (particulatActivityData?.leadsData?.stage && particulatActivityData?.leadsData?.status) ||
							particulatActivityData?.leadsData?.assignToName || particulatActivityData?.activityLabel?.includes('Assign to removed') 
							|| particulatActivityData?.leadsData?.followUpDate);

	return (
		<>
			<div className={styles.pageContent}>
				<div className={styles.pageContentRow}>
					<div className={styles.leftPart}>
						<div className={styles.outerContainer}>
							<div className={styles.outerContainerRow}>
								<div className={styles.timelineContainer}>
									{(activityData && activityData.length) ? 
										<ul className={styles.timeline}>
											{activityData.map((val: any, index: number) =>(
												<div key={index}>
													<div className={styles.startingMonthBox} onClick={() => nextSlide(index)} >
														<span className={styles.startingMonth}>{val.activityMonthYear}</span>
														<img 
															src={Calender} 
															className={styles.calender} 
															alt='calender'
															style={{width: '25px', cursor: 'pointer'}}
														></img>
													</div>
													<div 
														className={styles.monthlyActivity}
														id={`m${index}`}
														style={{ display: 'block' }}
													>
														{val.dataOfSameMonthYear.map((data: any, innerindex: number) => (
															<li key={innerindex}>
																<div className={styles.timelineBox}>
																	<span className={styles.timelineDate} >{data.createdAt}</span>
																	{isUserActivityPage ?
																		<div
																			className={`timelineTitleBox ${(activityNumber === data.id) ? 'timeLineActive' : ''}`} 
																			onClick={() => {
																				resetRightPart();
																				onParticularActivityClick(
																					data.id,
																					[data.LeadId],
																					data.activityLabel,
																					data.iconType,
																					data.createdAt,
																					data.created_by,
																					[],
																					[],
																					[],
																					data.groupName,
																					data.stage,
																					data.status,
																					data.assignToName,
																					data.followUpDate,
																					data.comment,
																					data.amount,
																					data.validity,
																					data.orderId,
																					data.leadId,
																					data.lastCalled,
																					data.ruleId,
																					data.updatedColumns,
																				);
																			}}
																		>
																			<img 
																				src={data.iconType} 
																				alt='removed-users' 
																				style={{height: '25px',width: '25px'}} 
																			/>
																			{innerindex === 0 && firstElementRef && <span ref={firstElementRef} ></span>}
																			<span className='timelineTitle'>{data.activityLabel}
																				{ data.LeadName && <span className='timelineSubTitle'>{`(${data.LeadName})`}</span>}
																			</span>
																		</div> : ''
																	}
																	{isLeadActivityPage ? 
																		<div 
																			className={`timelineTitleBox ${(activityNumber === data.id) ? 'timeLineActive' : ''}`} 
																			onClick={() => {
																				resetRightPart();
																				onParticularActivityClick(
																					data.id,
																					[data.LeadId],
																					data.activityLabel,
																					data.iconType,
																					data.createdAt,
																					data.created_by,
																					[],
																					[],
																					[],
																					data.groupName,
																					data.stage,
																					data.status,
																					data.assignToName,
																					data.followUpDate,
																					data.comment,
																					data.amount,
																					data.validity,
																					data.orderId,
																					data.leadId,
																					data.lastCalled,
																					data.ruleId,
																					data.updatedColumns,
																				);
																			}}
																		>
																			<img 
																				src={data.iconType} 
																				alt='removed-users' 
																				style={{height: '25px',width: '25px'}} 
																			/>
																			{data.ruleId && (
																				<img 
																					src={rulesIcon} 
																					alt='removed-users' 
																					style={{height: '25px',width: '25px'}} 
																				/>
																			)}
																			<span className='timelineTitle'>{data.activityLabel}</span>
																		</div> : ''
																	}
																	{isBulkActivityPage ?
																		<div 
																			className={`timelineTitleBox ${(activityNumber === data.id) ? 'timeLineActive' : ''}`} 
																			onClick={() => {
																				resetRightPart();
																				onParticularActivityClick(
																					data.id,
																					data.insertedLeadsId ? data.insertedLeadsId : data.LeadId,
																					data.activityLabel,
																					data.iconType,
																					data.createdAt,
																					data.created_by,
																					data.alreadyLeadsPresent,
																					data.duplicateLeads,
																					data.notInsertedLeads,
																					data.groupName,
																					data.stage,
																					data.status,
																					data.assignToName,
																					data.followUpDate,
																					data.comment,
																					data.amount,
																					data.validity,
																					data.orderId,
																					data.leadId,
																					data.lastCalled,
																					data.ruleId,
																					data.updatedColumns,
																				);
																			}}
																		>
																			<img 
																				src={data.iconType} 
																				alt='removed-users' 
																				style={{height: '25px',width: '25px'}} 
																			/>
																			<span className='timelineTitle'>{data.activityLabel}</span>
																		</div> : ''
																	}
																	{isGroupActivityPage ?
																		<div 
																			className={`timelineTitleBox ${(activityNumber === data.id) ? 'timeLineActive' : ''}`} 
																			onClick={() => {
																				resetRightPart();
																				onParticularActivityClick(
																					data.id,
																					data.insertedLeadsId ? data.insertedLeadsId : data.LeadId,
																					data.activityLabel,
																					data.iconType,
																					data.createdAt,
																					data.created_by,
																					[],
																					[],
																					[],
																					data.groupName,
																					data.stage,
																					data.status,
																					data.assignToName,
																					data.followUpDate,
																					data.comment,
																					data.amount,
																					data.validity,
																					data.orderId,
																					data.leadId,
																					data.lastCalled,
																					data.ruleId,
																					data.updatedColumns,
																				);
																			}}
																		>
																			<img 
																				src={data.iconType} 
																				alt='removed-users' 
																				style={{height: '25px',width: '25px'}} 
																			/>
																			<span className='timelineTitle' >{data.totalData} {data.activityLabel}</span>
																		</div> : ''
																	}
																	{isLeadsCommentActivityPage ?
																		<div 
																			className={`timelineTitleBox ${(activityNumber === data.id) ? 'timeLineActive' : ''}`} 
																			onClick={() => {
																				resetRightPart();
																				onParticularActivityClick(
																					data.id,
																					[],'',
																					data.iconType,
																					data.createdAt,
																					data.created_by,
																					[],[],[],'','','','','',
																					data.comment,
																					data.amount,
																					data.validity,
																					data.orderId,
																					data.leadId,
																					data.lastCalled,
																					data.ruleId,
																					data.updatedColumns,
																				);
																			}}
																		>
																			<CommentOutlined style={{fontSize: '25px'}}/>
																			<span className='timelineTitle' >{data.comment}</span>
																		</div> : ''
																	}
																</div>
															</li>
														))}
														
													</div>
												</div>
											))}
										</ul> : <p className={styles.noActivity}>No activity performed</p>
									}
									{lastElementRef && <div className={styles.observerForPagination} ref={lastElementRef}></div>}
								</div>
							</div>
						</div>
					</div>
					<div className={styles.rightPart} id='rightPart'>
						{(activityData && activityData.length) ? 
							<>
								{ (isLeadActivityPage || isLeadsCommentActivityPage || isUserActivityPage) ? 
									<>
										<div className={styles.activityHeader}>
											<p className={styles.activityHeaderInner}>
												<img className={styles.basicInfoImg} src={BasicInfo} />
												<span style={{position:'relative', bottom: '3px'}}>
													{ isUserActivityPage? 'Lead information' : 'Basic information' }
												</span>								
											</p>
										</div>
										<div style={{marginLeft: '22px'}}>
											<p className={styles.basicInformationDetails}>Name: {particulatActivityData?.leadsData?.name}</p>
											<p className={styles.basicInformationDetails}>Email: {particulatActivityData?.leadsData?.email}</p>
											<p className={styles.basicInformationDetails}>Created At: {particulatActivityData?.leadsData?.createdAt}</p>
											<p className={styles.basicInformationDetails}>Current Status: {particulatActivityData?.leadsData?.currentStatus}</p>
											{isLeadsCommentActivityPage ? <p className={styles.basicInformationDetails}>Commented By: {particulatActivityData?.leadsData?.created_by}</p> : '' }
										</div>
										{isLeadActivityPage || isUserActivityPage ? 
											<div className={styles.activityHeader}>
												<p className={styles.activityHeaderInner}>
													<div className={styles.activityHeaderInnerContainer}>
														<img src={particulatActivityData.iconType} className={styles.statutsUpdateImg} />
														<span className={styles.activityLabel}>{particulatActivityData.activityLabel}</span>
													</div>

													<small className={styles.createdBy} style={{marginLeft: '1.8rem'}}>By {particulatActivityData?.leadsData?.created_by}</small>
												</p>
												<hr style={{marginTop: 0}}></hr>
											</div> : ''
										}
									</>	: ''
								}

								{isBulkActivityPage ?
									<div className={styles.studentAddedContainer}>
										<p className={styles.selectedDate}>{particulatActivityData.createdAt}</p>
										{ (particulatActivityData && particulatActivityData.insertedLeads && particulatActivityData.insertedLeads.length) ?
											<>
												<p className={styles.totalStudentAdded}>
													<div className={styles.totalStudentAddedInner}>
														<div className={styles.totalStudentAddedInnerContainer}>
															<img 
																src={particulatActivityData.iconType} 
																style={{marginRight: '0.5rem', width: '23px'}} 
															/>
															<span className={styles.activityLabel}>
																{leadsStatusCheck ? particulatActivityData?.activityLabel : `${particulatActivityData.insertedLeads.length} Leads Inserted`}
															</span>
														</div>
														<img 
															src={Polygon} 
															width='12px' 
															onClick={() => setShowInsertedLeads(!showInsertedLeads)}
															style={{cursor: 'pointer'}}
															className={!showInsertedLeads ? styles.polygon : ''}
														/>
													</div>
													<small className={styles.createdBy}>By {particulatActivityData.created_by}</small>
												</p>
												<hr style={{marginTop: 0}}></hr>
												<div className={styles.studentAddedList} style={!showInsertedLeads ? {display: 'none'} : {display: 'block'}}>
													{particulatActivityData.insertedLeads.map((data: any, index: number) => (
														<div key={index}>
															<p 
																className={styles.studentDetails} 
																title={`${data.name} (${data.email})`}
															>
																{`${data.name} (${data.email})`}
															</p>
															<hr></hr>
														</div>
													))}
												</div>
											</> : ''
										}
										{ (particulatActivityData && particulatActivityData.alreadyLeadsPresent && particulatActivityData.alreadyLeadsPresent.length) ?
											<>
												<p className={styles.totalStudentAdded}>
													<div className={styles.totalStudentAddedInner}>
														<div className={styles.totalStudentAddedInnerContainer}>
															<img 
																src={particulatActivityData.iconType} 
																style={{marginRight: '0.5rem', width: '23px'}} 
															/>
															<span className={styles.activityLabel}>{particulatActivityData.alreadyLeadsPresent.length} {'Leads already present'}</span>
														</div>
														<img 
															src={Polygon} 
															width='12px' 
															onClick={() => setShowAlreadyPresentLeads(!showAlreadyPresentLeads) }
															style={{cursor: 'pointer'}}
															className={!showAlreadyPresentLeads ? styles.polygon : ''}
														/>
													</div>
													
													<small className={styles.createdBy}>By {particulatActivityData.created_by}</small>
												</p>
												<hr style={{marginTop: 0}}></hr>
												<div className={styles.studentAddedList} style={!showAlreadyPresentLeads ? {display: 'none'} : {display: 'block'}}>
													{particulatActivityData.alreadyLeadsPresent.map((data: any, index: number) => (
														<div key={index}>
															<p 
																className={styles.studentDetails} 
																title={`${data.data.email} (${data.error})`}
															>
																{`${data.data.email} (${data.error})`}
															</p>
															<hr></hr>
														</div>
													))}
												</div>
											</> : ''
										}
										{ (particulatActivityData && particulatActivityData.duplicateLeads && particulatActivityData.duplicateLeads.length) ?
											<>
												<p className={styles.totalStudentAdded}>
													<div className={styles.totalStudentAddedInner}>
														<div className={styles.totalStudentAddedInnerContainer}>	
															<img 
																src={particulatActivityData.iconType} 
																style={{marginRight: '0.5rem', width: '23px'}} 
															/>
															<span className={styles.activityLabel}>{particulatActivityData.duplicateLeads.length} {'Duplicate Leads'}</span>
														</div>
														<img 
															src={Polygon} 
															width='12px' 
															onClick={() => setShowDuplicateLeads(!showDuplicateLeads) }
															style={{cursor: 'pointer'}}
															className={!showDuplicateLeads ? styles.polygon : ''}
														/>
													</div>
													
													<small className={styles.createdBy}>By {particulatActivityData.created_by}</small>
												</p>
												<hr style={{marginTop: 0}}></hr>
												<div className={styles.studentAddedList} style={!showDuplicateLeads ? {display: 'none'} : {display: 'block'}}>
													{particulatActivityData.duplicateLeads.map((data: any, index: number) => (
														<div key={index}>
															<p 
																className={styles.studentDetails} 
																title={`${data.data.email} (${data.error})`}
															>
																{`${data.data.email} (${data.error})`}
															</p>
															<hr></hr>
														</div>
													))}
												</div>
											</> : ''
										}
										{ (particulatActivityData && particulatActivityData.notInsertedLeads && particulatActivityData.notInsertedLeads.length) ?
											<>
												<p style={{marginTop: 0}} className={styles.totalStudentAdded}>
													<div className={styles.totalStudentAddedInner}>
														<div className={styles.totalStudentAddedInnerContainer}>
															<img 
																src={particulatActivityData.iconType} 
																style={{marginRight: '0.5rem', width: '23px'}} 
															/>
															<span className={styles.activityLabel}>{particulatActivityData.notInsertedLeads.length} {'Leads not inserted'}</span>
														</div>
														<img 
															src={Polygon} 
															width='12px' 
															onClick={() => setShowNotInsertedLeads(!showNotInsertedLeads) }
															style={{cursor: 'pointer'}}
															className={!showNotInsertedLeads ? styles.polygon : ''}
														/>
													</div>
													<small className={styles.createdBy}>By {particulatActivityData.created_by}</small>
												</p>
												<hr style={{marginTop: 0}}></hr>
												<div className={styles.studentAddedList} style={!showNotInsertedLeads ? {display: 'none'} : {display: 'block'}}>
													{particulatActivityData.notInsertedLeads.map((data: any, index: number) => (
														<div key={index}>
															<p
																className={styles.studentDetails} 
																title={`${data.data.email} (${data.error})`}
															>
																{data.data.email ? data.data.email : data.data.name} {`(${data.error})`}
															</p>
															<hr></hr>
														</div>
													))}
												</div>
											</> : ''
										}
									</div> : ''
								}

								{isGroupActivityPage ? 
									<div className={styles.studentAddedContainer}>
										<p className={styles.selectedDate}>{particulatActivityData.createdAt}</p>
										<p className={styles.totalStudentAdded}>
											<div className={styles.totalStudentAddedInner}>
												<div className={styles.totalStudentAddedInnerContainer}>
													<img 
														src={particulatActivityData.iconType} 
														style={{marginRight: '0.5rem', width: '23px'}} 
													/>
													<span className={styles.activityLabel}>
														{leadsStatusCheck ? 
															particulatActivityData.activityLabel : `${particulatActivityData?.leadsData?.insertedLeads?.length} ${particulatActivityData.activityLabel}`
														}
													</span>
												</div>
												<img 
													src={Polygon} 
													width='12px' 
													onClick={() => setShowGroupInsertedLeads(!showGroupInsertedLeads) }
													style={{cursor: 'pointer'}}
													className={!showGroupInsertedLeads ? styles.polygon : ''}
												/>
											</div>	
											<small className={styles.createdBy}>By {particulatActivityData.created_by}</small>
										</p>
										<hr style={{marginTop: 0}}></hr>
										<div className={styles.studentAddedList} style={!showGroupInsertedLeads ? {display: 'none'} : {display: 'block'}}>
											{particulatActivityData?.leadsData?.insertedLeads?.map((data: any, index: number) => (
												<div key={index}>
													<p 
														className={styles.studentDetails} 
														title={`${data.name} (${data.email})`}
													>
														{`${data.name} (${data.email})`}
													</p>
													<hr></hr>
												</div>
											))}
										</div>
									</div> : ''
								}
								
								{(particulatActivityData?.leadsData?.status) ? 
									<div className={styles.activityBody}>
										{/* <p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Stage: </span>
											<span>{particulatActivityData?.leadsData?.stage}</span>
										</p> */}
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Status: </span>
											<span>{particulatActivityData?.leadsData?.status}</span>
										</p>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Comment: </span>
											<span>{particulatActivityData?.leadsData?.comment}</span>
										</p>
									</div> : ''
								}
								{(particulatActivityData?.leadsData?.assignToName) ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Assigned To: </span>
											<span>{particulatActivityData?.leadsData?.assignToName}</span>
										</p>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Comment: </span>
											<span>{particulatActivityData?.leadsData?.comment}</span>
										</p>
									</div> : ''
								}
								{(particulatActivityData?.activityLabel?.includes('Assign to removed')) ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Comment: </span>{particulatActivityData?.leadsData?.comment}
										</p>
									</div> : ''
								}
								{(particulatActivityData?.leadsData?.followUpDate) || (particulatActivityData?.leadsData?.lastCalled) ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>FollowUp Date: </span>
											<span>{particulatActivityData?.leadsData?.followUpDate || ''}</span>
										</p>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Last Called Date: </span>
											<span>{particulatActivityData?.leadsData?.lastCalled || ''}</span>
										</p>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Comment: </span>
											<span>{particulatActivityData?.leadsData?.comment}</span>
										</p>
									</div> : ''
								}
								{particulatActivityData?.leadsData?.groupName ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Group Name: </span>
											<span>{particulatActivityData?.leadsData?.groupName}</span>
										</p>
									</div> : ''
								}
								{particulatActivityData?.leadsData?.amount ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Amount: </span>
											<span>Rs.{particulatActivityData?.leadsData?.amount}</span>
										</p>
									</div> : ''
								}
								{particulatActivityData?.leadsData?.validity ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Link validity: </span>
											<span>{particulatActivityData?.leadsData?.validity}</span>
										</p>
									</div> : ''
								}
								{particulatActivityData?.leadsData?.orderId ? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span style={{fontWeight: 500}}>Order id: </span>
											<span>{particulatActivityData?.leadsData?.orderId}</span>
										</p>
									</div> : ''
								}
								{particulatActivityData?.leadsData?.validity && 
								particulatActivityData?.leadsData?.leadId? 
									<div className={styles.activityBody}>
										<p style={{marginTop: 0}}>
											<span
												style={{color:'#de6834', cursor:'pointer'}}
												onClick={()=> copyText(
													`${APP_CONFIG.MainServerURL}/payment/paymentsummary/${particulatActivityData?.leadsData?.leadId}`
													,'Link copied')}
											>Copy Payment Link</span>
										</p>
									</div> : ''
								}

								{particulatActivityData?.leadsData?.updatedColumns && 
								<div>
									{Object.keys(particulatActivityData?.leadsData?.updatedColumns).map((key, index) => {
										return (
											<div key={index} className={styles.activityBody}>
												<p style={{marginTop: 0}}>
													<span style={{fontWeight: 500}}>{key}: </span>
													<span>{particulatActivityData?.leadsData?.updatedColumns[key]}</span>
												</p>
											</div>
										);
									})}
								</div>}
							</> : ''
						}
					</div>
				</div>
			</div>
		</>
	);		
};