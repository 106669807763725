import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppStore } from '../../stores';

interface CheckPermissionsProps {
	children: JSX.Element,
	isLogginRequired?: boolean,
	resource:number,
	attribute:number
}

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
});

const CheckPermissions: React.FunctionComponent<CheckPermissionsProps> = ({children, ...props}) => {

	const { 
		sessionData, 
	} = useAppStore( appStoreSelector );

	const {resource, attribute} = props;
	

	if(!sessionData?.permissions[resource]?.attributes.includes(attribute)){
		return <Navigate to="/error"/>;
	}

	return children;
};

export default CheckPermissions;