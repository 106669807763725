import React, { useEffect, useRef, useState} from 'react';
import styles from './groupsReport-screen.module.css';
import { Header } from '../../components/header';
import { LeadsContentSubHeader } from '../../components/leadsContentSubHeader';
import { Button, Space, Table, Tooltip, message, Popconfirm, Modal, FloatButton, Popover } from 'antd';
import { LeadDetalisModal } from '../../components/leadDetailsModal';
import { CommentOutlined, DeleteOutlined } from '@ant-design/icons';
import { useAppStore } from '../../stores';
import { LeadSeekers, roleValue } from '../../config';
import type { TableProps } from 'antd';
import type { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { convertSnakeToCamelCase, convertTime } from '../../libs/utils';
import { TableComponent } from '../../components/mainTableComponent';
import { useParams } from 'react-router-dom';
import { TimeLineComponent } from '../../components/timeLineComponent/timeLineComponent';
import { useNavigate } from 'react-router-dom';
import userDeleteIcon from '../../assets/img/users-delete.svg';
import { SearchField } from '../../components/searchField';
import { FixedFilterComponent } from '../../components/fixedFilterComponent';
import { SearchByFilterComponent } from '../../components/searchByFilterComponent';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';
import states from '../../config/location';

const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadListData: state.leadListData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	sourceOptions: state.sourceOptions,
	logoutUser: state.logoutUser,
	createOneLead: state.createOneLead,
	getLeadsList: state.getLeadsList,
	deleteOneLead: state.deleteOneLead,
	updateOneLead: state.updateOneLead,
	getLeadsOfGroup: state.getLeadsOfGroup,
	getLeadsToAddInGroup: state.getLeadsToAddInGroup,
	addLeadsInGroup: state.addLeadsInGroup,
	removeLeadFromGroup: state.removeLeadFromGroup,
	updateLeadStatus: state.updateLeadStatus,
	updateAssignTo: state.updateAssignTo,
	unAssignLeads: state.unAssignLeads,
	updateFollowUpLastCalled: state.updateFollowUpLastCalled,
	getAssignList: state.getAssignList,
	setAppLoading: state.setAppLoading,
	createActivityBeforeDownload: state.createActivityBeforeDownload,
} );

export const GroupsReport: React.FunctionComponent = () => {
	// document.title = 'Group Report | CodeQuotient';
	const stateLeadsFilters: LeadsFilters = {
		startTime: '',
		endTime: '',
		// stage: null,
		// isEqualToStage: true,
		status: null,
		isEqualToStatus: true,
		assignTo: '',
		isEqualToAssignTo: true,
		state: null,
		notAssigned: false,
		isFollowUp: false,
		source: null,
	};
	
	// for lead table
	const statePaginationData: paginationData = { 
		sortField: 'updated_at',
		sortOrder: 'descend',
		pageNumber: 1,
		perPage: 20,
		searchValue: '',
		filters: stateLeadsFilters
	};
	
	// for lead table in add modal
	const initPaginationData: paginationData = {
		sortField: 'updated_at',
		sortOrder: 'descend',
		pageNumber: 1,
		perPage: 20,
		searchValue: ''
	};
	
	const [leadDetailModalOpen, setLeadDetailModalOpen] = useState<boolean>( false );
	const [finalCols, setFinalCols] = useState<any>([]);
	const [tableData, settableData] = useState<any[]>([]);
	const [finalAddLeadCols, setFinalAddLeadCols] = useState<any>([]);
	const [addLeadtableData, setAddLeadtableData] = useState<any[]>([]);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [oneLeadData, setOneLeadData] = useState<any>({});
	const [openAddLeadModal, setOpenAddLeadModal] = useState<boolean>(false);

	//for main leads table
	const [paginationData, setPaginationData] = useState<paginationData>(statePaginationData);
	const [perPageCount, setPerPageCount] = useState<number>(20);
	const [totalListDataCount, setTotalListDataCount] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [leadTableSearchValue, setLeadsTableSearchValue] = useState<string>('');
	const [prevSorterOrder, setPrevSorterOrder] = useState<any>('descend');

	//for leads table in modal
	const [
		addLeadpaginationData, 
		setAddLeadPaginationData
	] = useState<paginationData>(initPaginationData);
	const [addLeadperPageCount, setAddLeadPerPageCount] = useState<number>(20);
	const [addLeadTotalListDataCount, setAddLeadTotalListDataCount] = useState<number>(20);
	const [addLeadCurrentPage, setAddLeadCurrentPage] = useState<number>(1);
	const [addLeadTableSearchValue, setAddLeadsTableSearchValue] = useState<string>('');
	const [prevSorterOrderForModal, setPrevSorterOrderForModal] = useState<any>('descend');

	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [leadsFilters, setLeadsFilters] = useState<LeadsFilters>(stateLeadsFilters);

	// assign keys to table row
	const [addLeadselectedRowKeys, setAddLeadSelectedRowKeys] = useState<React.Key[]>([]);
	const [leadsTableSelectedRowKeys, setLeadsTableSelectedRowKeys] = useState<React.Key[]>([]);

	const [assignToList, setAssignToList] = useState<any[] | null>([]);
	const [groupDetails, setGroupDetails] = useState<groupDetails>({});

	const [detailModalBtnLoading, setDetailModalBtnLoading] = useState<boolean>(false);

	const collegeDefaultFields = LeadSeekers?.College?.fields || null;

	// getting group id from url params
	const { id } = useParams();
	const navigate = useNavigate();

	console.log(leadsTableSelectedRowKeys);

	const { 
		sessionData,
		leadListData,
		leadStages,
		leadStatus,
		sourceOptions,
		logoutUser,
		createOneLead, 
		getLeadsList,  
		deleteOneLead,
		updateOneLead,
		getLeadsOfGroup,
		getLeadsToAddInGroup,
		addLeadsInGroup,
		removeLeadFromGroup,
		updateLeadStatus,
		updateAssignTo,
		unAssignLeads,
		updateFollowUpLastCalled,
		getAssignList,
		createActivityBeforeDownload,
		setAppLoading,
	} = useAppStore( appStoreSelector );

	const leadDetailModalSubmitBtnRef = useRef<any>(null);

	const handleEditCreatLeadBtn = () => {
		leadDetailModalSubmitBtnRef.current ? leadDetailModalSubmitBtnRef.current.removeAttribute('disabled', 'disabled') : '';
		setDetailModalBtnLoading(false);
	};
	
	// get the lead list of particlar group
	const handleLeadList = async (paginationData: paginationData) => {
		try {
			setAppLoading(true);
			setTableLoading(true);
			const response = await getLeadsOfGroup({groupId: id, ...paginationData});
			setTableLoading(false);

			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();

			if( response?.error ){
				throw new Error(response?.error);
			}

			if(response && response?.data){
				setGroupDetails(response?.data?.groupData);
				document.title = `${response?.data?.groupData?.title} | CodeQuotient`;
				const leadsData = response?.data?.results || [];

				(leadsData && leadsData.length) ? 
					setLeadList( 
						leadsData, 
						response?.data?.usersData,
						response?.data?.sortField, 
						response?.data?.sortOrder,
						response?.data?.page,
						response?.data?.perPage
					) : 
					setLeadList([], {}, 'updated_at','descend');
					
				setPerPageCount(response?.data?.perPage);
				setTotalListDataCount(response?.data?.total);
				setCurrentPage(response?.data?.page);
			}else{
				setLeadList([], {}, 'updated_at',null);
			}
		} catch (error: any) {
			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();

			setLeadList([], {},'updated_at',null);
			message.error(error.message);
		}finally{
			setAppLoading(false);
		}
	};

	const getLeadsData = () => {
		//set the table page no. to 1
		paginationData.pageNumber = 1;
		// paginationData.sortField = 'updated_at';
		// paginationData.sortOrder = 'descend';
		// setPrevSorterOrder('descend');

		setPaginationData(paginationData);
		handleLeadList(paginationData);
		document.body.querySelector('#leadReportBody')?.scrollTo(9999, 0);
	};

	// get the lead list for the add lead modal table
	const handleAddLeadModalList = async (paginationData: paginationData) => {
		try {
			setTableLoading(true);
			const response = await getLeadsToAddInGroup({groupId: id, ...paginationData});
			setTableLoading(false);

			if( response?.error ){
				throw new Error(response?.error);
			}

			if(response && response?.data){
				const leadsData = response?.data?.results || [];
				(leadsData && leadsData.length) ? 
					setAddLeadModalList( 
						leadsData, 
						response?.data?.sortField, 
						response?.data?.sortOrder,
						response?.data?.page,
						response?.data?.perPage
					) : 
					setAddLeadModalList([],'updated_at','descend');
				setAddLeadPerPageCount(response?.data?.perPage);
				setAddLeadTotalListDataCount(response?.data?.total);
				setAddLeadCurrentPage(response?.data?.page);
			}else{
				setAddLeadModalList([], 'updated_at','descend');
			}

		} catch (error) {
			setAddLeadModalList([],'updated_at',null);
		}
	};


	// making the request of create new lead by sending the lead data after creating.
	const handleCreateOneLead = async ( data: any ) => {
		setTableLoading(true);
		const response = await createOneLead( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			setLeadDetailModalOpen(true);
			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();
			return;
		}

		setLeadDetailModalOpen(false);
		message.success( response.data.msg );

		getLeadsData();
	};

	// making the request of deleting one lead by sending the lead Id. 
	const handleRemoveLeadsFromGroup = async ( leadIds: string[] | React.Key[] ) => {
		setTableLoading(true);
		const response = await removeLeadFromGroup( leadIds, id );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	// making the request of updating one lead by sending the updated lead data.
	const handleUpdateOneLead = async ( data: any ) => {
		setTableLoading(true);
		const response = await updateOneLead( data );
		setTableLoading(false);

		setIsEdit(false);
		if( response.error ){
			message.error( response.error );
			setLeadDetailModalOpen(true);
			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();
			setIsEdit(true);
			return;
		}

		setIsEdit(false);
		setLeadDetailModalOpen(false);
		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUpdateLeadStatus = async ( data: UpdateLeadStatus ) => {
		setTableLoading(true);
		const response = await updateLeadStatus( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUpdateAssignTo = async ( data: UpdateAssignTo ) => {
		setTableLoading(true);
		const response = await updateAssignTo( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUnAssignLeads = async ( data: UnassignLeads ) => {
		setTableLoading(true);
		const response = await unAssignLeads( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUpdateFollowUpLastCalled = async ( data: UpdateFollowUpLastCalled ) => {
		setTableLoading(true);
		const response = await updateFollowUpLastCalled( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	// on lead list table change
	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		const data: paginationData = {
			sortField: sorter.field,
    		sortOrder: sorter.order || null,
    		pageNumber: pagination.current,
    		perPage: pagination.pageSize,
			searchValue: leadTableSearchValue,
			filters: leadsFilters
		};

		// on sort set the table page no. to 1
		if (sorter.order !== prevSorterOrder) {
			data.pageNumber = 1;
		}

		setPrevSorterOrder(sorter.order);
		handleLeadList(data);
		setPaginationData(data);
	};

	// on lead list table in add lead modal change
	const handleAddLeadTableChange = (pagination: any, filters: any, sorter: any) => {
		const data: paginationData = {
			sortField: sorter.field,
    		sortOrder: sorter.order || null,
    		pageNumber: pagination.current,
    		perPage: pagination.pageSize,
			searchValue: addLeadTableSearchValue
		};

		if (sorter.order !== prevSorterOrderForModal) {
			data.pageNumber = 1;
		}

		setPrevSorterOrderForModal(sorter.order);
		handleAddLeadModalList(data);
		setAddLeadPaginationData(data);
	};

	const handleAssignList = async() => {
		if(sessionData?.roleName === roleValue.caller.label){
			setAssignToList(null);
			return ;
		}
		const response = await getAssignList();
		if( response.error ){
			message.error( response.error );
			return;
		}
		setAssignToList(response.data);
	};

	// const handleAssignList = async() => {
	// 	const response = await getAssignList();
	// 	if( response.error ){
	// 		message.error( response.error );
	// 		return;
	// 	}
	// 	setAssignToList(response.data);
	// };

	// open the edit lead drawer
	const editLead = (record: any) => {
		setLeadDetailModalOpen(true);
		setIsEdit(true);
		setOneLeadData(record);
	};

	// open the create new lead drawer
	const openCreateLead = () => {
		setLeadDetailModalOpen( !leadDetailModalOpen );
	  	setIsEdit(false);
	};

	// on closing the drawers
	const onleadDetailModalClose = () => {
		setLeadDetailModalOpen( false );
	  	setIsEdit(false);
	};

	const openAddLead = () => {
		setOpenAddLeadModal(true);

		//set the table page no. to 1
		addLeadpaginationData.pageNumber = 1;

		handleAddLeadModalList(addLeadpaginationData);
	};

	const closeAddLead = () => {
		setOpenAddLeadModal(false);
		setAddLeadSelectedRowKeys([]);
	};

	const onAddLead = async () => {
		if(!addLeadselectedRowKeys.length){
			message.error('Please select atleast one lead');
			return;
		}

		const data: {groupId: string | undefined, leadIds: React.Key[]} = {
			groupId: id,
			leadIds: addLeadselectedRowKeys
		};
		if(tableLoading){
			return;
		}
		setTableLoading(true);
		const response = await addLeadsInGroup( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}
		message.success( response.data.msg );
		closeAddLead();
		handleLeadList(paginationData);
	};

	// on selecting the rows of table in add lead modal and set the selected row lead ids
	const addLeadModalRowSelection: TableRowSelection<any> = {
		selectedRowKeys: addLeadselectedRowKeys,
		onChange: (selectedRowKeys) => {
			// assign keys to table row
			setAddLeadSelectedRowKeys(selectedRowKeys);
		},
	};

	// on selecting the rows of group report table and set the selected row lead ids
	const leadsTableRowSelection: TableRowSelection<any> = {
		fixed:true,
		selectedRowKeys: leadsTableSelectedRowKeys,
		onChange: (selectedRowKeys) => {
			// assign keys to table row
			setLeadsTableSelectedRowKeys(selectedRowKeys);
		},
	};
	const copyText = (textValue:string,messageToShow:string) => {
		// navigator.clipboard.writeText(textValue); 

		const tempInput = document.createElement('textarea');
		tempInput.value = textValue;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);

		
		message.success(messageToShow);
	};

	// columns for table in add lead modal
	const addLeadsModalTableColumns: ColumnsType<addLeadsTableColumnsType> = [
		{
			width: 47,
			dataIndex: 'SrNum',
		  	key: 'SrNum',
			fixed:'left',
			render: ( _: any, record: any ) => (
				<div style={{display: 'flex', justifyContent: 'center'}}>{record.SrNum}.</div>
			)
		},
		{
	  	  	title: 'Name',
	  	  	dataIndex: 'name',
		  	key: 'name',
		  	width: 180,
			fixed:'left',
		  	sorter: ( a, b ) => a.name.localeCompare( b.name ),
		  	render: ( _: any, record: any ) => { 
				// console.log( record );
				return (
					<span className='groupAndLeadTitle' title={record.name} onClick={()=>window.open(`/lead/leadActivity/${record.id}`, '_blank')}>
						{record.name}
					</span>
				);
			}
	  	},
	  	{
	  	  title: 'Email',
	  	  dataIndex: 'email',
		  key: 'email',
		  width: 200,
		  ellipsis: true,
		  fixed:'left',
	  	  sorter: ( a, b ) => a?.email?.localeCompare( b?.email ),
			render: ( _: any, record: any) => {
				return (
					<span style={{cursor:'pointer'}}
						onClick={()=>{
							copyText(record.email,'Email Copied');
						}}
					>
						{record.email}
					</span>
				);
	  }

	  	},
	  	{
	  	  title: 'Phone Number',
	  	  dataIndex: 'number',
		  key: 'number',
		  width: 160,
		  ellipsis: true,
		  render: ( _: any, record: any) => {
				return (
					<span style={{cursor:'pointer'}}
						onClick={()=>{
							copyText(record.number,'Phone number Copied');
						}}
					>
						{record.number}
					</span>
				);
  		}
	  	},
		{
			title: 'Updated At',
		  	dataIndex: 'updated_at',
		  	width: 200,
		  	sorter: ( a, b ) => a.updated_at.localeCompare( b.updated_at ),
		  	render: ( _: any, record: any ) => { 
			  	// console.log( record );
			  	const time = record.updated_at;
			  	return (
				  convertTime(time)
			  	);
		  	}
		},
	];

	// fixed columns lead list table
	const defaultColumns:ColumnsType<any> = [
		...addLeadsModalTableColumns,
	  	{
			dataIndex:-1,
	  		title: 'Actions',
			fixed: 'right',
			width: 100,
	    	render: ( _: any, record: any ) => { 
				// console.log( record.id );
				return (
					<Space size="small">
						{/* <Tooltip title='Edit'>
							<div className={styles.leadsListingAction} >
								<EditOutlined 
									style={{opacity: '0.7'}} 
									onClick={() => editLead(record)} 
								/>
							</div>
						</Tooltip> */}
						<Tooltip title="Delete this lead from group">
							<Popconfirm
								title="Delete the lead"
								description="Are you sure to delete this lead from group?"
								placement="left"
								okText="Yes"
								cancelText="No"
								onConfirm={() => handleRemoveLeadsFromGroup( [record.id] )}
  							>
								<div className={styles.leadsListingAction} >
									<DeleteOutlined style={{opacity: '0.7'}}/>
								</div>
							</Popconfirm>
						</Tooltip>
						<Tooltip title='Comment Activity'>
							<div className={styles.leadsListingAction} onClick={() => navigate(`/lead/leadCommentActivity/${record.id}`)}>
								<CommentOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
					</Space>
				);
			}
	  }
	];

	// set dynamic columns with the fixed columns
	const columns: ColumnsType<any> = [
		...defaultColumns
	];

	// set the columns and the data that are coming dynamically
	const setLeadList:any = async (data:any, assignToDetails: any, sortField: string, sortOrder: any, currentPage: number, perPageCount:number) => {
		// TODO: need to clean this code
		// set the table when empty data is coming from backend

		// setting the pagination data coming from backend
		columns.forEach((column: any) => {
			if(sortField && sortOrder && column.dataIndex === sortField){
				column.sortOrder = sortOrder || null;
			}
		});

		if(!data?.length){
			setFinalCols(defaultColumns);
			settableData([]);
			return;
		}

		// ================================
		// logic for table columns set
		// ================================
		let defaultValuesFromLead:any[] = [];
		let tempColsForTable:any = {};
		data.forEach( ( lead:any ) => {
			lead.key = lead.id;

			if( lead.dynamic_fields ){
				const dynamicFieldKeys = lead.dynamic_fields;
				tempColsForTable = {...tempColsForTable, ...dynamicFieldKeys};
			}
			// console.log(Object.keys(lead));
			const sortedValuesFromLead: any = {
				0:Object.keys(lead)[0], 1:Object.keys(lead)[1], 2:Object.keys(lead)[2], 3:Object.keys(lead)[5], 4:Object.keys(lead)[3], 5:Object.keys(lead)[4],
				6:Object.keys(lead)[36], 7:Object.keys(lead)[37], 8:Object.keys(lead)[20], 9:Object.keys(lead)[41], 10:Object.keys(lead)[35], 11:Object.keys(lead)[38], 
				12:Object.keys(lead)[40], 13:Object.keys(lead)[39], 14:Object.keys(lead)[43], 15:Object.keys(lead)[42], 16:Object.keys(lead)[7], 17:Object.keys(lead)[8], 
				18:Object.keys(lead)[9], 19:Object.keys(lead)[10], 20:Object.keys(lead)[11], 21:Object.keys(lead)[12], 22:Object.keys(lead)[13], 23:Object.keys(lead)[14], 
				24:Object.keys(lead)[15], 25:Object.keys(lead)[16], 26:Object.keys(lead)[17], 27:Object.keys(lead)[18], 28:Object.keys(lead)[19], 29:Object.keys(lead)[20], 
				30:Object.keys(lead)[21], 31:Object.keys(lead)[22], 32:Object.keys(lead)[23], 33:Object.keys(lead)[24], 34:Object.keys(lead)[25], 35:Object.keys(lead)[26],
				36:Object.keys(lead)[27], 37:Object.keys(lead)[28], 38:Object.keys(lead)[29], 39:Object.keys(lead)[32], 40:Object.keys(lead)[33], 41:Object.keys(lead)[34],
				42:Object.keys(lead)[31], 43:Object.keys(lead)[32], 44:Object.keys(lead)[44],
			};
			// console.log(sortedValuesFromLead);
			defaultValuesFromLead = [...defaultValuesFromLead,...Object.values(sortedValuesFromLead)]; 
		} );

		defaultValuesFromLead = [...new Set(defaultValuesFromLead)];

		// console.log(defaultValuesFromLead);
		defaultValuesFromLead.forEach((col:string) => {
			// now removing underScore and capital next char
			const newStr: string = convertSnakeToCamelCase(col || '');
			// appending the default cols with title in column
			if(collegeDefaultFields[newStr]?.title){
				columns.splice(columns.length-1,0,
					{
						title: collegeDefaultFields[newStr]?.title || newStr,
						dataIndex: col,
						key: col,
						width: 190,
						ellipsis: true,
					}
				);
			}
		});

		// now append that in columns for table
		for (const property in tempColsForTable) {
			columns.splice(columns.length-1,0,
				{
					title: tempColsForTable[property]?.title || property,
					dataIndex: property,
					key: property,
					width: 190,
					ellipsis: true,
				}
			);
		}

		// columns = [...columns, ...tempCols];
		setFinalCols(columns);

		// ================================
		// logic for table columns set ends
		// ================================
		

		// appned dynamic_lead data to main object
		data.forEach((lead:any,leadIndex:number) => {
			// lead.current_stage = leadActivityStatus[lead.current_status]?.leadActivityStage;
			
			// set the label text for the column data asgetting the int value
			lead.current_status = leadStatus[lead.current_status]?.label;
			lead.current_lead_assign_to_date = convertTime(lead.current_lead_assign_to_date);
			lead.current_follow_up_date = convertTime(lead.current_follow_up_date);
			lead.current_last_called = convertTime(lead.current_last_called);
			lead.currently_assign_to = (lead.currently_assign_to && assignToDetails[lead.currently_assign_to]) ? assignToDetails[lead.currently_assign_to].name : null;
			
			// setting the values for dropdown fields as while editing we send the record data from table which is sending only label text for the dropdown field to set
			lead.sourceValue = sourceOptions[lead.source]?.value;
			lead.pref_stateValue = states[lead.pref_state]?.value;
			lead.stateValue = states[lead.state]?.value;

			lead.source = sourceOptions[lead.source]?.label;
			lead.pref_state = states[lead.pref_state]?.label;
			lead.state = states[lead.state]?.label;

			const dynamicField = lead?.dynamic_fields;
			for (const property in dynamicField) {
				data[leadIndex][property] = dynamicField[property].response;
				
			}
			lead.SrNum = (currentPage - 1) * perPageCount + leadIndex + 1;
		});

		settableData(data);
	};

	const setAddLeadModalList:any = (data:any, sortField: string, sortOrder: any, currentPage: number, perPageCount:number) => {
		addLeadsModalTableColumns.forEach((column: any) => {
			if(sortField && sortOrder && column.dataIndex === sortField){
				column.sortOrder = sortOrder || null;
			}
		});
		setFinalAddLeadCols(addLeadsModalTableColumns);

		data.forEach((lead:any, leadIndex:number) => {
			lead.SrNum = (currentPage - 1) * perPageCount + leadIndex + 1;
		});

		setAddLeadtableData(data);
	};

	const bulkRemoveLeads = () => {
		if(leadsTableSelectedRowKeys && leadsTableSelectedRowKeys.length){
			handleRemoveLeadsFromGroup(leadsTableSelectedRowKeys);
			setLeadsTableSelectedRowKeys([]);
		} else {
			message.error('Please select the leads to delete');
		}
	};

	let timerId: any;
	const onLeadsTableSearchInputChange = (e: any) => {
		clearTimeout(timerId);
		const searchValueTrimmed = e.target.value.trim();
		timerId = setTimeout(() => {
			setLeadsTableSearchValue(searchValueTrimmed);
			paginationData.searchValue = searchValueTrimmed;

			//set the table page no. to 1
			paginationData.pageNumber = 1;

			setPaginationData(paginationData);
			handleLeadList(paginationData);
		},500);
	};

	let addLeadtimerId: any;
	const onAddLeadsTableSearchInputChange = (e: any) => {
		clearTimeout(addLeadtimerId);

		addLeadtimerId = setTimeout(() => {
			setAddLeadsTableSearchValue(e.target.value);
			addLeadpaginationData.searchValue = e.target.value;

			//set the table page no. to 1
			addLeadpaginationData.pageNumber = 1;

			setAddLeadPaginationData(addLeadpaginationData);
			handleAddLeadModalList(addLeadpaginationData);
		},500);
	};

	useEffect(() => {
		if(sessionData && leadStages.length && Object.keys(sourceOptions).length){
			handleLeadList(paginationData);
			handleAssignList();
		}
	}, [sessionData, leadStages, sourceOptions] );

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<LeadsContentSubHeader 
				// groupId= {id}
				isGroup = {true}
				isActivityPage = {false}
				isLeadActivityPage = {false}
				isBulkAvtivity = {false}
				isLeadsCommentActivity = {false}
				groupDetails={groupDetails}
				leadDetails={{}}
			/>
			<div className={styles.leadReportBody} id='leadReportBody'>
				<SearchByFilterComponent 
					handleLeadList={handleLeadList}
					setLeadsFilters={setLeadsFilters}
					paginationData={paginationData}
					assignToList={assignToList}
					setPaginationData={setPaginationData}
					leadStages={leadStages}
					leadStatus={leadStatus}
					sourceOptions={sourceOptions}
				/>
				<div className={styles.listLeadsSreenbodyheader}>
					<div className={styles.listLeadsSreenbodyheaderLeft}>
						{useCheckAttribute(resources.lead.value, attributes.list.value) && (
							<Button type="primary" onClick={openAddLead}>
								Add Leads
							</Button>
						)}
						{(leadsTableSelectedRowKeys && leadsTableSelectedRowKeys.length) ? 
							<Tooltip placement='bottom' title='Remove Leads'>
								<Popconfirm
									title="Delete the selected lead"
									description="Are you sure to delete the selected leads?"
									placement="right"
									okText="Yes"
									cancelText="No"
									onConfirm={bulkRemoveLeads}
  								>
									<Button className={styles.btnThemeSecondary} type="primary">
										<img src={userDeleteIcon} width='20px'/>
									</Button>
								</Popconfirm>
							</Tooltip> : 
							<Tooltip placement='bottom' title='Remove Leads'>
								<Button 
									className={styles.btnThemeSecondary} 
									type="primary"
									onClick={() => message.error('Please select at least 1 lead to remove')}
								>
									<img src={userDeleteIcon} width='20px'/>
								</Button>
							</Tooltip>
						}
					</div>
					<div className={styles.listLeadsSreenbodyheaderRight}>
						<SearchField 
							onSearchInputChange={onLeadsTableSearchInputChange}
							isAddLeadModal={false}
						/>
					</div>
				</div>
				<Modal 
					title="Add Leads Into The Group" 
					open={openAddLeadModal} 
					onOk={onAddLead} 
					onCancel={closeAddLead}
					confirmLoading={tableLoading}
					width={'90%'}
					okText='Add'
					className='addLeadModal'
				>
					<SearchField 
						onSearchInputChange={onAddLeadsTableSearchInputChange}
						isAddLeadModal={true}
					/>
					<TableComponent
						tableColumns={finalAddLeadCols}
						listData={addLeadtableData}
						rowSelection={addLeadModalRowSelection}
						handleTableChange={handleAddLeadTableChange}
						isReportPage={false}
						perPageCount={addLeadperPageCount}
						totalListDataCount={addLeadTotalListDataCount}
						loading={tableLoading}
						currentPage={addLeadCurrentPage}
					/>
     			</Modal>
				<LeadDetalisModal 
					leadDetailModalOpen={leadDetailModalOpen}
					setLeadDetailModalOpen={setLeadDetailModalOpen}
					onleadDetailModalClose={onleadDetailModalClose}
					handleCreateOneLead = {handleCreateOneLead}
					isEdit = {isEdit}
					oneLeadData = {oneLeadData}
					handleUpdateOneLead = {handleUpdateOneLead}
					leadListData = {leadListData}
					setDetailModalBtnLoading={setDetailModalBtnLoading}
					detailModalBtnLoading={detailModalBtnLoading}
					leadDetailModalSubmitBtnRef={leadDetailModalSubmitBtnRef}
					sourceOptions={sourceOptions}
				/>
				<div className={styles.listLeadsSreenbodyTable}>
					<TableComponent 
						tableColumns={finalCols}
						listData={tableData}
						rowSelection={leadsTableRowSelection}
						handleTableChange={handleTableChange}
						isReportPage={true}
						perPageCount={perPageCount}
						totalListDataCount={totalListDataCount}
						loading={tableLoading}
						currentPage={currentPage}
					/>
				</div>
			</div>
			<FixedFilterComponent 
				leadsTableSelectedRowKeys={leadsTableSelectedRowKeys}
				handleUpdateLeadStatus = {handleUpdateLeadStatus}
				handleUpdateAssignTo = {handleUpdateAssignTo}
				handleUnAssignLeads = {handleUnAssignLeads}
				handleUpdateFollowUpLastCalled = {handleUpdateFollowUpLastCalled}
				createActivityBeforeDownload = {createActivityBeforeDownload}
				assignToList={assignToList}
				tableData={tableData}
				finalCols={finalCols}
				xlsxFileName={groupDetails?.title}
				leadStages={leadStages}
				leadStatus={leadStatus}
			/>
		</>
	);		
};