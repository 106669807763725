import React,{useState} from 'react';
import { Popover,ConfigProvider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import styles from './header.module.css';
import { LogoutOutlined } from '@ant-design/icons';
import Logo from '../../assets/img/cq-logo.svg';
import Profilepic from '../../assets/img/profile-pic.png';
import { Button } from 'antd';
import {Submenu} from '../submenu';
import { SettingOutlined } from '@ant-design/icons';
import { ChangePassModal } from '../changePassModal';

interface headerProps {
	sessionData: sessionData | null,
	logoutUser: () => void
}

export const Header: React.FunctionComponent<headerProps> = ( props ) => {
	const {
		sessionData,
		logoutUser,
	} = props;

	const [open, setOpen] = useState(false);
	const [isChangePassword, setIsChangePassword] = useState<boolean>(false);

	const navigate = useNavigate();

	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	//adding the contents logout 
	const content = (
		<div className={styles.contentContainer} role='profileSectionDropdown'>
			<div onClick={()=>setIsChangePassword(true)} className= {styles.contentInnerContainer} role='changePassword'>
				<SettingOutlined style={{fontSize:'20px',color:'#de6834'}}/>
				<p>Change Password</p>
			</div>
			<div onClick={logoutUser} className= {styles.contentInnerContainer}>
				<LogoutOutlined style={{fontSize:'20px',color:'#de6834'}}/>
				<p>Logout</p>
			</div>
		</div>
	);
	
	return (
		<div className={styles.appHeader} role='headerComponent'>
			<div className={styles.leftappHeader}>
				<Button 
					type="link"
					onClick={() => navigate('/')} 
					icon={<img role='superCoderLogo' alt="logo" src={Logo} height="35px" />}
          		/>
				{/* <a href=''>Home</a>
				<a href=''>Home</a>
				<a href=''>Home</a> */}
			</div>
			<div className={styles.rightappHeader}>
				<Submenu isAdmin={sessionData?.isAdmin} isLeadManager={sessionData?.isLeadManager}/>
				<ConfigProvider
					theme={{
						token: {
							sizePopupArrow:0,
							
						},
					}}
				>
					<Popover
						placement="bottomRight"
						content={content}
						trigger="click"
						open={open}
						onOpenChange={handleOpenChange}
					>
						<div className={styles.userInfoOuterContainer} role='profileSection'>
							<div className={styles.userInfoContainer}>
								<span className={styles.profileName} role='userProfileName'>
									{sessionData?.name}
								</span>
								<span className={styles.profileRole} role='userRoleName' >
									{sessionData?.roleName}
								</span>
							</div>
							<div className={styles.profilePicContainer}>
								<img src={Profilepic} alt="" height="35px" width='40px' />
							</div>
						</div>
					</Popover>			
				</ConfigProvider>

				
			</div>

			<ChangePassModal isVisible={isChangePassword} isVisibleChange={setIsChangePassword} />
		</div>
	);
};