import states from './location';

export const formFieldTypes: any = {
	'Number': 1,
	'Text': 2,
	'Dropdown': 3,
	'Phone Number': 4,
	'Year': 5,
	'MultiSelectDropdown': 6
};

// export const sources: any = {
// 	1: { value: 1, label: 'Facebook' },
// 	2: { value: 2, label: 'Google' },
// 	3: { value: 3, label: 'Instagram' },
// 	4: { value: 4, label: 'Organic' },
// 	5: { value: 5, label: 'Offline Campaign' },
// 	6: { value: 6, label: 'LinkedIn' },
// 	7: { value: 7, label: 'YouTube' },
// 	8: { value: 8, label: 'Schools' },
// 	9: { value: 9, label: 'Others' },
// 	10: { value: 10, label: 'Collegedunia' },
// };

export const months: any = {
	1 : {
		value:1, label: 'January'
	},
	2 : {
		value:2, label: 'February'
	},
	3 : {
		value:3, label: 'March'
	},
	4 : {
		value:4, label: 'April'
	},
	5 : {
		value:5, label: 'May'
	},
	6 : {
		value:6, label: 'June'
	},
	7 : {
		value:7, label: 'July'
	},
	8 : {
		value:8, label: 'August'
	},
	9 : {
		value:9, label: 'September'
	},
	10 : {
		value:10, label: 'October'
	},
	11 : {
		value:11, label: 'November'
	},
	12 : {
		value:12, label: 'December'
	},
};

export const LeadSeekers: any = {
	College: {
		title: 'College Fields',
		fields: {
			alternateNumber: {
				title: 'Alternate Number',
				type: formFieldTypes['Phone Number']
			},
			matricYear: {
				title: 'Matric Year',
				type: formFieldTypes['Year']
			},
			matricScore: {
				title: 'Matric Score',
				type: formFieldTypes['Number']
			},
			seniorSecondaryYear: {
				title: 'Senior Secondary Year',
				type: formFieldTypes['Year']
			},
			seniorSecondaryYearScore: {
				title: 'Senior Secondary Year Score',
				type: formFieldTypes['Number']
			},
			ugYear: {
				title: 'UG Year',
				type: formFieldTypes['Year']
			},
			ugScore: {
				title: 'UG Score',
				type: formFieldTypes['Number']
			},
			pgOrOther: {
				title: 'PG Or Other',
				type: formFieldTypes['Text']
			},
			pgOrOtherScore: {
				title: 'PG or Other Score',
				type: formFieldTypes['Number']
			},
			testTaken: {
				title: 'Test Taken',
				type: formFieldTypes['Text']
			},
			testScore: {
				title: 'Test Score',
				type: formFieldTypes['Number']
			},
			testPlanMonth: {
				title: 'Test Plan Month',
				type: formFieldTypes['Dropdown'],
				values: months
			},
			programType: {
				title: 'Program Type',
				type: formFieldTypes['Text']
			},
			state: {
				title: 'State',
				type: formFieldTypes['Dropdown'],
				values: states
			},
			source: {
				title: 'Source',
				type: formFieldTypes['Dropdown'],
				values: {}
			},
			// currentStage: {
			// 	title: 'Stage',
			// 	type: formFieldTypes['text'],
			// 	values: {}
			// },
			currentStatus: {
				title: 'Status',
				type: formFieldTypes['text'],
				values: {}
			},
			comment: {
				title: 'Remark',
				type: formFieldTypes['text'],
				values: {}
			},
			currentlyAssignTo: {
				title: 'Assigned To',
				type: formFieldTypes['text'],
				values: {}
			},
			currentFollowUpDate: {
				title: 'Follow up Date',
				type: formFieldTypes['text'],
				values: {}
			},
			currentLeadAssignToDate: {
				title: 'Lead Assigned Date',
				type: formFieldTypes['text'],
				values: {}
			},
			currentLastCalled: {
				title: 'Last Called',
				type: formFieldTypes['text'],
				values: {}
			},
			city: {
				title: 'City',
				type: formFieldTypes['Text']
			},
			prefState: {
				title: 'Prefered State',
				type: formFieldTypes['MultiSelectDropdown'],
				values: states
			},
			prefCity: {
				title: 'Prefered City',
				type: formFieldTypes['Text']
			},
			location: {
				title: 'Location',
				type: formFieldTypes['Text']
			},
			intakeYear: {
				title: 'Intake Year',
				type: formFieldTypes['Year']
			},
			financialStatus: {
				title: 'Financial Status',
				type: formFieldTypes['Text']
			},
			referralCode: {
				title: 'Referral Code',
				type: formFieldTypes['Text']
			},
		},
	},
	Internet: {
		title: 'Internet Fields',
		fields: {
			alternateNumber: {
				title: 'Alternate Number',
				type: formFieldTypes['Number']
			},
			location: {
				title: 'Location',
				type: formFieldTypes['Text']
			},
		},
	}
};

export const activityType = {
	1: { value:1, label: 'Add' },
	2: { value:2, label: 'Lead deleted' },
	3: { value:3, label: 'Lead created' },
	4: { value:4, label: 'Lead Updated' },
	5: { value:5, label: 'Leads added in group' },
	6: { value:6, label: 'Leads removed from group' },
	7: { value:7, label: 'Leads added in bulk' },
	8: { value:8, label: 'Status updated' },
	9: { value:9, label: 'Assign to removed' },
	10: { value:10, label: 'Follow up & last called updated' },
	11: { value:11, label: 'Assign to updated' },
	12: { value:12, label: 'Duplicate Leads' },
	13: { value:13, label: 'Leads already present' },
	14: { value:14, label: 'Leads Inserted' },
	15: { value:15, label: 'Leads not inserted' },
};

export const leadActivityStatus: any = {
	'1': { value: 1, label: 'No Answer', leadActivityStage: 'Cold' },
	'2': { value: 2, label: 'Number Busy', leadActivityStage: 'Cold' },
	'3': { value: 3, label: 'Not Reachable', leadActivityStage: 'Cold' },
	'4': { value: 4, label: 'Out of Network', leadActivityStage: 'Cold' },
	'5': { value: 5, label: 'Next Year', leadActivityStage: 'Cold' },
	'6': { value: 6, label: 'Incomming call Facility Not Available', leadActivityStage: 'Cold' },
	'7': { value: 7, label: 'Interested', leadActivityStage: 'Warm' },
	'8': { value: 8, label: 'Call Later', leadActivityStage: 'Warm' },
	'9': { value: 9, label: 'Walk In Done on campus', leadActivityStage: 'Warm' },
	'10': { value: 10, label: 'Follow Up', leadActivityStage: 'Warm' },
	'11': { value: 11, label: 'Test Need To Schedule', leadActivityStage: 'Hot' },
	'12': { value: 12, label: 'Send To Counsellor', leadActivityStage: 'Hot' },
	'13': { value: 13, label: 'Walk In', leadActivityStage: 'Hot' },
	'14': { value: 14, label: 'Test/GDPI Done', leadActivityStage: 'Hot' },
	'15': { value: 15, label: 'Offer Letter Released', leadActivityStage: 'Hot' },
	'16': { value: 16, label: 'Ready To take Admission', leadActivityStage: 'Hot' },
	'17': { value: 17, label: 'Form Filled', leadActivityStage: 'Hot' },
	'18': { value: 18, label: 'Admission Done', leadActivityStage: 'Hot' },
	'19': { value: 19, label: 'Not Interested', leadActivityStage: 'Junk' },
	'20': { value: 20, label: 'Budget Problem', leadActivityStage: 'Junk' },
	'21': { value: 21, label: 'Taken Admission', leadActivityStage: 'Junk' },
	'22': { value: 22, label: 'Wrong Number', leadActivityStage: 'Junk' },
	'23': { value: 23, label: 'Language Problem', leadActivityStage: 'Junk' },
	'24': { value: 24, label: 'Not Eligible', leadActivityStage: 'Junk' },
	'25': { value: 25, label: 'Fail In Test', leadActivityStage: 'Junk' },
	'26': { value: 26, label: 'Drop The Plan', leadActivityStage: 'Junk' },
	'27': { value: 27, label: 'Registration/ 1st Sem fee Paid', leadActivityStage: 'Hot' },
	'28': { value: 28, label: 'New Lead', leadActivityStage: 'Fresh' },
	'30': { value: 30, label: 'Payment Link Generated', leadActivityStage: 'Hot' },
	'31': { value: 31, label: 'Payment Successful', leadActivityStage: 'Hot' },
	'32': { value: 32, label: 'Payment Failed', leadActivityStage: 'Hot' },
};

// export const leadActivityHotStatus = {
// 	formFilled: leadActivityStatus[17],
// 	scheduleTest: leadActivityStatus[11],
// 	sendToCounsellor: leadActivityStatus[12],
// 	walkIn: leadActivityStatus[13],
// 	testDone: leadActivityStatus[14],
// 	offerLetterReleased: leadActivityStatus[15],
// 	readyForAdmission: leadActivityStatus[16],
// 	registrationDone: leadActivityStatus[27],
// 	admissionDone: leadActivityStatus[18],
// 	linkGenerated: leadActivityStatus[30],
// 	paymentDone: leadActivityStatus[31],
// 	paymentFailed: leadActivityStatus[32],
// };

// export const leadActivityColdStatus = {
// 	noAnswer: leadActivityStatus[1],
// 	numberBusy: leadActivityStatus[2],
// 	notReachable: leadActivityStatus[3],
// 	outOfNetwork: leadActivityStatus[4],
// 	nextYear: leadActivityStatus[5],
// 	noIncomingCallFacility: leadActivityStatus[6],
// };

// export const leadActivityWarmStatus = {
// 	interested: leadActivityStatus[7],
// 	callLater: leadActivityStatus[8],
// 	walkInDoneInCampus: leadActivityStatus[9],
// 	followUp: leadActivityStatus[10],
// };

// export const leadActivityJunkStatus = {
// 	notInterested: leadActivityStatus[19],
// 	budgetProblem: leadActivityStatus[20],
// 	takenAdminssion: leadActivityStatus[21],
// 	wrongNumber: leadActivityStatus[22],
// 	languageProblem: leadActivityStatus[23],
// 	notEligible: leadActivityStatus[24],
// 	testFailed: leadActivityStatus[25],
// 	dropPlan: leadActivityStatus[26],
// };

// export const leadActivityStatusOptions: any = [
// 	{ value: 5, label: 'Hot', 
// 		children: [
// 			{ value: 11, label: leadActivityStatus[11].label},
// 			{ value: 12, label: leadActivityStatus[12].label},
// 			{ value: 13, label: leadActivityStatus[13].label},
// 			{ value: 14, label: leadActivityStatus[14].label},
// 			{ value: 15, label: leadActivityStatus[15].label},
// 			{ value: 16, label: leadActivityStatus[16].label},
// 			{ value: 17, label: leadActivityStatus[17].label},
// 			{ value: 18, label: leadActivityStatus[18].label},
// 			{ value: 27, label: leadActivityStatus[27].label},
// 			{ value: 30, label: leadActivityStatus[30].label},
// 			{ value: 31, label: leadActivityStatus[31].label},
// 			{ value: 32, label: leadActivityStatus[32].label},
// 		] 
// 	},
// 	{ value: 15, label: 'Cold', 
// 		children: [
// 			{ value: 1, label: leadActivityStatus[1].label},
// 			{ value: 2, label: leadActivityStatus[2].label},
// 			{ value: 3, label: leadActivityStatus[3].label},
// 			{ value: 4, label: leadActivityStatus[4].label},
// 			{ value: 5, label: leadActivityStatus[5].label},
// 		] 
// 	},
// 	{ value: 18, label: 'Warm', 
// 		children: [
// 			{ value: 7, label: leadActivityStatus[7].label},
// 			{ value: 8, label: leadActivityStatus[8].label},
// 			{ value: 9, label: leadActivityStatus[9].label},
// 			{ value: 10, label: leadActivityStatus[10].label},
// 		] 
// 	},
// 	{ value: 20, label: 'Junk', 
// 		children: [
// 			{ value: 19, label: leadActivityStatus[19].label},
// 			{ value: 20, label: leadActivityStatus[20].label},
// 			{ value: 21, label: leadActivityStatus[21].label},
// 			{ value: 22, label: leadActivityStatus[22].label},
// 			{ value: 23, label: leadActivityStatus[23].label},
// 			{ value: 24, label: leadActivityStatus[24].label},
// 			{ value: 25, label: leadActivityStatus[25].label},
// 			{ value: 26, label: leadActivityStatus[26].label},
// 		] 
// 	},
// 	{ value: 30, label: 'Fresh', children: [ { value: 28, label: leadActivityStatus[28].label} ] },
// ];

// const hotStatusValues: number[] =[];
// const coldStatusValues: number[] =[];
// const warmStatusValues: number[] =[];
// const junkStatusValues: number[] =[];
// const freshStatusValues: number[] =[];

// Object.values(leadActivityStatus).forEach((val: any) => {
// 	if(val.leadActivityStage === 'Hot') hotStatusValues.push(val.value);
// 	else if(val.leadActivityStage === 'Cold') coldStatusValues.push(val.value);
// 	else if(val.leadActivityStage === 'Warm') warmStatusValues.push(val.value);
// 	else if(val.leadActivityStage === 'Junk') junkStatusValues.push(val.value);
// 	else freshStatusValues.push(val.value);
// });

// export const leadActivityStatusOptions: leadActivityStatusOptions[] = [
// 	{ 
// 		value: hotStatusValues,
// 		label: 'Hot', 
// 		// children: [
// 		// 	{ value: 11, label: leadActivityStatus[11].label},
// 		// 	{ value: 12, label: leadActivityStatus[12].label},
// 		// 	{ value: 13, label: leadActivityStatus[13].label},
// 		// 	{ value: 14, label: leadActivityStatus[14].label},
// 		// 	{ value: 15, label: leadActivityStatus[15].label},
// 		// 	{ value: 16, label: leadActivityStatus[16].label},
// 		// 	{ value: 17, label: leadActivityStatus[17].label},
// 		// 	{ value: 18, label: leadActivityStatus[18].label},
// 		// 	{ value: 27, label: leadActivityStatus[27].label},
// 		// 	{ value: 30, label: leadActivityStatus[30].label},
// 		// 	{ value: 31, label: leadActivityStatus[31].label},
// 		// 	{ value: 32, label: leadActivityStatus[32].label},
// 		// ]
// 		children: Object.values(leadActivityStatus).filter((val: any) => {
// 			if(val.leadActivityStage === 'Hot') return {value: val.value, label: val.label};
// 		})
// 	},
// 	{ 
// 		value: coldStatusValues, 
// 		label: 'Cold', 
// 		// children: [
// 		// 	{ value: 1, label: leadActivityStatus[1].label},
// 		// 	{ value: 2, label: leadActivityStatus[2].label},
// 		// 	{ value: 3, label: leadActivityStatus[3].label},
// 		// 	{ value: 4, label: leadActivityStatus[4].label},
// 		// 	{ value: 5, label: leadActivityStatus[5].label},
// 		// ] 
// 		children: Object.values(leadActivityStatus).filter((val: any) => {
// 			if(val.leadActivityStage === 'Cold') return {value: val.value, label: val.label};
// 		})
// 	},
// 	{ 
// 		value: warmStatusValues, 
// 		label: 'Warm', 
// 		// children: [
// 		// 	{ value: 7, label: leadActivityStatus[7].label},
// 		// 	{ value: 8, label: leadActivityStatus[8].label},
// 		// 	{ value: 9, label: leadActivityStatus[9].label},
// 		// 	{ value: 10, label: leadActivityStatus[10].label},
// 		// ] 
// 		children: Object.values(leadActivityStatus).filter((val: any) => {
// 			if(val.leadActivityStage === 'Warm') return {value: val.value, label: val.label};
// 		})
// 	},
// 	{ 
// 		value: junkStatusValues, 
// 		label: 'Junk', 
// 		// children: [
// 		// 	{ value: 19, label: leadActivityStatus[19].label},
// 		// 	{ value: 20, label: leadActivityStatus[20].label},
// 		// 	{ value: 21, label: leadActivityStatus[21].label},
// 		// 	{ value: 22, label: leadActivityStatus[22].label},
// 		// 	{ value: 23, label: leadActivityStatus[23].label},
// 		// 	{ value: 24, label: leadActivityStatus[24].label},
// 		// 	{ value: 25, label: leadActivityStatus[25].label},
// 		// 	{ value: 26, label: leadActivityStatus[26].label},
// 		// ] 
// 		children: Object.values(leadActivityStatus).filter((val: any) => {
// 			if(val.leadActivityStage === 'Junk') return {value: val.value, label: val.label};
// 		})
// 	},
// 	{ 
// 		value: freshStatusValues, 
// 		label: 'Fresh', 
// 		// children: [ { value: 28, label: leadActivityStatus[28].label} ] 
// 		children: Object.values(leadActivityStatus).filter((val: any) => {
// 			if(val.leadActivityStage === 'Fresh') return {value: val.value, label: val.label};
// 		})
// 	},
// ];


// export const leadActivityFreshStatus = {
// 	new: leadActivityStatus[28],
// };

// export {default as states} from './location';