import React, { useCallback, useContext, useEffect, useState } from 'react';
import {Button, Cascader, Input, Select, message, } from 'antd';
import styles from './ruleEngineThenModel.module.css';
import { CloseOutlined } from '@ant-design/icons/lib/icons';
import { ConditionContext } from '../../screens/rule-engine';
import { InputElementProvider } from '../inputElementProvider';
import { v4 as uuid } from 'uuid';
import { Dayjs } from 'dayjs';

export const RuleEngineThenModel = (props: any) => {
	const { thenCondition, reRender, options, communicationObject} = props;

	// let communicationMode;
	// if (communicationObject?.noOfOccurences) {
	// 	communicationMode = 'noOfOccurences';
	// 	delete communicationObject.immediate;
	// } else if (communicationObject?.immediate) {
	// 	communicationMode = 'immediate';
	// } else {
	// 	communicationMode = '';
	// }

	const removeFromThenCondition = (key: string)=>{
		delete thenCondition[key];
		setLengthOfThen(lengthofThen-1);
		reRender();
	};

	const removeOneLength = () => {
		setLengthOfThen(lengthofThen-1);
		reRender();
	};

	const insertOrEditValueThen = (key: string, value: any, previousKey: string | undefined) => {
		if(previousKey){
			delete thenCondition[previousKey];
		}
		if(thenCondition[key]){
			message.error('Already in use');
			reRender();
			return ;
		}
		thenCondition[key] = value;
		reRender();
	};
 
	const [lengthofThen, setLengthOfThen ] = useState(
		(Object.keys(thenCondition).length!==0)?Object.keys(thenCondition).length:1);

	interface Option{
		value: string;
		label: string;
		children?: Option[];
	  }

	const addNewThenCondition = () => {
		setLengthOfThen(lengthofThen+1);
		reRender();
	};
	
	let keysOfThen: Array<string> = [];

	keysOfThen = Object.keys(thenCondition).reduce( (result,current:string) => {
		result.push(current);
		return result;
	},keysOfThen);
    
	const newArray = new Array(lengthofThen - Object.keys(thenCondition).length).fill('test');
	// const communicationOptions: Option[] = [
	// 	{ value: 'immediate', label: 'Immediate' },
	// 	{ value: 'noOfOccurences', label: 'No. Of Occurences' },
	//   ];

	// const onChangeOnCommunicationChannel = (value: string) => {
	// 	console.log(value);
	// 	if(value === 'immediate') {
	// 		delete communicationObject.noOfOccurences;
	// 		communicationObject.immediate = {
	// 			senderName: '',
	// 			emailAddress: '',
	// 			replyEmailAddress: '',
	// 		};
	// 	} else if (value === 'noOfOccurences'){
	// 		delete communicationObject.immediate;
	// 		communicationObject.noOfOccurences = {
	// 			senderName: '',
	// 			emailAddress: '',
	// 			replyEmailAddress: '',	
	// 			noOfOccurences:'',
	// 		};
	// 	} else {
	// 		delete communicationObject.immediate;
	// 		delete communicationObject.noOfOccurences;
	// 	}
	// 	reRender();
	// };
    
	return (
		<div className={styles.mainBody}>
			<div>
				<Button className={styles.addBtnThenCondition} type='primary' onClick={addNewThenCondition}>Add</Button>
			</div>
			<div className={styles.thenConditionContainer}>
				{keysOfThen.map((element: any,index: number) => {
					return (<div key={element} className={styles.thenIndividualContainer}>
						<ThenCondition insertFunction={insertOrEditValueThen} previousKey={element} values={thenCondition[element] ?? { value: '', contentType: '' }}/>
						{lengthofThen > 1 &&
							<CloseOutlined onClick={()=>removeFromThenCondition(element)} />
						}
					</div>);
				})}
				{newArray.map((element,index) => {
					return (<div key={uuid()} className={styles.thenIndividualContainer}>
						<ThenCondition options={options} insertFunction={insertOrEditValueThen} previousKey={undefined} value={undefined ?? { value: '', contentType: '' }}/>
						{lengthofThen > 1 &&
							<CloseOutlined onClick={()=>removeOneLength()} />
						}
					</div>);
				})}
			</div>
			{/* 	Communication Model
				<p>AND</p>
			<Select
				value={communicationMode}
				style={{ width: 200 }}
				options={communicationOptions}
				allowClear
				onChange={onChangeOnCommunicationChannel}
			/>
			<div>
				{communicationMode &&
					<>
						<Input placeholder='Sender Name' type='text'/>
						<Input placeholder='Sender Email' type='email'/>
						<Input placeholder='Sender ReplyEmail' type='email'/>
					</>
				}
				{communicationMode === 'immediate' &&
						<></>
				}
				{communicationMode === 'noOfOccurences' &&
					<Input placeholder={'Enter Number of occurences'} />
				}
			</div> */}
		</div>
	);
};


const ThenCondition = (props : any) => {

	const {insertFunction, previousKey, values} = props;
	const {conditions, refData, fetchDataForDynamicLink} = useContext(ConditionContext);

	const [fact, setFact] = useState(previousKey ?? '');
	const [value, setValue] = useState(values?.value ?? '');
	const [valueList, setValueList] = useState<any>();
	const [valueInputType, setValueIntputType] = useState<number>(values?.contentType ?? 0);
	const [loadingValues, setLoadingValues] = useState(false);

	const getValueFromUrl = useCallback(async (url: string) => {
		try{
			const data = await fetchDataForDynamicLink(url);
			setValueList(data);
		} catch (error: any) {
			setLoadingValues(false);
			message.error(error?.message ?? error);
		}
	},[setValueList, setLoadingValues, fetchDataForDynamicLink]);

	useEffect(() => {
		if(fact){
			if(conditions[fact].url){
				getValueFromUrl(conditions[fact].url);
				setValueIntputType(3);
			} else if(refData[fact]){
				setValueList(refData[fact]);
				setValueIntputType(3);
			} else {
				setValueIntputType(conditions[fact].type.value);
			}
		} else {
			setValueList([]);
		}

	},[fact]);

	const {Option} = Select;

	const handleOnChangeForFact = (value:string) => {
		insertFunction(value,{value: '', contentType: ''}, previousKey);
		console.log(value);
		setFact(value);
		setValue('');
	};

	const handleOnChangeForValues = (value: any) => {
		let valueLabel;
		if(valueInputType === 3) {
			valueLabel = valueList[value].label;
		}
		if(valueInputType === 4) {
			if(value)	value = (value as Dayjs).millisecond(0).second(0).toISOString();
		}
		insertFunction(fact,{value, contentType: valueInputType, valueLabel}, previousKey);
		setValue(value);
	};

	return (
		<div className={styles.thenSingleElement}>
			<Select
				value={fact}
				style={{width:'150px'}}
				onChange={handleOnChangeForFact}
				showSearch
				filterOption={(inputValue: any, option: any) =>
					option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
				}
			>
				{(Object.keys(conditions ?? {})).map((element: any) => {
					return (
						<Option key={element} value={element}>
							{conditions[element].label}
						</Option>
					);
				})
				}
			</Select>

			{
				<InputElementProvider loading={loadingValues} value={value} onChange={handleOnChangeForValues} inputType={valueInputType} list={valueList} width={'200px'}/>
			}


		</div>
	);
};
