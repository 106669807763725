import React, { ReactComponentElement, ReactElement, useState, useEffect } from 'react';
import { CardConstant, ExportCSVName } from '../../config/report';
import {Card} from 'antd';
import { CardModeData, Caller } from '../../@types/report';
import styles from './card.module.css';

import { useAppStore } from '../../stores';

import {RiseOutlined, FallOutlined} from '@ant-design/icons';
import dayjs from 'dayjs';
interface CardModelProp{
	dateRange: any,
	selectedCallerId: string | undefined,
	selectCard: any,
}
const appStoreSelector = (state: AppState) => ({
	getLeadCount: state.getLeadCount,
	getCardData: state.getCardData,
});

export const CardModel = (props:CardModelProp) => {
	const {dateRange, selectedCallerId, selectCard } = props;
	const { getLeadCount, getCardData } = useAppStore(appStoreSelector);

	const [leadCount, setLeadCount] = useState<CardModeData | undefined | null >();
	const [totalApplications, setTotalApplications] = useState<CardModeData | undefined | null>();
	const [scheduleTestCount, setScheduleTestCount] = useState<CardModeData | undefined | null >();
	const [walkInCount, setWalkInCount] = useState<CardModeData | undefined | null> ();
	const [offerLetterReleasedCount, setOfferLetterReleasedCount] = useState< CardModeData | undefined | null > ();
	const [readyForAdmissionCount, setReadyForAdmission] = useState< CardModeData | undefined | null > ();
	const [registrationDoneCount, setRegistrationDoneCount] = useState< CardModeData | undefined | null > ();
	const [admissionDoneCount, setAdmissionDoneCount] = useState< CardModeData | undefined | null > ();
	const [linkGeneratedCount, setLinkGeneratedCount] = useState< CardModeData | undefined | null > ();
	const [paymentFailedCount, setPaymentFailedCount] = useState< CardModeData | undefined | null> ();
	const [noAnswerCount, setNoAnswerCount] = useState< CardModeData | undefined | null> ();
	const [interestedCount, setInterestedCount] = useState< CardModeData | undefined | null> ();
	const [notInterested, setNotInterested] = useState< CardModeData | undefined | null> (null);
	const getAllData = () => {
		getLeadsData();
		getTotalApplicationData();
		getScheduleTestCount();
		getWalkInCount();
		getOfferLetterReleasedCount();
		getRegistrationDoneCount();
		getReadyForAdmissionCount();
		getAdmissionDoneCount();
		getLinkGeneratedCount();
		getPaymentFailedCount();
		getNoAnswerCount();
		// getNotInterested();
		getInterestedCount();
	};


	const getLeadsData = async () => {
		try{
			const response = await getLeadCount(dateRange[0], dateRange[1], selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setLeadCount(response.data);
		} catch(err: any){
			setLeadCount(null);
		}
	};

	const getTotalApplicationData = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.totalApplications.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setTotalApplications(response?.data);
		} catch (error: any){
			setTotalApplications(null);
		}
	};

	const getScheduleTestCount = async () => {
		try{
			const response = await await getCardData(dateRange[0], dateRange[1], CardConstant.scheduleTestCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setScheduleTestCount(response?.data);
		} catch (error: any){
			setScheduleTestCount(null);
		}
	};

	const getOfferLetterReleasedCount = async () => {
		try{
			const response = await await getCardData(dateRange[0], dateRange[1], CardConstant.offerLetterReleasedCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setOfferLetterReleasedCount(response?.data);
		} catch (error: any){
			setOfferLetterReleasedCount(null);
		}
	};

	const getWalkInCount = async () => {
		try{
			const response = await await getCardData(dateRange[0], dateRange[1], CardConstant.walkInCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setWalkInCount(response?.data);
		} catch (error: any){
			setWalkInCount(null);
		}
	};

	const getReadyForAdmissionCount = async () => {
		try{
			const response = await await getCardData(dateRange[0], dateRange[1], CardConstant.readyForAdmissionCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setReadyForAdmission(response?.data);
		} catch (error: any){
			setReadyForAdmission(null);
		}
	};

	const getRegistrationDoneCount = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.registrationDoneCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setRegistrationDoneCount(response?.data);
		} catch (error: any){
			setRegistrationDoneCount(null);
		}
	};

	const getAdmissionDoneCount = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.admissionDoneCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setAdmissionDoneCount(response?.data);
		} catch (error: any){
			setAdmissionDoneCount(null);
		}
	};

	const getLinkGeneratedCount = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.linkGeneratedCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setLinkGeneratedCount(response?.data);
		} catch (error: any){
			setLinkGeneratedCount(null);
		}
	};

	const getPaymentFailedCount = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.paymentFailedCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setPaymentFailedCount(response?.data);
		} catch (error: any){
			setPaymentFailedCount(null);
		}
	};

	const getNoAnswerCount = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.noAnswerCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setNoAnswerCount(response?.data);
		} catch (error: any){
			setNoAnswerCount(null);
		}
	};

	const getInterestedCount = async () => {
		try{
			const response = await getCardData(dateRange[0], dateRange[1], CardConstant.interestedCount.value, selectedCallerId);
			if(response.error){
				throw new Error(response.error);
			}
			setInterestedCount(response?.data);
		} catch (error: any){
			setInterestedCount(null);
		}
	};


	useEffect(() =>{
		setLeadCount(undefined);
		setTotalApplications(undefined);
		setScheduleTestCount(undefined);
		setWalkInCount(undefined);
		setOfferLetterReleasedCount(undefined);
		setReadyForAdmission(undefined);
		setRegistrationDoneCount(undefined);
		setAdmissionDoneCount(undefined);
		setLinkGeneratedCount(undefined);
		setPaymentFailedCount(undefined);
		setNoAnswerCount(undefined);
		setInterestedCount(undefined);
		setNotInterested(null);

		getAllData();

	},[dateRange, selectedCallerId]);

	return (
		<>
			<div>
				{leadCount !==null
				&& 
				<IndividualCard loading={!leadCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.lead.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.leadCount.value,
								title: ExportCSVName.leadCount.label
							}
						)}
				>
					<CardChildComponent prevCount={leadCount?.prevCount ?? '0'} 
						title={CardConstant.lead.name} count={leadCount?.currentCount ?? '0'}
						trend={((leadCount?.currentCount as number)>(leadCount?.prevCount as number ))?? false} 
					/>
				</IndividualCard>
				}
			</div>
			<div>
				{totalApplications !== null
				&&
				<IndividualCard loading={!totalApplications} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.totalApplications.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.totalApplications.value,
								title: ExportCSVName.totalApplications.label
							}
						)}
				>
					<CardChildComponent prevCount={totalApplications?.prevCount ?? '0'} title={CardConstant.totalApplications.name} count={totalApplications?.currentCount ?? '0'} 
						trend={((leadCount?.currentCount as number)>(leadCount?.prevCount as number ))?? false}
					 />
				</IndividualCard>	
				}
			</div>
			<div>
				{scheduleTestCount !== null
				&&
				<IndividualCard loading={!scheduleTestCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.scheduleTestCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.scheduledTest.value,
								title: ExportCSVName.scheduledTest.label
							}
						)}
				>
					<CardChildComponent prevCount={scheduleTestCount?.prevCount ?? '0'} title={CardConstant.scheduleTestCount.name} count={scheduleTestCount?.currentCount ?? '0'}
						trend={((scheduleTestCount?.currentCount as number)>(scheduleTestCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{walkInCount !== null
				&&
				<IndividualCard loading={!walkInCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.walkInCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.walkin.value,
								title: ExportCSVName.walkin.label
							}
						)}
				>
					<CardChildComponent prevCount={walkInCount?.prevCount ?? '0'} title={CardConstant.walkInCount.name} count={walkInCount?.currentCount ?? '0'}
						trend={((walkInCount?.currentCount as number)>(walkInCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{offerLetterReleasedCount !== null
				&&
				<IndividualCard loading={!offerLetterReleasedCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.offerLetterReleasedCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.offerLetterRelease.value,
								title: ExportCSVName.offerLetterRelease.label
							}
						)}
				>
					<CardChildComponent prevCount={offerLetterReleasedCount?.prevCount ?? '0'} title={CardConstant.offerLetterReleasedCount.name} count={offerLetterReleasedCount?.currentCount ?? '0'}
						trend={((offerLetterReleasedCount?.currentCount as number)>(offerLetterReleasedCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{readyForAdmissionCount !== null
				&&
				<IndividualCard loading={!readyForAdmissionCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.readyForAdmissionCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.readyForAdmission.value,
								title: ExportCSVName.readyForAdmission.label
							}
						)}
				>
					<CardChildComponent prevCount={readyForAdmissionCount?.prevCount ?? '0'} title={CardConstant.readyForAdmissionCount.name} count={readyForAdmissionCount?.currentCount ?? '0'}
						trend={((readyForAdmissionCount?.currentCount as number)>(readyForAdmissionCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{registrationDoneCount !== null
				&&
				<IndividualCard loading={!registrationDoneCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.registrationDoneCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.registrationDone.value,
								title: ExportCSVName.registrationDone.label
							}
						)}
				>
					<CardChildComponent prevCount={registrationDoneCount?.prevCount ?? '0'} title={CardConstant.registrationDoneCount.name} count={registrationDoneCount?.currentCount ?? '0'}
						trend={((registrationDoneCount?.currentCount as number)>(registrationDoneCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{admissionDoneCount !== null
				&&
				<IndividualCard loading={!admissionDoneCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.admissionDoneCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.admissionDone.value,
								title: ExportCSVName.admissionDone.label
							}
						)}
				>
					<CardChildComponent prevCount={admissionDoneCount?.prevCount ?? '0'} title={CardConstant.admissionDoneCount.name} count={admissionDoneCount?.currentCount ?? '0'}
						trend={((admissionDoneCount?.currentCount as number)>(admissionDoneCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{linkGeneratedCount !== null
				&&
				<IndividualCard loading={!linkGeneratedCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.linkGeneratedCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.linkGenerated.value,
								title: ExportCSVName.linkGenerated.label
							}
						)}
				>
					<CardChildComponent prevCount={linkGeneratedCount?.prevCount ?? '0'} title={CardConstant.linkGeneratedCount.name} count={linkGeneratedCount?.currentCount ?? '0'}
						trend={((linkGeneratedCount?.currentCount as number)>(linkGeneratedCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{paymentFailedCount !== null
				&&
				<IndividualCard loading={!paymentFailedCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.paymentFailedCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.paymentFailed.value,
								title: ExportCSVName.paymentFailed.label
							}
						)}
				>
					<CardChildComponent prevCount={paymentFailedCount?.prevCount ?? '0'} title={CardConstant.paymentFailedCount.name} count={paymentFailedCount?.currentCount ?? '0'}
						trend={((paymentFailedCount?.currentCount as number)>(paymentFailedCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{noAnswerCount !== null
				&&
				<IndividualCard loading={!noAnswerCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.noAnswerCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.noAnswerCount.value,
								title: ExportCSVName.noAnswerCount.label
							}
						)}
				>
					<CardChildComponent prevCount={noAnswerCount?.prevCount ?? '0'} title={CardConstant.noAnswerCount.name} count={noAnswerCount?.currentCount ?? '0'}
						trend={((noAnswerCount?.currentCount as number)>(noAnswerCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>
			<div>
				{interestedCount !== null
				&&
				<IndividualCard loading={!interestedCount} className={`${styles.cardComponent}`} style={{backgroundColor: CardConstant.interestedCount.color}}
					onClick={
						()=>selectCard(
							{
								data:null, 
								selected: ExportCSVName.interested.value,
								title: ExportCSVName.interested.label
							}
						)}
				>
					<CardChildComponent prevCount={interestedCount?.prevCount ?? '0'} title={CardConstant.interestedCount.name} count={interestedCount?.currentCount ?? '0'}
						trend={((interestedCount?.currentCount as number)>(interestedCount?.prevCount as number ))?? false}
					/>
				</IndividualCard>	
				}
			</div>

		</>
	);
};

interface CardChildComponentProp {
	title: string,
	count: string | number,
	trend: boolean,
	prevCount: string | number,
}

export const CardChildComponent = (props:CardChildComponentProp) => {
	const {title, count,prevCount, trend} = props;
	return (
		<div>
			<p className={`${styles.cardComponetCommon} ${styles.cardComponentTitle}`}>{title}</p>
			<p className={`${styles.cardComponetCommon} ${styles.cardComponentCount}`}>{count}</p>
			<p className={`${styles.cardComponetCommon} ${styles.cardComponentTrend}`}>{trend?<RiseOutlined />:<FallOutlined />} {prevCount}</p>
		</div>
	);
};

interface IndividualCardProps {
	loading: boolean,
	children?: ReactElement,
	className?: string,
	style?: React.CSSProperties,
	onClick?: any,
	color?: string,
}

export const IndividualCard = (props:IndividualCardProps) => {
	const {loading, children, className, style, onClick, color} = props;
	return (
		<>
			<Card
				style={{...style,cursor:'pointer', backgroundColor: color || 'red'}}
				className={`${className}`}
				loading={loading}
				onClick={onClick}
			>
				{children}
			</Card>
		</>
	);
};