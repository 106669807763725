import React, { useState, Dispatch, SetStateAction, useEffect,useRef } from 'react';
import styles from './groupDetailsModal.module.css';
import type { InputRef } from 'antd';
import { 
	Button, 
	Drawer, 
	Input,  
	message,
	Form,
	Select,
	Space,
} from 'antd';
import { title } from 'process';

const { TextArea } = Input;
const {Option} = Select;

interface GroupDetailsModalProps {
	groupDetailModalOpen: boolean,
	setgroupDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
	onGroupDetailModalClose: () => void,
	isEdit: boolean,
	handleCreateOneGroup: (data: any) => void,
	oneGroupData: any,
	handleEditOneGroup: (data: any) => void,
	setDetailModalBtnLoading: any,
	detailModalBtnLoading: boolean,
	groupDetailModalSubmitBtnRef: any
}

export const GroupDetailsModal: React.FunctionComponent<GroupDetailsModalProps> = (props) => {
	const {
		groupDetailModalOpen, 
		setgroupDetailModalOpen, 
		onGroupDetailModalClose,
		isEdit,
		handleCreateOneGroup,
		oneGroupData,
		handleEditOneGroup,
		setDetailModalBtnLoading,
		detailModalBtnLoading,
		groupDetailModalSubmitBtnRef
	} = props;

	const [createNewGroupForm] = Form.useForm();
	const groupTitleFieldRef = useRef<InputRef>(null);

	// reset the drawer while closing
	const resetDrawerOnClose = () => { 
		createNewGroupForm.resetFields();
		document.body.querySelector('.createNewGroupDrawer .ant-drawer-body')?.scrollTo(9999, 0);
	};

	// reset the drawer after group created or edited
	const resetDrawer = () => { 
		resetDrawerOnClose(); 
		setgroupDetailModalOpen( false );
	};

	const addNewGroup = (formValues: any) => {
		// resetDrawer();

		// while editing/creating group need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		groupDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleCreateOneGroup(formValues);
	};

	const editGroup = (formValues: any, groupId: string) => {
		// resetDrawer();

		// while editing/creating group need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		groupDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleEditOneGroup({title: formValues.title,description: formValues.description, groupId});
	};

	const setFieldsAndVals = () => {
		createNewGroupForm.setFieldsValue({
			title: oneGroupData.title,
			description: oneGroupData.description
		});
	};

	useEffect(() => {
		if( isEdit ){
			setFieldsAndVals();
		}

		if(!groupDetailModalOpen) {
			resetDrawer();
		}
	}, [isEdit, groupDetailModalOpen]);

	return (
		<div className={styles.createNewGroupDrawerContainer}>
			<Drawer
				title={(() =>{
					if(isEdit) return 'Edit Group';
					else return 'Create New Group';
				})()}
				width={450}
				onClose={() => {
					resetDrawerOnClose();
					onGroupDetailModalClose();
				}}
				open={groupDetailModalOpen}
				extra={
					<Space>
						<Button 
							ref={groupDetailModalSubmitBtnRef}
							type="primary" 
							onClick={ () => createNewGroupForm.submit() }
							loading={detailModalBtnLoading}
						>
							{ isEdit ? 'Edit' : 'Create' }
						</Button>
					</Space>
				}
			>
				<div className={styles.createNewGroupBody}>
					<Form
						form={createNewGroupForm}
						onFinish={
							(values) => {
								if(isEdit)	
									editGroup(values, oneGroupData.id);
								else {
									addNewGroup(values);
								}
							}
						}
						layout="vertical"
					>
						<Form.Item
							label="Title"
							name="title"
							rules={[
								{
									required: true,
									message: 'Please input group title'
								},
								{
									pattern: /^[a-zA-Z0-9-_. ]*$/,
									message: 'Not a valid title, please remove special characters!',
								},
							]}
						>
							<Input ref={groupTitleFieldRef} showCount maxLength={50} placeholder={`Enter Group's Title`} />
						</Form.Item>
						<Form.Item label="Description" name="description" >
							<TextArea rows={7} showCount maxLength={200} placeholder={`Enter Group's description`}/>
						</Form.Item>
					</Form>
				</div>
			</Drawer>
		</div>
  	);
};
