import React, { useState, useEffect, useRef } from 'react';
import { Column } from '@ant-design/plots';
import { DailyLeadTrendInterface } from '../../@types/report';
import dayjs from 'dayjs';
import { ReportGraphConstants } from '../../config/report';
interface DailyLeadTrendGraphProps{
	data: Array<DailyLeadTrendInterface> | undefined | null,
	labelSize?: number,
	setSelectedGraph: any,
}

export const DailyLeadTrendGraph = (props:DailyLeadTrendGraphProps) => {
	const { data, labelSize, setSelectedGraph } = props;
	const [localData, setLocalData] = useState<Array<DailyLeadTrendInterface>>([]);
	const [maxValue, setMaxValue] = useState<number>(0);

	const chartRef = useRef<any>();

	useEffect(() => {
		const handleClick = (ev: any) => {
			setSelectedGraph({
				type: ReportGraphConstants.leadTrend.value,
				value: ev?.data?.data?.date,
				title: ReportGraphConstants.leadTrend.label,
			});
		};
		chartRef.current.on('element:click',handleClick);

		return () => {
			chartRef.current.off('element:click',handleClick);
		};
	},[]);
    
	useEffect(() =>{
		if(data) {
			let maxValue = 0;
			setLocalData(data.map((element)=>{
				maxValue = Math.max(maxValue, parseInt(element.count as string));
				return {
					date: dayjs(element.date).format('YYYY-MM-DD'),
					count: parseInt(element.count as string),
				};
			}));
			setMaxValue(maxValue);
		}
	},[data]);

	const config = {
		xField: 'date',
		yField: 'count',
		maxColumnWidth: 30,
		yAxis:{
			tickMethod: (() => {
				if(maxValue < 5){
					return 'cat';
				}else{
					return 'wilkinson-extended';
				}
			})(),
		},
		slider: {
			start: 0.9,
			end: 1,
		},
		xAxis:{
			label:{
				style:{fontSize: labelSize}
			}
		}
	};

	return <Column chartRef={chartRef} loading={data===undefined} data={localData} padding={'auto'} {...config} />;
};
