import React from 'react';
import {Link} from 'react-router-dom';
import { useAppStore } from '../../stores';
import { Header } from '../../components/header';
import styles from './errorPage-screen.module.css';
import {
	Col,Row
} from 'antd';

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
	logoutUser: state.logoutUser,
});
import NotFound from '../../assets/img/NotFound.svg';
export const ErrorPageScreen: React.FunctionComponent = () => {
	document.title = 'Error | CodeQuotient';
	const { 
		sessionData,
		logoutUser,
	} = useAppStore( appStoreSelector );

	
	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<div className={styles.mainContainer}>
				<Row className={styles.row}>
					<Col lg={14} md={14} sm={24} className={styles.col}>
						<div className={styles.circle}>
							<div className={styles.circle1}></div>
							<div className={styles.circle2}></div>
							<div className={styles.circle3}></div>
						</div>
						<div className={styles.text}>
							<p style={{fontSize:'2.8rem',margin:'0',color:'#de6834',marginBottom:'1rem'}}>Uh oh!</p>
							<p style={{fontSize:'1.5rem',margin:'0',padding:'0',marginBottom:'2.5rem',opacity:'0.8'}}>Sorry, it seems we can’t find the page you’re looking for :(</p>
							<Link to = '/lead/list' className={styles.link} >Go back to home</Link>
						</div>
						
					</Col>
					<Col lg={10} md={10} sm={24} className={styles.col}>
						<div style={{maxHeight:'400px'}}>
							<img src={NotFound} alt="" className={styles.image}/>
						</div>
					</Col>
				</Row>
			</div>
		</>
	);
};