import React, { ReactElement, useCallback } from 'react';
import { DatePicker } from 'antd';
import {DownloadOutlined, FullscreenOutlined, FullscreenExitOutlined, SyncOutlined } from '@ant-design/icons/lib/icons';
import styles from './subHeaderReport.module.css';
import type { Dayjs } from 'dayjs';
import {Select} from 'antd';
import { ListOfCaller, Caller } from '../../@types/report';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';

const {Option} = Select;

interface SubHeaderReportProps{
	title?: any,
	dateRage?: any,
	setDateRange?: any,
	setFullScreen?: any,
	download?: any,
	className?: string,
	removeFullScreen?: any,
	format?: string,
	refreshFunction?: any,
	callerList?: ListOfCaller | undefined | null,
	callerSelected?: any,
	handleCallerSelection?: any,
	pickerRange?: any,
	extraElementToShow?: ReactElement
	placeHolderForCalander?: [string, string]
}

export const SubHeaderReport = (props: SubHeaderReportProps) => {
	const {
		title, dateRage, 
		setDateRange, setFullScreen, download, 
		className, removeFullScreen, format, 
		refreshFunction, callerList, callerSelected,
		handleCallerSelection, pickerRange, extraElementToShow,
		placeHolderForCalander
	} = props;
	const handleDateChange = useCallback((dates: any,
		dateStrings: string[]
	) => {
		if(dates){
			setDateRange(dates);
		}else{
			setDateRange([undefined,undefined]);
		}
	},[setDateRange]);

	const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
		// Can not select days before today and today
		return current && current < dayjs('2023-01-01');
	  };
	
	return (
		<div className={`${styles.body} ${className}`}>
			{title && <h4 className={styles.title}>{title}</h4>}
			<div className={styles.selectionContainer}>
				{extraElementToShow}
				{callerList && 
					<Select
						style={{width:'350px'}}
						value={callerSelected?.id}
						onChange={handleCallerSelection}
						placeholder={'Select Caller'}
						allowClear
						showSearch
						filterOption={(inputValue: any, option: any) =>
							option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
						}
					>
						{callerList
							&& Object.keys(callerList).map( (element: string) => {
								return (
									<Option key={element} value={element} label={callerList[element].name}>
										{callerList[element].name}
									</Option>
								);
							})
						}
					</Select>
				}
				{setDateRange && 				
				<DatePicker.RangePicker
					value={dateRage}
					showTime
					format={format ?? `DD-MM-YYYY HH:mm`}
					picker={pickerRange}
					disabledDate={disabledDate}
					onChange={handleDateChange}
					placeholder={placeHolderForCalander}
				/>}
			</div>
			{refreshFunction && <SyncOutlined className={styles.subHeaderButton} onClick={refreshFunction}/>}
			{setFullScreen && <FullscreenOutlined className={styles.subHeaderButton} onClick={setFullScreen} />}
			{removeFullScreen && <FullscreenExitOutlined className={styles.subHeaderButton} onClick={removeFullScreen} />}
			{download && <DownloadOutlined className={styles.subHeaderButton} onClick={download} />}
		</div>
	);
};