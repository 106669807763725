import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './searchByFilterComponent.module.css';
import { 
	Button, 
	Form,
	Select,
	DatePicker,
	Checkbox,
	Tooltip,
	Cascader,
} from 'antd';
import dayjs from 'dayjs';
import equal from '../../assets/img/equal.svg';
import isNotEqual from '../../assets/img/is-not-equal.svg';
import states from '../../config/location';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { DefaultOptionType } from 'antd/es/cascader';
interface searchByFilterComponent {
	handleLeadList: ( paginationData: paginationData ) => Promise<void>,
	setLeadsFilters: Dispatch<SetStateAction<LeadsFilters>>,
	paginationData: paginationData,
	assignToList: any[] | null,
	setPaginationData: Dispatch<SetStateAction<paginationData>>,
	leadStages: leadStages[],
	leadStatus: any,
	sourceOptions: any
}

export const SearchByFilterComponent: React.FunctionComponent<searchByFilterComponent> = (props) => {
	const {
		paginationData,
		assignToList,
		setPaginationData,
		handleLeadList,
		setLeadsFilters,
		leadStages,
		leadStatus,
		sourceOptions,
	} = props;

	// const statusOptions: statusOptions[] = [
	// 	{ 
	// 		value: leadStages[0]?.childs,
	// 		label: 'Hot',
	// 		children: leadStages[0]?.childs.map((val: number) => leadStatus[val])
	// 	},
	// 	{ 
	// 		value: leadStages[1]?.childs, 
	// 		label: 'Cold',
	// 		children: leadStages[1]?.childs.map((val: number) => leadStatus[val])
	// 	},
	// 	{ 
	// 		value: leadStages[2]?.childs, 
	// 		label: 'Warm',
	// 		children: leadStages[2]?.childs.map((val: number) => leadStatus[val]),
	// 	},
	// 	{ 
	// 		value: leadStages[3]?.childs, 
	// 		label: 'Junk',
	// 		children: leadStages[3]?.childs.map((val: number) => leadStatus[val]),
	// 	},
	// 	{ 
	// 		value: leadStages[4]?.childs, 
	// 		label: 'Fresh',
	// 		children: leadStages[4]?.childs.map((val: number) => leadStatus[val]),
	// 	},
	// ];

	const statusOptions: statusOptions[] = leadStages?.map((StageDetails: any) => ({
		value: StageDetails?.childs,
		label: StageDetails?.label,
		children: StageDetails?.childs.reduce((result: any, val: number) => {
			if(val && leadStatus[val]){
				result.push(
					{
						label:leadStatus[val]?.label,
						value: val,
					}
				);
			}
			return result;
		},[]),
		// children: StageDetails?.childs.map((val: number) => (
		// 	{
		// 		label:leadStatus[val]?.label,
		// 		value: val,
		// 	}
		// ))
	}));

	// const [updateStatusList, setUpdateStatusList] = useState<any>({});
	const [isEqualToStage, setIsEqualToStage] = useState<boolean>(true);
	const [isEqualToStatus, setIsEqualToStatus] = useState<boolean>(true);
	const [isEqualToAssignTo, setIsEqualToAssignTo] = useState<boolean>(true);
	

	const [searchFilterForm] = Form.useForm();

	// const handleUpdateStatusList = (value: number) => {
	// 	if(value === 5) setUpdateStatusList(leadActivityHotStatus);
	// 	else if(value === 15) setUpdateStatusList(leadActivityColdStatus);
	// 	else if(value === 18) setUpdateStatusList(leadActivityWarmStatus);
	// 	else if(value === 20) setUpdateStatusList(leadActivityJunkStatus);
	// 	else if(value === 30) setUpdateStatusList(leadActivityFreshStatus);
	// };

	const handleFilterDataToSearch = (values: any) => {
		// console.log(values.status);
		const selectedStatusVals: number[] = [];
		let finalSelectedStatusVals: number[] = [];
		values?.status?.forEach((val: any) => {
			if(val[1]) selectedStatusVals.push(val[1]);
			else selectedStatusVals.push(val[0]);
		});
		finalSelectedStatusVals = selectedStatusVals.flat(1);
		// console.log(finalSelectedStatusVals);
		const data: LeadsFilters = {
			startTime: (values.startTime && values.startTime.$d) ? new Date(values.startTime.$d).toISOString() : '',
			endTime: (values.endTime && values.endTime.$d) ? new Date(values.endTime.$d).toISOString() : '',
			// stage: values.stage,
			// isEqualToStage: isEqualToStage,
			status: finalSelectedStatusVals.length ? finalSelectedStatusVals : null,
			isEqualToStatus: isEqualToStatus,
			assignTo: values.assignTo,
			isEqualToAssignTo: isEqualToAssignTo,
			state: values.state,
			notAssigned: !(values.notAssigned) ? false : values.notAssigned,
			isFollowUp: !(values.isFollowUp) ? false : values.isFollowUp,
			source: values.source,
		};

		paginationData.filters = data;
		paginationData.pageNumber = 1;

		setPaginationData(paginationData);
		handleLeadList(paginationData);
		setLeadsFilters(data);
	};

	const handleClearFilter = () => {
		searchFilterForm.resetFields();
		setIsEqualToStage(true);
		setIsEqualToStatus(true);
		setIsEqualToAssignTo(true);

		const resetFilters: any = {};

		paginationData.filters = resetFilters;
		paginationData.pageNumber = 1;

		setPaginationData(paginationData);
		handleLeadList(paginationData);
		setLeadsFilters(resetFilters);
	};

	const onNotAssignedChange = (e: CheckboxChangeEvent) => {
		if(e.target.checked){
			searchFilterForm.resetFields(['assignTo']);
			setIsEqualToAssignTo(true);
		}
	};

	// stages and status cascader search
	const filter = (inputValue: string, path: DefaultOptionType[]) =>
  		path.some(
    		(option) => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
  		);

	return (
		<>
			<div className='searchFilterContainer'>
				<Form
					form={searchFilterForm}
					onFinish={handleFilterDataToSearch}
				>
					<div className={styles.searchFilterForm}>
						<Form.Item name="startTime" label="Start Time">
							<DatePicker
								format="DD-MM-YYYY HH:mm"
								style={{width: '180px'}}
								showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
    						/>
						</Form.Item>
						<Form.Item name="endTime" label="End Time">
							<DatePicker
								format="DD-MM-YYYY HH:mm"
								style={{width: '180px'}}
								showTime={{ defaultValue: dayjs('23:59', 'HH:mm') }}
    						/>
						</Form.Item>
						{/* <Form.Item 
							name="stage" 
							label={(()=>{
								 return <div className={styles.labelWithIcon}>
									Stage on this day 
									<Tooltip title={isEqualToStage ? 'Equal' : 'Not Equal'}>
										<img onClick={() => setIsEqualToStage(!isEqualToStage)} src={isEqualToStage ? equal : isNotEqual} width={14}/>
									</Tooltip>
								</div>;
							})()} >
							<Select  
								onChange={handleUpdateStatusList}
								options={Object.values(leadActivityStages).map((options: any) => options)}
								placeholder='Select Assign To'
								style={{width: '180px'}} 
							/>
						</Form.Item> */}
						{/* <Form.Item 
							name="status" 
							label={(()=>{
								return <div className={styles.labelWithIcon}>
									Status on this day
									<Tooltip title={isEqualToStatus ? 'Equal' : 'Not Equal'}>
										<img onClick={() => setIsEqualToStatus(!isEqualToStatus)} src={isEqualToStatus ? equal : isNotEqual} width={14}/>
									</Tooltip>
								</div>;
						   })()} >
							<Select  
								options={Object.values(leadActivityStatus).map((options: any) => options)}
								placeholder='Select Stage'
								style={{width: '180px'}} 
							/>
						</Form.Item> */}
						<Form.Item 
							name="status" 
							label={(()=>{
								return <div className={styles.labelWithIcon}>
									Status on this day
									<Tooltip title={isEqualToStatus ? 'Equal' : 'Not Equal'}>
										<img onClick={() => setIsEqualToStatus(!isEqualToStatus)} src={isEqualToStatus ? equal : isNotEqual} width={14}/>
									</Tooltip>
								</div>;
						   })()} >
							<Cascader 
								multiple
								options={statusOptions} 
								showSearch={{ filter }}
								placeholder="Select Status" 
								// style={{width: '180px'}}
							/>
						</Form.Item>
						{assignToList &&
						<Form.Item 
							name="assignTo" 
							label={(()=>{
								return <div className={styles.labelWithIcon}>
									Assign To
									<Tooltip title={isEqualToAssignTo ? 'Equal' : 'Not Equal'}>
										<img onClick={() => setIsEqualToAssignTo(!isEqualToAssignTo)} src={isEqualToAssignTo ? equal : isNotEqual} width={14}/>
									</Tooltip>
								</div>;
						   })()} >
							<Select  
								options={assignToList.map((option: any) => ({value: option._id, label: option.name}))}
								placeholder='Assign To'
								allowClear
								showSearch
								filterOption={(inputValue, option) =>
									(option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase().trim())
								}
								style={{width: '180px'}} 
							/>
						</Form.Item>
						}
						<Form.Item name="state" label='State'>
							<Select  
								options={Object.values(states).map((options: any) => options)}
								allowClear
								showSearch
								filterOption={(inputValue, option) =>
									(option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase().trim())
								  }
								placeholder='Select State'
								// style={{width: '180px'}} 
							/>
						</Form.Item>
						<Form.Item name='source' label='Source'>
							<Select
								options={Object.values(sourceOptions).map((options: any) => options)}
								allowClear
								showSearch
								filterOption={(inputValue, option) =>
									(option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase().trim())
								}
								placeholder='Select Source'
							/>
						</Form.Item>
						{assignToList &&
							<Form.Item name="notAssigned" label="No Assigned" valuePropName="checked">
								<Checkbox onChange={onNotAssignedChange}/>
							</Form.Item>
						}
						<Form.Item name="isFollowUp" label="Follow Up" valuePropName="checked">
							<Checkbox />
						</Form.Item>
					</div>
				</Form>
				<div className={styles.buttonContainer}>
					<Button type='primary' onClick={() => searchFilterForm.submit()}>Load</Button>
					<Button type='default' onClick={handleClearFilter}>Clear</Button>
				</div>
			</div>
			<hr></hr>
		</>
  	);
};
