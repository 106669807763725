import React, { useEffect, useState } from 'react';
import { useAppStore } from '../../stores';
import StatusUpdated from '../../assets/img/status-update.png';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { LeadsContentSubHeader } from '../../components/leadsContentSubHeader';
import { Header } from '../../components/header';
import { TimeLineComponent } from '../../components/timeLineComponent';
import { activityType } from '../../config';
import { convertTime } from '../../libs/utils';

const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	logoutUser: state.logoutUser,
	setAppLoading: state.setAppLoading,
	getLeadComments: state.getLeadComments
} );

export const LeadsCommentActivity: React.FunctionComponent = () => {

	const {
		sessionData,
		leadStages,
		leadStatus,
		logoutUser,
		setAppLoading,
		getLeadComments
	} = useAppStore(appStoreSelector);

	const [activityData, setActivityData] = useState<any[]>([]);
	const [particulatActivityData, setParticulatActivityData] = useState<any>({});
	const [activityNumber, setActivityNumber] = useState<string>('');
	const [createdByDetails, setCreatedByDetails] = useState<any>({});
	const [leadDetails, setLeadDetails] = useState<any>({});

	const { leadId } = useParams();

	const getLeadCommentActivities = async () => {
		try{
			setAppLoading(true);
			const response = await getLeadComments(leadId);

			if( response?.error ){
				message.error( response.error );
				return;
			}

			setCreatedByDetails(response?.data?.usersData);
			setLeadDetails(response?.data?.leadData);

			// [
			// 	{
			// 		activityMonthYear: '',
			// 		dataOfSameMonthYear: [{
			// 			createdAt: '',
			// 			activityType: '',
			// 			totalData: '',
			// 			insertedLeadsId: []
			// 		}]
			// 	 }
			// ];

			const finalActivityData: any[] = [];
			let currentMonth = new Date().getMonth()+1;
			let currentYear = new Date().getFullYear();		

			response?.data?.data.forEach((val: any, index:number) => {
				// console.log(val.created_at);
				if(val.comment){
					const date = new Date(val.created_at);
					const prevMonth = date.getMonth(); 
					const prevYear = date.getFullYear();
					
					if(
						(prevMonth!==currentMonth && prevYear===currentYear) || 
						((prevMonth===currentMonth || prevMonth!==currentMonth) && prevYear!==currentYear)
					){
						finalActivityData.push({
							activityMonthYear: `${date.toLocaleString('default', {month: 'short'})} ${prevYear}`,
							dataOfSameMonthYear: []
						});
						currentMonth = prevMonth;
						currentYear = prevYear;
					}
				
					// eslint-disable-next-line max-len
					const createdAt = `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})}, ${date.getHours()>9 ? date.getHours() : '0'+date.getHours()}:${date.getMinutes()>9 ? date.getMinutes() : '0'+date.getMinutes()}`;
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						comment: val.comment,
						iconType: StatusUpdated,
						id: val.id
					});
				}
			});
			setActivityData(finalActivityData);
			setTimeout(() => {
				const element: HTMLElement = document.getElementsByClassName('timelineTitleBox')[0] as HTMLElement;
				if(element){
					element.click();
				}
			}, 100);
		} catch(e: any){
			setActivityData([]);
		} finally {
			setAppLoading(false);
		}
	};

	const onParticularActivityClick = async (activityNum: string, leadIds: string[], activityLabel: string, iconType: any, createdAt: string,
		created_by: string, alreadyLeadsPresent: any, duplicateLeads: any, notInsertedLeads: any, groupName: string, stage: string, status: string,
		assignToName: string, followUpDate: string, comment: string, amount: any) => {
		setActivityNumber(activityNum);

		const leadsData: leadsData = {
			name: leadDetails.name,
			email: leadDetails.email,
			createdAt: `${new Date(leadDetails.created_at).toLocaleString('default', {month: 'short'})} ${new Date(leadDetails.created_at).getFullYear()}`,
			created_by: createdByDetails[created_by]?.name,
			currentStage: leadStatus[leadDetails.current_status]?.leadActivityStage,
			currentStatus: leadStatus[leadDetails.current_status]?.label, comment, amount
		};
		// console.log(leadsData);
		setParticulatActivityData({
			leadsData
		});
	};

	useEffect(() => {
		if(leadStages.length) getLeadCommentActivities();
	}, [leadStages]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<LeadsContentSubHeader 
				// groupId= {id}
				isGroup = {false}
				isActivityPage = {true}
				isLeadActivityPage = {false}
				isBulkAvtivity = {false}
				isLeadsCommentActivity = {true}
				groupDetails={{}}
				leadDetails={leadDetails}
			/>
			<TimeLineComponent 
				activityData={activityData}
				particulatActivityData={particulatActivityData}
				activityNumber={activityNumber}
				onParticularActivityClick={onParticularActivityClick}
				isLeadActivityPage = {false}
				isBulkActivityPage = {false}
				isGroupActivityPage = {false}
				isLeadsCommentActivityPage = {true}
			/>
		</>
	);		
};