import React, { useCallback, useEffect, useRef, useState} from 'react';
import { useAppStore } from '../../stores';
import { Header } from '../../components/header';
import styles from './listUsers-screen.module.css';
import {
	Button,
	Space,
	Tooltip, 
	message, 
	Popconfirm,
	Select
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined, CommentOutlined } from '@ant-design/icons';
import { TableComponent } from '../../components/mainTableComponent';
import { convertTime } from '../../libs/utils';
import { useNavigate } from 'react-router-dom';
import { SearchField } from '../../components/searchField';
import { UserDetailsModal } from '../../components/userDetailModal';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
//! DONT USE ROLEVALUE ANY OTHER PLACE THIS WILL BE DEPRICATED SOON
import { roleValue, attributes, resources } from '../../config';

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
	allowedRolesOfCurrentRole: state.allowedRolesOfCurrentRole,
	logoutUser: state.logoutUser,
	createOneUser: state.createOneUser,
	getAllowedRolesOfCurrentRole: state.getAllowedRolesOfCurrentRole,
	getUserList: state.getUserList,
	deleteUser: state.deleteUser,
	getAllReportingManager: state.getAllReportingManager,
	editOneUser: state.editOneUser,
	getUserDetails: state.getUserDetails,
});

export const ListUserScreen: React.FunctionComponent = () => {
	document.title = 'Users | CodeQuotient';
	const { 
		sessionData, 
		allowedRolesOfCurrentRole,
		logoutUser,
		createOneUser,
		getAllowedRolesOfCurrentRole,
		getUserList,
		deleteUser,
		getAllReportingManager,
		editOneUser,
		getUserDetails,
	} = useAppStore( appStoreSelector );

	const [userDetailModalOpen, setUserDetailModalOpen] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [userList, setUserList] = useState<any[]>([]);
	const [oneUserData, setoneUserData] = useState<any>({});
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [paginationData, setPaginationData] = useState<paginationData>({
		sortField: 'updatedAt',
		sortOrder: 'descend',
		pageNumber: 1,
		perPage: 20,
		searchValue: ''
	});
	const [sortField, setSortField] = useState<string>('updated_at');
	const [sortOrder, setSortOrder] = useState<any>('descend');
	const [perPageCount, setPerPageCount] = useState<number>(20);
	const [totalListDataCount, setTotalListDataCount] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	// const [prevSorterOrder, setPrevSorterOrder] = useState<any>('descend');

	const [detailModalBtnLoading, setDetailModalBtnLoading] = useState<boolean>(false);
	const [reportingManagerList, setReportingManagerList] = useState<reportingManagerList[]>([]);
	const userDetailModalSubmitBtnRef = useRef<any>(null);

	const [userCached, setUserCache] = useState<any>({});
	
	const serNumber = useRef({
		number: 1
	});

	const navigate = useNavigate();
	// assign keys to table row
	// const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	// open the create new user drawer
	const openCreateUser = () => {
		setUserDetailModalOpen(!userDetailModalOpen);
		setIsEdit(false);
	};
  
	// on closing the user drawer
	const onUserDetailModalClose = () => {
		setUserDetailModalOpen(false);
		setIsEdit(false);
	};

	// open the edit lead drawer
	const editUser = (record: any) => {
		setUserDetailModalOpen(true);
		setIsEdit(true);
		setoneUserData(record);
	};

	const handleEditCreatUserBtn = () => {
		userDetailModalSubmitBtnRef.current ? userDetailModalSubmitBtnRef.current.removeAttribute('disabled', 'disabled') : '';
		setDetailModalBtnLoading(false);
	};

	const handleRoleFilterChnage = useCallback((value:string) => {
		console.log('value changedd', value);
		const filter = {
			roleId: value,
		};
		handleUserList(paginationData, filter);
	},[paginationData]);

	const handleUserList = async (paginationData: paginationData, filter?:any) => {
		setTableLoading(true);
		const response = await getUserList(paginationData,filter);
		setTableLoading(false);

		// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
		handleEditCreatUserBtn();

		if(response?.error){
			message.error(response.error || 'Error in fetching groups list data');
			setUserList([]);
			return;
		}

		// set the sr number along with reporting managers name
		serNumber.current.number = (paginationData.pageNumber - 1) * paginationData.perPage + 1;
		const idsToGet:Array<string> = [];
		response?.data?.users?.forEach((userData: any, index: number) => {
			userData.SrNum = (paginationData.pageNumber - 1) * paginationData.perPage + index + 1;
			userData.reportingManagerNames = userData?.reportingManager?.map((managerId: string) => response?.data?.reportingManagersData[managerId].name).toString();	
			userData.updatedAt = userData?.appData[0].updatedAt;
			userData.createdBy = userData?.appData[0].createdBy;
			if(userData.createdBy){
				idsToGet.push(userData.createdBy);
			}
		});

		await getCachedUserData(idsToGet);

		setUserList(response?.data?.users || []);
		setSortField(response?.data?.sortField);
		setSortOrder(response?.data?.setSortOrder || null);
		setPerPageCount(response?.data?.perPage);
		setTotalListDataCount(response?.data?.total || response?.data?.recordsFiltered);
		setCurrentPage(paginationData.pageNumber);
	};

	const getCachedUserData = async ( ids: Array<string>) => {
		if(ids && ids.length){
			const userToGet = ids.filter((id: string) => {
				if(!userCached[id]){
					return true;
				} else {
					return false;
				}
			});
			const data = await getUserDetails(userToGet);
			if(data.error){
				message.error( data.error?.message ?? data.error );
			} else {
				setUserCache({ ... userCached, ...data});
			}
		}
	};

	const getUsersData = () => {
		//set the table page no. to 1
		paginationData.pageNumber = 1;
		// paginationData.sortField = 'updated_at';
		// paginationData.sortOrder = 'descend';
		// setPrevSorterOrder('descend');

		setPaginationData(paginationData);
		handleUserList(paginationData);
		document.body.querySelector('#listUsersSreenbodyTable .ant-table-body')?.scrollTo(9999, 0);
	};

	const handleCreateOneUser = async (data: any) => {
		setTableLoading(true);
		const response = await createOneUser(data);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error );
			setUserDetailModalOpen(true);
			// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatUserBtn();
			return;
		}

		setUserDetailModalOpen(false);
		message.success( response?.data?.msg || 'User Created Sucessfully' );

		getUsersData();
	};

	const handleDeleteOneUser = async (deleteUserId: string) => {
		setTableLoading(true);
		const response = await deleteUser(deleteUserId);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error );
			return;
		}
		message.success( response?.data?.msg || 'Group Deleted Sucessfully' );

		getUsersData();
	};
	
	const handleEditOneUser = async (data: editUserPayload) => {
		setTableLoading(true);
		const response = await editOneUser(data);
		setTableLoading(false);

		if(response?.error){
			message.error( response.error );
			setUserDetailModalOpen(true);
			// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatUserBtn();
			setIsEdit(true);
			return;
		}

		setIsEdit(false);
		setUserDetailModalOpen(false);
		message.success( response?.data?.msg || 'User Edited Sucessfully' );

		getUsersData();
	};

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		// console.log(pagination, filters, sorter);
		const data: paginationData = {
			sortField: sorter.field,
			sortOrder: sorter.order || null,
			pageNumber: pagination.current,
			perPage: pagination.pageSize,
			searchValue
		};

		// on sort set the table page no. to 1
		if (sorter.order !== paginationData.sortOrder || paginationData.sortField !== sorter.field) {
			data.pageNumber = 1;
		}

		// setPrevSorterOrder(sorter.order);
		handleUserList(data);
		setPaginationData(data);
	};

	const handlerRoleOptions = async () => {
		const response = await getAllowedRolesOfCurrentRole();
		if( response?.error ){
			message.error( response.error );
			return;
		}
	};

	const handleReportingManagerList = async () => {
		const response = await getAllReportingManager();
	
		if( response?.error ){
			message.error( response.error );
			setReportingManagerList([]);
			return;
		}

		setReportingManagerList(Object.values(response.data));
	};

	const userTableColumns: ColumnsType<userTableDataType> = [
		{
			width: 47,
			dataIndex: 'SrNum',
		  	key: 'SrNum',
			render: ( _: any, record: any, index ) => (
				<div style={{display: 'flex', justifyContent: 'center'}}>{serNumber.current.number + index}.</div>
			)
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			ellipsis: true,
			sorter: true,
		},
		{
		  	title: 'Email',
		  	dataIndex: 'email',
		  	ellipsis: true,
		  	key: 'email',
			sorter: true,
		},
		{
			title: 'Role',
			dataIndex: 'roleName',
			ellipsis: true,
			key: 'roleName',
		},
		{
			title: 'Reporting Manager',
			dataIndex: 'reportingManagerNames',
			ellipsis: true,
			key: 'reportingManagerNames',
		},
		{
			title: 'Updated At',
		  	dataIndex: 'updatedAt',
			key: 'updatedAt',
		  	width: 200,
		  	sorter: true,
			defaultSortOrder: 'descend',
		  	render: ( _: any, record: any ) => { 
			  // console.log( record );
			  const time = record.updatedAt;
			  return (
				  convertTime(time)
				);
		  	}
		},
		{
			title: 'Created By',
			dataIndex: 'createdBy',
			key: 'createdBy',
			width:250,
			render: ( _: any, record: any) => {
				if(userCached[record.createdBy]) {
					return <p>{userCached[record.createdBy].name}</p>;
				} else{
					return <p>Loading</p>;
				}
			}
		},
		{
			dataIndex: -1,
	  		title: 'Actions',
			key: 'actions',
			fixed: 'right',
			width: 100,
	    	render: ( _: any, record: any ) => { 
				// console.log( record.id );
				return (
					<Space size="small">
						<Tooltip title='Edit'>
							<div className={styles.usersListingAction} onClick={() => editUser(record)}>
								<EditOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
						<Tooltip title="Delete">
							<Popconfirm
								title="Delete the user"
								description="Are you sure to delete this user?"
								okText="Yes"
								cancelText="No"
								onConfirm={() => handleDeleteOneUser( record._id )}
  							>
								<div className={styles.usersListingAction} >
									<DeleteOutlined style={{opacity: '0.7'}}/>
								</div>
							</Popconfirm>
						</Tooltip>
						{
							sessionData?.isCaller &&
							<Tooltip title='Callers Activity'>
								<div className={styles.usersListingAction} onClick={() => navigate(`/users/userActivity/${record._id}`)}>
									<CommentOutlined style={{opacity: '0.7'}} />
								</div>
							</Tooltip>
						}
					</Space>
				);
			}
	  	}
	];

	userTableColumns.forEach((column: any) => {
		if(sortField && sortOrder && column.dataIndex === sortField){
			column.sortOrder = sortOrder || null;
		}
	});

	// const rowSelection: TableRowSelection<any> = {
	// 	selectedRowKeys,
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		console.log('select1', selectedRows);
	// 		// assign keys to table row
	// 		setSelectedRowKeys(selectedRowKeys);
	// 	},
	// };

	let timerId: any;
	const onSearchInputChange = (e: any) => {
		clearTimeout(timerId);
		const searchValueTrimmed = e.target.value.trim();
		timerId = setTimeout(() => {
			setSearchValue(searchValueTrimmed);
			paginationData.searchValue = searchValueTrimmed;

			//set the table page no. to 1
			paginationData.pageNumber = 1;
			
			setPaginationData(paginationData);
			handleUserList(paginationData);
		},500);
		
	};

	// getting group table data and roles list
	useEffect(() => {
		handleUserList(paginationData);
		handlerRoleOptions();
		handleReportingManagerList();
	},[]);

	// whenever userDetail modal opens we want reporting mangers list
	useEffect(() => {
		userDetailModalOpen ? handleReportingManagerList() : '';
	}, [userDetailModalOpen]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<div className={styles.listUsersSreenbody} role='listGroupsSreenbody'>
				<div className={styles.listUsersSreenbodyheader}>
					{useCheckAttribute(resources.user.value, attributes.add.value) && (
						<Button type="primary" onClick={openCreateUser}>
							Create new user
						</Button>
					)}
					<Select
						options={Object.values(allowedRolesOfCurrentRole).map((options: any) => {
							return {
								label: options.name,
								value: options.id,
							};
						})}
						onChange={handleRoleFilterChnage}
						allowClear
						showSearch
						filterOption={(inputValue, option) =>
							(option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase().trim())
						}
						placeholder='Select Role'
						style={{width: '180px'}} 
					/>
					<SearchField 
						onSearchInputChange={onSearchInputChange}
						isAddLeadModal={false}
					/>
					<div>

					</div>
					<UserDetailsModal
						userDetailModalOpen={userDetailModalOpen}
						setUserDetailModalOpen={setUserDetailModalOpen}
						onUserDetailModalClose={onUserDetailModalClose}
						isEdit = {isEdit}
						handleCreateOneUser={handleCreateOneUser}
						oneUserData={oneUserData}
						handleEditOneUser={handleEditOneUser}
						roleOptions={allowedRolesOfCurrentRole}
						setDetailModalBtnLoading={setDetailModalBtnLoading}
						detailModalBtnLoading={detailModalBtnLoading}
						userDetailModalSubmitBtnRef={userDetailModalSubmitBtnRef}
						reportingManagerList={reportingManagerList}
					/>
				</div>
				<div className={styles.listUsersSreenbodyTable} id='listUsersSreenbodyTable'>
					<TableComponent 
						tableColumns={userTableColumns}
						listData={userList}
						handleTableChange={handleTableChange}
						isReportPage={false}
						perPageCount={perPageCount}
						totalListDataCount={totalListDataCount}
						loading={tableLoading}
						rowSelection={undefined}
						currentPage={currentPage}
					/>
				</div>
			</div>
		</>
	);
};