import React, { useContext } from 'react';
import styles from './previewModelRuleEngine.module.css';
import dayjs from 'dayjs';
import { ConditionContext } from '../../screens/rule-engine';

export const PreviewSingle = (props: any) => {
	const { conditions, operations } = useContext(ConditionContext);
	const {signleObj} = props;
	const children = (signleObj?.all ?? signleObj?.any);
	const toShowTitle = ((children).length > 1);
	const header = (Object.keys(signleObj)[0]).toUpperCase();
	return (
		<div className={styles.childElement}>
			{toShowTitle &&
				<h4 className={styles.childElementTag}>{header}</h4>
			}
			{children.reduce((result:any,current:any, index:any)=>{
				if(current.fact !== undefined && current.fact !== ''){
					result.push(
						<div key={current.key} className={styles.contentContainer}>
							<p>{conditions[current.fact].label}</p>
							<p>{operations.reverseOperation[current.operator].label}</p>
							{current.contentType === 4
							 ?
								<p>{dayjs(current.value).format('YYYY:MM:DD HH:mm:ss')}</p>
							 :
								<p>{(current.valueLabel)?current.valueLabel:current.value}</p>
							}
						</div>
					);
				}
				return result;
			},[])
			}
		</div>
	);
};
