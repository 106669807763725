import React from 'react';
import styles from './searchField.module.css';
import SearchIcon from '../../assets/img/icons8-search.svg';


interface SearchFieldProps {
	onSearchInputChange: (e: any) => void;
	isAddLeadModal: boolean,
	placeholder?: string
}

export const SearchField: React.FunctionComponent<SearchFieldProps> = (props) => {

	const {
		onSearchInputChange,
		isAddLeadModal,
		placeholder
	} = props;

	return (
		<div style={isAddLeadModal ? {position: 'relative', top: '5px', marginBottom: '1.5rem'} : {position: 'relative', top: '5px'}}>
			<img src={SearchIcon} className={styles.searchImg} />
			<input className={styles.search} placeholder={ placeholder ?? 'Search in leads'} onChange={(e) => onSearchInputChange(e)}/>
		</div>
	);		
};