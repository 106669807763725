import React, { useEffect, useState } from 'react';
import { useAppStore } from '../../stores';
import UserDeleted from '../../assets/img/users_deleted.svg';
import UserAdded from '../../assets/img/users_add.svg';
import StatusUpdated from '../../assets/img/status-update.png';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { LeadsContentSubHeader } from '../../components/leadsContentSubHeader';
import { Header } from '../../components/header';
import { TimeLineComponent } from '../../components/timeLineComponent';
import { activityType } from '../../config';
import { convertTime } from '../../libs/utils';

const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	logoutUser: state.logoutUser,
	getGroupActivity: state.getGroupActivity,
	getManyLeads: state.getManyLeads,
	setAppLoading: state.setAppLoading
} );

export const GroupsActivity: React.FunctionComponent = () => {
	document.title = 'Group Activity | CodeQuotient';
	const {
		sessionData,
		leadStages,
		leadStatus,
		logoutUser,
		getGroupActivity,
		getManyLeads,
		setAppLoading
	} = useAppStore(appStoreSelector);

	const [activityData, setActivityData] = useState<any[]>([]);
	const [particulatActivityData, setParticulatActivityData] = useState<any>({});
	const [activityNumber, setActivityNumber] = useState<string>('');
	const [createdByDetails, setCreatedByDetails] = useState<any>({});
	const [groupDetails, setGroupDetails] = useState<groupDetails>({});

	const { id } = useParams();

	const getGroupActivities = async () => {
		try {
			setAppLoading(true);
			const response = await getGroupActivity(id);

			if( response?.error ){
				throw new Error(response?.error);
			}

			setCreatedByDetails(response?.data?.usersData);
			setGroupDetails(response?.data?.groupData);

			// [
			// 	{
			// 		activityMonthYear: '',
			// 		dataOfSameMonthYear: [{
			// 			createdAt: '',
			// 			activityType: '',
			// 			totalData: '',
			// 			insertedLeadsId: []
			// 		}]
			// 	 }
			// ];

			const finalActivityData: any[] = [];
			let currentMonth = new Date().getMonth()+1;
			let currentYear = new Date().getFullYear();		

			response?.data?.data.forEach((val: any, index:number) => {
				// console.log(val.created_at);
				const date = new Date(val.created_at);
				const prevMonth = date.getMonth(); 
				const prevYear = date.getFullYear();

				if(
					(prevMonth!==currentMonth && prevYear===currentYear) || 
					((prevMonth===currentMonth || prevMonth!==currentMonth) && prevYear!==currentYear)
				){
					finalActivityData.push({
						activityMonthYear: `${date.toLocaleString('default', {month: 'short'})} ${prevYear}`,
						dataOfSameMonthYear: []
					});
					currentMonth = prevMonth;
					currentYear = prevYear;
				}

				// eslint-disable-next-line max-len
				const createdAt = `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})}, ${date.getHours()>9 ? date.getHours() : '0'+date.getHours()}:${date.getMinutes()>9 ? date.getMinutes() : '0'+date.getMinutes()}`;
				if(val.inserted_lead_ids && val.inserted_lead_ids.length){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[5].label,
						totalData: val.inserted_lead_ids.length,
						insertedLeadsId: val.inserted_lead_ids,
						iconType: UserAdded,
						id: val.id
					});
				} else if(val.removed_lead_ids && val.removed_lead_ids.length){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: activityType[6].label,
						totalData: val.removed_lead_ids.length,
						insertedLeadsId: val.removed_lead_ids,
						iconType: UserDeleted,
						id: val.id
					});
				} else if(val.activity_type === 8){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[8].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						id: val.id,
						stage: leadStatus[val.status]?.leadActivityStage,
						status: leadStatus[val.status]?.label,
						comment: val.comment
					});
				} else if(val.activity_type === 9 && val.assign_to !== null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[11].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						assignToName: val.assign_to,
						comment: val.comment,
						id: val.id
					});
				} else if(val.activity_type === 9 && val.assign_to === null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[9].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						comment: val.comment,
						id: val.id
					});
				} else if(val.activity_type === 10){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} ${activityType[10].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						followUpDate: `${convertTime(val.follow_up_date).split(' ')[0]},  ${convertTime(val.follow_up_date).split(' ')[1]}`,
						comment: val.comment,
						id: val.id
					});
				}
			});
			setActivityData(finalActivityData);
			setTimeout(() => {
				const element: HTMLElement = document.getElementsByClassName('timelineTitleBox')[0] as HTMLElement;
				if(element){
					element.click();
				}
			}, 100);
		} catch (e: any) {
			message.error(e.message);
			setActivityData([]);
		} finally {
			setAppLoading(false);
		}
	};

	const onParticularActivityClick = async (activityNum: string, insertedLeadsId: string[], activityLabel: string, iconType: any, createdAt: string,
		created_by: string, alreadyLeadsPresent: any, duplicateLeads: any, notInsertedLeads: any, groupName: string, stage: string, status: string,
		assignToName: string, followUpDate: string, comment: string) => {
		setActivityNumber(activityNum);

		try {
			setAppLoading(true);
			const response = await getManyLeads(insertedLeadsId);

			if( response?.error ){
				throw new Error(response?.error);
			}

			const leadsData: any = {
				insertedLeads: [],
				groupName, stage, status, assignToName: createdByDetails[assignToName]?.name, followUpDate, comment
			};
			response?.data.forEach((data: any) => {
				leadsData.insertedLeads.push({
					name: data.name,
					email: data.email,
				});
			});

			// console.log(created_by);

			setParticulatActivityData({
				leadsData, activityLabel, iconType, createdAt, created_by: createdByDetails[created_by].name ,
			});
		} catch(e: any){
			message.error(e.message);
			setParticulatActivityData({});
		} finally {
			setAppLoading(false);
		}

	};

	useEffect(() => {
		if(leadStages.length) getGroupActivities();
	}, [leadStages]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<LeadsContentSubHeader 
				// groupId= {id}
				isGroup = {true}
				isActivityPage = {true}
				isLeadActivityPage = {false}
				isBulkAvtivity = {false}
				isLeadsCommentActivity = {false}
				groupDetails={groupDetails}
				leadDetails={{}}
			/>
			<TimeLineComponent 
				activityData={activityData}
				particulatActivityData={particulatActivityData}
				activityNumber={activityNumber}
				onParticularActivityClick={onParticularActivityClick}
				isLeadActivityPage = {false}
				isBulkActivityPage = {false}
				isGroupActivityPage = {true}
				isLeadsCommentActivityPage = {false}
			/>
		</>
	);		
};