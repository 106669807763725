import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ListLeadsSreen } from './listLeads-screen';
import CheckPermissions from '../libs/auth/checkPermissions';
import { ListGroupScreen } from './listGroup-screen';
import { GroupsReport } from './groupsReport-screen';
import { GroupsActivity } from './groupsActivity-screen';
import { LeadsActivity } from './leadsActivity-screen';
import { BulkLeadsActivity } from './bulkLeadActivity-screen';
import { LeadRolesScreen } from './leadRoles-screen';
import { ListUserScreen } from './listUsers-screen';
import { attributes, resources } from '../config/authorization';
import { ErrorPageScreen } from './errorPage-screen';
import { UnAuthorizedPageScreen } from './unauthorizedPage-screen';
import { MainReportsScreen } from './mainReports-screen';
import { Report } from './report-screen/report-screen';
import RolesPermissions from './rolesPermissions-screen/rolesPermissions-screen';
import { DynamicStatusScren } from './dynamicStatus-screen';
import { LeadsCommentActivity } from './leadsCommentActivity-screen';
import { UserActivity } from './userActivity-screen';
import { ListCallerActivity } from './listCallerActivity-screen';
import { ListSourceScren } from './listSources-screen';
import { RuleEngine } from './rule-engine';
import { DownloadActivity } from './downloadActivity-screen';
import useCheckAttribute from '../libs/auth/useCheckAttribute';

export const MainScreen: React.FunctionComponent = () => {
	return (
		<Routes>
			{/* <Route 
				path='/reports'
				element={<MainReportsScreen />}
			/> */}
			<Route 
				path='/dashboard'
				element = {
					<CheckPermissions
						resource={resources.reports.value}
						attribute={attributes.view.value}
					>
						<Report />
					</CheckPermissions>
				}
			/>
			<Route 
				path='/ruleEngine'
				element={
					<CheckPermissions
						resource={resources.reports.value}
						attribute={attributes.view.value}
					>
						<RuleEngine/>
					</CheckPermissions>
				}
			/>
			<Route path='/users'>
				<Route
					path="list"
					element={
						<CheckPermissions
							resource={resources.user.value}
							attribute={attributes.list.value}
						>
							<ListUserScreen />
						</CheckPermissions>
					} 
				/>
				<Route 
					path='userActivity/:userId'
					element={
						<CheckPermissions
							resource={resources.user.value}
							attribute={attributes.list.value}
						>
							<UserActivity/>
						</CheckPermissions>
					}
				/>
				<Route
					path='callerActivity'
					element = {
						<CheckPermissions
							resource={resources.user.value}
							attribute={attributes.list.value}
						>
							<ListCallerActivity/>
						</CheckPermissions>
					}
				/>
			</Route>
			<Route path='/lead'>
				<Route
					path="list"
					element={
						<CheckPermissions
							resource={resources.lead.value}
							attribute={attributes.list.value}
						>
							<ListLeadsSreen />
						</CheckPermissions>
					} 
				/>
				<Route 
					path='leadActivity/:leadId' 
					element={
						<CheckPermissions
							resource={resources.lead.value}
							attribute={attributes.activity.value}
						>
							<LeadsActivity />
						</CheckPermissions>
					}
				/>
				<Route 
					path='leadCommentActivity/:leadId' 
					element={
						<CheckPermissions
							resource={resources.lead.value}
							attribute={attributes.activity.value}
						>
							<LeadsCommentActivity />
						</CheckPermissions>
					}
				/>
				<Route
					path="bulkActivity"
					element={
						<CheckPermissions
							resource={resources.lead.value}
							attribute={attributes.activity.value}
						>
							<BulkLeadsActivity />
						</CheckPermissions>
					} 
				/>
				{/* <Route
					path='downloadActivity'
					element={
						<CheckPermissions
							resource={resources.lead.value}
							attribute={attributes.activity.value}
						>
							<DownloadActivity />
						</CheckPermissions>
					}
				/> */}
			</Route>

			<Route path='/group'>
				<Route path='list' element={
					<CheckPermissions
						resource={resources.group.value}
						attribute={attributes.list.value}
					>
						<ListGroupScreen />
					</CheckPermissions>
				}/>
				<Route path='groupContent' >
					<Route path='leads/:id' element={
						<CheckPermissions
							resource={resources.lead.value}
							attribute={attributes.list.value}
						>
							<GroupsReport />
						</CheckPermissions>
					}/>
					<Route path='activity/:id' element={
						<CheckPermissions
							resource={resources.group.value}
							attribute={attributes.activity.value}
						>
							<GroupsActivity />
						</CheckPermissions>
					}/>
				</Route>
			</Route>

			<Route path='roles' element={
				<LeadRolesScreen/>
			}>
			</Route>
			<Route path='rolesPermissions/:id' element={
				<RolesPermissions/>
			}></Route>

			<Route path='dynamicStatus' element={
				<DynamicStatusScren/>
			}></Route>

			<Route path='sources' element={
				<ListSourceScren />
			}></Route>
			
			<Route path='error'>
				<Route path='' element={
					<ErrorPageScreen />
				}>
				</Route>
				<Route path='unauthorized' element={
					<UnAuthorizedPageScreen />
				}>
				</Route>
			</Route>

			<Route path="/" element={
				<>
					{useCheckAttribute(resources.reports.value, attributes.view.value)
						?
						<Navigate to="/dashboard" replace/>
						:
						<Navigate to="/lead/list" replace/>
					}
				</>
			} />

			<Route path="*" element ={<ErrorPageScreen />} />
		</Routes>
	);
};