import React, { useEffect, useRef, useState} from 'react';
import { useAppStore } from '../../stores';
import { Header } from '../../components/header';
import styles from './listGroup-screen.module.css';
import {
	Button,
	Space,
	Tooltip, 
	message, 
	Popconfirm
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { GroupDetailsModal } from '../../components/groupDetailsModal';
import { TableComponent } from '../../components/mainTableComponent';
import { convertTime } from '../../libs/utils';
import { useNavigate } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import { SearchField } from '../../components/searchField';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
	groupListData: state.groupListData,
	logoutUser: state.logoutUser,
	getGroupsList: state.getGroupsList,
	createOneGroup: state.createOneGroup,
	deleteOneGroup: state.deleteOneGroup,
	updateOneGroup: state.updateOneGroup
});

export const ListGroupScreen: React.FunctionComponent = () => {
	document.title = 'Groups | CodeQuotient';
	const { 
		sessionData, 
		groupListData, 
		logoutUser,
		getGroupsList, 
		createOneGroup,
		deleteOneGroup,
		updateOneGroup 
	} = useAppStore( appStoreSelector );

	const [groupDetailModalOpen, setgroupDetailModalOpen] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [groupList, setGroupList] = useState<any[]>([]);
	const [oneGroupData, setOneGroupData] = useState<any>({});
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [paginationData, setPaginationData] = useState<paginationData>({
		sortField: 'updated_at',
    	sortOrder: 'descend',
    	pageNumber: 1,
    	perPage: 20,
		searchValue: ''
	});
	const [sortField, setSortField] = useState<string>('updated_at');
	const [sortOrder, setSortOrder] = useState<any>('descend');
	const [perPageCount, setPerPageCount] = useState<number>(20);
	const [totalListDataCount, setTotalListDataCount] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	const [prevSorterOrder, setPrevSorterOrder] = useState<any>('descend');

	const [detailModalBtnLoading, setDetailModalBtnLoading] = useState<boolean>(false);
	const groupDetailModalSubmitBtnRef = useRef<any>(null);

	// assign keys to table row
	// const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	const navigate = useNavigate();

	// open the create new group drawer
	const openCreateGroup = () => {
		setgroupDetailModalOpen(!groupDetailModalOpen);
		setIsEdit(false);
	};
  
	// on closing the group drawer
	const onGroupDetailModalClose = () => {
		setgroupDetailModalOpen(false);
		setIsEdit(false);
	};

	// open the edit lead drawer
	const editGroup = (record: any) => {
		setgroupDetailModalOpen(true);
		setIsEdit(true);
		setOneGroupData(record);
	};

	const handleEditCreatGroupBtn = () => {
		groupDetailModalSubmitBtnRef.current ? groupDetailModalSubmitBtnRef.current.removeAttribute('disabled', 'disabled') : '';
		setDetailModalBtnLoading(false);
	};

	const handleGroupList = async (paginationData: paginationData) => {
		setTableLoading(true);
		const response = await getGroupsList(paginationData);
		setTableLoading(false);

		// while editing/creating group need to handle the behaviour of edit/create button in leadDetailModal form
		handleEditCreatGroupBtn();

		if(response?.error){
			message.error(response.error || 'Error in fetching groups list data');
			setGroupList([]);
			return;
		}

		response?.data?.results.forEach((groupData: any, index: number) => {
			groupData.SrNum = (response?.data?.page - 1) * response?.data?.perPage + index + 1;
		});

		setGroupList(response?.data?.results || []);
		setSortField(response?.data?.sortField);
		setSortOrder(response?.data?.setSortOrder || null);
		setPerPageCount(response?.data?.perPage);
		setTotalListDataCount(response?.data?.total);
		setCurrentPage(response?.data?.page);
	};

	const getGroupsData = () => {
		//set the table page no. to 1
		paginationData.pageNumber = 1;
		// paginationData.sortField = 'updated_at';
		// paginationData.sortOrder = 'descend';
		// setPrevSorterOrder('descend');

		setPaginationData(paginationData);
		handleGroupList(paginationData);
		document.body.querySelector('#listGroupsSreenbodyTable .ant-table-body')?.scrollTo(9999, 0);
	};

	const handleCreateOneGroup = async (data: any) => {
		setTableLoading(true);
		const response = await createOneGroup(data);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error );
			setgroupDetailModalOpen(true);
			// while editing/creating group need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatGroupBtn();
			return;
		}

		setgroupDetailModalOpen(false);
		message.success( response?.data?.msg || 'Group Created Sucessfully' );

		getGroupsData();
	};

	const handleDeleteOneGroup = async (groupId: string) => {
		setTableLoading(true);
		const response = await deleteOneGroup(groupId);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error?.message ?? response.error );
			return ;
		}
		message.success( response?.data?.msg || 'Group Deleted Sucessfully' );
		getGroupsData();
	};
	
	const handleEditOneGroup = async (data: any) => {
		setTableLoading(true);
		const response = await updateOneGroup(data);
		setTableLoading(false);

		if(response?.error){
			message.error( response.error );
			setgroupDetailModalOpen(true);
			// while editing/creating group need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatGroupBtn();
			setIsEdit(true);
			return;
		}

		setIsEdit(false);
		setgroupDetailModalOpen(false);
		message.success( response?.data?.msg || 'Group Edited Sucessfully' );

		getGroupsData();
	};

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		console.log(pagination, filters, sorter);
		const data: paginationData = {
			sortField: sorter.field,
    		sortOrder: sorter.order || null,
    		pageNumber: pagination.current,
    		perPage: pagination.pageSize,
			searchValue
		};

		// on sort set the table page no. to 1
		if (sorter.order !== prevSorterOrder) {
			data.pageNumber = 1;
		}

		setPrevSorterOrder(sorter.order);
		handleGroupList(data);
		setPaginationData(data);
	};

	const isEditGroupAllowed = useCheckAttribute(resources.group.value, attributes.edit.value);
	const isDeleteGroupAllowed = useCheckAttribute(resources.group.value, attributes.delete.value);

	const groupTableColumns: ColumnsType<grpTableDataType> = [
		{
			width: 47,
			dataIndex: 'SrNum',
		  	key: 'SrNum',
			render: ( _: any, record: any ) => (
				<div style={{display: 'flex', justifyContent: 'center'}}>{record.SrNum}.</div>
			)
		},
		{
		  title: 'Title',
		  dataIndex: 'title',
		  key: 'title',
		  ellipsis: true,
		  sorter: ( a, b ) => a.title.localeCompare( b.title ),
		  render: ( _: any, record: any ) => { 
				// console.log( record );
				return (
					<span className='groupAndLeadTitle' title={record.title} onClick={()=>navigate(`/group/groupContent/leads/${record.id}`, {replace: false })}>
						{record.title}
					</span>
			  	);
			}
		},
		{
		  title: 'Description',
		  dataIndex: 'description',
		  ellipsis: true,
		  key: 'description',
		},
		// {
		// 	title: 'Total Leads',
		// 	dataIndex: 'count',
		// 	ellipsis: true,
		// 	key: 'count',
		// 	width: 115
		//   },
		{
			title: 'Updated At',
		  	dataIndex: 'updated_at',
			key: 'updated_at',
		  	width: 200,
		  	sorter: ( a, b ) => a.updated_at.localeCompare( b.updated_at ),
		  	render: ( _: any, record: any ) => { 
			  // console.log( record );
			  const time = record.updated_at;
			  return (
				  convertTime(time)
				);
		  	}
		},
		{
			dataIndex: -1,
	  		title: 'Actions',
			key: 'actions',
			fixed: 'right',
			width: 100,
	    	render: ( _: any, record: any ) => { 
				// console.log( record.id );
				return (
					<Space size="small">
						{isEditGroupAllowed && (
							<Tooltip title='Edit'>
								<div className={styles.groupsListingAction} onClick={() => editGroup(record)}>
									<EditOutlined style={{opacity: '0.7'}} />
								</div>
							</Tooltip>
						)}
						{isDeleteGroupAllowed && (
							<Tooltip title="Delete">
								<Popconfirm
									title="Delete the group"
									description="Are you sure to delete this lead?"
									okText="Yes"
									cancelText="No"
									onConfirm={() => handleDeleteOneGroup( record.id )}
								>
									<div className={styles.groupsListingAction} >
										<DeleteOutlined style={{opacity: '0.7'}}/>
									</div>
								</Popconfirm>
							</Tooltip>
						)}
					</Space>
				);
			}
	  	}
	];

	groupTableColumns.forEach((column: any) => {
		if(sortField && sortOrder && column.dataIndex === sortField){
			column.sortOrder = sortOrder || null;
		}
	});

	// const rowSelection: TableRowSelection<any> = {
	// 	selectedRowKeys,
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		console.log('select1', selectedRows);
	// 		// assign keys to table row
	// 		setSelectedRowKeys(selectedRowKeys);
	// 	},
	// };

	let timerId: any;
	const onSearchInputChange = (e: any) => {
		clearTimeout(timerId);
		const searchValueTrimmed = e.target.value.trim();
		timerId = setTimeout(() => {
			setSearchValue(searchValueTrimmed);
			paginationData.searchValue = searchValueTrimmed;

			//set the table page no. to 1
			paginationData.pageNumber = 1;

			setPaginationData(paginationData);
			handleGroupList(paginationData);
		},500);
	};

	// getting group table data
	useEffect(() => {
		handleGroupList(paginationData);
	},[]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<div className={styles.listGroupsSreenbody} role='listGroupsSreenbody'>
				<div className={styles.listGroupsSreenbodyheader}>
					{useCheckAttribute(resources.group.value, attributes.add.value) && (
						<Button type="primary" onClick={openCreateGroup}>
							Create new group
						</Button>
					)}
					<SearchField 
						onSearchInputChange={onSearchInputChange}
						isAddLeadModal={false}
					/>
					<GroupDetailsModal 
						groupDetailModalOpen={groupDetailModalOpen}
						setgroupDetailModalOpen={setgroupDetailModalOpen}
						onGroupDetailModalClose={onGroupDetailModalClose}
						isEdit = {isEdit}
						handleCreateOneGroup={handleCreateOneGroup}
						oneGroupData={oneGroupData}
						handleEditOneGroup={handleEditOneGroup}
						setDetailModalBtnLoading={setDetailModalBtnLoading}
						detailModalBtnLoading={detailModalBtnLoading}
						groupDetailModalSubmitBtnRef={groupDetailModalSubmitBtnRef}
					/>
				</div>
				<div className={styles.listGroupsSreenbodyTable} id='listGroupsSreenbodyTable'>
					<TableComponent 
						tableColumns={groupTableColumns}
						listData={groupList}
						handleTableChange={handleTableChange}
						isReportPage={false}
						perPageCount={perPageCount}
						totalListDataCount={totalListDataCount}
						loading={tableLoading}
						rowSelection={undefined}
						currentPage={currentPage}
					/>
				</div>
			</div>
		</>
	);
};