import React, { useEffect,useRef } from 'react';
import styles from './userDetailModal.module.css';
import type { InputRef } from 'antd';
import { 
	Button, 
	Drawer, 
	Input,  
	message,
	Form,
	Select,
	Space,
} from 'antd';

const { TextArea } = Input;
const {Option} = Select;

interface UserDetailsModalProps {
	userDetailModalOpen: boolean,
	setUserDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
	onUserDetailModalClose: () => void,
	isEdit: boolean,
	handleCreateOneUser: (data: any) => void,
	oneUserData: any,
	handleEditOneUser: (data: any) => void,
	roleOptions: any,
	setDetailModalBtnLoading: any,
	detailModalBtnLoading: boolean,
	userDetailModalSubmitBtnRef: any,
	reportingManagerList: reportingManagerList[]
}

export const UserDetailsModal: React.FunctionComponent<UserDetailsModalProps> = (props) => {
	const {
		userDetailModalOpen, 
		setUserDetailModalOpen, 
		onUserDetailModalClose,
		isEdit,
		handleCreateOneUser,
		oneUserData,
		handleEditOneUser,
		roleOptions,
		setDetailModalBtnLoading,
		detailModalBtnLoading,
		userDetailModalSubmitBtnRef,
		reportingManagerList
	} = props;

	const [createNewUserForm] = Form.useForm();
	// reset the drawer while closing
	const resetDrawerOnClose = () => { 
		createNewUserForm.resetFields();
		document.body.querySelector('.createNewUserDrawer .ant-drawer-body')?.scrollTo(9999, 0);
	};

	// reset the drawer after User created or edited
	const resetDrawer = () => { 
		resetDrawerOnClose(); 
		setUserDetailModalOpen( false );
	};

	const addNewUser = (formValues: any) => {
		// resetDrawer();

		// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		userDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleCreateOneUser(formValues);
	};

	const notAllowSpaces = (e:any) => {
		if(e.key === ' '){
			e.preventDefault();
		} 
	};
	const spacesValidation = (e:any) => {
		console.log('on input');
		console.log(e.target.value);
		e.target.value = e.target.value.replace(/\s/g,'');
	};

	const editUser = (formValues: any, userId: string) => {
		// 	resetDrawer();

		// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		userDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleEditOneUser({name: formValues.name,reportingManager: formValues.reportingManager, userId});
	};

	const setFieldsAndVals = () => {
		// console.log(oneUserData);
		// createNewUserForm.setFieldsValue({
		// 	name: oneUserData.name,
		// 	email: oneUserData.description
		// });
		createNewUserForm.setFieldsValue(oneUserData);
	};

	useEffect(() => {
		if( isEdit ){
			setFieldsAndVals();
		}

		if(!userDetailModalOpen) {
			resetDrawer();
		}
	}, [isEdit, userDetailModalOpen]);

	return (
		<div className={styles.createNewUserDrawerContainer}>
			<Drawer
				title={(() =>{
					if(isEdit) return 'Edit User';
					else return 'Create New User';
				})()}
				width={450}
				onClose={() => {
					resetDrawerOnClose();
					onUserDetailModalClose();
				}}
				open={userDetailModalOpen}
				extra={
					<Space>
						<Button 
							ref={userDetailModalSubmitBtnRef}
							type="primary" 
							onClick={ () => createNewUserForm.submit() }
							loading={detailModalBtnLoading}
						>
							{ isEdit ? 'Edit' : 'Create' }
						</Button>
					</Space>
				}
			>
				<div className={styles.createNewUserBody}>
					<Form
						form={createNewUserForm}
						onFinish={
							(values) => {
								if(isEdit){
									editUser(values, oneUserData._id);
								}else {
									addNewUser(values);
								}
							}
						}
						layout="vertical"
					>
						<Form.Item
							label="Name"
							name="name"
							rules={[
								{
									required: true,
									message: 'Please input user name'
								},
								{
									pattern: /^(?!\s)[a-zA-Z0-9-_. ]*$/,
									message: 'Not a valid title,remove special characters or any initial spaces!',
								},
							]}
						>
							<Input showCount maxLength={50} placeholder={`Enter User's Name`} />
						</Form.Item>
						<Form.Item
							label="E-mail"
							name="email"
							rules={[
								{
									type: 'email',
									message: 'The input is not valid E-mail!',
								},
								{ 
									required: true, 
									message: 'Please input user email!' 
								}
							]}
						>
							<Input disabled={isEdit ? true : false} placeholder={`Enter User's E-mail`} />
						</Form.Item>
						{!isEdit ? 
							<Form.Item
								label="Password"
								name="password"
								rules={[
									{
										required: true,
										message: 'please enter user password'
									},
									{
										pattern:   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,32}$/,
										message: 'Password size must be between 8 to 32 characters!Must contain one Uppercase , one LowerCase and one Digit character!'
									},
								]}
							>
								<Input showCount maxLength={32} onKeyDown={notAllowSpaces} placeholder={`Enter User's Password`}/>
							</Form.Item> : ''
						}
						<Form.Item
							name='roleId'
							label='Role'
							rules={[
								{
									required: true,
									message: 'Please select user role'
								},
							]}
						>
							<Select 
								allowClear 
								disabled={isEdit ? true : false}
								options={roleOptions.map((options: any) => ({label: options.name, value: options.id, key: options.id}))}
								placeholder='Select Roles'
								showSearch
								filterOption={(inputValue:any, option:any) =>
									(option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase().trim())
								  }
								style={{width: '100%'}} 
							/>
						</Form.Item>
						<Form.Item
							name='reportingManager'
							label='Reporting Manager'
						>
							<Select
								allowClear
								mode="multiple"
								options={reportingManagerList.map((options: reportingManagerList) => (
									{label: `${options.name}`, value: options.id, key: options.id, title: options.email}
								))}
								placeholder='Select Reporting Managers'
								style={{width: '100%'}} 
								filterOption={(input: string, option: any) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
							/>
						</Form.Item>
					</Form>
				</div>
			</Drawer>
		</div>
  	);
};
