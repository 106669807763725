import React,{useState,useEffect,useRef} from 'react';
import { Header } from '../../components/header';
import { Collapse,Button,Checkbox,Card,message } from 'antd';
import { useAppStore } from '../../stores';
import styles from './rolesPermissions-screen.module.css';
import {resources,attributes} from '../../config/authorization';	
import { stringToRGB } from '../../libs/utils';
import { resourcesAttributes } from '../../config/roles';
import { useNavigate } from 'react-router-dom';
import { CheckboxComponent } from '../../components/checkboxComponent';
import { useParams } from 'react-router-dom';
import CollapsePanel from 'antd/es/collapse/CollapsePanel';


const appStoreSelector = ( state: AppState ) => ( {
	logoutUser: state.logoutUser,
	sessionData: state.sessionData,
	getPermissionData: state.getPermissionData,
	setPermissionData: state.setPermissionData
	
} );
const resourceMap = new Map();

const attributeMap = new Map();

for(const [key, value] of Object.entries(resources)){
	resourceMap.set(value.value,key);
	
}
for(const [key, value] of Object.entries(attributes)){
	attributeMap.set(value.value,key);
}
let result = {};
const selectAllOnAllSelected:any = [];
const initialPermissions:any = {};
const RolesPermissions:React.FC = () => {
	const [selectAllOption,setSelectAllOption] = useState<any>(null);
	const [initPermit,setInitPermit] = useState<any>();
	const navigate = useNavigate();
	const roleName = useParams();
	const { sessionData,logoutUser,getPermissionData,setPermissionData} = useAppStore( appStoreSelector );
	const [resource, setResource] = useState({...resourcesAttributes});
	const [screen,setScreen] = useState<Array<string> | null>(null);
	const [activePanel,setActivepanel] = useState<any>([]);

 	const handleUserPermissionList = async() => {
		
		for(const [key, sessionValue] of Object.entries(sessionData?.permissions)){
				
			if(resourceMap.has(sessionData?.permissions[key].resource)){
				const arr:any = [];
				sessionData?.permissions[key].attributes.forEach((element:any)=>{
					arr.push(element);
				});
				
				initialPermissions[(sessionData?.permissions[key].resource)] = arr;
				
			}
	
		}
		
		setInitPermit(initialPermissions);
		result = initialPermissions;
	
		setScreen(Object.keys(initialPermissions));
	};
	const selectAll = (e:any)=>{
		if(e.target.checked){
			setSelectAllOption(true);
			
		}else {
			setSelectAllOption(false);
		}
		setResource({...resource});
	};
	useEffect(()=>{
		handleUserPermissionList();
		
	},[]);
	
	if(screen === null){
		return <></>;
	}
	
	const SubmitForm = async ()=>{
		console.log(result);
		const obj:any = {};
		Object.entries(result).forEach((value:any)=>obj[value[0]]={'resources':value[0],'attributes':value[1]});
		console.log(obj);
		// const response = await setPermissionData(result);
		// if(response?.error){
		// 	message.error(response.error);
		// 	return;
		// }
		// message.success(response?.data?.msg);
		// navigate('/navigation page');
	};
	return (
		<>
		 	
			<Header 
				logoutUser={logoutUser}
				sessionData = {sessionData}
			/>
			<div className={styles.container}>
				<div className={styles.leftPart}>
					<Card className={styles.cardComponent}>
						<div className={styles.cardComponentInner}>

							<div className={styles.logo} style={{backgroundColor:stringToRGB(roleName?.id??'')}}>
								{(roleName?.id ?? ' ').charAt(0)}
								{/* {(roleName?.id??''.split(' ').map((value:string)=> value.charAt(0).toUpperCase())).toString().replace(',','')} */}
							</div>
							<div className={styles.roleName}>{roleName?.id??''.charAt(0).toUpperCase()+roleName?.id??''.slice(1)}</div>
							<div className={styles.buttonContainer}>
								<Button size='large' className={styles.button} onClick={()=>navigate('/roles')} type='primary'>Back</Button>
								<Button size='large' className={styles.button} onClick={() => SubmitForm()} type='primary'>Save</Button>
							</div>
							<div><Checkbox checked={selectAllOption === true}onChange={selectAll} >Select All</Checkbox></div>
						</div>
						
					</Card>
				</div>
				<div className={styles.rightPart}>
					<Collapse size="large" collapsible={'header'} activeKey={[1,2]}>					
						{Object.values(resource).map((value)=>{
							return (
								<CheckboxComponent key={value.resource} checked={selectAllOption === true?true:false}
								 	defaultValue={initPermit[value.resource]} options={value} result={result} selectAll={selectAllOption}
									setSelectAll={setSelectAllOption} length={Object.values(resource).length} selectAllOnAllSelected={selectAllOnAllSelected}
								/>
							);
						})}
					</Collapse>
				</div>
			</div>
		</>
		
	);
};
export default RolesPermissions;