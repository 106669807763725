/* eslint-disable @typescript-eslint/no-empty-function */
import { create } from 'zustand';
import { CQLeadsClient } from '../../clients';
import { devtools } from 'zustand/middleware';
import { UUID } from 'crypto';

const initialState: AppState = {
	loading: false,
	loadingText: '',
	welcome: false,
	initialConnectionEstablished: false,
	sessionData: null,
	leadListData: [],
	groupListData: [],
	leadsOfGroupList: [],
	allowedRolesOfCurrentRole: [],
	leadStages: [],
	leadStatus: {},
	sourceOptions: {},
	sourceList: [],

	init: async () => {},
	getLeadStagesAndStatus: async () => {},
	setAppLoading: async () => {},
	logoutUser: async () => {},
	createOneLead: async () => {},
	welcomeUser: async () => {},
	getLeadsList: async () => {},
	deleteOneLead: async () => {},
	updateOneLead: async () => {},
	getGroupsList: async () => {},
	createOneGroup: async () => {},
	deleteOneGroup: async () => {},
	updateOneGroup: async () => {},
	getLeadsOfGroup: async () => {},
	getLeadsToAddInGroup: async () => {},
	addLeadsInGroup: async () => {},
	getGroupActivity: async () => {},
	getManyLeads: async () => {},
	removeLeadFromGroup: async () => {},
	getLeadActivity: async () => {},
	bulkUploadLeads: async () => {},
	createActivityBeforeDownload: async () => {},
	getBulkActivity: async () => {},
	updateLeadStatus: async () => {},
	updateAssignTo: async () => {},
	unAssignLeads: async () => {},
	updateFollowUpLastCalled: async () => {},
	createOneUser: async () => {},
	editOneUser: async () => {},
	deleteUser: async () => {},
	getAllowedRolesOfCurrentRole: async () => {},
	getUserList: async () => {},
	getAssignList: async () => {},
	getAllReportingManager: async () => {},
	getReports: async () => {},
	getPermissionData: async () => {},
	setPermissionData: async () => {},
	// getAllRoles: async () => {},
	addNewRole: async () => {},
	getLeadComments: async () => {},
	getUserActivity: async () => {},
	getCallerData: async () => {},
	getStatusList: async () => {},
	createOneStatus: async () => {},
	editOneStatus: async () => {},
	getLeadCount: async () => {},
	getCardData: async () => {},
	getLeadVsStatusCount: async () => {},
	getPaymentRecivedVsWorkedByCallerCount: async () => {},
	getLeadVsSourceCount: async () => {},
	getUserCreatedDaily: async () => {},
	getEngagementData: async () => {},
	getLeadCountByCounselor: async() => {},
	listSources: async () => {},
	createSource: async () => {},
	editSource: async () => {},
	deleteSource: async () => {},
	generatePaymentLink: async () => {},
	getPaymentDetails: async () => {},
	getRuleEngineConditions: async () => {},


	getRuleList: async () => {},
	postRule: async () => {},
	changeRuleState: async () => {},
	getSingleRule: async () => {},
	getDetailedDataForLeadStatus: async () => {},
	getDetailedDataForLeadVsCaller: async () => {},
	getPymentRecieveVsWorkedOnLead: async () => {},
	getDetailedDataOfDailyLeadTrend: async () => {},
	getDetailedCardData: async () => {},
	getDetailedSourceData: async () => {},
	getDetailedEngagementData: async () => {},
	deleteRule: async () => {},
	editRule: async () => {},
	getDataFromDynamicLink: async () => {},
	getUserDetails: async () => {},
	checkEditRulePermission: async() => {},
	runRuleEngine: async () => {},
};

export function CreateAppStore ( CQLeadsClient: CQLeadsClient ) {
	return create<AppState>()(
		devtools(
			( set ) => ( {
				...initialState,
				init: async () => {
					try{
						const response:any = await CQLeadsClient.getSessionData();
						if( response ) {
							if(response.welcome){
								set(
									{
										welcome: true,
										initialConnectionEstablished: true,
									},
									false
								);
								return;
							}
							set(
								{
									initialConnectionEstablished: true,
									sessionData: response.data,
								},
								false
							);
						}
					} catch( e: any ) {
						set(
							{
								initialConnectionEstablished: true,
								sessionData: null,
							},
							false
						);
						throw new Error(
							e.message || 'Error while connection to the server'
						);
					}
				},

				getLeadStagesAndStatus: async () => {
					try{
						const response:any = await CQLeadsClient.getLeadStagesAndStatus();

						if( response ) {
							const finalLeadStatus: any = {};

							if(response?.data?.leadStatus) {
								response?.data?.leadStatus.forEach((statusData: leadStatusData) => {
									finalLeadStatus[statusData.value] = statusData;
								});
							}

							set(
								{
									leadStages: response?.data?.leadStages || [],
									leadStatus: finalLeadStatus
								},
								false
							);
							
						}
					} catch( e: any ) {
						set(
							{
								leadStages: [],
								leadStatus: {}
							},
							false
						);
						throw new Error(
							e.message || 'Error while connection to the server'
						);
					}
				},

				setAppLoading: async (isLoading: boolean, loadingText?: string): Promise<void> => {
					set({
						loading: isLoading,
						loadingText: loadingText,
					}, false);
				},

				logoutUser: async () => {
					try {
						const response = await CQLeadsClient.logoutUser();
						if(response) {
							localStorage.clear();
							window.location.replace('/');
						}
						return response;
					} catch (e:any) {
						return { error: e.message };
					}
				},
				createOneLead: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.createOneLead( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				welcomeUser: async (data: any) => {
					try {
						const response = await CQLeadsClient.welcomeUser(data);
						return response;
					} catch (e: any) {
						return { error: e.message };
					}
				},
				getLeadsList: async (data: paginationData) => {
					try {
						const response = await CQLeadsClient.getLeadsList(data);
						set({
							leadListData: response?.data?.results || []
						});
						return response;
					} catch ( e: any ) {
						set({
							leadListData: []
						});
						return { error: e.message };
					}
				},
				deleteOneLead: async ( leadId: string ) => {
					try{
						const response = await CQLeadsClient.deleteOneLead( leadId );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				updateOneLead: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.updateOneLead( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},

				getGroupsList: async (data: paginationData) => {
					try{
						const response = await CQLeadsClient.getGroupsList(data);
						set({
							groupListData: response?.data?.results || []
						});
						return response || [];
					} catch(e:any){
						set({
							groupListData: []
						});
						return { error: e.message };
					}
				},
				createOneGroup: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.createOneGroup( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				deleteOneGroup: async (groupId: string) => {
					try{
						const response = await CQLeadsClient.deleteOneGroup(groupId);
						return response;
					} catch(e:any) {
						return {error: e};
					}
				},
				updateOneGroup: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.updateOneGroup( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				getLeadsOfGroup: async (data: grpLeadsPaginateData) => {
					try{
						const response = await CQLeadsClient.getLeadsOfGroup(data);
						set({
							leadsOfGroupList: response?.data?.results || []
						});
						return response || [];
					} catch(e:any){
						set({
							leadsOfGroupList: []
						});
						return { error: e.message };
					}
				},
				getLeadsToAddInGroup: async (data: grpLeadsPaginateData) => {
					try{
						const response = await CQLeadsClient.getLeadsToAddInGroup(data);
						// set({
						// 	leadsOfGroupList: response?.data?.results || []
						// });
						return response || [];
					} catch(e:any){
						// set({
						// 	leadsOfGroupList: []
						// });
						return { error: e.message };
					}
				},
				addLeadsInGroup: async (data: any) => {
					try{
						const response = await CQLeadsClient.addLeadsInGroup(data);
						// set({
						// 	leadsOfGroupList: response?.data || []
						// });
						return response || [];
					} catch(e:any){
						// set({
						// 	leadsOfGroupList: []
						// });
						return { error: e.message };
					}
				},
				getGroupActivity: async (groupId: string | undefined ) => {
					try{
						const response = await CQLeadsClient.getGroupActivity(groupId);
						return response;
					} catch(e:any) {
						return {error: e};
					}
				},
				getManyLeads: async (leadIds: string[]) => {
					try{
						const response = await CQLeadsClient.getManyLeads(leadIds);
						return response;
					} catch(e:any) {
						return {error: e};
					}
				},
				removeLeadFromGroup: async (leadIds: string[] | React.Key[], groupId: string | undefined) => {
					try{
						const response = await CQLeadsClient.removeLeadFromGroup(leadIds, groupId);
						return response;
					} catch(e:any) {
						return {error: e};
					}
				},
				getLeadActivity: async ( leadId: string | undefined ) => {
					try{
						const response = await CQLeadsClient.getLeadActivity( leadId );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				bulkUploadLeads: async ( uploadFile: any ) => {
					try{
						const response = await CQLeadsClient.bulkUploadLeads( uploadFile );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				createActivityBeforeDownload: async (uploadFile: any) => {
					try {
						const response = await CQLeadsClient.createActivityBeforeDownload( uploadFile );
						return response;
					} catch (e:any) {
						return { error: e.message };
					}
				},
				getBulkActivity: async () => {
					try{
						const response = await CQLeadsClient.getBulkActivity();
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				updateLeadStatus: async (data: UpdateLeadStatus) => {
					try{
						const response = await CQLeadsClient.updateLeadStatus(data);
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				updateAssignTo: async (data: UpdateAssignTo) => {
					try{
						const response = await CQLeadsClient.updateAssignTo(data);
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				unAssignLeads: async (data: UnassignLeads) => {
					try{
						const response = await CQLeadsClient.unAssignLeads(data);
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				updateFollowUpLastCalled: async (data: UpdateFollowUpLastCalled) => {
					try{
						const response = await CQLeadsClient.updateFollowUpLastCalled(data);
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				createOneUser: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.createOneUser( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				editOneUser: async ( data: editUserPayload ) => {
					try{
						const response = await CQLeadsClient.editOneUser( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				deleteUser: async (deleteUserId: string) => {
					try{
						const response = await CQLeadsClient.deleteUser(deleteUserId);
						return response;
					} catch(e:any) {
						return {error: e};
					}
				},
				getAllowedRolesOfCurrentRole: async () => {
					try{
						const response = await CQLeadsClient.getAllowedRolesOfCurrentRole();
						set({
							allowedRolesOfCurrentRole: response?.data || []
						});
						return response || [];
					} catch(e:any){
						set({
							allowedRolesOfCurrentRole: []
						});
						return { error: e.message };
					}
				},
				getUserList: async (data: paginationData, filter?:any) => {
					try{
						const response = await CQLeadsClient.getUserList(data, filter);
						return response || [];
					} catch(e:any){
						return { error: e.message };
					}
				},
				getAssignList: async () => {
					try{
						const response = await CQLeadsClient.getAssignList();
						return response || [];
					} catch(e:any){
						return { error: e.message };
					}
				},
				getAllReportingManager: async () => {
					try{
						const response = await CQLeadsClient.getAllReportingManager();
						return response || [];
					} catch(e:any){
						return { error: e.message };
					}
				},
				getReports: async () => {
					try {
						const response = await CQLeadsClient.getReports();
						return response || [];
					} catch (e:any) {
						return { error: e.message };
					}
				},
				getPermissionData: async (data:any) => {
					try {
						const response = await CQLeadsClient.getPermissionData(data);

						return response || [];
					} catch (e:any) {
						return { error: e.message };
					}
				},
				getLeadComments: async (leadId: string | undefined) => {
					try {
						const response = await CQLeadsClient.getLeadComments(leadId);

						return response || [];
					} catch (e:any) {
						return { error: e.message };
					}
				},
				setPermissionData: async (data:any) => {
					try {
						const response = await CQLeadsClient.setPermissionData(data);
						return response || [];
					} catch (e:any) {
						return { error: e.message };
					}
				},
				
				addNewRole: async (data:any) => {
					try {
						const response = await CQLeadsClient.addNewRole(data);
						return response || [];
					} catch (e:any) {
						return { error: e.message };
					}
				},
				getStatusList: async (data: paginationData) => {
					try{
						const response = await CQLeadsClient.getStatusList(data);
						return response || [];
					} catch(e:any){
						return { error: e.message };
					}
				},
				createOneStatus: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.createOneStatus( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				editOneStatus: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.editOneStatus( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				getUserActivity: async ( userId: string | undefined, page: number ) => {
					try{
						const response = await CQLeadsClient.getUserActivity( userId, page );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				getCallerData: async () => {
					try{
						const response = await CQLeadsClient.getCallerData();
						return response;
					} catch( e: any){
						return { error: e.message };
					}
				},
				getLeadCount: async ( startDate: Date, endDate: Date, selectedCallerId: string | undefined) => {
					try{
						const response = await CQLeadsClient.getLeadCount(startDate?.toISOString(), endDate?.toISOString(), selectedCallerId);
						return response;
					} catch( e: any ){
						return { error: e.message };
					}
				},
				// getCardData: async (startDate: Date, endDate: Date, value: number, selectedCallerId: string | undefined ) => {
				// 	try{
				// 		const response = await CQLeadsClient.getCardData(startDate, endDate, value, selectedCallerId);
				// 		return response;
				// 	} catch( e: any ){
				// 		return { error: e.message };
				// 	}
				// },
				// getLeadVsStatusCount: async (startDate: Date, endDate: Date, selectedCallerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getLeadVsStatusCount(startDate, endDate, selectedCallerId);
				// 		return response;
				// 	} catch (e:any){
				// 		return {error: e.message};
				// 	}
				// },
				// getPaymentRecivedVsWorkedByCallerCount: async (startDate: Date, endDate: Date, selectedCallerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getPaymentRecivedVsWorkedByCallerCount(startDate, endDate, selectedCallerId);
				// 		return response;
				// 	} catch (e: any){
				// 		return { error: e.message };
				// 	}
				// },
				// getLeadVsSourceCount: async (startDate: Date, endDate: Date, selectedCallerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getLeadVsSourceCount(startDate, endDate, selectedCallerId);
				// 		return response;
				// 	} catch (e: any){
				// 		return { error: e.message };
				// 	}
				// },
				// getUserCreatedDaily: async (startDate: Date, endDate: Date, selectionType: string, selectedCallerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getUserCreatedDaily(startDate, endDate, selectionType, selectedCallerId);
				// 		return response;
				// 	} catch (e: any){
				// 		return {error: e.message};
				// 	}
				// },
				// getEngagementData: async (startDate: Date, endDate: Date, selectedCallerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getEngagementData(startDate, endDate,selectedCallerId);
				// 		return response;
				// 	} catch (e: any){
				// 		return { error: e.message };
				// 	}
				// },
				// getLeadCountByCounselor: async (startDate: Date, endDate: Date, selectedCallerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getLeadCountByCounselor(startDate, endDate, selectedCallerId);
				// 		return response;
				// 	} catch (e: any){
				// 		return { error: e.message };
				// 	}
				// },
				listSources: async (data: paginationData | any) => {
					try{
						const response = await CQLeadsClient.listSources(data);

						const sourceOptions: any = {};
						response?.data?.sources.forEach((source: any) => {
							sourceOptions[source?.value] = {label: source?.label, value: source?.value};
						});

						set(
							{
								sourceList: response?.data?.sources || [],
								sourceOptions: sourceOptions
							},
							false
						);
						return response || [];
					} catch(e:any){
						return { error: e.message };
					}
				},
				createSource: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.createSource( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				editSource: async ( data: any ) => {
					try{
						const response = await CQLeadsClient.editSource( data );
						return response;
					} catch( e:any ) {
						return { error: e.message };
					}
				},
				deleteSource: async (value: number) => {
					try{
						const response = await CQLeadsClient.deleteSource(value);
						return response;
					} catch(e:any) {
						return {error: e};
					}
				},

				generatePaymentLink: async (amount:number, leadId: string) => {
					try {
						const response = await CQLeadsClient.generatePaymentLink(amount, leadId);
						return response;
					} catch (error) {
						return {error};
					}
				},

				getPaymentDetails: async (leadId: string) => {
					try {
						const response = await CQLeadsClient.getPaymentDetails(leadId);
						return response;		
					} catch (error) {
						return {error};
					}
				},
				// getDetailedDataForLeadStatus: async (status: number | undefined, startDate: Date | undefined, endDate: Date | undefined, callerId: string | undefined, dataFromFrontEnd?: any) => {
				// 	try{
				// 		const response = await CQLeadsClient.getDetailedDataForLeadStatus( status, startDate, endDate, callerId, dataFromFrontEnd );
				// 		return response;
				// 	} catch (e: any) {
				// 		return { error: e};
				// 	}
				// },
				// getDetailedDataForLeadVsCaller: async (callerId: string, startDate: Date | undefined, endDate: Date | undefined, dataFromFrontEnd: any ) => {
				// 	try{
				// 		const response = await CQLeadsClient.getDetailedDataForLeadVsCaller(callerId, startDate, endDate, dataFromFrontEnd);
				// 		return response;
				// 	} catch (e: any) {
				// 		return { error: e };
				// 	}
				// },
				// getPymentRecieveVsWorkedOnLead: async (callerId: string, status: number, startDate: Date | undefined, endDate: Date | undefined, dataFromFrontEnd: any ) => {
				// 	try{
				// 		const response = await CQLeadsClient.getPymentRecieveVsWorkedOnLead(callerId, status, startDate, endDate, dataFromFrontEnd);
				// 		return response;
				// 	} catch (e: any){
				// 		return { error: e };
				// 	}
				// },
				getDetailedDataOfDailyLeadTrend: async (date: string, selectionType: string, callerId: string | undefined) => {
					try{
						const response = await CQLeadsClient.getDetailedDataOfDailyLeadTrend(date, selectionType, callerId);
						return response;
					} catch (e: any){
						return { error: e };
					}
				},
				// getDetailedCardData: async ( status: number | undefined, startDate: Date | undefined, endDate: Date | undefined, callerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getDetailedCardData(status, startDate, endDate, callerId);
				// 		return response;
				// 	} catch (e: unknown){
				// 		return { error: e };
				// 	}
				// },
				// getDetailedSourceData: async (source: number | null, startDate: Date | undefined, endDate: Date | undefined, callerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getDetailedSourceData(source, startDate, endDate, callerId);
				// 		return response;
				// 	} catch (e: any){
				// 		return { error: e };
				// 	}
				// },
				// getDetailedEngagementData: async ( selection: number, date: Date, callerId: string | undefined) => {
				// 	try{
				// 		const response = await CQLeadsClient.getDetailedEngagementData(selection,date,callerId);
				// 		return response;
				// 	}	catch (error: any){
				// 		return { error: error?.message ?? error};
				// 	}
				// },

				// Rule Engine
				getRuleList: async () => {
					try{
						const response = await CQLeadsClient.getRuleList();
						return response;
					} catch (error: any) {
						return {error : error?.message ?? error};
					}
				},
				postRule: async (payload: any) => {
					try{
						const response = await CQLeadsClient.postRule(payload);
						return response;
					} catch (error: any) {
						return {error : error?.message ?? error};
					}
				},

				changeRuleState: async (payload: any) => {
					try{
						const response = await CQLeadsClient.changeRuleState(payload);
						return response;
					} catch (error: any) {
						return {error : error?.message ?? error};
					}
				},

				getRuleEngineConditions: async () => {
					try{
						const response = await CQLeadsClient.getRuleEngineConditions();
						return response;
					} catch (error: any) {
						return {error : error?.message ?? error};
					}
				},

				getSingleRule: async (id: string) => {
					try{
						const response = await CQLeadsClient.getSingleRule(id);
						return response;
					} catch (error: any) {
						return {error : error?.message ?? error};
					}
				},
				deleteRule: async (id:string) => {
					try{
						const response = await CQLeadsClient.deleteRule(id);
						return response;
					} catch (error: any) {
						return { error: error?.message ?? error };
					}
				},
				editRule: async (payload: any) => {
					try{
						const response = await CQLeadsClient.editRule(payload);
						return response;
					} catch (error: any) {
						return { error: error?.message ?? error };
					}
				},
				getDataFromDynamicLink: async (url: string): Promise<any> => {
					try{
						const response = await CQLeadsClient.getDataFromDynamicLink(url);
						return response;
					} catch (error: any) {
						return { error: error?.message ?? error };
					}
				},
				getUserDetails: async (ids: Array<UUID>) => {
					try{
						const response = await CQLeadsClient.getUserDetails(ids);
						return response;
					} catch (error: any) {
						return { error: error?.messsage ?? error };
					}
				},
				checkEditRulePermission: async (id: UUID) => {
					try{
						const response = await CQLeadsClient.checkEditRulePermission(id);
						return response;
					} catch (error: any) {
						return { error: error?.message ?? error };
					}
				},
				runRuleEngine: async () => {
					try {
						const response = await CQLeadsClient.runRuleEngine();
						return response;
					} catch (error: any) {
						return { error: error?.messsage ?? error };
					}
				}
			})
		)
	);
}