import React, { useState, useEffect, useRef } from 'react';
import { Column } from '@ant-design/plots';
import {LeadWorkedVScaller, LocalLeadWorkedVScaller } from '../../@types/report';
import { tickIntervalFinder } from '../../libs/utils';
import { ReportGraphConstants } from '../../config/report';
import { useReportStore } from '../../stores';
import { ReportState } from '../../@types/store/report';

interface LeadWorkedVsCallerProps{
	data: Array<LeadWorkedVScaller> | undefined | null;
	labelSize?: number,
	setSelectedGraph: any,
}


export const LeadWorkedVsCaller = (props: LeadWorkedVsCallerProps) => {
	const { data, labelSize, setSelectedGraph } = props;
	const [localData, setLocalData] = useState<Array<LocalLeadWorkedVScaller> | null>(null);
	const [maxValue, setMaxValue] = useState<number>(0); 

	const chartRef = useRef<any>();

	useEffect(() => {
		const handleClick = (ev: any) => {
			setSelectedGraph({
				type: ReportGraphConstants.callerPerformace.value,
				value: {callerId: ev?.data?.data.id, status:  ev?.data?.data.status},
				title: ReportGraphConstants.callerPerformace.label,
			});
		};
		chartRef.current.on('element:click',handleClick);

		return () => {
			chartRef.current.off('element:click',handleClick);
		};
	},[]);

	useEffect(() => {
		if(data){
			let max = 0;
			let processedData:Array<LocalLeadWorkedVScaller> = [];
			processedData = data.reduce((result, currentElement)=>{
				result.push({
					id: currentElement.id,
					name: currentElement.name,
					label: currentElement.Worked.label,
					count: parseInt(currentElement.Worked.count as string),
					status: currentElement.Worked.status,
				});
				max = Math.max(result[result.length-1].count,max);
				result.push({
					id: currentElement.id,
					name: currentElement.name,
					label: currentElement.Payment.label,
					count: parseInt(currentElement.Payment.count as string),
					status: currentElement.Payment.status,
				});
				max = Math.max(result[result.length-1].count, max);
				setMaxValue(max);
				return result;
			},processedData);
			setLocalData(processedData);
		}
	},[data]);

	const config = {
		minColumnWidth: 20,
		maxColumnWidth: 30,
		intervalPadding: 30,
		dodgePadding: 30,
		yAxis: {
			tickMethod: (() => {
				if(maxValue < 5){
					return 'cat';
				}else{
					return 'wilkinson-extended';
				}
			})(),
		},
		color: ['#5891ad', '#004561'],
		isGroup: true,
		xField:'name',
		yField:'count', 
		seriesField:'label',
		xAxis:{
			label:{
				style:{fontSize: labelSize}
			}
		}
	};

	return <Column 
		chartRef={chartRef}
		scrollbar={{type:'horizontal'}}
		loading={data===undefined} data={localData?localData:[]} 
		 {...config}
	 />;
};
