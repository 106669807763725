import {resources,attributes} from './authorization';
export const roleStringFromNumber = {
	'0': 'admin',
	'1' : 'user',
	'2' : 'mentor',
	'3' : 'subAdmin',
	'4' : 'contentCreator',
	'5' : 'learner',
	'6' : 'recruiter',
	'7' : 'custom',
};
export const resourcesAttributes = {
	[resources.lead.value]: { 
		resource : resources.lead.value,
		attributes : [ 
			attributes.add.value, attributes.edit.value, attributes.delete.value,
			attributes.list.value, attributes.assign.value, attributes.activity.value,
			attributes.bulkUpload.value, attributes.download.value,
			attributes.statusUpdate.value,attributes.lastCalledFollowUp.value,
		] 
	},
	[resources.group.value]: { 
		resource : resources.group.value, 
		attributes : [ 
			attributes.add.value, attributes.delete.value,
			attributes.list.value, attributes.activity.value,
			attributes.download.value,attributes.edit.value,
		] 
	},
	[resources.role.value]: { 
		resource : resources.role.value, 
		attributes : [ 
			attributes.add.value, attributes.edit.value,
			attributes.delete.value, attributes.list.value,
		] 
	},
	[resources.user.value]: { 
		resource : resources.user.value, 
		attributes : [ 
			attributes.add.value, attributes.edit.value,
			attributes.delete.value, attributes.list.value,
		] 
	},
	[resources.reports.value]: { 
		resource : resources.reports.value, 
		attributes : [ 
			attributes.view.value
		] 
	},
};


//! DONT USE ROLEVALUE ANY OTHER PLACE THIS WILL BE DEPRICATED SOON
export const roleValue = {
	'admin' : { value : 1 , label : 'Admin' },
	'leadManager': { value: 2, label: 'Lead Manager' },
	'caller': { value: 3, label: 'Caller' },
	'dynamic': { value: 4 },
};