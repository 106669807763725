import { Collapse } from 'antd';
import React, { useContext } from 'react';
import styles from './previewModelRuleEngine.module.css';
import { PreviewSingle } from './previewSingle';
import dayjs from 'dayjs';
import { ConditionContext } from '../../screens/rule-engine';

export const Preview = (props: any) => {
	const { conditions } = useContext(ConditionContext);
	const { mainObj } = props;
	const children = mainObj.conditions?.all ?? mainObj.conditions?.any;
	return (
		<div>
			<div>
				<Collapse defaultActiveKey={['if','then']} >
					<Collapse.Panel header="If" key="if">
						<div className={styles.border}>
							<h3 className={styles.titleHeading}>{Object.keys(mainObj.conditions)[0] === 'all'?'ALL':'OR'}</h3>
							{children.map((element: any,index: number)=>{
								return (<div key={index}>
									<PreviewSingle signleObj={element}/>
								</div>);
							})}
						</div>
					</Collapse.Panel>
					<Collapse.Panel header="Then" key="then">
						<div className={styles.childElement}>
							{Object.keys(mainObj.event.params).map((element)=>{
								return (
									<div key={element} className={styles.contentContainer}>
										<div>{conditions[element].label}</div>
										{mainObj.event.params[element].contentType === 4
											?
											<div>{dayjs(mainObj.event.params[element].value).format('YYYY:MM:DD HH:mm:ss')}</div>
										
											:
											<div>{mainObj.event.params[element].valueLabel ?? mainObj.event.params[element].value}</div>
										}
									</div>
								);
							})}
						</div>
					</Collapse.Panel>
				</Collapse>	
			</div>
		</div>
	);
};
