import React, { useEffect, useReducer, useState, useRef, useCallback, useMemo } from 'react';
import styles from './ruleEngineMode.module.css';
import { DatePicker, Input, Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import type { DatePickerProps } from 'antd/es/date-picker';
import { RuleEngineIfModel } from '../ruleEngineIfModel';
import { Preview } from '../previewModelRuleEngine';


import { Tabs } from 'antd';
import { RuleEngineThenModel } from '../ruleEngineThenModel/ruleEngineThenModel';

//Preview Box

export const RuleEngineModel = (props :any) => {

	const { value, id, closeModel, save, previewShow, setPreviewShow } = props;

	//Contains all the info
	const mainObj = useRef<any>(structuredClone(value));
	//Used for force rerender;
	const [render, setRender] = useState<boolean>(false);
	
	//Basic Info States
	const [name, setName] = useState<any>(value?.name ?? '');
	const [date, setDate] = useState<any>( mainObj.current.date ? dayjs(mainObj.current.date) : dayjs() );
	
	const [conditionMainState, setMainState] = useState<any>(mainObj.current.conditions);

	const disabledDate = (current:Dayjs) => {
		return current && current < dayjs().startOf('day');
	  };

	useEffect(()=>{
		mainObj.current.name=name;
	},[name]);

	useEffect(() => {
		if(date) mainObj.current.date = date.toISOString();
	}, [date]);

	useEffect(()=>{
		setMainState(mainObj.current.conditions);
		console.log(mainObj.current);
	},[render]);

	const items = useMemo(() => [
		{
		  key: '1',
		  label: `If`,
		  children: <div className={styles.tabChild}>
				<RuleEngineIfModel values={mainObj.current.conditions} reRender={() => setRender(!render)} />
		  </div>,
		},
		{
		  key: '2',
		  label: `Then`,
		  children: <div className={styles.tabChild}><RuleEngineThenModel thenCondition={mainObj.current.event.params}
				reRender={() => setRender(!render)}
		  /></div>
		},
	  ], [conditionMainState, render]);

	const onChange = (
		value: DatePickerProps['value']
	) => {
		setDate(date);
	};
      
	const onOk = (value: DatePickerProps['value']) => {
    	setDate(value);
	};

	return (
		<>
			<div className={styles.background}></div>
			{previewShow ?
				<div className={styles.mainBody}>
					<div>
						<div className={styles.titleHolder}>
							<h2>Preview</h2>
							<div className={styles.buttonCluster}>
								<Button onClick={()=>setPreviewShow(false)}>Close Preview</Button>
								<Button onClick={()=>save(mainObj.current,id)}>Save</Button>
								<Button onClick={closeModel}>Close</Button>
							</div>
						</div>
						<div className={styles.container}>
							<h3 className={styles.subHeading}>Preview Rule</h3>
							<div className={styles.previewContainer}>
								<Preview mainObj={mainObj.current}/>
							</div>
						</div>
					</div>
				</div>
				:
				<div className={styles.mainBody}>
					<div>
						<div className={styles.titleHolder}>
							<h2>Add Rule</h2>
							<div className={styles.buttonCluster}>
								<Button onClick={()=>setPreviewShow(true, mainObj.current)}>Preview</Button>
								<Button onClick={()=>save(mainObj.current,id)}>Save</Button>
								<Button onClick={closeModel}>Close</Button>
							</div>
						</div>
						<div className={styles.container}>
							<h5 className={styles.subHeading}>A new rule can be added using this dialog, you need to select Rules and actions to be performed based on the Rules</h5>
							<div className={styles.basicRuleDetails}>
								<Input style={{maxWidth:'500px'}} placeholder='Enter Rule Name' value={name} onChange={(ev)=>setName(ev.target.value)}/>
								<DatePicker showTime 
									onChange={onChange}
									allowClear={false}
									onOk={onOk}
									defaultValue={date}
									format="DD-MM-YYYY HH:mm"
								/>
							</div>
							<div>
								<Tabs defaultActiveKey="1" items={items} />
							</div>
						</div>
					</div>
				</div>
			}
		</>
	);
};

