import React, { useEffect, useState } from 'react';
import { useAppStore } from '../../stores';
import UserAdded from '../../assets/img/users_add.svg';
import StatusUpdated from '../../assets/img/status-update.png';
import { useParams } from 'react-router-dom';
import { message } from 'antd';
import { LeadsContentSubHeader } from '../../components/leadsContentSubHeader';
import { Header } from '../../components/header';
import { TimeLineComponent } from '../../components/timeLineComponent';
import { activityType } from '../../config';
import { convertTime } from '../../libs/utils';

const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	logoutUser: state.logoutUser,
	getBulkActivity: state.getBulkActivity,
	getManyLeads: state.getManyLeads,
	setAppLoading: state.setAppLoading,
} );

export const BulkLeadsActivity: React.FunctionComponent = () => {
	document.title = 'Bulk Activity | CodeQuotient';
	const {
		sessionData,
		leadStages,
		leadStatus,
		logoutUser,
		getBulkActivity,
		getManyLeads,
		setAppLoading
	} = useAppStore(appStoreSelector);

	const [activityData, setActivityData] = useState<any[]>([]);
	const [particulatActivityData, setParticulatActivityData] = useState<any>({});
	const [activityNumber, setActivityNumber] = useState<string>('');
	const [createdByDetails, setCreatedByDetails] = useState<any>({});

	const { id } = useParams();

	const getBulkLeadsActivities = async () => {
		try{
			setAppLoading(true);
			const response = await getBulkActivity();

			if( response?.error ){
				throw new Error( response?.error || 'Something Went Wrong' );
			}

			setCreatedByDetails(response?.data?.usersData);

			// [
			// 	{
			// 		activityMonthYear: '',
			// 		dataOfSameMonthYear: [{
			// 			createdAt: '',
			// 			activityType: '',
			// 			totalData: '',
			// 			insertedLeadsId: []
			// 		}]
			// 	 }
			// ];

			const finalActivityData: any[] = [];
			let currentMonth = new Date().getMonth()+1;
			let currentYear = new Date().getFullYear();		

			response?.data?.data.forEach((val: any, index:number) => {
				// console.log(val.created_at);
				const date = new Date(val.created_at);
				const prevMonth = date.getMonth(); 
				const prevYear = date.getFullYear();

				if(
					(prevMonth!==currentMonth && prevYear===currentYear) || 
					((prevMonth===currentMonth || prevMonth!==currentMonth) && prevYear!==currentYear)
				){
					finalActivityData.push({
						activityMonthYear: `${date.toLocaleString('default', {month: 'short'})} ${prevYear}`,
						dataOfSameMonthYear: []
					});
					currentMonth = prevMonth;
					currentYear = prevYear;
				}

				// eslint-disable-next-line max-len
				const createdAt = `${date.getDate()} ${date.toLocaleString('default', {month: 'short'})}, ${date.getHours()>9 ? date.getHours() : '0'+date.getHours()}:${date.getMinutes()>9 ? date.getMinutes() : '0'+date.getMinutes()}`;
				if(val.already_present_leads && val.already_present_leads.length){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(val.duplicate_leads && val.duplicate_leads.length){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.duplicate_leads.length} ${activityType[12].label}`,
						duplicateLeads: val.duplicate_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(val.inserted_leads && val.inserted_leads.length){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.inserted_leads.length} ${activityType[14].label}`,
						insertedLeadsId: val.inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(val.not_inserted_leads && val.not_inserted_leads.length){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.not_inserted_leads.length} ${activityType[15].label}`,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.already_present_leads && val.already_present_leads.length &&
        	        val.duplicate_leads && val.duplicate_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label}, 
        	                            ${val.duplicate_leads.length} ${activityType[12].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						duplicateLeads: val.duplicate_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.already_present_leads && val.already_present_leads.length &&
        	        val.inserted_leads && val.inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label}, 
        	                            ${val.inserted_leads.length} ${activityType[14].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						insertedLeadsId: val.inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.already_present_leads && val.already_present_leads.length &&
        	        val.not_inserted_leads && val.not_inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label}, 
        	                            ${val.not_inserted_leads.length} ${activityType[15].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.duplicate_leads && val.duplicate_leads.length &&
        	        val.inserted_leads && val.inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.duplicate_leads.length} ${activityType[12].label},
										${val.inserted_leads.length} ${activityType[14].label}`,
						duplicateLeads: val.duplicate_leads,
						insertedLeadsId: val.inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.duplicate_leads && val.duplicate_leads.length &&
        	        val.not_inserted_leads && val.not_inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.duplicate_leads.length} ${activityType[12].label},
										${val.not_inserted_leads.length} ${activityType[15].label}`,
						duplicateLeads: val.duplicate_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.inserted_leads && val.inserted_leads.length &&
        	        val.not_inserted_leads && val.not_inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.inserted_leads.length} ${activityType[14].label},
										${val.not_inserted_leads.length} ${activityType[15].label}`,
						insertedLeadsId: val.inserted_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.already_present_leads && val.already_present_leads.length &&
        	        val.duplicate_leads && val.duplicate_leads.length && 
					val.inserted_leads && val.inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label},
										${val.duplicate_leads.length} ${activityType[12].label},
										${val.inserted_leads.length} ${activityType[14].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						duplicateLeads: val.duplicate_leads,
						insertedLeadsId: val.inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.already_present_leads && val.already_present_leads.length &&
        	        val.duplicate_leads && val.duplicate_leads.length && 
					val.not_inserted_leads && val.not_inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label},
										${val.duplicate_leads.length} ${activityType[12].label},
										${val.not_inserted_leads.length} ${activityType[15].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						duplicateLeads: val.duplicate_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.already_present_leads && val.already_present_leads.length &&
        	        val.inserted_leads && val.inserted_leads.length && 
					val.not_inserted_leads && val.not_inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label},
										${val.inserted_leads.length} ${activityType[14].label},
										${val.not_inserted_leads.length} ${activityType[15].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						insertedLeadsId: val.inserted_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					val.duplicate_leads && val.duplicate_leads.length &&
        	        val.inserted_leads && val.inserted_leads.length && 
					val.not_inserted_leads && val.not_inserted_leads.length
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.duplicate_leads.length} ${activityType[12].label},
										${val.inserted_leads.length} ${activityType[14].label},
										${val.not_inserted_leads.length} ${activityType[15].label}`,
						duplicateLeads: val.duplicate_leads,
						insertedLeadsId: val.inserted_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				} 

				if(
					(val.already_present_leads && val.already_present_leads.length) &&
        	        (val.duplicate_leads && val.duplicate_leads.length) && 
					(val.inserted_leads && val.inserted_leads.length) && 
					(val.not_inserted_leads && val.not_inserted_leads.length)
				){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear[index] = {
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.already_present_leads.length} ${activityType[13].label},
										${val.duplicate_leads.length} ${activityType[12].label},
										${val.inserted_leads.length} ${activityType[14].label},
										${val.not_inserted_leads.length} ${activityType[15].label}`,
						alreadyLeadsPresent: val.already_present_leads,
						duplicateLeads: val.duplicate_leads,
						insertedLeadsId: val.inserted_leads,
						notInsertedLeads: val.not_inserted_leads,
						iconType: UserAdded,
						id: val.id
					};
				}

				if(val.activity_type === 8){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[8].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						id: val.id,
						stage: leadStatus[val.status]?.leadActivityStage,
						status: leadStatus[val.status]?.label,
						comment: val.comment
					});
				} 

				if(val.activity_type === 9 && val.assign_to !== null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[11].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						assignToName: val.assign_to,
						comment: val.comment,
						id: val.id
					});
				} 

				if(val.activity_type === 9 && val.assign_to === null){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[9].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						comment: val.comment,
						id: val.id
					});
				} 

				if(val.activity_type === 10){
					finalActivityData[finalActivityData.length-1].dataOfSameMonthYear.push({
						createdAt: createdAt,
						created_by: val.created_by,
						activityType: val.activity_type,
						activityLabel: `${val.updated_lead_ids.length} Leads ${activityType[10].label}`,
						groupId: val.group_id,
						iconType: StatusUpdated,
						LeadId: val.updated_lead_ids,
						followUpDate: `${convertTime(val.follow_up_date).split(' ')[0]},  ${convertTime(val.follow_up_date).split(' ')[1]}`,
						comment: val.comment,
						id: val.id
					});
				}

			});
			setActivityData(finalActivityData);
			setTimeout(() => {
				const element: HTMLElement = document.getElementsByClassName('timelineTitleBox')[0] as HTMLElement;
				if(element){
					element.click();
				}
			}, 100);
		} catch(e: any) {
			setActivityData([]);
			message.error(e.message);
		} finally {
			setAppLoading(false);
		}
	};
	

	const onParticularActivityClick = async (activityNum: string, insertedLeadsId: string[], activityLabel: string, iconType: any, createdAt: string,
		created_by: string, alreadyLeadsPresent: any, duplicateLeads: any, notInsertedLeads: any, groupName: string, stage: string, status: string,
		assignToName: string, followUpDate: string, comment: string) => {
		setActivityNumber(activityNum);
		
		const allLeadsData: any = {
			iconType, 
			createdAt,
			created_by: createdByDetails[created_by].name,
			insertedLeads: [],
			alreadyLeadsPresent,
			duplicateLeads,
			notInsertedLeads,
			activityLabel,
			leadsData: {groupName, stage, status, assignToName: createdByDetails[assignToName]?.name, followUpDate, comment}
		};
				
		if(insertedLeadsId && insertedLeadsId.length){
			try{
				setAppLoading(true);
				const response = await getManyLeads(insertedLeadsId);

				if( response?.error ){
					throw new Error(response?.error);
				}

				response?.data.forEach((data: any) => {
					allLeadsData.insertedLeads.push({
						name: data.name,
						email: data.email,
					});
				});

			} catch(e: any) {
				allLeadsData.insertedLeads.push({});
				message.error( e.message );
			} finally {
				setAppLoading(false);
			}
		}

		// console.log(allLeadsData);
		setParticulatActivityData(allLeadsData);

	};

	useEffect(() => {
		if(leadStages.length) getBulkLeadsActivities();
	}, [leadStages]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<LeadsContentSubHeader 
				// groupId= {id}
				isGroup = {false}
				isActivityPage = {true}
				isLeadActivityPage = {false}
				isBulkAvtivity = {true}
				isLeadsCommentActivity = {false}
				groupDetails={{}}
				leadDetails={{}}
			/>
			<TimeLineComponent 
				activityData={activityData}
				particulatActivityData={particulatActivityData}
				activityNumber={activityNumber}
				onParticularActivityClick={onParticularActivityClick}
				isLeadActivityPage = {false}
				isBulkActivityPage = {true}
				isGroupActivityPage = {false}
				isLeadsCommentActivityPage = {false}
			/>
		</>
	);		
};