import React from 'react';
import styles from './mainTableComponent.module.css';
import {
	Spin,
	Table,
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { TableRowSelection } from 'antd/es/table/interface';
import { current } from 'immer';

interface TableCompnentsProps {
	tableColumns: ColumnsType<grpTableDataType> | any[],
	listData: any[],
	handleTableChange: (pagination: any, filters: any, sorter: any) => void
	isReportPage: boolean
	perPageCount: number
	totalListDataCount: number | null
	loading: boolean
	rowSelection: TableRowSelection<any> | undefined
	currentPage: number | undefined,
	pageSizeOptions?: Array<string>
}

export const TableComponent: React.FunctionComponent<TableCompnentsProps> = (props) => {

	const {
		tableColumns,
		listData,
		handleTableChange,
		isReportPage,
		perPageCount,
		totalListDataCount,
		loading,
		rowSelection,
		currentPage,
		pageSizeOptions,
	} = props;

	return (
		<Table 
			loading={{indicator: <div><Spin /></div>, spinning: loading, tip: 'loading'}}
			columns={tableColumns} 
			dataSource={listData}
			bordered
			scroll={ isReportPage ? {x: 1000} : {x: 1000, y: 100} }
			onChange={
				(pagination, filters, sorter) => handleTableChange(pagination, filters, sorter)
			}
			pagination={{ 
				defaultPageSize: perPageCount,
				showSizeChanger: true, 
				pageSizeOptions: pageSizeOptions ?? ['20', '50', '100'],
				position: ['bottomCenter'],
				total: totalListDataCount ?? listData?.length ?? 0,
				current: currentPage
			}}
			rowKey={(record) => record.id ? record.id : record._id}
			rowSelection={rowSelection}
		/>
	);		
};