import { Popover,ConfigProvider,theme } from 'antd';
import { Link } from 'react-router-dom';
import NineDots from '../../assets/img/9Dots.png';
import { FundProjectionScreenOutlined, GroupOutlined, ReconciliationOutlined
	,AuditOutlined, BarChartOutlined, PhoneOutlined, AppstoreAddOutlined, DashboardOutlined, PicLeftOutlined,
	SettingOutlined
} from '@ant-design/icons';
import styles from './submenu.module.css';
import React,{ useState } from 'react';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';

const { useToken } = theme;

interface SubmenuPropsInterface{
	isAdmin?: boolean,
	isLeadManager?: boolean,
	isCaller?: boolean,
	isCustomRole?: boolean,
}

export const Submenu = function (props: SubmenuPropsInterface) {
	const {isAdmin, isLeadManager} = props;
	const { token } = useToken();
	const [open, setOpen] = useState(false);
	const handleOpenChange = (newOpen: boolean) => {
		setOpen(newOpen);
	};

	const content = (
		<div className={styles.container} role='headerMenuBox'>
			{useCheckAttribute(resources.lead.value,attributes.list.value) && (
				<Link to="/lead/list" role='linkToLeadsList'>
					<div className={styles.col}>
						<div className={styles.content}>
							<FundProjectionScreenOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Leads
							</p>
						</div>
					</div>
				</Link>
			)}
			{useCheckAttribute(resources.group.value,attributes.list.value) && (
				<Link to="/group/list" role='linkToGroupsList'>
					<div className={styles.col}>
						<div className={styles.content}>
							<GroupOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Group
							</p>
						</div>
					</div>
				</Link>
			)}
			{useCheckAttribute(resources.lead.value,attributes.list.value) && (
				<Link to="/lead/bulkActivity" role='linkToBulkActivity'>
					<div className={styles.col}>
						<div className={styles.content}>
							<ReconciliationOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Bulk Activity
							</p>
						</div>
					</div>
				</Link>
			)}
			{useCheckAttribute(resources.user.value,attributes.list.value) && (
				<Link to="/users/list" role='linkToUserList'>
					<div className={styles.col}>
						<div className={styles.content}>
							<AuditOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Users
							</p>
						</div>
					</div>
				</Link>
			)}
			{useCheckAttribute(resources.user.value,attributes.list.value) && (
				<Link to="/dynamicStatus" role='linkToUserList'>
					<div className={styles.col}>
						<div className={styles.content}>
							<AppstoreAddOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Status
							</p>
						</div>
					</div>
				</Link>
			)}
			{/* {useCheckAttribute(resources.reports.value, attributes.view.value) && (
				<Link to='/reports' role='linkToReports'>
					<div className={styles.col}>
						<div className={styles.content}>
							<BarChartOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Reports
							</p>
						</div>
					</div>
				</Link>
			)} */}
			{useCheckAttribute(resources.reports.value, attributes.view.value) && (
				<Link to='/dashboard' role='linkToReports'>
					<div className={styles.col}>
						<div className={styles.content}>
							<DashboardOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Dashboard
							</p>
						</div>
					</div>
				</Link>
			)}
			{useCheckAttribute(resources.user.value, attributes.list.value) && (
				<Link to='/users/callerActivity' role='linkToReports'>
					<div className={styles.col}>
						<div className={styles.content}>
							<PhoneOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Caller Activity
							</p>
						</div>
					</div>
				</Link>
			)}
			{useCheckAttribute(resources.user.value, attributes.list.value) && (
				<Link to='/sources' role='linkToReports'>
					<div className={styles.col}>
						<div className={styles.content}>
							<PicLeftOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Sources
							</p>
						</div>
					</div>
				</Link>
			)}
			{(isAdmin || isLeadManager) &&(
				<Link to='/ruleEngine' role='linkToReports'>
					<div className={styles.col}>
						<div className={styles.content}>
							<SettingOutlined style={{fontSize:'30px',color:`${token.colorPrimary}`}}/>
							<p className={styles.heading}>
								Business Rules
							</p>
						</div>
					</div>
				</Link>
			)}
		</div>
	);

	return (
		<div>		
			<Popover
				placement="bottomRight"
				content={content}
				trigger="click"
				open={open}
				onOpenChange={handleOpenChange}
			>
				<div role='headerMenuBtn'
					className={
						styles.nineDotsContainer + (open ? ` ${styles.setBg}` : '')
					}
				>
					<button
						style={{ background: `url(${NineDots})` }}
						className={styles.nineDots}
					></button>
				</div>
			</Popover>
		</div>
	);
};
