import React, { useState, useRef, useEffect, useMemo } from 'react';
import styles from './reportMode.module.css';
import type { InputRef } from 'antd';
import { Button, FloatButton, Input, Pagination, Space, Table, theme } from 'antd';
import type { ColumnType, ColumnsType } from 'antd/es/table';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import { TableComponent } from '../mainTableComponent';
import { render } from '@testing-library/react';
import dayjs from 'dayjs';
import { useAppStore } from '../../stores';
import { Header } from '../header';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

interface ReportModelProps{
	closeFunction: ()=>void,
	data: any;
	sessionData: any,
	logoutUser: any,
}

const { useToken } = theme;

export const ReportModel = (props: ReportModelProps) => {

	const [maxCount, setMaxCount] = useState<number>(0);
	const [seachText, setSearchText] = useState('');
	const searchInput = useRef<InputRef>(null);
	const [searchedColumn, setSearchedColumn] = useState('');
	const { closeFunction, data, logoutUser, sessionData} = props;
	const { token } = useToken();


	//Using Table Model
	const [paginationData, setPaginationData] = useState({
		current: 1,
		pageSize: 20,
		total: 0,
		data: []
	});

	useEffect(() => {
		let processedData;
		try{
			if(data){
				processedData = data.data;
				processedData.forEach((element: any) => {
					if (element.currently_assign_to){
						element.assign_to_email = data.callerData[element.currently_assign_to]?.email;
						element.assign_to = data.callerData[element.currently_assign_to]?.name;
					}
				});
			}
		} catch (error: any){
			processedData = [];
		}
	
		setPaginationData({
			current: 1,
			pageSize: 20,
			total: processedData?.length ?? 0,
			data: processedData
		});
	}, [data]);


	const handleSearch = (
		selectedKeys: string[],
		confirm: (param?: FilterConfirmProps) => void,
		dataIndex: any,
	  ) => {
		console.log(selectedKeys, confirm, dataIndex);
		confirm();
		setSearchText(selectedKeys[0]);
		setSearchedColumn(dataIndex);
	  };

	  const handleReset = (clearFilters: () => void) => {
		setSearchText('');
		setSearchedColumn('');
		clearFilters();
	  };

	  const getColumnSearchProps = (dataIndex: any): ColumnType<any> => ({
		filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
			<div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
				<Input
					ref={searchInput}
					placeholder={`Search ${dataIndex}`}
					value={selectedKeys[0]}
					onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
					onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
					style={{ marginBottom: 8, display: 'block' }}
				/>
				<Space>
					<Button
						type="primary"
						onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
						icon={<SearchOutlined />}
						size="small"
						style={{ width: 90 }}
			  >
						Search
					</Button>
					<Button
						onClick={()=>{
							clearFilters && handleReset(clearFilters);
							handleSearch([] as string[], confirm, dataIndex);
						}}
						size="small"
						style={{ width: 90 }}
			  >
						Clear
					</Button>
					<Button
						type="link"
						size="small"
						onClick={() => {
				  close();
						}}
			  >
						close
					</Button>
				</Space>
			</div>
		),
		filterIcon: (filtered: boolean) => (
			<SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />
		),
		onFilter: (value, record) =>{
			const dataVisible = (record[dataIndex] ?? '')
				  .toString()
				  .toLowerCase()
				  .includes((value as string).toLowerCase());

			setMaxCount(Math.max(dataVisible, maxCount));
			return dataVisible;
		},
		onFilterDropdownOpenChange: (visible) => {
		  if (visible) {
				setTimeout(() => searchInput.current?.select(), 100);
		  }
		},
		render: (text) =>
			text
	  });

	  const ColumnData = useMemo( () => [
		{
			title: 'SNo',
			key: 'SNo',
			width: 80,
			render: (_: any, record: any, index: number) => {
				return <p>{ ((paginationData.current - 1)*paginationData.pageSize)+index + 1}</p>;
			}
		},
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			// width: 150,
			...getColumnSearchProps('name'),
		},
		{
			title: 'Email',
			dataIndex: 'email',
			key: 'email',
			// width: 200,
			...getColumnSearchProps('email'),
		},
		{
			title: 'Created At',
			dataIndex: 'created_at',
			key: 'created_at',
			width: 230,
			render: (_: any) => {
				return <p>{dayjs(_).format('YYYY-MM-DD HH:mm')}</p>;
			}
		},
		{
			title: 'Status',
			dataIndex: 'statusLabel',
			key: 'statusLabel',
			width: 180,
			...getColumnSearchProps('statusLabel'),
		},
		{
			title: 'Current Stage',
			dataIndex: 'stageLabel',
			key: 'stageLabel',
			width: 170,
			...getColumnSearchProps('stageLabel'),
		},
		{
			title: 'Assignee Name',
			dataIndex: 'assign_to',
			key:'assign_to',

			...getColumnSearchProps('assign_to'),
		},
		{
			title: 'Assignee Email',
			dataIndex: 'assign_to_email',
			key: 'assign_to_email',
			...getColumnSearchProps('assign_to_email'),
		}
	],[paginationData]);

	const handleTableChange = (Pagination: any, filters: any, sorter: any) => {
		console.log(Pagination, filters, sorter);
		setPaginationData({
			pageSize: Pagination.pageSize,
			current: Pagination.current,
			total: paginationData.total,
			data: structuredClone(paginationData.data),
		});
	};

	return (
		<>
			<div className={styles.background}></div>
			<FloatButton type='primary' icon={<CloseOutlined />} onClick={closeFunction}/>
			<div className={styles.mainContainer}>
				<div className={styles.tableContainer}>
					<TableComponent
						loading={data === null} listData={paginationData.data} tableColumns={ColumnData} 
						handleTableChange={handleTableChange} 
						isReportPage={false} perPageCount={paginationData.pageSize} totalListDataCount={paginationData.total} rowSelection={undefined} currentPage={paginationData.current}/>
				</div>
			</div>
		</>
	);
};