import React from 'react';
import { useAppStore } from '../../stores';
import { useParams } from 'react-router-dom';
import { Header } from '../../components/header';
import { CallerTimeLine } from '../../components/callerTimeLineModel';

const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	logoutUser: state.logoutUser,
	getLeadActivity: state.getLeadActivity,
	getManyLeads: state.getManyLeads,
	setAppLoading: state.setAppLoading,
	getUserActivity: state.getUserActivity,
	loading: state.loading,
} );

export const UserActivity: React.FunctionComponent = () => {

	const {
		sessionData,
		logoutUser,
	} = useAppStore(appStoreSelector);

	const { userId } = useParams();

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<CallerTimeLine userId={userId}/>
		</>
	);		
};