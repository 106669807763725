import React, { useState } from 'react';
import styles from './fixedFilterComponent.module.css';
import {Cascader, DatePicker, FloatButton, Form, Modal, Popover, Select, message } from 'antd';
import { CloseOutlined, ThunderboltOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';

interface FixedFilterComponentProps {
	leadsTableSelectedRowKeys: React.Key[]
	handleUpdateLeadStatus: (data: UpdateLeadStatus) => void
	handleUpdateAssignTo: (data: UpdateAssignTo) => void
	handleUnAssignLeads: (data: UnassignLeads) => void
	handleUpdateFollowUpLastCalled: (data: UpdateFollowUpLastCalled) => void,
	createActivityBeforeDownload: (uploadFile: FormData) => Promise<void>,
	assignToList: any[] | null ,
	tableData: any[],
	finalCols: any[],
	xlsxFileName: string | undefined,
	leadStages: leadStages[],
	leadStatus: any,
}

export const FixedFilterComponent: React.FunctionComponent<FixedFilterComponentProps> = ( props ) => {
	const {
		leadsTableSelectedRowKeys,
		handleUpdateLeadStatus,
		handleUpdateAssignTo,
		handleUnAssignLeads,
		handleUpdateFollowUpLastCalled,
		createActivityBeforeDownload,
		assignToList,
		tableData,
		finalCols,
		xlsxFileName,
		leadStages,
		leadStatus
	} = props;

	const statusOptions: statusOptions[] = leadStages?.map((StageDetails: any) => ({
		value: StageDetails?.value,
		label: StageDetails?.label,
		children: StageDetails?.childs.reduce((result: any, val: number) => {
			if(val && leadStatus[val]){
				result.push(
					{
						label:leadStatus[val]?.label,
						value: val,
					}
				);
			}
			return result;
		},[]),
	}));

	const [fixedFilterOpen, setFixedFilterOpen] = useState<boolean>(false);
	const [statusCommentModalOpen, setStatusCommentModalOpen] = useState<boolean>(false);
	const [assignToOpen, setAssignToOpen] = useState<boolean>(false);
	const [unAssignOpen, setUnAssignToOpen] = useState<boolean>(false);
	const [followUpOpen, setFollowUpOpen] = useState<boolean>(false);
	// const [updateStatusList, setUpdateStatusList] = useState<any>({});

	const [updateStatusForm] = Form.useForm();
	const [updateAssignToForm] = Form.useForm();
	const [updateUnAssignToForm] = Form.useForm();
	const [updateFollowUpForm] = Form.useForm();

	const { id } = useParams();

	const handleFixedFilterOpen = (open: boolean) => {
		setFixedFilterOpen(open);
	};

	const appendValuesInFormData = (values: any) => {
		values.leadIds = leadsTableSelectedRowKeys;
		values.groupId = id;
	};

	const handleStatusAndCommentUpdate = (values: any) => {
		// if(!values.stage){
		// 	message.error('Please select stage');
		// 	return;
		// }

		if(!values.status){
			message.error('Please select status');
			return;
		}

		values.status = values.status[1];

		appendValuesInFormData(values);
		handleUpdateLeadStatus(values);
		setStatusCommentModalOpen(false);
		setFixedFilterOpen(true);
		updateStatusForm.resetFields();
	};

	const handleStatusAndCommentCancel = () => {
		setStatusCommentModalOpen(false);
		setFixedFilterOpen(true);
	};

	const handleAssignToUpdate = (values: any) => {
		if(!values.assignTo){
			message.error('Please select assign to');
			return;
		}

		appendValuesInFormData(values);
		handleUpdateAssignTo(values);
		setAssignToOpen(false);
		setFixedFilterOpen(true);
		updateAssignToForm.resetFields();
	};

	const handleAssignToCancel = () => {
		setAssignToOpen(false);
		setFixedFilterOpen(true);
	};

	const handleUnAssignUpdate = (values: any) => {
		appendValuesInFormData(values);
		handleUnAssignLeads(values);
		setUnAssignToOpen(false);
		setFixedFilterOpen(true);
		updateUnAssignToForm.resetFields();
	};

	const handleUnAssignCancel = () => {
		setUnAssignToOpen(false);
		setFixedFilterOpen(true);
	};

	const handleFollowUpUpdate = (values: any) => {
		if(!values.followUpDate && !values.lastCalledDate){
			message.error('Please select date');
			return;
		}

		const data: UpdateFollowUpLastCalled = {
			comment: values.comment,
			followUpDate: values.followUpDate?.$d ? new Date(values.followUpDate.$d).toISOString() : '',
			lastCalledDate: values.lastCalledDate?.$d ? new Date(values.lastCalledDate.$d).toISOString() : '',
			leadIds: leadsTableSelectedRowKeys,
			groupId: id
		};

		handleUpdateFollowUpLastCalled(data);
		setFollowUpOpen(false);
		setFixedFilterOpen(true);
		updateFollowUpForm.resetFields();
	};

	const handleFollowUpCancel = () => {
		setFollowUpOpen(false);
		setFixedFilterOpen(true);
	};

	// const handleUpdateStatusList = (value: number) => {
	// 	if(value === 5) setUpdateStatusList(leadActivityHotStatus);
	// 	else if(value === 15) setUpdateStatusList(leadActivityColdStatus);
	// 	else if(value === 18) setUpdateStatusList(leadActivityWarmStatus);
	// 	else if(value === 20) setUpdateStatusList(leadActivityJunkStatus);
	// 	else if(value === 30) setUpdateStatusList(leadActivityFreshStatus);
	// };

	const downloadXLSX = async (e: any, tableData: any[]) => {
		let downloadData = tableData;

		if(leadsTableSelectedRowKeys?.length) {
			downloadData = downloadData.filter((lead) => leadsTableSelectedRowKeys.includes(lead.id));
		}

		const data: any[]= downloadData.map((data: any) => (
			{
				'Name': data.name,
				'Email': data.email,
				'Phone Number': data.number,
				'Source': data.source,
				// 'Stage': data.current_stage,
				'Status': data.current_status,
				'Remark': data.comment,
				'Assigned To': data.currently_assign_to,
				'Lead Assigned Date': data.current_lead_assign_to_date,
				'Follow up Date': data.current_follow_up_date,
				'Last Called': data.current_last_called,
				'Alternate Number': data.alternate_number,
				'Matric Year': data.matric_year,
				'Matric Score': data.matric_score,
				'Senior Secondary Year': data.senior_secondary_Year,
				'Senior Secondary Year Score': data.senior_secondary_year_score,
				'UG Year': data.ug_year,
				'UG Score': data.ug_score,
				'PG Or Other': data.pg_or_other,
				'PG or Other Score': data.pg_or_other_score,
				'Test Taken': data.test_taken,
				'Test Score': data.test_score,
				'Test Plan Month': data.test_plan_month,
				'Program Type': data.program_type,
				'State': data.state,
				'City': data.city,
				'Prefered State': data.prefered_state,
				'Prefered City': data.prefered_city,
				'Location': data.location,
				'Intake Year': data.intake_year,
				'Financial Status': data.financial_status,
				'Referral Code': data.referral_code
			}
		));
		e.preventDefault();
		const ws = XLSX.utils.json_to_sheet(data);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, 'SheetJS');

		// GENERATING BLOB OF FILE AND SEND TO BACKEND FOR STORAGE
		const wbout = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
		const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
		const formData = new FormData();
		formData.append('uploadFile', blob, `${xlsxFileName}-Leads.xlsx`);

		const response:any = await createActivityBeforeDownload(formData);

		if(response?.msg){
			/* generate XLSX file and send to client */
			XLSX.writeFile(wb, `${xlsxFileName}-Leads.xlsx`);
		} else {
			console.log(response);
			message.error('Something went wrong');
		}
	};


	return (
		<div role='fixedFilterComponent'>
			<Popover
				placement='leftBottom'
				trigger="click"
				open={fixedFilterOpen}
				onOpenChange={handleFixedFilterOpen}
				className={styles.fixedFilterDropdown}
				content={
					<div role='fixedFilterComponentInner'>
						{useCheckAttribute(resources.lead.value, attributes.statusUpdate.value) && (
							<div 
								className={styles.fixedFilterDropdownItem} 
								onClick={() => {
									if(leadsTableSelectedRowKeys.length){
										setStatusCommentModalOpen(true);
										setAssignToOpen(false);
										setUnAssignToOpen(false);
										setFollowUpOpen(false);
									} else {
										message.error('Please select alteast 1 lead');
									}
								}}
							>
								Update status & comment
							</div>
						)}
						{useCheckAttribute(resources.lead.value, attributes.assign.value) && (
							<div 
								className={styles.fixedFilterDropdownItem} 
								onClick={() => {
									if(leadsTableSelectedRowKeys.length){
										setStatusCommentModalOpen(false);
										setAssignToOpen(true);
										setUnAssignToOpen(false);
										setFollowUpOpen(false);
									} else {
										message.error('Please select alteast 1 lead');
									}
								}}
							>
								Update Assign to
							</div>
						)}
						{useCheckAttribute(resources.lead.value, attributes.assign.value) && (
							<div 
								className={styles.fixedFilterDropdownItem} 
								onClick={() => {
									if(leadsTableSelectedRowKeys.length){
										setStatusCommentModalOpen(false);
										setAssignToOpen(false);
										setUnAssignToOpen(true);
										setFollowUpOpen(false);
									} else {
										message.error('Please select alteast 1 lead');
									}
								}}
							>
								UnAssign
							</div>
						)}
						{useCheckAttribute(resources.lead.value, attributes.lastCalledFollowUp.value) && (
							<div 
								className={styles.fixedFilterDropdownItem} 
								onClick={() => {
									if(leadsTableSelectedRowKeys.length){
										setStatusCommentModalOpen(false);
										setAssignToOpen(false);
										setUnAssignToOpen(false);
										setFollowUpOpen(true);
									} else {
										message.error('Please select alteast 1 lead');
									}
								}}
							>
								Update followup & last called date
							</div>
						)}
						{useCheckAttribute(resources.lead.value, attributes.download.value) && (
							<div 
								className={styles.fixedFilterDropdownItem} 
								onClick={(e) => downloadXLSX(e, tableData)}
							>
								Download as XLSX
							</div>
						)}
					</div>
				}
    		>			
				<FloatButton type='primary' className='floatButton' icon={fixedFilterOpen ? <CloseOutlined style={{ pointerEvents: 'none' }} /> :
					<ThunderboltOutlined style={{pointerEvents: 'none' }} />}/>
			</Popover>
			<Modal 
				title={(()=>{
					return `Total users selected: ${leadsTableSelectedRowKeys.length}`; 
				})()}
				open={statusCommentModalOpen} 
				onOk={() => updateStatusForm.submit()}  
				onCancel={handleStatusAndCommentCancel}
				className='groupReportStatutsModals'
				okText='Update'
				width={400}
			>
				<div className='modalForm' style={{fontSize: '16px'}}>
					<Form
						form={updateStatusForm}
						onFinish={handleStatusAndCommentUpdate}
						layout="vertical"
						style={{marginTop: '1rem', marginBottom: '2rem'}}
					>
						{/* <Form.Item 
							label='Update Stage*' 
							name='stage'
							style={{width: '180px'}} 
						>
							<Select  
								onChange={handleUpdateStatusList}
								options={Object.values(leadActivityStages).map((options: any) => options)}
								placeholder='Select Assign To'
							/>
						</Form.Item> */}
						{/* <Form.Item 
							label='Update Status*' 
							name='status'
							style={{width: '180px'}} 
						>
							<Select  
								// defaultValue={Object.keys(LeadSeekers)[0]}
								// onChange={handleDefaultFields}
								options={Object.values(leadActivityStatus).map((option: any) => (option) )}
								placeholder='Select Assign To'
							/>
						</Form.Item> */}
						<Form.Item 
							label='Update Status*' 
							name='status'
							style={{width: '180px'}} 
						>
							<Cascader
								// defaultValue={Object.keys(LeadSeekers)[0]}
								// onChange={handleDefaultFields}
								options={statusOptions}
								placeholder='Select Status'
							/>
						</Form.Item>
						<Form.Item 
							label='Add a remark' 
							name='comment' 
						>
							<TextArea
								showCount
								maxLength={200}
								style={{ height: 80, resize: 'none' }}
								placeholder="Type your remark here"
    						/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
			<Modal 
				title={(()=>{
					return `Total users selected: ${leadsTableSelectedRowKeys.length}`; 
				})()}
				open={assignToOpen} 
				onOk={() => updateAssignToForm.submit()} 
				onCancel={handleAssignToCancel}
				className='groupReportStatutsModals'
				okText='Update'
				width={400}
			>
				<div className='modalForm'>
					<Form
						form={updateAssignToForm}
						onFinish={handleAssignToUpdate}
						layout="vertical"
						style={{marginTop: '1rem', marginBottom: '2rem'}}
					>
						{assignToList &&
							<Form.Item 
								label='Update Assign To*' 
								name='assignTo' 
								style={{width: '180px'}}
							>
								<Select  
									options={assignToList.map((option: any) => ({value: option._id, label: option.name}))}
									placeholder='Select Assign To'
									allowClear
									showSearch
									filterOption={(inputValue, option) =>
										(option?.label ?? '').toLowerCase().includes(inputValue.toLowerCase().trim())
									}
								/>
							</Form.Item>
						}
						<Form.Item 
							label='Add a remark' 
							name='comment' 
						>
							<TextArea
								showCount
								maxLength={200}
								style={{ height: 80, resize: 'none' }}
								placeholder="Type your remark here"
    						/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
			<Modal 
				title={(()=>{
					return `Total users selected: ${leadsTableSelectedRowKeys.length}`; 
				})()}
				open={unAssignOpen} 
				onOk={() => updateUnAssignToForm.submit()} 
				onCancel={handleUnAssignCancel}
				className='groupReportStatutsModals'
				okText='Update'
				width={400}
			>
				<div className='modalForm'>
					<Form
						form={updateUnAssignToForm}
						onFinish={handleUnAssignUpdate}
						layout="vertical"
						style={{marginTop: '1rem', marginBottom: '2rem'}}
					>
						<Form.Item 
							label='Add a remark' 
							name='comment' 
						>
							<TextArea
								showCount
								maxLength={200}
								style={{ height: 80, resize: 'none' }}
								placeholder="Type your remark here"
    						/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
			<Modal 
				title={(()=>{
					return `Total users selected: ${leadsTableSelectedRowKeys.length}`; 
				})()}
				open={followUpOpen} 
				onOk={() => updateFollowUpForm.submit()} 
				onCancel={handleFollowUpCancel}
				className='groupReportStatutsModals'
				okText='Update'
				width={400}
			>
				<div className='modalForm'>
					<Form
						form={updateFollowUpForm}
						onFinish={handleFollowUpUpdate}
						layout="vertical"
						style={{marginTop: '1rem', marginBottom: '2rem'}}
					>
						<Form.Item 
							label='Update Follow up date' 
							name='followUpDate' 
						>
							<DatePicker
								format="DD-MM-YYYY HH:mm"
								showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
    						/>
						</Form.Item>
						<Form.Item 
							label='Update Last Called date' 
							name='lastCalledDate' 
						>
							<DatePicker
								format="DD-MM-YYYY HH:mm"
								showTime={{ defaultValue: dayjs('00:00', 'HH:mm') }}
    						/>
						</Form.Item>
						<Form.Item 
							label='Add a remark' 
							name='comment' 
						>
							<TextArea
								showCount
								maxLength={200}
								style={{ height: 80, resize: 'none' }}
								placeholder="Type your remark here"
    						/>
						</Form.Item>
					</Form>
				</div>
			</Modal>
		</div>
	);
};