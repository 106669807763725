/* eslint-disable @typescript-eslint/no-empty-function */
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { ReportState } from '../../@types/store/report';
import { CQLeadsClient } from '../../clients';
import { FullScreenDataType, GlobalDateType, LeadTrendSelectionType, LeadVsCallerCountInterface, SelectedCallerType, selectedGraphType } from '../../@types/report';
import dayjs, { Dayjs } from 'dayjs';
import { CardConstant, ExportCSVName, ReportGraphConstants, reverseReportGraphConstant } from '../../config/report';
import { CardModel } from '../../components/cardModel';
import { message } from 'antd';
import { exportToCSV } from '../../libs/utils';

const initalState: ReportState = {
	selectedCaller: null,
	callerList: {},
	fullScreen: null,
	fullScreenData: null,
	selectedGraph: null,
	selectedGraphData: null,

	globalTime: [null, null],
	leadVsStatusTime: [null, null],
	leadVsSourceTime: [null, null],
	leadVsCallerTime: [null, null],
	callerPerformanceTime: [null, null],
	leadTrendTime: [dayjs().subtract(1,'month'), dayjs()],
	engagementTrendTime: [dayjs().subtract(1,'month'), dayjs()],

	leadVsStatus: undefined,
	leadVsSources: undefined,
	leadVsCaller: undefined,
	callerPerformace: undefined,
	leadTrend: undefined,
	engagementTrend: undefined,


	leadTrendDateType: 'day',

	cards: structuredClone(CardConstant),
	cardData: new Map(),

	setGlobalTime: () => {},

	setLeadVsStatusTime: () => {},
	setLeadVsSourceTime: () => {},
	setLeadVsCallerTime: () => {},
	setCallerPerformaceTime: () => {},
	setLeadTrendTime: () => {},
	setEngagementTrendTime: () => {},
	
	setSelectedCaller: () => {},
	setLeadTrendSelectionType: () => {},
	getCallerList: async () => {},
	setFullScreen: () => {},
	setFullScreenData: () => {},
	setSelectedGraph: () => {},

	download: () => {},

	getCardData: async () => {},
	getLeadStatus: async () => {},
	getLeadSource: async() => {},
	getLeadCountPerCaller: async() => {},
	getCallerPerformace: async() => {},
	getLeadTrend: async() => {},
	getEngagementTrend: async () => {},

	getDetailedCardData: async () => {},
	getDetailedLeadStatus: async () => {},
	getDetailedLeadSource: async () => {},
	getDetailedCountPerCaller: async () => {},
	getDetailedCallerPerformance: async () => {},
	getDetailedLeadTrend: async() => {},
	getDetailedEngagementTrend: async () => {},

	refreshAll:() =>{},
};

export function CreateReportStore(CQLeadsClient: CQLeadsClient) {
	return create<ReportState>()(
		devtools(( set, get ) => {

			return {
				...initalState,

				setGlobalTime: (data: GlobalDateType) => {
					set({
						globalTime: data,
						cardData: new Map(),
					});
					get().setLeadVsStatusTime(data);
					get().setLeadVsSourceTime(data);
					get().setLeadVsCallerTime(data);
					get().setCallerPerformaceTime(data);
					get().setLeadTrendTime(data);
					get().setEngagementTrendTime(data);
				},

				setSelectedCaller:  (data: SelectedCallerType) => {
					set({
						selectedCaller: data,
						cardData: new Map(),
					});

					get().getLeadStatus();
					get().getLeadSource();
					get().getLeadCountPerCaller();
					get().getCallerPerformace();
					get().getLeadTrend();
					get().getEngagementTrend();
				},

				setFullScreen: (data: number | null) => {
					set({
						fullScreen: data
					});
					get().setFullScreenData();
				},

				setFullScreenData: () => {
					const fullScreenValue = get().fullScreen;

					if(!fullScreenValue){
						return ;
					}

					const data: any = {
						title: reverseReportGraphConstant[fullScreenValue]?.label,
						data: null,
					};

					if(!data.title) {
						message.error('How are you able to set invalid fullscreen value.');
						set({
							fullScreenData: null,
							fullScreen: null,
						});
						return ;
					}

					switch(fullScreenValue) {
						case ReportGraphConstants.leadStatus.value : {
							data.data = {
								data: get().leadVsStatus,
								time: get().leadVsStatusTime,
								setTime: get().setLeadVsStatusTime,
								refreshFunction: get().getLeadStatus,
							};
							break;
						}
						case ReportGraphConstants.leadSource.value: {
							data.data = {
								data: get().leadVsSources,
								time: get().leadVsSourceTime,
								setTime: get().setLeadVsSourceTime,
								refreshFunction: get().getLeadSource,
							};
							break;
						}
						case ReportGraphConstants.leadVsCaller.value: {
							data.data = {
								data: get().leadVsCaller,
								time: get().leadVsCallerTime,
								setTime: get().setLeadVsCallerTime,
								refreshFunction: get().getLeadCountPerCaller,
							};
							break;
						}
						case ReportGraphConstants.callerPerformace.value: {
							data.data = {
								data: get().callerPerformace,
								time: get().callerPerformanceTime,
								setTime: get().setCallerPerformaceTime,
								refreshFunction: get().getCallerPerformace,
							};
							break;
						}
						case ReportGraphConstants.leadTrend.value: {
							data.data = {
								data: get().leadTrend,
								time: get().leadTrendTime,
								setTime: get().setLeadTrendTime,
								refreshFunction: get().getLeadTrend,
							};
							break;
						}
						case ReportGraphConstants.engagementTrend.value: {
							data.data = {
								data: get().engagementTrend,
								time: get().engagementTrendTime,
								setTime: get().setEngagementTrendTime,
								refreshFunction: get().getEngagementTrend,
							};
							break;
						}
					}
					if(!data.data){
						message.error('Some Error Occured');
					}
					set({
						fullScreenData: data
					});

				},

				setSelectedGraph: (data: selectedGraphType) => {
					if(!data){
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						return ;
					}

					set({
						selectedGraph: data
					});

					switch(data.type) {
						case ReportGraphConstants.cardModel.value: {
							get().getDetailedCardData(data.value);
							break;
						}
						case ReportGraphConstants.leadStatus.value: {
							get().getDetailedLeadStatus();
							break;
						}
						case ReportGraphConstants.leadSource.value: {
							get().getDetailedLeadSource();
							break;
						}
						case ReportGraphConstants.leadVsCaller.value: {
							get().getDetailedCountPerCaller();
							break;
						}
						case ReportGraphConstants.callerPerformace.value: {
							get().getDetailedCallerPerformance();
							break;
						}
						case ReportGraphConstants.leadTrend.value: {
							get().getDetailedLeadTrend();
							break;
						}
						case ReportGraphConstants.engagementTrend.value: {
							get().getDetailedEngagementTrend();
							break;
						}
						default: {
							message.error('Invalid Model Type');
							set({
								selectedGraph: null,
								selectedGraphData: null,
							});
							return ;
						}
					}
				},

				setLeadVsStatusTime: (data: GlobalDateType) => {
					set({
						leadVsStatusTime: data,
					});
					get().getLeadStatus();
				},

				setLeadVsSourceTime: (data: GlobalDateType) => {
					set({
						leadVsSourceTime: data,
					});
					get().getLeadSource();
				},

				setLeadVsCallerTime: (data: GlobalDateType) => {
					set({
						leadVsCallerTime: data,
					});
					get().getLeadCountPerCaller();
					
				},

				setCallerPerformaceTime: (data: GlobalDateType) => {
					set({
						callerPerformanceTime: data,
					});
					get().getCallerPerformace();
				},

				setLeadTrendTime: (data: GlobalDateType) => {
					let newDate: [Dayjs, Dayjs];
					if(!data[0]) {
						const dataTypeOfLead = get().leadTrendDateType;
						switch(dataTypeOfLead) {
							case 'day': 
							case 'week':{
								newDate = [dayjs().subtract(1,'month'), dayjs()];
								break;
							}
							case 'year': {
								newDate = [dayjs().subtract(1,'year'), dayjs()];
							}
						}
					} else {
						newDate = data;
					}
					set({
						leadTrendTime: newDate,
						leadTrend: undefined,
					});
					get().getLeadTrend();
				},

				setEngagementTrendTime: (data: GlobalDateType) => {
					let newData: [Dayjs, Dayjs];
					if(!data[0]) {
						newData = [dayjs().subtract(1,'month'), dayjs()];
					} else {
						newData = data;
					}
					set({
						engagementTrendTime: newData,
						engagementTrend: undefined,
					});
					get().getEngagementTrend();
				},

				setLeadTrendSelectionType: (data: LeadTrendSelectionType) => {
					set({
						leadTrendDateType: data,
					});
					get().getLeadTrend();
				},


				download: (type: number) => {
					try{
						switch(type) {
							case ReportGraphConstants.leadStatus.value:{
								exportToCSV(get().leadVsStatus,ExportCSVName.callerStatusGraph.label, ExportCSVName.callerStatusGraph.columns);
								break;
							}
							case ReportGraphConstants.leadSource.value: {
								exportToCSV(get().leadVsSources,ExportCSVName.leadSource.label, ExportCSVName.leadSource.columns);
								break;
							}
							case ReportGraphConstants.leadVsCaller.value: {
								exportToCSV(get().leadVsCaller,ExportCSVName.leadVsCaller.label, ExportCSVName.leadVsCaller.columns);
								break;
							}
							case ReportGraphConstants.callerPerformace.value: {
								exportToCSV(get().callerPerformace?.map((element: any)=>{
									return {
										worked: element.Worked.count,
										payment: element.Payment.count,
										name: element.name,
										email: element.email,
									};
								}),ExportCSVName.paymentRecieveVsWorkedOnLead.label, ExportCSVName.paymentRecieveVsWorkedOnLead.columns);
								break;
							}
							case ReportGraphConstants.leadTrend.value: {
								exportToCSV(get().leadTrend,ExportCSVName.dailyLeadTrend.label);
								break;
							}
							case ReportGraphConstants.engagementTrend.value: {
								exportToCSV(get().engagementTrend,ExportCSVName.engagementChart.label);
								break;
							}
							default: {
								throw new Error('Invalid Download');
							}
						}
					} catch (error: any) {
						message.error(error?.message ?? error);
					}
				},
				
				getCallerList: async () => {
					try{
						const data = await CQLeadsClient.getCallerData();
						if((Object.keys(data).length) === 0){
							throw new Error('Caller not assigned.');
						}
						set({
							callerList: data
						});
					} catch (error: any){
						message.error(error?.message ?? error);
						set({
							callerList: {}
						});
					}
				},

				getCardData: async (value: number) => {
					try{
						const time = get().globalTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getCardData(time[0]?.toISOString(), time[1]?.toISOString(), value, selectedCaller?.id);
						const cardData = get().cardData;
						cardData.set(value, data);
						set({
							cardData: new Map(cardData),
						});
					} catch (error: any) {
						message?.error(error?.message ?? error);
						const cardData = get().cardData;
						cardData.delete(value);
						set({
							cardData: new Map(cardData),
						});
					}
				},

				getLeadStatus: async () => {
					try{
						set({
							leadVsStatus: undefined
						});
						get().setFullScreenData();
						const time = get().leadVsStatusTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getLeadVsStatusCount( time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id );
						set({
							leadVsStatus: data,
						});
					} catch (error: any) {
						message.error(error?.message ?? error);
						set({
							leadVsStatus: []
						});
					} finally {
						get().setFullScreenData();
					}
				},

				getLeadSource: async () => {
					try{
						set({
							leadVsSources: undefined,
						});
						get().setFullScreenData();
						const time = get().leadVsSourceTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getLeadVsSourceCount(time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id);
						set({
							leadVsSources: data,
						});
					} catch (error: any) {
						message.error(error?.message ?? error);
						set({
							leadVsSources: [],
						});
					} finally {
						get().setFullScreenData();
					}
				},

				getLeadCountPerCaller: async () => {
					try{
						set({
							leadVsCaller: undefined
						});
						get().setFullScreenData();
						const time = get().leadVsCallerTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getLeadCountByCounselor(time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id);
						const processedData:Array<LeadVsCallerCountInterface> = [];
						if(!data){
							throw new Error('LeadCountPerCaller data is null Hmmm...');
						}
						data.data.forEach((element: any) => {
							const currentObj = {
								callerName: data.callerDetails[element.caller].name,
								callerEmail: data.callerDetails[element.caller].email,
								leadCount: parseInt(element.leads),
								callerId: element.caller,
							};
							processedData.push(currentObj);
						});
						set({
							leadVsCaller: processedData,
						});
					} catch (error: any) {
						message.error( error?.message ?? error );
						set({
							leadVsCaller: []
						});
					} finally {
						get().setFullScreenData();
					}
				},

				getCallerPerformace: async () => {
					try{
						set({
							callerPerformace: undefined,
						});
						get().setFullScreenData();
						const time = get().callerPerformanceTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getPaymentRecivedVsWorkedByCallerCount(time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id);
						set({
							callerPerformace: data
						});
					} catch (error: any) {
						set({
							callerPerformace: []
						});
					} finally {
						get().setFullScreenData();
					}
				},

				getLeadTrend: async () => {
					try{
						set({
							leadTrend: undefined
						});
						get().setFullScreenData();
						const time = get().leadTrendTime;
						const selectedCaller = get().selectedCaller;
						const dataType = get().leadTrendDateType;
						const data = await CQLeadsClient.getUserCreatedDaily(time[0]?.toISOString(), time[1]?.toISOString(), dataType,selectedCaller?.id);
						if(data.current) {
							let startDate = dayjs((time[0] ?? dayjs(new Date()).subtract(1,'month') ));
							const endDate = dayjs((time[1] ?? dayjs(new Date())));
							const map = new Map<string, any> ();
							data.previous.forEach((element: any)=>{
								map.set(element.lead_id,element);
							});
							let currentCount = 0;

							const result = [];
							
							const dateType = dataType;
						
							while(endDate.isAfter(startDate) || endDate.isSame(startDate)){
								while(currentCount < data.current.length
									&& 
									(dayjs(data.current[currentCount].date as Date).isSame(startDate,dateType))
								){
									if(map.has(data.current[currentCount].lead_id)){
										if(map.get(data.current[currentCount].assign_to) !== selectedCaller?.id)
											map.delete(data.current[currentCount].lead_id);
									}else{
										if(data.current[currentCount].assign_to === selectedCaller?.id){
											map.set(data.current[currentCount].lead_id,data.current[currentCount]);
										}
									}
									currentCount++;
								}
								
								result.push({
									date:startDate.format('YYYY-MM-DD'),
									count:map.size
								});
								startDate = startDate.add(1,dateType);
							}
							set({
								leadTrend: result,
							});

						} else {
							const processedData = data.map( (element: any) => {
								return{
									date: dayjs(element.date).format('YYYY-MM-DD'),
									count: element.count,
								};
							});
							set({
								leadTrend: processedData,
							});
						}
					} catch (error: any) {
						message.error(error?.message ?? error);
						set({
							leadTrend: [],
						});
					} finally {
						get().setFullScreenData();
					}
				},

				getEngagementTrend: async () => {
					try{
						set({
							engagementTrend: undefined,
						});
						get().setFullScreenData();
						const time = get().engagementTrendTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getEngagementData(time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id);
						set({
							engagementTrend: data,
						});
					} catch (error: any) {
						message.error(error?.message ?? error);
						set({
							engagementTrend: [],
						});
					} finally {
						get().setFullScreenData();
					}
				},

				getDetailedCardData: async (value: number) => {
					try{
						const time = get().globalTime;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getDetailedCardData(value, time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				getDetailedLeadStatus: async () => {
					try{
						const time = get().leadVsStatusTime;
						const selectedGraph = get().selectedGraph;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getDetailedDataForLeadStatus( selectedGraph?.value?.status, time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				getDetailedLeadSource: async () => {
					try{
						const time = get().leadVsSourceTime;
						const selectedGraph = get().selectedGraph;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getDetailedSourceData( selectedGraph?.value ,time[0]?.toISOString(), time[1]?.toISOString(), selectedCaller?.id );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				getDetailedCountPerCaller: async () => {
					try{
						const time = get().leadVsCallerTime;
						const selectedGraph = get().selectedGraph;
						const data = await CQLeadsClient.getDetailedDataForLeadVsCaller( selectedGraph?.value, time[0]?.toISOString(), time[1]?.toISOString() );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				getDetailedCallerPerformance: async () => {
					try{
						const time = get().leadVsSourceTime;
						const selectedGraph = get().selectedGraph;
						const data = await CQLeadsClient.getPymentRecieveVsWorkedOnLead( selectedGraph?.value?.callerId, selectedGraph?.value?.status, time[0]?.toISOString(), time[1]?.toISOString() );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				getDetailedLeadTrend: async() => {
					try{
						const selectedGraph = get().selectedGraph;
						const leadTrendDateType = get().leadTrendDateType;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getDetailedDataOfDailyLeadTrend( selectedGraph?.value, leadTrendDateType, selectedCaller?.id );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				getDetailedEngagementTrend: async () => {
					try{
						const selectedGraph = get().selectedGraph;
						const selectedCaller = get().selectedCaller;
						const data = await CQLeadsClient.getDetailedEngagementData( selectedGraph?.value?.value, selectedGraph?.value?.date, selectedCaller?.id );
						set({
							selectedGraphData: data,
						});
					} catch(error: any) {
						set({
							selectedGraph: null,
							selectedGraphData: null,
						});
						message.error(error?.message ?? error);
					}
				},

				refreshAll: () => {
					const globalTime = get().globalTime;
					get().setGlobalTime(structuredClone(globalTime));
				}
			};
		}));
}
