import React, { useEffect,useRef } from 'react';
import styles from './sourceDetailsModal.module.css';
import type { InputRef } from 'antd';
import { 
	Button, 
	Drawer, 
	Input,  
	message,
	Form,
	Select,
	Space,
} from 'antd';
import { title } from 'process';
import states from '../../config/location';

const { TextArea } = Input;
const {Option} = Select;

interface SourceDetailsModalProps {
	sourceDetailModalOpen: boolean,
	setSourceDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
	onSourceDetailModalClose: () => void,
	isEdit: boolean,
	handleCreateOneSource: (data: any) => void,
	oneSourceData: any,
	handleEditOneSource: (data: any) => void,
	setDetailModalBtnLoading: any,
	detailModalBtnLoading: boolean,
	sourceDetailModalSubmitBtnRef: any
}

export const SourceDetailsModal: React.FunctionComponent<SourceDetailsModalProps> = (props) => {
	const {
		sourceDetailModalOpen, 
		setSourceDetailModalOpen, 
		onSourceDetailModalClose,
		isEdit,
		handleCreateOneSource,
		oneSourceData,
		handleEditOneSource,
		setDetailModalBtnLoading,
		detailModalBtnLoading,
		sourceDetailModalSubmitBtnRef
	} = props;

	const [createNewSourceForm] = Form.useForm();
	// reset the drawer while closing
	const resetDrawerOnClose = () => { 
		createNewSourceForm.resetFields();
		document.body.querySelector('.createNewSourceDrawer .ant-drawer-body')?.scrollTo(9999, 0);
	};

	// reset the drawer after Source created or edited
	const resetDrawer = () => { 
		resetDrawerOnClose(); 
		setSourceDetailModalOpen( false );
	};

	const addNewSource = (formValues: any) => {
		// resetDrawer();

		// while editing/creating Source need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		sourceDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleCreateOneSource(formValues);
	};

	const editSource = (formValues: any, sourceValue: string) => {
		// 	resetDrawer();

		// while editing/creating Source need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		sourceDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleEditOneSource({label: formValues.label, value: sourceValue});
	};

	const setFieldsAndVals = () => {

		createNewSourceForm.setFieldsValue(oneSourceData);
	};

	useEffect(() => {
		if( isEdit ){
			setFieldsAndVals();
		}

		if(!sourceDetailModalOpen) {
			resetDrawer();
		}
	}, [isEdit, sourceDetailModalOpen]);

	return (
		<div className={styles.createNewSourceDrawerContainer}>
			<Drawer
				title={(() =>{
					if(isEdit) return 'Edit Source';
					else return 'Create New Source';
				})()}
				width={450}
				onClose={() => {
					resetDrawerOnClose();
					onSourceDetailModalClose();
				}}
				open={sourceDetailModalOpen}
				extra={
					<Space>
						<Button 
							ref={sourceDetailModalSubmitBtnRef}
							type="primary" 
							onClick={ () => createNewSourceForm.submit() }
							loading={detailModalBtnLoading}
						>
							{ isEdit ? 'Edit' : 'Create' }
						</Button>
					</Space>
				}
			>
				<div className={styles.createNewSourceBody}>
					<Form
						form={createNewSourceForm}
						onFinish={
							(values) => {
								if(isEdit){
									editSource(values, oneSourceData.value);
								}else {
									addNewSource(values);
								}
							}
						}
						layout="vertical"
					>
						<Form.Item
							label="Source Name"
							name="label"
							rules={[
								{
									required: true,
									message: 'Please input Source name'
								},
								{
									pattern: /^(?!\s)[a-zA-Z0-9-_. ]*$/,
									message: 'Not a valid title, Remove special characters or any initial spaces',
								},
							]}
						>
							<Input showCount maxLength={50} placeholder={`Enter Source's Name`} />
						</Form.Item>
					</Form>
				</div>
			</Drawer>
		</div>
  	);
};
