import React, { useEffect } from 'react';
import { Button, Modal, message } from 'antd';

import styles from './changePassModal.module.css';
import { APP_CONFIG } from '../../config';

interface ChangePassModalProps{
	isVisible: boolean,
	isVisibleChange:(visible:boolean) => void,
}

export const ChangePassModal: React.FunctionComponent<ChangePassModalProps> = (props) => {
	const {
		isVisible,
		isVisibleChange
	} = props;
	const receiveMessage = (event:any) => {
		if(event?.data && event.data.token){
			const token = event.data.token;

			// STORE TOKEN IN LOCAL STORAGE
			localStorage.setItem('token',token);
			window.location.reload();
		}else if(event?.data?.error){
			message.error(event.data.error);		
		}else if(event?.data?.msg){
			message.success(event.data.msg);
			isVisibleChange(false);
		}
	};

	useEffect(() => {
		window.addEventListener('message', receiveMessage);

		return () => {
			window.removeEventListener('message', receiveMessage);
		};
	},[]);

	useEffect(() => {
		const iframe:any = document.getElementById('changePass');
		if(iframe) {
			iframe.src = iframe.src;
		}
	},[isVisible]);

	return (
		<div style={{position:'absolute'}}>
			<Modal
				title="Change password" 
				open={isVisible}
				footer={[]}
				onCancel={() => isVisibleChange(false)}
			>
				<div className={styles.iframeContainer} role='changePasswordModal'>
					<iframe id='changePass' src={`${APP_CONFIG.AuthServerURL}/auth/changePassword?token=${localStorage.getItem('token')}&url=${window.location.href}`} title="Authenticate"></iframe>
				</div>
			</Modal>
		</div>
	);
};
