import React,{useState,useEffect} from 'react';
import {resources,attributes} from '../../config/authorization';
import { Checkbox } from 'antd';
import Panel from 'antd/es/collapse/CollapsePanel';
import styles from './checkboxComponent.module.css';
const CheckboxGroup = Checkbox.Group;

const resourceMap = new Map();
const resourceReverseMap = new Map();
const attributeReverseMap = new Map();
const attributeMap = new Map();
for(const [key, value] of Object.entries(resources)){
	resourceMap.set(value.value,key);
	resourceReverseMap.set(key,value.value);
	
}
for(const [key, value] of Object.entries(attributes)){
	attributeMap.set(value.value,key);
	attributeReverseMap.set(key,value.value);
}
export const CheckboxComponent: React.FunctionComponent<any> = ( {defaultValue,options,result,selectAll,setSelectAll,length,selectAllOnAllSelected} ) => {
	useEffect(()=>{
		if(selectAll){
			const list = options.attributes.map((value:any)=>attributeMap.get(value));
			setCheckedList(list);
			setIndeterminate(false);
			setCheckAll(true);
			if(!selectAllOnAllSelected.includes(options.resource)){
				selectAllOnAllSelected.push(options.resource);
			}
			
			result[options.resource] = options.attributes;
		}else if(selectAll === false){
			setCheckedList([]);
			setIndeterminate(true);
			setCheckAll(false);
			if(selectAllOnAllSelected.includes(options.resource)){
				selectAllOnAllSelected.splice(selectAllOnAllSelected.indexOf(options.resource),1);
			}
			result[options.resource] = [];
		}
	},[selectAll]);
	useEffect(()=>{
		
		if(defaultValue.length === options.attributes.length){
			if(!selectAllOnAllSelected.includes(options.resource)){
				selectAllOnAllSelected.push(options.resource);
			}

		}
		if(selectAllOnAllSelected.length === length){
			setSelectAll(true);
		}
	},[]);
	const [checkedList, setCheckedList] = useState(defaultValue.map((value:any)=> attributeMap.get(value)));
	const [indeterminate, setIndeterminate] = useState(defaultValue.length !== options.attributes.length );
	const [checkAll, setCheckAll] = useState(defaultValue.length === options.attributes.length);
	const onChange = (list:any,resource:any) => {
		result[resource]=list.map((value:any)=>attributeReverseMap.get(value));
		setCheckedList(list);
		setIndeterminate(!!list.length && list.length < options.attributes.length);
		setCheckAll(list.length === options.attributes.length);
		if(list.length === options.attributes.length ){
			if(!selectAllOnAllSelected.includes(resource)){
				selectAllOnAllSelected.push(resource);
				
			}
			
			if(length === selectAllOnAllSelected.length){
				setSelectAll(true);
			}
		}else{
			if(selectAllOnAllSelected.includes(resource)){
				selectAllOnAllSelected.splice(selectAllOnAllSelected.indexOf(resource),1);
			}
		}
		if(checkAll){
			setSelectAll(null);
		}
	};

	const onCheckAllChange = (e:any,resource:any) => {
		if(e.target.checked){
			result[resource] = options.attributes;
			if(!selectAllOnAllSelected.includes(resource)){
				selectAllOnAllSelected.push(resource);
			}
			if(selectAllOnAllSelected.length === length){
				setSelectAll(true);
			}
		}else {
			result[resource]=[];
			if(selectAllOnAllSelected.includes(resource)){
				selectAllOnAllSelected.splice(selectAllOnAllSelected.indexOf(resource),1);
			}
			
		}
		if(checkAll){
			setSelectAll(null);
		}
		setCheckedList(e.target.checked ? options.attributes.map((value:any)=>attributeMap.get(value)) : []);
		setIndeterminate(false);
		setCheckAll(e.target.checked);
	};

	const screenCheckbox = (value:any,index:number) => {
		return (
			<div onClick={(e:any) => {
				e.stopPropagation(e);
			}}>
				<Checkbox indeterminate={indeterminate} onChange={(e:any)=>onCheckAllChange(e,index)} checked={checkAll}>
				</Checkbox>
			</div>
		);
	};

	return (
		<>
			<Panel forceRender={true}
				header={resourceMap.get(options.resource).charAt(0).toUpperCase()+resourceMap.get(options.resource).slice(1)}
				extra={screenCheckbox(resourceMap.get(options.resource),options.resource)}
				key={options.resource}
			>
				<CheckboxGroup name={options.resource} className={styles.gridElement} options={options.attributes.map((value:any)=> attributeMap.get(value))}
					value={checkedList} onChange={(list:any)=>onChange(list,options.resource)} 
				/>
			</Panel>
		</>
	);
};