import React, { useEffect,useRef } from 'react';
import styles from './dynamicStatusDetailModal.module.css';
import type { InputRef } from 'antd';
import { 
	Button, 
	Drawer, 
	Input,  
	message,
	Form,
	Select,
	Space,
} from 'antd';
import { title } from 'process';
import states from '../../config/location';

const { TextArea } = Input;
const {Option} = Select;

interface StatusDetailsModalProps {
	statusDetailModalOpen: boolean,
	setStatusDetailModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
	onStatusDetailModalClose: () => void,
	isEdit: boolean,
	handleCreateOneStatus: (data: any) => void,
	oneStatusData: any,
	handleEditOneStatus: (data: any) => void,
	stageOptions: any,
	setDetailModalBtnLoading: any,
	detailModalBtnLoading: boolean,
	statusDetailModalSubmitBtnRef: any
}

export const StatusDetailsModal: React.FunctionComponent<StatusDetailsModalProps> = (props) => {
	const {
		statusDetailModalOpen, 
		setStatusDetailModalOpen, 
		onStatusDetailModalClose,
		isEdit,
		handleCreateOneStatus,
		oneStatusData,
		handleEditOneStatus,
		stageOptions,
		setDetailModalBtnLoading,
		detailModalBtnLoading,
		statusDetailModalSubmitBtnRef
	} = props;

	const [createNewstatusForm] = Form.useForm();
	// reset the drawer while closing
	const resetDrawerOnClose = () => { 
		createNewstatusForm.resetFields();
		document.body.querySelector('.createNewstatusDrawer .ant-drawer-body')?.scrollTo(9999, 0);
	};

	// reset the drawer after status created or edited
	const resetDrawer = () => { 
		resetDrawerOnClose(); 
		setStatusDetailModalOpen( false );
	};

	const addNewstatus = (formValues: any) => {
		// resetDrawer();

		// while editing/creating status need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		statusDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleCreateOneStatus(formValues);
	};
	const notAllowSpaces = (e:any) => {
		if(e.key === ' '){
			e.preventDefault();
		} 
	};

	//function to validate the password


	const editstatus = (formValues: any, statusValue: string) => {
		// 	resetDrawer();

		// while editing/creating status need to handle the behaviour of edit/create button in leadDetailModal form
		setDetailModalBtnLoading(true);
		statusDetailModalSubmitBtnRef.current.setAttribute('disabled', 'disabled');
		handleEditOneStatus({label: formValues.label,stage: formValues.stage, statusValue});
	};

	const setFieldsAndVals = () => {

		createNewstatusForm.setFieldsValue(oneStatusData);
	};

	useEffect(() => {
		if( isEdit ){
			setFieldsAndVals();
		}

		if(!statusDetailModalOpen) {
			resetDrawer();
		}
	}, [isEdit, statusDetailModalOpen]);

	return (
		<div className={styles.createNewStatusDrawerContainer}>
			<Drawer
				title={(() =>{
					if(isEdit) return 'Edit status';
					else return 'Create New status';
				})()}
				width={450}
				onClose={() => {
					resetDrawerOnClose();
					onStatusDetailModalClose();
				}}
				open={statusDetailModalOpen}
				extra={
					<Space>
						<Button 
							ref={statusDetailModalSubmitBtnRef}
							type="primary" 
							onClick={ () => createNewstatusForm.submit() }
							loading={detailModalBtnLoading}
						>
							{ isEdit ? 'Edit' : 'Create' }
						</Button>
					</Space>
				}
			>
				<div className={styles.createNewStatusBody}>
					<Form
						form={createNewstatusForm}
						onFinish={
							(values) => {
								if(isEdit){
									editstatus(values, oneStatusData.value);
								}else {
									addNewstatus(values);
								}
							}
						}
						layout="vertical"
					>
						<Form.Item
							label="Status Name"
							name="label"
							rules={[
								{
									required: true,
									message: 'Please input status name'
								},
								{
									pattern: /^(?!\s)[a-zA-Z0-9-_. ]*$/,
									message: 'Not a valid title, Remove special characters or any initial spaces',
								},
							]}
						>
							<Input showCount maxLength={50} placeholder={`Enter status's Name`} />
						</Form.Item>
						
						<Form.Item
							name="stage"
							label='Stage'
							rules={[
								{
									required: true,
									message: 'Please select status role'
								},
							]}
						>
							<Select  
								options={stageOptions.map((options: any) => ({label: options.label, value: options.value, key: options.value}))}
								placeholder='Select Stages'
								style={{width: '100%'}} 
							/>
						</Form.Item>
					</Form>
				</div>
			</Drawer>
		</div>
  	);
};
