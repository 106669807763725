import React, { useCallback, useRef } from 'react';

import styles from './fullScreenModel-report.module.css';

import { SubHeaderReport } from '../subHeader-report';
import { CallerStatusBarGraph } from '../callerStatus-bargraph/callerStatus-bargaph';
import { ReportGraphConstants } from '../../config/report';
import { LeadVsSource } from '../leadSource-report/LeadVsSource';
import { LeadWorkedVsCaller } from '../leadWorkedVsCallerGraph-report';
import { DailyLeadTrendGraph } from '../dailyLeadTrendGraph-report';
import { EngagementChart } from '../engagementChart-report';
import { LeadVsCallerCount } from '../leadvscallercount-report';

interface FullScreenModelReportInterface{
	fullScreenData: any,
	setFullScreen: any,
	setFullScreenData: any,
	fullScreen: any,
	download: any,
	setSelectedGraph: any,
}

export const FullScreenModelReport = (props:FullScreenModelReportInterface) => {
	const { fullScreenData, fullScreen, setFullScreen, download, setSelectedGraph } = props;
	const labelSize = useRef<number>(16);
    
	return (
		<div>
			<div className={styles.fullScreenModelMain}>
				<SubHeaderReport title={fullScreenData?.title ?? ''} 
					download={()=>download(fullScreen ?? -1)}
					dateRage={ fullScreenData?.data?.time} 
					setDateRange={fullScreenData?.data?.setTime}
					removeFullScreen={()=>setFullScreen(null)}
					refreshFunction={fullScreenData?.data?.refreshFunction}
				/>
				<div className={styles.fullScreenGraphHolder}>
					{fullScreen === ReportGraphConstants.leadStatus.value
                    &&	<CallerStatusBarGraph labelSize={labelSize.current} data={fullScreenData?.data?.data} setSelectedGraph={setSelectedGraph}/>
					}
					{fullScreen === ReportGraphConstants.leadSource.value
					&& 	<LeadVsSource data={fullScreenData?.data?.data} pageSize={20} pageSizeOptions={['20','50','100']} setSelectedGraph={setSelectedGraph}/>
					}
					{fullScreen === ReportGraphConstants.leadVsCaller.value 
					&& <LeadVsCallerCount labelSize={labelSize.current} data={fullScreenData?.data?.data} setSelectedGraph={setSelectedGraph}/>
					}
					{fullScreen === ReportGraphConstants.callerPerformace.value
					&& <LeadWorkedVsCaller labelSize={labelSize.current} data={fullScreenData?.data?.data} setSelectedGraph={setSelectedGraph}/>
					}
					{fullScreen === ReportGraphConstants.leadTrend.value 
					&& <DailyLeadTrendGraph labelSize={labelSize.current} data={fullScreenData?.data?.data} setSelectedGraph={setSelectedGraph}/>
					}
					{fullScreen === ReportGraphConstants.engagementTrend.value
					&& <EngagementChart labelSize={labelSize.current} data={fullScreenData?.data?.data} setSelectedGraph={setSelectedGraph}/>
					}
				</div>
			</div>
		</div>
	);
};


// interface FullScreenModelReportProps{
// 	data: any,
// 	selected: number,
// 	dateRange: any,
// 	setDateRange: any,
// 	removeFullScreen: any,
// 	title: string,
// 	reload?: any,
// 	chartRef?: any,
// 	// eslint-disable-next-line @typescript-eslint/ban-types
// 	dataProcesserFunctionForDownload?: Function,
// }

// export const FullScreenModelReport = (props: FullScreenModelReportProps) => {
// 	const labelSize = useRef<number>(16);
// 	const {
// 		data, selected, dateRange, setDateRange, 
// 		removeFullScreen, title, reload, chartRef,
// 		dataProcesserFunctionForDownload
// 	} = props;

// 	return (
// 		<div>
// 			<div className={styles.fullScreenModelMain}>
// 				<SubHeaderReport title={title} 
// 					download={()=>{
// 						exportToCSV( dataProcesserFunctionForDownload? dataProcesserFunctionForDownload(data): data,reverseExportCSVName[selected].label, reverseExportCSVName[selected].columns);
// 					}}
// 					dateRage={dateRange} 
// 					setDateRange={setDateRange}
// 					removeFullScreen={removeFullScreen}
// 				/>
// 				<div className={styles.fullScreenGraphHolder}>
// 					{selected === ExportCSVName.callerStatusGraph.value
// 					&&	<CallerStatusBarGraph labelSize={labelSize.current} data = {data} chartRef={chartRef}/>
// 					}
// 					{selected === ExportCSVName.leadSource.value &&
// 						<LeadVsSource 
// 							data = {data}
// 							selectSource={chartRef}
// 							pageSize={20}
// 							pageSizeOptions={['20','50','100']}
// 						/>
// 					}
// 					{selected === ExportCSVName.paymentRecieveVsWorkedOnLead.value &&
// 						<LeadWorkedVsCaller labelSize={labelSize.current} data={data} chartRef={chartRef}/>
// 					}
// 					{selected === ExportCSVName.dailyLeadTrend.value && 
// 						<DailyLeadTrendGraph labelSize={labelSize.current} data={data} chartRef={chartRef}/>
// 					}
// 					{selected === ExportCSVName.engagementChart.value
// 					&&
// 						<EngagementChart labelSize={labelSize.current} data={data} chartRef={chartRef}/>
// 					}
// 					{selected === ExportCSVName.leadVsCaller.value
// 					&&
// 						<LeadVsCallerCount labelSize={labelSize.current} data={data} chartRef={chartRef}/>
// 					}
// 				</div>
// 			</div>
// 		</div>
// 	);
// };