import React, { useEffect, useState,useRef} from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Checkbox, Space,Drawer,Form,Input,Card,Select,Popconfirm,message } from 'antd';
import { Header } from '../../components/header';
import { useAppStore } from '../../stores';
import { useNavigate } from 'react-router-dom';
import { stringToRGB } from '../../libs/utils';
import { roleStringFromNumber } from '../../config/roles';
import { convertSnakeToCamelCase } from '../../libs/utils';
import styles from './leadRoles-screen.module.css';

const { Option } = Select;
const rolesMap = new Map();
const rolesReverseMap = new Map();
for(const [key,value] of Object.entries(roleStringFromNumber)){
	rolesMap.set(key,value);
	rolesReverseMap.set(value,key);
}
const appStoreSelector = ( state: AppState ) => ( {
	logoutUser: state.logoutUser,
	sessionData: state.sessionData,
	//  getAllRoles: state.getAllRoles,
	getAllowedRolesOfCurrentRole: state.getAllowedRolesOfCurrentRole,
	// editOneRole: state.editOneRole,
	 addNewRole: state.addNewRole
} );


export const LeadRolesScreen: React.FunctionComponent = () => {
	console.log(stringToRGB('admin'));
	const navigate = useNavigate();
	const [open,setOpen] = useState<boolean>(false);
	// const [isEdit,setIsEdit] = useState<boolean>(false);
	// const [roles,setRoles] = useState<any>([]);
	const [createNewRoleForm] = Form.useForm();
	// const [editRole] = Form.useForm();
	const roleNameInput = useRef<any>();
	// const [selectedValue,setSelectedValue] = useState<any>();
	const [allRoles,setAllRoles] = useState<any>([]);

	const { sessionData,logoutUser,addNewRole,getAllowedRolesOfCurrentRole} = useAppStore( appStoreSelector );
	

	const openCreateRole = () => {
		
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
		// editRole.resetFields();
		createNewRoleForm.resetFields();
		// setIsEdit(false);
	};
	
	const addNewRoles = async(value:any) => {
		const arr = value.roleName.split(/[\s]+/);
		value = '';
		arr.forEach((roleNameSubstr:any)=>value+=roleNameSubstr.charAt(0).toUpperCase()+roleNameSubstr.slice(1)+' ');
		value = value.trim();
		console.log(value);
		const response = await addNewRole(value);
		console.log('consoling the response');
		console.log(response);
		if(response?.error){
			message.error(response?.error);
			return;
		}
		message.success(response?.data?.msg);
		
		createNewRoleForm.resetFields();
		setOpen(false);
		handleRolesList();
		navigate(`/rolesPermissions/${response?.data?.data?.id}`);
		

	};
	const editPermissions = (id:string)=>{
		// setIsEdit(true);
		// setOpen(true);
		// setSelectedValue(id);
		navigate(`/rolesPermissions/${id}`);

	};
	const handleRolesList = async() => {
	
		const response = await getAllowedRolesOfCurrentRole();
		console.log('consoling the response');
		console.log(response);
		// const response = ['0','2','5'];
		const allRoles = response?.data;
		// setRoles(response);
		setAllRoles(allRoles);
	};
	useEffect(()=>{
		handleRolesList();
		//call api for get the roles
		//const response = await getAllRoles();
		//if(response.error) return;
		//else {const allRoles = response.data.map((value))=>value.roleName
		//setAllRoles(allRoles) setRoles(response.data)
		
	},[]);

	useEffect(()=>{
		// editRole.resetFields();
		createNewRoleForm.resetFields();
	});

	const deleteRole = (value:string) => {
		console.log(value);
		// console.log(value);
		//const response = await deleteOneRole(value);
		//if(response.error) return;
		//else { setAllRoles(response.data.allRoles) setRoles(response.data.roles)}
	};

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData = {sessionData}
			/>
			<div className={styles.listLeadsSreenbody} role='listLeadsSreenbody'>
				<div className={styles.listLeadsSreenbodyheader}>
					<Button type="primary" onClick={openCreateRole} >
						Create new Role
					</Button>

					{/* drawerComponent Starts Here */}
					<Drawer
						title={'Create Role'}
						placement={'right'}
						width={450}
						onClose={onClose}
						open={open}
						extra={
							<Space>
								{/* { isEdit 
									?
									<Button type="primary" onClick={() => editRole.submit()} >Edit</Button>
									: */}
								<Button type="primary" onClick={() => createNewRoleForm.submit()} >Create</Button>
								{/* } */}
							</Space>
						}
					>
						<div className={styles.createNewLeadBody}>
						
							
							<Form
								form = {createNewRoleForm}
								layout="vertical"
								onFinish={
									(values:any) => {												
										
										const {roleName,roles} = values;
										
										createNewRoleForm.resetFields();
										//check if role with same name exists
										//check for valid name
										//roleName = roleName.charAt(0).toUpperCase()+roleName.slice(1).toLowerCase();
										//if(!nameRegex.test(roleName)) setMessage('Please Enter a valid Username')
										//else if(allRoles.includes(roleName)) setMessage('Role With same Name exists')
										//else setMessage('Please input your RoleName!')
										addNewRoles({roleName,roles});
									}
								}
							>		
								<Form.Item
									label="Role Name"
									name="roleName"
									rules={
										[
											{ 
												required: true, 
												message: 'Please input your username!' 
											},
											{
												pattern: /^(?!\s)[a-zA-Z0-9-_. ]*$/,
												message: 'Not a valid name, remove special characters or any initial spaces!',
											},
										]}
								>
									<Input ref={roleNameInput} placeholder={`Enter Role's Name`} />
								</Form.Item>
								{/* <Form.Item
									label="Roles can be created"
									name='roles'
									rules={[
										{
											message: 'Please select your role!',
											type: 'array'
										},
									]}
								>
									<Select mode='multiple' placeholder="Please select a role">
										{allRoles.map((value:any)=>{
											return (
												
												<Option key={value.name}  
													value={value.id}
												>{rolesMap.get(value.name)}</Option>
											);
										})}
									</Select> 
									
									
								</Form.Item> */}
							</Form>	
						
						</div>
						
					</Drawer>
					{/* DrawerComponent Ends Here */}
	
					{/* rendering roles */}
					<div className={styles.rolesDisplay}>
						{allRoles.map((value:any)=>{
							return (
								
								<Card className={styles.cardComponent} key={value.id}>
									<div className={styles.cardComponentInner}>
										<div style={{backgroundColor:stringToRGB(value?.name)}} className={styles.icon}>{(value?.name.split(' ').map((value:string)=>
										 value.charAt(0).toUpperCase())).toString().replace(',','')}</div>
										<p style={{margin:'0',fontSize:'24px'}}>{value?.name.charAt(0).toUpperCase()+value?.name.slice(1)}</p>
										<div className={styles.roleButtons}>
											<Button title='Edit Permission' size='large' type="primary" onClick={()=>editPermissions(value?.id)}><EditOutlined/></Button>
											<Popconfirm
												placement="top"
												title={'Are you sure you want to delete the role'}
												description={'Delete the role'}
												onConfirm={()=>deleteRole(value.id)}
												okText="Yes"
												cancelText="No"
											>
												<Button title='Delete Role' size='large' type="primary"><DeleteOutlined/></Button>
											</Popconfirm>
										</div>
									</div>
								</Card>
								
							);
						})}
					</div>
				</div>
				
			</div>
		</>
  	);
};


