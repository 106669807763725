import React, { useEffect, useRef, useState} from 'react';
import styles from './listLeads-screen.module.css';
import { Header } from '../../components/header';
import { Button, Space, Table, Tooltip, message, Popconfirm, Input, Drawer, Upload } from 'antd';
import { LeadDetalisModal } from '../../components/leadDetailsModal';
import { EditOutlined, DeleteOutlined, CommentOutlined,LinkOutlined,
	UploadOutlined } from '@ant-design/icons';
import { useAppStore } from '../../stores';
import { APP_CONFIG, LeadSeekers, months, roleValue } from '../../config';
import type { InputRef, TableProps } from 'antd';
import type { ColumnsType, TableRowSelection } from 'antd/es/table/interface';
import { convertSnakeToCamelCase, convertTime } from '../../libs/utils';
import { TableComponent } from '../../components/mainTableComponent';
import BulkAddIcon from '../../assets/img/bulk-add.svg';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { SearchField } from '../../components/searchField';
import { SearchByFilterComponent } from '../../components/searchByFilterComponent';
import { FixedFilterComponent } from '../../components/fixedFilterComponent';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';
import states from '../../config/location';
import { Link,useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';


const appStoreSelector = ( state: AppState ) => ( {
	sessionData: state.sessionData,
	leadListData: state.leadListData,
	leadStages: state.leadStages,
	leadStatus: state.leadStatus,
	sourceOptions: state.sourceOptions,
	logoutUser: state.logoutUser,
	createOneLead: state.createOneLead,
	getLeadsList: state.getLeadsList,
	deleteOneLead: state.deleteOneLead,
	updateOneLead: state.updateOneLead,
	bulkUploadLeads: state.bulkUploadLeads,
	createActivityBeforeDownload: state.createActivityBeforeDownload,
	updateLeadStatus: state.updateLeadStatus,
	updateAssignTo: state.updateAssignTo,
	unAssignLeads: state.unAssignLeads,
	updateFollowUpLastCalled: state.updateFollowUpLastCalled,
	getAssignList: state.getAssignList,
	generatePaymentLink: state.generatePaymentLink,
} );

export const ListLeadsSreen: React.FunctionComponent = () => {
	document.title = 'Leads | CodeQuotient';
	const stateLeadsFilters: LeadsFilters = {
		startTime: '',
		endTime: '',
		// stage: null,
		// isEqualToStage: true,
		status: null,
		isEqualToStatus: true,
		assignTo: '',
		isEqualToAssignTo: true,
		state: null,
		notAssigned: false,
		isFollowUp: false,
		source: null,
	};
	
	const statePaginationData: paginationData = {
		sortField: 'updated_at',
		sortOrder: 'descend',
		pageNumber: 1,
		perPage: 20,
		searchValue: '',
		filters: stateLeadsFilters
	};
	
	const [leadDetailModalOpen, setLeadDetailModalOpen] = useState<boolean>( false );
	const [finalCols, setFinalCols] = useState<any>([]);
	const [tableData, settableData] = useState<any[]>([]);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [oneLeadData, setOneLeadData] = useState<any>({});
	const [paginationData, setPaginationData] = useState<paginationData>(statePaginationData);
	const [perPageCount, setPerPageCount] = useState<number>(20);
	const [totalListDataCount, setTotalListDataCount] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [searchValue, setSearchValue] = useState<string>('');
	const [leadsFilters, setLeadsFilters] = useState<LeadsFilters>(stateLeadsFilters);
	const [bulkAddLeadModal, setBulkAddLeadModal] = useState<boolean>(false);
	const [bulkAddLeadFile, setBulkAddLeadFile] = useState<UploadFile[]>([]);
  	const [uploading, setUploading] = useState(false);
	const [prevSorterOrder, setPrevSorterOrder] = useState<any>('descend');
	const [assignToList, setAssignToList] = useState<any[] | null>([]);
	const amountRef = useRef<InputRef>(null);
	
	// assign keys to table row
	const [leadsTableSelectedRowKeys, setLeadsTableSelectedRowKeys] = useState<React.Key[]>([]);
	const [detailModalBtnLoading, setDetailModalBtnLoading] = useState<boolean>(false);

	const collegeDefaultFields = LeadSeekers?.College?.fields || null;
	const navigate = useNavigate();
	const { 
		sessionData,
		leadListData,
		leadStages,
		leadStatus,
		sourceOptions,
		logoutUser,
		createOneLead, 
		getLeadsList,  
		deleteOneLead,
		updateOneLead,
		bulkUploadLeads,
		createActivityBeforeDownload,
		updateLeadStatus,
		updateAssignTo,
		unAssignLeads,
		updateFollowUpLastCalled,
		getAssignList,
		generatePaymentLink,
	} = useAppStore( appStoreSelector );

	const leadDetailModalSubmitBtnRef = useRef<any>(null);

	const isEditLeadAllowed = useCheckAttribute(resources.lead.value, attributes.edit.value);
	const isDeleteLeadAllowed = useCheckAttribute(resources.lead.value, attributes.delete.value);
	

	const handleEditCreatLeadBtn = () => {
		leadDetailModalSubmitBtnRef.current ? leadDetailModalSubmitBtnRef.current.removeAttribute('disabled', 'disabled') : '';
		setDetailModalBtnLoading(false);
	};

	const handleLeadList = async (paginationData: paginationData) => {
		// this function will get lead list data and call set data in table function
		try {
			setTableLoading(true);
			const response = await getLeadsList(paginationData);
			setTableLoading(false);

			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();

			if( response?.error ){
				throw new Error(response?.error);
			}
			if(response && response?.data){
				const leadsData = response?.data?.results || [];
				(leadsData && leadsData.length) ? 
					setLeadList( leadsData, response?.data?.usersData, response?.data?.sortField, response?.data?.sortOrder, response?.data?.page, response?.data?.perPage) : 
					setLeadList([], {}, 'updated_at',null);
				setPerPageCount(response?.data?.perPage);
				setTotalListDataCount(response?.data?.total);
				setCurrentPage(response?.data?.page);
			}else{
				setLeadList([], {}, 'updated_at',null);
			}

		} catch (error:any) {
			// console.log(error);

			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();

			setLeadList([], {}, 'updated_at',null);
			message.error( error?.message );
		}
	};

	const getLeadsData = () => {
		//set the table page no. to 1
		paginationData.pageNumber = 1;
		// paginationData.sortField = 'updated_at';
		// paginationData.sortOrder = 'descend';
		// setPrevSorterOrder('descend');

		setPaginationData(paginationData);
		handleLeadList(paginationData);
		document.body.querySelector('#listLeadsSreenbody')?.scrollTo(9999, 0);
	};

	// making the request of create new lead by sending the lead data after creating.
	const handleCreateOneLead = async ( data: any ) => {
		// console.log(paginationData);
		setTableLoading(true);
		const response = await createOneLead( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			setLeadDetailModalOpen(true);
			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();
			return;
		}

		setLeadDetailModalOpen(false);
		message.success( response.data.msg );

		getLeadsData();
	};

	// making the request of deleting one lead by sending the lead Id. 
	const handleDeleteOneLead = async ( leadId: string ) => {
		setTableLoading(true);
		const response = await deleteOneLead( leadId );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}
		message.success( response.data.msg );

		getLeadsData();
	};

	// making the request of updating one lead by sending the updated lead data.
	const handleUpdateOneLead = async ( data: any ) => {
		setTableLoading(true);
		const response = await updateOneLead( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			setLeadDetailModalOpen(true);
			// while editing/creating lead need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreatLeadBtn();
			setIsEdit(true);
			return;
		}

		setIsEdit(false);
		setLeadDetailModalOpen(false);
		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUpdateLeadStatus = async ( data: UpdateLeadStatus ) => {
		setTableLoading(true);
		const response = await updateLeadStatus( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUpdateAssignTo = async ( data: UpdateAssignTo ) => {
		setTableLoading(true);
		const response = await updateAssignTo( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUnAssignLeads = async ( data: UnassignLeads ) => {
		setTableLoading(true);
		const response = await unAssignLeads( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleUpdateFollowUpLastCalled = async ( data: UpdateFollowUpLastCalled ) => {
		setTableLoading(true);
		if(data.followUpDate) {
			data.followUpDate = dayjs(data.followUpDate).millisecond(0).second(0).toISOString();
		}
		if(data.lastCalledDate) {
			data.lastCalledDate = dayjs(data.lastCalledDate).millisecond(0).second(0).toISOString();
		}
		const response = await updateFollowUpLastCalled( data );
		setTableLoading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		message.success( response.data.msg );

		getLeadsData();
	};

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		const data: paginationData = {
			sortField: sorter.field,
    		sortOrder: sorter.order || null,
    		pageNumber: pagination.current,
    		perPage: pagination.pageSize,
			searchValue,
			filters: leadsFilters
		};

		// on sort set the table page no. to 1
		if (sorter.order !== prevSorterOrder) {
			data.pageNumber = 1;
		}

		setPrevSorterOrder(sorter.order);
		handleLeadList(data);
		setPaginationData(data);
	};

	const handleAssignList = async() => {
		if(sessionData?.roleName === roleValue.caller.label){
			setAssignToList(null);
			return ;
		}
		const response = await getAssignList();
		if( response.error ){
			message.error( response.error );
			return;
		}
		setAssignToList(response.data);
	};

	const handleGeneratePaymentLink = async (leadId:string) => {
		if(amountRef?.current?.input?.value && leadId) {
			const amount = parseInt(amountRef?.current?.input?.value);
			if(amount < 1){
				message.error('Please enter amount greater than 1');
			}
			const response = await generatePaymentLink(
				amount,
				leadId,
			);

			if(response?.error){
				message.error(response.error.message || response.error);
			}else if(response?.data?.msg) {
				const link = `${APP_CONFIG.MainServerURL}/payment/paymentsummary/${leadId}`;
				console.log('link',link);
				// navigator.clipboard.writeText(link);
				message.success(response?.data?.msg);
				copyText(link, 'link copied');
			}
		}
	};

	// open the edit lead drawer
	const editLead = (record: any) => {
		setLeadDetailModalOpen(true);
		setIsEdit(true);
		setOneLeadData(record);
	};

	// open the create new lead drawer
	const openCreateLead = () => {
		setLeadDetailModalOpen( !leadDetailModalOpen );
	  	setIsEdit(false);
	};

	// on closing the drawers
	const onleadDetailModalClose = () => {
		setLeadDetailModalOpen( false );
	  	setIsEdit(false);
	};
	const copyText = (textValue:string,messageToShow:string) => {
		// navigator.clipboard.writeText(textValue); 

		const tempInput = document.createElement('textarea');
		tempInput.value = textValue;
		document.body.appendChild(tempInput);
		tempInput.select();
		document.execCommand('copy');
		document.body.removeChild(tempInput);

		
		message.success(messageToShow);
	};
	

	// fixed columns
	const defaultColumns:ColumnsType<any> = [
		{
			width: 47,
			dataIndex: 'SrNum',
		  	key: 'SrNum',
			fixed:'left',
			render: ( _: any, record: any ) => (
				<div style={{display: 'flex', justifyContent: 'center'}}>{record.SrNum}.</div>
			)
		},
		{
	  	  	title: 'Name',
	  	  	dataIndex: 'name',
		  	key: 'name',
		  	width: 180,
			fixed:'left',
		  	sorter: true,
		  	render: ( _: any, record: any ) => { 
				return (
					<span className='groupAndLeadTitle' title={record.name} onClick={()=>window.open(`/lead/leadActivity/${record.id}`, '_blank')}>
						{record.name}
					</span>
				);
			}
	  	},
	  	{
	  	  title: 'Email',
	  	  dataIndex: 'email',
		  key: 'email',
		  width: 200,
		  fixed:'left',
		  ellipsis: true,
	  	  sorter: true,
			render: ( _: any, record: any) => {
				return (
					<span style={{cursor:'pointer'}}
						onClick={()=>{
							copyText(record.email,'Email Copied');
						}}
					>
						{record.email}
					</span>
					
				);
		  }
	  	},
	  	{
	  	  title: 'Phone Number',
	  	  dataIndex: 'number',
		  key: 'number',
		  ellipsis: true,
		  width: 160,
		  render: ( _: any, record: any) => {
				return (
					<span style={{cursor:'pointer'}}
						onClick={()=>{
							copyText(record.number,'Phone number Copied');
						}}
					>
						{record.number}
					</span>
				);
	  }
	  	},
		{
	  		title: 'Updated At',
			dataIndex: 'updated_at',
			width: 200,
			sorter: true,
	    	render: ( _: any, record: any ) => { 
				// console.log( record );
				const time = record.updated_at;
				return (
					convertTime(time)
				);
			}
	  	},
	  	{
			dataIndex:-1,
	  		title: 'Actions',
			fixed: 'right',
			width: 140,
	    	render: ( _: any, record: any ) => { 
				// console.log( record.id );
				return (
					<Space size="small">
						{isEditLeadAllowed && (
							<Tooltip title='Edit'>
								<div className={styles.leadsListingAction} onClick={() => editLead(record)}>
									<EditOutlined style={{opacity: '0.7'}} />
								</div>
							</Tooltip>
						)}
						{isDeleteLeadAllowed && (
							<Tooltip title="Delete">
								<Popconfirm
									title="Delete the lead"
									description="Are you sure to delete this lead?"
									placement="right"
									okText="Yes"
									cancelText="No"
									onConfirm={() => handleDeleteOneLead( record.id )}
								>
									<div className={styles.leadsListingAction} >
										<DeleteOutlined style={{opacity: '0.7'}}/>
									</div>
								</Popconfirm>
							</Tooltip>
						)}
						<Tooltip title='Comment Activity'>
							<div className={styles.leadsListingAction} onClick={() => navigate(`/lead/leadCommentActivity/${record.id}`)}>
								<CommentOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
						<Tooltip title='Generate Payment link'>
							<Popconfirm
								title="Please enter amount"
								description={(
									<Input
										placeholder='Amount'
										ref={amountRef}
									/>
								)}
								onConfirm={() => handleGeneratePaymentLink(record.id)}
								icon={null}
								placement='leftBottom'
							>
								<div className={styles.leadsListingAction}>
									<LinkOutlined style={{opacity: '0.7'}} />
								</div>
							</Popconfirm>
						</Tooltip>
					</Space>
				);
			}
	  }
	];

	// set dynamic columns with the fixed columns
	const columns: ColumnsType<any> = [
		...defaultColumns
	];

	// set the columns and the data that are coming dynamically
	const setLeadList:any = async (data:any, assignToDetails: any, sortField: string, sortOrder: any, currentPage: number, perPageCount:number) => {
		// TODO: need to clean this code

		// set the table when empty data is coming from backend
		if(!data?.length){
			setFinalCols(defaultColumns);
			settableData([]);
			return;
		}

		// ================================
		// logic for table columns set
		// ================================
		let defaultValuesFromLead:any[] = [];
		let tempColsForTable:any = {};
		data.forEach( ( lead:any ) => {
			lead.key = lead.id;

			if( lead.dynamic_fields ){
				const dynamicFieldKeys = lead.dynamic_fields;
				tempColsForTable = {...tempColsForTable, ...dynamicFieldKeys};
			}
			// console.log(Object.keys(lead));
			const sortedValuesFromLead: any = {
				0:Object.keys(lead)[0], 1:Object.keys(lead)[3], 2:Object.keys(lead)[1], 3:Object.keys(lead)[2], 4:Object.keys(lead)[34], 5:Object.keys(lead)[35], 6:Object.keys(lead)[18],
				7:Object.keys(lead)[39], 8:Object.keys(lead)[33], 9:Object.keys(lead)[38], 10:Object.keys(lead)[36], 11:Object.keys(lead)[37], 12:Object.keys(lead)[4], 13:Object.keys(lead)[41], 
				14:Object.keys(lead)[40], 15:Object.keys(lead)[5], 16:Object.keys(lead)[6], 17:Object.keys(lead)[7], 18:Object.keys(lead)[8], 
				19:Object.keys(lead)[9], 20:Object.keys(lead)[10], 21:Object.keys(lead)[11], 22:Object.keys(lead)[12], 23:Object.keys(lead)[13], 24:Object.keys(lead)[14], 
				25:Object.keys(lead)[15], 26:Object.keys(lead)[16], 27:Object.keys(lead)[17], 28:Object.keys(lead)[18], 29:Object.keys(lead)[19], 30:Object.keys(lead)[20], 
				31:Object.keys(lead)[21], 32:Object.keys(lead)[22], 33:Object.keys(lead)[23], 34:Object.keys(lead)[24], 35:Object.keys(lead)[25], 36:Object.keys(lead)[26],
				37:Object.keys(lead)[27], 38:Object.keys(lead)[28], 39:Object.keys(lead)[29], 40:Object.keys(lead)[30], 41:Object.keys(lead)[31], 42:Object.keys(lead)[32],
				43:Object.keys(lead)[42],
			};
			// console.log(sortedValuesFromLead);
			defaultValuesFromLead = [...defaultValuesFromLead,...Object.values(sortedValuesFromLead)];
		} );

		defaultValuesFromLead = [...new Set(defaultValuesFromLead)];

		defaultValuesFromLead.forEach((col:string) => {
			// now removing underScore and capital next char
			const newStr: string = convertSnakeToCamelCase(col || '');
			// appending the default cols with title in column
			if(collegeDefaultFields[newStr]?.title){
				if(col === 'current_lead_assign_to_date') {
					columns.splice(columns.length-1,0,
						{
							title: collegeDefaultFields[newStr]?.title || newStr,
							dataIndex: col,
							key: col,
							width: 190,
							ellipsis: true,
							sorter:true,
						}
					);
				}else {
					columns.splice(columns.length-1,0,
						{
							title: collegeDefaultFields[newStr]?.title || newStr,
							dataIndex: col,
							key: col,
							width: 190,
							ellipsis: true,
						}
					);
				}
			}
		});

		// now append that in columns for table
		for (const property in tempColsForTable) {
			columns.splice(columns.length-1,0,
				{
					title: tempColsForTable[property]?.title || property,
					dataIndex: property,
					key: property,
					width: 190,
					ellipsis: true,
				}
			);
		}

		columns.forEach((column: any) => {
			if(sortField && sortOrder && column.dataIndex === sortField){
				column.sortOrder = sortOrder || null;
			}
		});

		// columns = [...columns, ...tempCols];
		setFinalCols(columns);

		// ================================
		// logic for table columns set ends
		// ================================
		

		// appned dynamic_lead data to main object
		data.forEach((lead:any,leadIndex:number) => {
			// lead.current_stage = leadActivityStatus[lead.current_status]?.leadActivityStage;

			// set the label text for the column data asgetting the int value
			lead.current_status = leadStatus[lead.current_status]?.label;
			lead.current_lead_assign_to_date = convertTime(lead.current_lead_assign_to_date);
			lead.current_follow_up_date = convertTime(lead.current_follow_up_date);
			lead.current_last_called = convertTime(lead.current_last_called);
			lead.currently_assign_to = (lead.currently_assign_to && assignToDetails[lead.currently_assign_to]) ? assignToDetails[lead.currently_assign_to].name : null;
			
			// setting the values for dropdown fields as while editing we send the record data from table which is sending only label text for the dropdown field to set
			lead.sourceValue = sourceOptions[lead.source]?.value;
			lead.prefStateValue = lead.pref_state;
			lead.stateValue = states[lead.state]?.value;
			lead.testPlanMonthValue = months[lead.test_plan_month]?.value;

			// setting the labels for dropdown
			lead.source = sourceOptions[lead.source]?.label;
			lead.pref_state = lead?.pref_state?.map((state: number) => states[state]?.label).toString();
			lead.state = states[lead.state]?.label;
			lead.test_plan_month = months[lead.test_plan_month]?.label;

			const dynamicField = lead?.dynamic_fields;
			for (const property in dynamicField) {
				data[leadIndex][property] = dynamicField[property].response;
			}
			lead.SrNum = (currentPage - 1) * perPageCount + leadIndex + 1;
		});

		// console.log(data);

		settableData(data);
	};

	const openBulkAddLeadModal = () => {
		setBulkAddLeadModal(true);
	};

	const closeBulkAddLeadModal = () => {
		setBulkAddLeadModal(false);
	};

	const bulkLeadsUploadProps: UploadProps = {
		onRemove: (file) => {
			setBulkAddLeadFile([]);
		},
		beforeUpload: (file) => {
			if(file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			|| file.type === 'application/vnd.ms-excel'){
				setBulkAddLeadFile([file]);
				return true;
			}
			message.error('Please upload supported files(.xls, .xlsx)');
			setBulkAddLeadFile([]);
			return false;
		},
	};

	const handleUpload = async () => {
		const formData = new FormData();

		bulkAddLeadFile.forEach((file) => {
		  formData.append('uploadFile', file as RcFile);
		});

		setUploading(true);
		const response = await bulkUploadLeads(formData);
		setUploading(false);

		if( response.error ){
			message.error( response.error );
			return;
		}

		// [
		// 	leadsAlreadyPresentInDb: [{}, {}],
		// 	duplicateLeadsInFile: [{}, {}],
		// 	notInsertedIds: [{}, {}]
		// ]

		// if(response?.already_present_leads?.leadsAlreadyPresentInDb){
		// 	// message.error( 'Leads already present' );
		// 	// append data in structure
		// }

		// if(response?.duplicate_leads?.duplicateLeadsInFile){
		// 	// message.error( 'Leads already present' );
		// 	// append data in structure
		// }

		// if(response?.not_inserted_leads?.notInsertedIds){
		// 	// message.error( 'Leads already present' );
		// 	// append data in structure
		// }
		// check if error data is present then show erroe modals with the leads

		setBulkAddLeadFile([]);
		closeBulkAddLeadModal();
		message.success('Please go to bulk ativity to check your bulk add leads status.');
		handleLeadList(paginationData);
	};

	useEffect(() => {
		if(sessionData && leadStages.length && Object.keys(sourceOptions).length){
			handleLeadList(paginationData);
			handleAssignList();
		}
	}, [sessionData, leadStages, sourceOptions] );

	// on selecting the rows of group report table and set the selected row lead ids
	const leadsTableRowSelection: TableRowSelection<any> = {
		fixed:true,
		selectedRowKeys: leadsTableSelectedRowKeys,
		onChange: (selectedRowKeys) => {
			// assign keys to table row
			setLeadsTableSelectedRowKeys(selectedRowKeys);
		},
	};

	let timerId: any;
	const onSearchInputChange = (e: any) => {
		clearTimeout(timerId);
		const searchValueTrimmed = e.target.value.trim();
		timerId = setTimeout(() => {
			setSearchValue(searchValueTrimmed);
			paginationData.searchValue = searchValueTrimmed;

			//set the table page no. to 1
			paginationData.pageNumber = 1;

			setPaginationData(paginationData);
			handleLeadList(paginationData);
		},500);
	};
	
	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData = {sessionData}
			/>
			<div className={styles.listLeadsSreenbody} id='listLeadsSreenbody' role='listLeadsSreenbody'>
				<SearchByFilterComponent 
					handleLeadList={handleLeadList}
					setLeadsFilters={setLeadsFilters}
					paginationData={paginationData}
					assignToList={assignToList}
					setPaginationData={setPaginationData}
					leadStages={leadStages}
					leadStatus={leadStatus}
					sourceOptions={sourceOptions}
				/>
				<div className={styles.listLeadsSreenbodyheader}>
					{useCheckAttribute(resources.lead.value,attributes.add.value) && (
						<Button type="primary" onClick={openCreateLead} role='createLead'>
							Create new lead
						</Button>
					)}
					{useCheckAttribute(resources.lead.value,attributes.bulkUpload.value) && (
						<Tooltip placement='bottom' title='Bulk add Leads'>
							<Button 
								className={styles.btnThemeSecondary} 
								type="primary"
								onClick={openBulkAddLeadModal}
							>
								<img src={BulkAddIcon} width='20px'/>
							</Button>
						</Tooltip>
					)}
					<SearchField 
						onSearchInputChange={onSearchInputChange}
						isAddLeadModal={false}
					/>
				</div>
				<div className={styles.listLeadsSreenbodyTable}>
					<TableComponent 
						tableColumns={finalCols}
						listData={tableData}
						handleTableChange={handleTableChange}
						isReportPage={true}
						perPageCount={perPageCount}
						totalListDataCount={totalListDataCount}
						loading={tableLoading}
						rowSelection={leadsTableRowSelection}
						currentPage={currentPage}
					/>
				</div>
			</div>
			<LeadDetalisModal 
				leadDetailModalOpen={leadDetailModalOpen}
				setLeadDetailModalOpen={setLeadDetailModalOpen}
				onleadDetailModalClose={onleadDetailModalClose}
				handleCreateOneLead = {handleCreateOneLead}
				isEdit = {isEdit}
				oneLeadData = {oneLeadData}
				handleUpdateOneLead = {handleUpdateOneLead}
				leadListData = {leadListData}
				setDetailModalBtnLoading={setDetailModalBtnLoading}
				detailModalBtnLoading={detailModalBtnLoading}
				leadDetailModalSubmitBtnRef={leadDetailModalSubmitBtnRef}
				sourceOptions={sourceOptions}
			/>
			<Drawer
				title='Bulk add leads'
				width={400}
				onClose={closeBulkAddLeadModal}
				open={bulkAddLeadModal}
				placement="right"
			>
				<div className={styles.bulkAddLeadsBody}>
					<label className={styles.uploadText}>Upload file to add leads*</label>
					<Upload 
						fileList={bulkAddLeadFile}
						maxCount={1}
						{...bulkLeadsUploadProps}
					>
						<Button icon={<UploadOutlined />}>Choose File</Button>
					</Upload>
					<small style={{color: '#6c757d', fontSize: '84%', fontWeight: '400', lineHeight: '19px', fontFamily: 'Avenir, sans-serif'}}>
						Choose file to import users. Supported extensions are (.xls, .xlsx) or download 
						<a style={{color: '#DE6834'}} href='/BulkUploadLeads-template.xlsx'>{' add leads template'}.</a>
					</small>
					<div style={{marginTop: '30px', display: 'flex', justifyContent: 'end'}}>
						<Button 
							type="primary"
							disabled={bulkAddLeadFile.length === 0}
							loading={uploading}
							onClick={handleUpload}
						>
							{uploading ? 'Uploading' : 'Upload'}
						</Button>
					</div>
				</div>
			</Drawer>
			<FixedFilterComponent 
				leadsTableSelectedRowKeys={leadsTableSelectedRowKeys}
				handleUpdateLeadStatus = {handleUpdateLeadStatus}
				handleUpdateAssignTo = {handleUpdateAssignTo}
				handleUnAssignLeads = {handleUnAssignLeads}
				handleUpdateFollowUpLastCalled = {handleUpdateFollowUpLastCalled}
				createActivityBeforeDownload = {createActivityBeforeDownload}
				assignToList={assignToList}
				tableData={tableData}
				finalCols={finalCols}
				xlsxFileName={sessionData?.name}
				leadStages={leadStages}
				leadStatus={leadStatus}
			/>
		</>
  	);
};
