import React,{useEffect, useState} from 'react';
import { LeadVSsource } from '../../@types/report';
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ExportCSVName, ReportGraphConstants } from '../../config/report';
import { TableComponent } from '../mainTableComponent';
import { useReportStore } from '../../stores';
import { ReportState } from '../../@types/store/report';

interface LeadVsSourceProps{
	data: Array<LeadVSsource> | undefined | null;
	pageSize?: number,
	pageSizeOptions?: Array<string>,
	setSelectedGraph: any,
}

export const LeadVsSource = (props:LeadVsSourceProps) =>{
	const { data, pageSize, pageSizeOptions, setSelectedGraph } = props;


	const [paginationData, setPaginationData] = useState({
		current: 1,
		pageSize: pageSize ?? 20,
		total: 0,
		data: []
	});

	const columns: ColumnsType<LeadVSsource> = [
		{
			title: 'Source',
			dataIndex: 'label',
			key: 'source',
			render: (text,record,index) =>{
				return (
					<a 
						onClick={()=>{
							setSelectedGraph({
								value: record.value,
								type: ReportGraphConstants.leadSource.value,
								title:`Source (${text})`,
							});
						}}
					>{text}</a>
				);
			}
		},{
			title: 'Leads',
			dataIndex: 'count',
			key: 'count',
			sorter: (a,b) => a.percentage - b.percentage,
			defaultSortOrder: 'descend'
		}
	];

	const handleTableChange = (Pagination: any, filters: any, sorter: any) => {
		console.log(Pagination, filters, sorter);
		setPaginationData({
			pageSize: Pagination.pageSize,
			current: Pagination.current,
			total: paginationData.total,
			data: structuredClone(paginationData.data),
		});
	};

	useEffect(()=>{
		if(data){
			const total = data.reduce( (result,prev) => result+parseInt(prev.count as string) ,0);
			const newData = data && data.map((element)=>{
				return {
					label: element.label,
					count: `${element.count} (${(parseInt(element.count as string)*100/total).toFixed(2)}%)`,
					percentage: (parseInt(element.count as string)*100/total).toFixed(2),
					value: element.value,
				};
			});
			setPaginationData({
				current: 1,
				pageSize: pageSize ?? 20,
				total: newData.length,
				data: (newData as any),
			});
		}
	},[data]);

	return (
		<TableComponent
			loading={data?false:true} listData={paginationData.data} tableColumns={columns} 
			handleTableChange={handleTableChange} 
			pageSizeOptions={pageSizeOptions}
			isReportPage={false} perPageCount={paginationData.pageSize} totalListDataCount={paginationData.total} rowSelection={undefined} currentPage={paginationData.current}
		/>
	);
};
