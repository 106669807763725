import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { Bar } from '@ant-design/plots';
import { LeadVsCallerCountInterface } from '../../@types/report';
import { ReportGraphConstants } from '../../config/report';
import { ReportState } from '../../@types/store/report';
import { useReportStore } from '../../stores';
// interface LeadVsCountData{
// 	lead: number | string,
// 	caller: string,
// }

// interface callerData{
// 	[key: string]:{
// 		email: string,
// 		id: string,
// 		name: string,
// 	}
// }

interface LeadVsCallerCountProps{
	data:Array<LeadVsCallerCountInterface> | undefined | null,
	labelSize?: number,
	setSelectedGraph: any,
}


export const LeadVsCallerCount = (props:LeadVsCallerCountProps) => {
	const { data, labelSize, setSelectedGraph } = props;
	const [localData, setLocalData] = useState<Array<LeadVsCallerCountInterface>>([]);
	const [maxValue, setMaxValue] = useState<number>(0);

	const chartRef = useRef<any>();

	useEffect(() => {
		const handleClick = (ev: any) => {
			setSelectedGraph({
				type: ReportGraphConstants.leadVsCaller.value,
				value: ev?.data?.data?.callerId,
				title: ReportGraphConstants.leadVsCaller.label,
			});
		};
		chartRef.current.on('element:click',handleClick);

		return () => {
			chartRef.current.off('element:click',handleClick);
		};
	},[]);

	useEffect(()=>{
		if(data){
			setLocalData(data);
			let max = 0;
			data.forEach((element) => {
				max = Math.max(element.leadCount, max);
			});
			setMaxValue(max);
		}
	},[data]);
	const config = {
		xField: 'leadCount',
		yField: 'callerName',
		minBarWidth: 30,
		maxBarWidth: 30,
		xAxis: {
			tickMethod: (() => {
				if(maxValue < 5){
					return 'cat';
				}else{
					return 'wilkinson-extended';
				}
			})(),
		},
		yAxis:{
			label:{
				style:{fontSize: labelSize}
			}
		}
	};
	if(data === null){
		return <></>;
	}
	return <Bar chartRef={chartRef} scrollbar={{type:'vertical'}} loading={data===undefined} data={localData} {...config} />;
};