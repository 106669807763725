import React, { useEffect, useState, useRef } from 'react';
import { Column } from '@ant-design/plots';
import { EngagementChartInterface, LocalEngagementChartInterface } from '../../@types/report';
import { ReportGraphConstants, engagementDataType } from '../../config/report';

interface EngagementChartProps{
	data: Array<EngagementChartInterface> | undefined | null,
	labelSize?: number,
	setSelectedGraph: any
}


export const EngagementChart = (props:EngagementChartProps) => {
	const {data, labelSize, setSelectedGraph} = props;
	const [localData, setLocalData] = useState<Array<LocalEngagementChartInterface>>([]);

	const chartRef = useRef<any>();

	useEffect(() => {
		const handleClick = (ev: any) => {
			setSelectedGraph({
				type: ReportGraphConstants.engagementTrend.value,
				value: {value: ev?.data?.data?.value, date: ev?.data?.data?.date },
				title: ReportGraphConstants.engagementTrend.label,
			});
		};
		chartRef.current.on('element:click',handleClick);

		return () => {
			chartRef.current.off('element:click',handleClick);
		};
	},[]);

	useEffect(() =>{
		if(data){
			let processedData:Array<LocalEngagementChartInterface> = [];
			processedData = data.reduce((result, currentElement)=>{
				result.push({
					date: currentElement.date,
					count: parseInt(currentElement.assigned as string),
					type: engagementDataType.assigned.label,
					value: engagementDataType.assigned.value,
				});
				result.push({
					date: currentElement.date,
					count: parseInt(currentElement.worked as string),
					type: engagementDataType.worked.label,
					value: engagementDataType.worked.value,
				});
				result.push({
					date: currentElement.date,
					count: parseInt(currentElement.followup as string),
					type: engagementDataType.followup.label,
					value: engagementDataType.followup.value,
				});
				return result;
			},processedData);

			setLocalData(processedData);
		}
	},[data]);

	const config = {
		data: localData,
		xField: 'date',
		yField: 'count',
		seriesField: 'type',
		isGroup: true,
		slider: {
			start: 0.9,
			end: 1,
		},
		maxColumnWidth:70,
		minColumnWidth: 20,
		xAxis:{
			label:{
				style:{fontSize: labelSize}
			}
		}
	};

	return <Column 
		chartRef={chartRef} color={['#DE6834','#FFA764','#A62F00']} loading={data===undefined} {...config} />;
};
