import React, { ChangeEvent, useEffect, useRef, useState} from 'react';
import { useAppStore } from '../../stores';
import { Header } from '../../components/header';
import styles from './dynamicStatus-screen.module.css';
import {
	Button,
	Space,
	Tooltip, 
	message, 
	Popconfirm,
	Table
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { TableComponent } from '../../components/mainTableComponent';
import { convertTime } from '../../libs/utils';
import { useNavigate } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import { SearchField } from '../../components/searchField';
import { StatusDetailsModal } from '../../components/dynamicStatusDetailModal';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
	leadStatus:state.leadStatus,
	leadStages:state.leadStages,
	logoutUser: state.logoutUser,
	getLeadStagesAndStatus: state.getLeadStagesAndStatus,
	getStatusList: state.getStatusList,
	createOneStatus: state.createOneStatus,
	editOneStatus: state.editOneStatus
});

export const DynamicStatusScren: React.FunctionComponent = () => {

	const { 
		sessionData,
		leadStatus,
		leadStages,
		logoutUser,
		getLeadStagesAndStatus,
		getStatusList,
		createOneStatus,
		editOneStatus
	} = useAppStore( appStoreSelector );

	const [statusDetailModalOpen, setStatusDetailModalOpen] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [statusList, setStatusList] = useState<any[]>([]);
	const [oneStatusData, setoneStatusData] = useState<any>({});
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [sortField, setSortField] = useState<string>('updated_at');
	const [sortOrder, setSortOrder] = useState<any>('descend');
	const [perPageCount, setPerPageCount] = useState<number>(20);
	const [totalListDataCount, setTotalListDataCount] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	const [prevSorterOrder, setPrevSorterOrder] = useState<any>('descend');
	const [detailModalBtnLoading, setDetailModalBtnLoading] = useState<boolean>(false);
	const [paginationData, setPaginationData] = useState<paginationData>({
		sortField: 'updated_at',
		sortOrder: 'descend',
		pageNumber: 1,
		perPage: 20,
		searchValue: ''
	});
	
	const statusDetailModalSubmitBtnRef = useRef<any>(null);

	const [dataToShowInTable, setDataToShowInTable] = useState<Array<any>>([]);

	useEffect(() => {
		document.title = 'Status | CodeQuotient';
	},[]);
	
	const [] = useState<Array<any>>([]);

	useEffect(() => {
		const data = statusList.filter((element: any) => {
			return (element?.label as string).toLowerCase().includes(searchValue.toLowerCase());
		});
		setDataToShowInTable(data);
	}, [searchValue, statusList]);

	// assign keys to table row
	// const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

	// open the create new status drawer
	const openCreateStatus = () => {
		setStatusDetailModalOpen(!statusDetailModalOpen);
		setIsEdit(false);
	};
  
	// // on closing the status drawer
	const onStatusDetailModalClose = () => {
		setStatusDetailModalOpen(false);
		setIsEdit(false);
	};

	// open the edit lead drawer
	const editStatus = (record: any) => {
		setStatusDetailModalOpen(true);
		setIsEdit(true);
		setoneStatusData(record);
	};


	const handleEditCreateStatusBtn = () => {
		statusDetailModalSubmitBtnRef.current ? statusDetailModalSubmitBtnRef.current.removeAttribute('disabled', 'disabled') : '';
		setDetailModalBtnLoading(false);
	};

	const handleStatusList = async (paginationData: paginationData) => {
		setTableLoading(true);
		const response = await getStatusList(paginationData);
		setTableLoading(false);

		// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
		handleEditCreateStatusBtn();

		if(response?.error){
			message.error(response.error || 'Error in fetching groups list data');
			setStatusList([]);
			return;
		}
		
		// mapping stages values with their stage name
		const stagesValsWithStages: any = {};
		// mapping status values with their stage name
		const statusValsWithStages: any = {};
		response?.data?.leadStages.forEach((stageDeatils: any) => {
			stagesValsWithStages[stageDeatils.label] = stageDeatils.value;
			stageDeatils?.childs.forEach((val: number) => {
				statusValsWithStages[val] = stageDeatils.label;
			});
		});

		// set the sr number along with stage name, stage values and created by for the table 
		response?.data?.leadStatus?.forEach((statusData: any, index: number) => {
			statusData.SrNum = (paginationData.pageNumber - 1) * paginationData.perPage + index + 1;
			statusData.stageName = statusValsWithStages[statusData.value];
			statusData.stage = stagesValsWithStages[statusValsWithStages[statusData.value]];
			statusData.created_by = statusData.created_by ? response?.data?.usersData[statusData.created_by]?.name : '';
		});

		// console.log(response?.data?.status);

		 setStatusList(response?.data?.leadStatus || []);
		// setSortField(response?.data?.sortField);
		// setSortOrder(response?.data?.setSortOrder || null);
		// setPerPageCount(response?.data?.perPage);
		setTotalListDataCount(response?.data?.total || response?.data?.leadStatus?.length);
		// setCurrentPage(response?.data?.page);
	};

	const getStatusData = () => {
		//set the table page no. to 1
		// paginationData.pageNumber = 1;
		// paginationData.sortField = 'updated_at';
		// paginationData.sortOrder = 'descend';
		// setPrevSorterOrder('descend');

		// setPaginationData(paginationData);
		handleStatusList(paginationData);
		// document.body.querySelector('#listStatusSreenbodyTable .ant-table-body')?.scrollTo(9999, 0);
	};

	const handleCreateOneStatus = async (data: any) => {
		setTableLoading(true);
		const response = await createOneStatus(data);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error );
			setStatusDetailModalOpen(true);
			// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreateStatusBtn();
			return;
		}

		setStatusDetailModalOpen(false);
		message.success( response?.data?.msg || 'Status Created Sucessfully' );

		 getStatusData();
	};

	const handleDeleteOneStatus = async (deleteStatusId: string) => {
		// setTableLoading(true);
		// const response = await deleteStatus(deleteStatusId);
		// setTableLoading(false);

		// if( response?.error ){
		// 	message.error( response.error );
		// 	return;
		// }
		// message.success( response?.data?.msg || 'Group Deleted Sucessfully' );

		// getStatusData();
	};
	
	const handleEditOneStatus = async (data: any) => {
		setTableLoading(true);
		const response = await editOneStatus(data);
		setTableLoading(false);

		if(response?.error){
			message.error( response.error );
			setStatusDetailModalOpen(true);
			// while editing/creating Status need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreateStatusBtn();
			setIsEdit(true);
			return;
		}

		setIsEdit(false);
		setStatusDetailModalOpen(false);
		message.success( response?.data?.msg || 'Group Edited Sucessfully' );

		getStatusData();
	};

	const onSearchInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(ev.target.value);
	};

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		// console.log(pagination, filters, sorter);
		// const data: paginationData = {
		// 	sortField: sorter.field,
		// 	sortOrder: sorter.order || null,
		// 	pageNumber: pagination.current,
		// 	perPage: pagination.pageSize,
		// 	searchValue
		// };

		// // on sort set the table page no. to 1
		// if (sorter.order !== prevSorterOrder) {
		// 	data.pageNumber = 1;
		// }

		// setPrevSorterOrder(sorter.order);
		// handleStatusList(data);
		// setPaginationData(data);
	};

	// const handlerRoleOptions = async () => {
	// 	// const response = await getAllowedRolesOfCurrentRole();

	// 	if( response?.error ){
	// 		message.error( response.error );
	// 		return;
	// 	}

	// };

	const dynamicStatusColumns: ColumnsType<dynamicStatusTableDataType> = [
		{
			width: 47,
			dataIndex: 'SrNum',
		  	key: 'SrNum',
			render: ( _: any, record: any ) => (
				<div style={{display: 'flex', justifyContent: 'center'}}>{record.SrNum}.</div>
			)
		},
		{
		  	title: 'Status Name',
		  	dataIndex: 'label',
		  	key: 'statusName',
		  	ellipsis: true,
		},
		{
		  	title: 'Stage Name',
		  	dataIndex: 'stageName',
		  	ellipsis: true,
		  	key: 'stageName',
		},
		{
			title: 'Created By',
			dataIndex: 'created_by',
			ellipsis: true,
			key: 'createdBy',
		},
		{
			dataIndex: -1,
	  		title: 'Actions',
			key: 'actions',
			fixed: 'right',
			width: 100,
	    	render: ( _: any, record: any ) => { 
				// console.log( record.id );
				return (
					<Space size='small'>
						<Tooltip title='Edit'>
							<div className={styles.statusListingAction} onClick={() => editStatus(record)}>
								<EditOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
						{/* <Tooltip title='Delete'>
							<Popconfirm
								title='Delete the Status'
								description='Are you sure to delete this Status?'
								okText='Yes'
								cancelText='No'
								onConfirm={() => handleDeleteOneStatus( record._id )}
  							>
								<div className={styles.statusListingAction} >
									<DeleteOutlined style={{opacity: '0.7'}}/>
								</div>
							</Popconfirm>
						</Tooltip> */}
					</Space>
				);
			}
	  	}
	];

	// dynamicStatusColumns.forEach((column: any) => {
	// 	if(sortField && sortOrder && column.dataIndex === sortField){
	// 		column.sortOrder = sortOrder || null;
	// 	}
	// });

	// const rowSelection: TableRowSelection<any> = {
	// 	selectedRowKeys,
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		console.log('select1', selectedRows);
	// 		// assign keys to table row
	// 		setSelectedRowKeys(selectedRowKeys);
	// 	},
	// };

	// let timerId: any;
	// const onSearchInputChange = (e: any) => {
	// 	clearTimeout(timerId);
	// 	const searchValueTrimmed = e.target.value.trim();
	// 	timerId = setTimeout(() => {
	// 		setSearchValue(searchValueTrimmed);
	// 		paginationData.searchValue = searchValueTrimmed;

	// 		//set the table page no. to 1
	// 		paginationData.pageNumber = 1;

	// 		setPaginationData(paginationData);
	// 		handleStatusList(paginationData);
	// 	},500);
		
	// };

	// getting group table data and roles list
	useEffect(() => {
		handleStatusList(paginationData);
		// handlerRoleOptions();
	},[]);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<div className={styles.listStatusSreenbody} role='listGroupsSreenbody'>
				<div className={styles.listStatusSreenbodyheader}>
					
					<Button type="primary" onClick={openCreateStatus}>
						Create new Status
					</Button>
					
					<SearchField 
						onSearchInputChange={onSearchInputChange}
						isAddLeadModal={false}
						placeholder={'Search in status'}
					/>
					<StatusDetailsModal
						statusDetailModalOpen={statusDetailModalOpen}
						setStatusDetailModalOpen={setStatusDetailModalOpen}
						onStatusDetailModalClose={onStatusDetailModalClose}
						isEdit = {isEdit}
						handleCreateOneStatus={handleCreateOneStatus}
						oneStatusData={oneStatusData}
						handleEditOneStatus={handleEditOneStatus}
						stageOptions={leadStages}
						setDetailModalBtnLoading={setDetailModalBtnLoading}
						detailModalBtnLoading={detailModalBtnLoading}
						statusDetailModalSubmitBtnRef={statusDetailModalSubmitBtnRef}
					/>
				</div>
				<div className={styles.listStatusSreenbodyTable} id='listStatusSreenbodyTable'>
					<TableComponent 
						tableColumns={dynamicStatusColumns}
						listData={dataToShowInTable}
						handleTableChange={handleTableChange}
						isReportPage={false}
						perPageCount={perPageCount}
						totalListDataCount={totalListDataCount}
						loading={tableLoading}
						rowSelection={undefined}
						currentPage={undefined}
					/>
				</div>
			</div>
		</>
	);
};