import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { DateWithCountInterface } from '../../@types/report';
// this function return the string by: - 
// 1) removing all the underScores
// 2) making the letter next to underscore capital

export const convertSnakeToCamelCase = (Title: string) => {
	return Title.replace(/_([a-z])/g, function(match, char) {
		return char.toUpperCase();
	});
};

export const convertTime = (time: string) => {
	const timeDate: any = new Date(time);
	if (timeDate === 'Invalid Date' || time === '' || time === null) {
		return '';
	}
	let hours: any = timeDate.getHours();
	let minutes: any = timeDate.getMinutes();
	hours = hours < 10 ? '0' + hours : hours;
	minutes = minutes < 10 ? '0' + minutes : minutes;
	let date = timeDate.getDate();
	if (date < 10) {
		date = `0${timeDate.getDate()}`;
	}
	let month = timeDate.getMonth() + 1;
	if (month < 10) {
		month = `0${timeDate.getMonth() + 1}`;
	}
	const year = timeDate.getFullYear();

	return `${date}-${month}-${year} ${hours}:${minutes}`;
};
export const stringToRGB = function(str:string) {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	let colour = '#';
	for (let i = 0; i < 3; i++) {
		const value = (hash >> (i * 8)) & 0xFF;
		colour += ('00' + value.toString(16)).substr(-2);
	}
	return colour;
};


//EXPORT DATA TO Excel
export const exportToCSV = (passedData:any, fileName:string, columnSelected?: Array<string>) => {
	const fileType ='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	let dateToExport = passedData;
	if(columnSelected){
		
		dateToExport = passedData.map((objectElement: any) => {
			const result:any = {};
			columnSelected.forEach((element: string) => {
				if(objectElement[element]){
					result[element] = objectElement[element];
				}
			});
			return result;
		});
	}
	const ws = XLSX.utils.json_to_sheet(dateToExport);
	const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
	const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
	const data = new Blob([excelBuffer], { type: fileType });
	XLSX.writeFile(wb, `${fileName}.xlsx`);
};

//Process Date Data between range
export const rangeDateProcessor = (startDate:Dayjs, endDate:Dayjs, sortedData:Array<any>, callback: (condition: boolean,currentValue?:any)=>object) => {
	try{
		if(startDate > endDate){
			throw new Error('Start Date is greater');
		}
		let iterator = 0;
		const length = sortedData.length;
		const processedData = [];
		while(endDate.isAfter(startDate) || endDate.isSame(startDate)){
			const newDateFormated = startDate.format('YYYY-MM-DD');
			if( iterator < length && startDate.format('YYYY-MM-DD') === dayjs(sortedData[iterator].date).format('YYYY-MM-DD')){
				startDate = startDate.add(1,'day');
				// processedData.push({
				// date: newDateFormated,
				// 	count: sortedData[iterator]?.count ?? 0
				// });
				processedData.push({ date: newDateFormated ,...callback(true,sortedData[iterator])});
				iterator++;
			} else {
				startDate = startDate.add(1,'day');
				processedData.push({ date: newDateFormated ,...callback(false)});
				// processedData.push({
				// 	date: newDateFormated,
				// 	count: 0
				// });
			}
		}
		return processedData;
	} catch(error :any){
		throw new Error('Error In RangeDateProcessor');
	}
};


export const tickIntervalFinder = (count: number) => {
	if(count <= 4){
		return count;
	}else{
		return Math.log10(count)*10;
	}
};