import React, { useEffect } from 'react';

import styles from './welcomeModal.module.css';
import { Button, Form, Input, Modal, message } from 'antd';
import { useAppStore } from '../../stores';

const appStoreSelector = ( state: AppState ) => ( {
	welcomeUser: state.welcomeUser,
} );

export const WelcomeModal: React.FunctionComponent<any> = (props) => {

	const { 
		welcomeUser,
	} = useAppStore( appStoreSelector );

	const onFinish = async (values: any) => {
		const response = await welcomeUser(values);
		if(response?.data?.msg){
			window.location.reload();
		}
	};

	useEffect(() => {
		localStorage.clear();
	},[]);

	return (
		<div>
			<Modal
				title="WELCOME" open={true}
				footer={[]}
				closeIcon={false}
			>
				<Form
					name='WELCOME'
					autoComplete='off'
					labelCol={{ span: 8 }}
					wrapperCol={{ span: 16 }}
					onFinish={onFinish}
				>
					<Form.Item
						label='Name'
						name='name'
						rules={[{ required: true, message: 'Please input your name!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label='Email'
						name='email'
						rules={[{ required: true, message: 'Please input your email!' }]}
					>
						<Input />
					</Form.Item>

					<Form.Item
						label='Password'
						name='password'
						rules={[{ required: true, message: 'Please input your password!' },
							
							{
								pattern:   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,32}$/,
								message: 'Password size must be between 8 to 32 characters!Must contain one Uppercase , one LowerCase and one Digit character!'
							},
						]}
					>
						<Input.Password />
					</Form.Item>

					<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
						<Button type="primary" htmlType="submit">
							Start Your Journey
						</Button>
					</Form.Item>

				</Form>
			</Modal>
		</div>
	);
};
