import { CloseOutlined } from '@ant-design/icons';
import { Button, Segmented, theme } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
const { useToken } = theme;
import styles from './ruleEngineIfModel.module.css';
import { SingleIfBlock } from './subModuel-singleIfBlock';
import { v4 as uuid } from 'uuid';

export const DisplayElements = (props: any) => {
	const { token } = useToken();
	const { values, reRender, removeFunction, options } = props;
	const [condition, setCondition] = useState<string>((Object.keys(values)[0])==='any'?'OR':'AND');
	const [initalLoad, setInitalLoad] = useState<boolean>(true);
	const children = useRef<Array<any>>(values.all ?? values.any);

	// if(!children.current)
	// children.current = values.all??values.any;

	useEffect(()=>{
		if(initalLoad){
			setInitalLoad(false);
			return ;
		}
		if(condition==='AND'){
			values.all = values.any;
			delete values.any;
		} else {
			values.any = values.all;
			delete values.all;
		}
		reRender();
	},[condition]);

	const addNewCondition = useCallback(()=>{
		children.current?.push({
			fact: undefined,
			operator: undefined,
			value: undefined,
			valueLabel: undefined
		});
		reRender();
	},[]);

	const removeElement = (index: number) => {
		if(children.current)
			children.current.splice(index, 1);
		reRender();
		if(children.current.length === 0){
			removeFunction();
		}
	};
	  
	return (
		<div className={styles.displayElement}>
			<div className={styles.innerElements}>
				<Button onClick={addNewCondition}>Add Sub Condition</Button>
				{ children.current.length > 1 &&
					<Segmented options={['AND','OR']} value={condition} onChange={(ev)=>setCondition(ev as string)} style={{background:token.colorPrimary}}/>}
			</div>
			<div>
				{children.current?.map((element,index)=>{
					if(!element.key){
						element.key = uuid();
					}
					return (
						<div key={element.key} className={styles.individualElement}>
							<SingleIfBlock key={index} values={children.current[index]} options={options} reRender={reRender}/>
							<CloseOutlined onClick={()=>removeElement(index)} />
						</div>
					);
				})}
			</div>

		</div>
	);  
};
