import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './cardComponent.module.css';

import { IndividualCard } from '../cardModel';
import { FallOutlined, RiseOutlined } from '@ant-design/icons';
import { ReportState } from '../../@types/store/report';
import { useReportStore } from '../../stores';
import { message } from 'antd';
import { ReportGraphConstants } from '../../config/report';

interface CardComponentPropInterface{
	label: string,
	value: number,
	color: string,
}

const reportStoreSelector = (state: ReportState) => ({
	cardData: state.cardData,
	
	setSelectedGraph: state.setSelectedGraph,
	getCardData: state.getCardData,
	
});

export const CardComponent = (props: CardComponentPropInterface) => {
	const { cardData, getCardData, setSelectedGraph } = useReportStore(reportStoreSelector);
	const [data, setData] = useState(cardData.get(props.value));

	const getDataFunction = useCallback(async (value: number) => {
		try{
			await getCardData(value);

		} catch(error: any) {
			message.error(error?.message ?? error );
		}
	}, [getCardData]);

	useEffect(() => {
		setData(cardData.get(props.value));
	},[cardData]);

	useEffect(() => {
		if(!data){
			getDataFunction(props.value);
		}
	},[data]);

	return (
		<IndividualCard onClick={()=>{
			setSelectedGraph({
				type: ReportGraphConstants.cardModel.value,
				value: (props.value === 0)?undefined:props.value,
				title: props.label,
			});
		}} className={styles.cardComponent} loading={!data} color={props.color}>
			<div>
				<p className={`${styles.cardComponetCommon} ${styles.cardComponentTitle}`}>{props.label}</p>
				<p className={`${styles.cardComponetCommon} ${styles.cardComponentCount}`}>{data?.currentCount ?? 0}</p>
				<p className={`${styles.cardComponetCommon} ${styles.cardComponentTrend}`}>{((data?.currentCount ?? 0) >
					(data?.prevCount ?? 0) )?<RiseOutlined />:<FallOutlined />} {data?.prevCount ?? 0}
				</p>
			</div>
		</IndividualCard>
	);
};