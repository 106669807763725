import React, { ChangeEvent, ChangeEventHandler, KeyboardEventHandler, useEffect, useRef, useState} from 'react';
import { useAppStore } from '../../stores';
import { Header } from '../../components/header';
import styles from './listSources-screen.module.css';
import {
	Button,
	Space,
	Tooltip, 
	message, 
	Popconfirm,
	Table
} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { TableComponent } from '../../components/mainTableComponent';
import { convertTime } from '../../libs/utils';
import { useNavigate } from 'react-router-dom';
import { TableRowSelection } from 'antd/es/table/interface';
import { SearchField } from '../../components/searchField';
import { SourceDetailsModal } from '../../components/sourceDetailsModal';
import useCheckAttribute from '../../libs/auth/useCheckAttribute';
import { attributes, resources } from '../../config/authorization';

const appStoreSelector = ( state: AppState ) => ({
	sessionData: state.sessionData,
	sourcelist: state.sourceList,
	logoutUser: state.logoutUser,
	listSources: state.listSources,
	createSource: state.createSource,
	deleteSource: state.deleteSource,
	editsource: state.editSource
});

export const ListSourceScren: React.FunctionComponent = () => {

	const { 
		sessionData,
		sourcelist,
		logoutUser,
		listSources,
		createSource,
		deleteSource,
		editsource
	} = useAppStore( appStoreSelector );

	const [sourceDetailModalOpen, setSourceDetailModalOpen] = useState<boolean>(false);
	const [isEdit, setIsEdit] = useState<boolean>(false);
	const [sourceList, setSourceList] = useState<any[]>([]);
	const [oneSourceData, setoneSourceData] = useState<any>({});
	const [tableLoading, setTableLoading] = useState<boolean>(false);
	const [sortField, setSortField] = useState<string>('updated_at');
	const [sortOrder, setSortOrder] = useState<any>('descend');
	const [perPageCount, setPerPageCount] = useState<number>(20);
	const [totalListDataCount, setTotalListDataCount] = useState<number>(20);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchValue, setSearchValue] = useState<string>('');
	const [prevSorterOrder, setPrevSorterOrder] = useState<any>('descend');
	const [detailModalBtnLoading, setDetailModalBtnLoading] = useState<boolean>(false);
	const [paginationData, setPaginationData] = useState<paginationData>({
		sortField: 'updated_at',
		sortOrder: 'descend',
		pageNumber: 1,
		perPage: 20,
		searchValue: ''
	});
	const [dataToShowInTable, setDataToShowInTable] = useState<Array<any>>([]);

	const sourceDetailModalSubmitBtnRef = useRef<any>(null);

	useEffect(() => {
		document.title = 'Source | CodeQuotient';
	},[]);

	useEffect(() => {
		const data = sourceList.filter((element: any) => {
			return (element?.label as string).toLowerCase().includes(searchValue.toLowerCase());
		});
		setDataToShowInTable(data);
	}, [searchValue, sourceList]);

	// assign keys to table row
	// const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
	// open the create new Source drawer
	const openCreateSource = () => {
		setSourceDetailModalOpen(!sourceDetailModalOpen);
		setIsEdit(false);
	};
  
	// // on closing the Source drawer
	const onSourceDetailModalClose = () => {
		setSourceDetailModalOpen(false);
		setIsEdit(false);
	};

	// open the edit source drawer
	const editSource = (record: any) => {
		setSourceDetailModalOpen(true);
		setIsEdit(true);
		setoneSourceData(record);
	};


	const handleEditCreateSourceBtn = () => {
		sourceDetailModalSubmitBtnRef.current ? sourceDetailModalSubmitBtnRef.current.removeAttribute('disabled', 'disabled') : '';
		setDetailModalBtnLoading(false);
	};

	const handleSourceList = async (paginationData: paginationData) => {
		setTableLoading(true);
		const response = await listSources(paginationData);
		setTableLoading(false);

		// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
		handleEditCreateSourceBtn();

		if(response?.error){
			message.error(response.error || 'Error in fetching groups list data');
			setSourceList([]);
			return;
		}

		// console.log(response?.data);

		// set the sr number 
		response?.data?.sources.forEach((sourceData: any, index: number) => {
			sourceData.SrNum = (paginationData.pageNumber - 1) * paginationData.perPage + index + 1;
			if( sourceData.created_by ){
				sourceData.created_by = response?.data?.usersData[sourceData.created_by] ? response?.data?.usersData[sourceData.created_by].name : '';
			}
		});

		 setSourceList(response?.data?.sources || []);
		// setSortField(response?.data?.sortField);
		// setSortOrder(response?.data?.setSortOrder || null);
		// setPerPageCount(response?.data?.perPage);
		setTotalListDataCount(response?.data?.sources.total || response?.data?.sources.length);
		// setCurrentPage(response?.data?.page);
	};

	const getSourceData = () => {
		//set the table page no. to 1
		// paginationData.pageNumber = 1;
		// paginationData.sortField = 'updated_at';
		// paginationData.sortOrder = 'descend';
		// setPrevSorterOrder('descend');

		// setPaginationData(paginationData);
		handleSourceList(paginationData);
		// document.body.querySelector('#listSourceSreenbodyTable .ant-table-body')?.scrollTo(9999, 0);
	};

	const handleCreateOneSource = async (data: any) => {
		setTableLoading(true);
		const response = await createSource(data);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error );
			setSourceDetailModalOpen(true);
			// while editing/creating user need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreateSourceBtn();
			return;
		}

		setSourceDetailModalOpen(false);
		message.success( response?.data?.msg || 'Source Created Sucessfully' );

		getSourceData();
	};

	const handleDeleteOneSource = async (value: number) => {
		setTableLoading(true);
		const response = await deleteSource(value);
		setTableLoading(false);

		if( response?.error ){
			message.error( response.error );
			return;
		}
		message.success( response?.data?.msg || 'Group Deleted Sucessfully' );

		getSourceData();
	};
	
	const handleEditOneSource = async (data: any) => {
		setTableLoading(true);
		const response = await editsource(data);
		setTableLoading(false);

		if(response?.error){
			message.error( response.error );
			setSourceDetailModalOpen(true);
			// while editing/creating Source need to handle the behaviour of edit/create button in leadDetailModal form
			handleEditCreateSourceBtn();
			setIsEdit(true);
			return;
		}

		setIsEdit(false);
		setSourceDetailModalOpen(false);
		message.success( response?.data?.msg || 'Group Edited Sucessfully' );

		getSourceData();
	};

	const handleTableChange = (pagination: any, filters: any, sorter: any) => {
		// console.log(pagination, filters, sorter);
		// const data: paginationData = {
		// 	sortField: sorter.field,
		// 	sortOrder: sorter.order || null,
		// 	pageNumber: pagination.current,
		// 	perPage: pagination.pageSize,
		// 	searchValue
		// };

		// // on sort set the table page no. to 1
		// if (sorter.order !== prevSorterOrder) {
		// 	data.pageNumber = 1;
		// }

		// setPrevSorterOrder(sorter.order);
		// handleSourceList(data);
		// setPaginationData(data);
	};

	// const handlerRoleOptions = async () => {
	// 	// const response = await getAllowedRolesOfCurrentRole();

	// 	if( response?.error ){
	// 		message.error( response.error );
	// 		return;
	// 	}

	// };

	const onSearchInputChange = (ev: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(ev.target.value);
	};

	const listSourceColumns: ColumnsType<sourcesTableDataType> = [
		{
			width: 47,
			dataIndex: 'SrNum',
		  	key: 'SrNum',
			render: ( _: any, record: any ) => (
				<div style={{display: 'flex', justifyContent: 'center'}}>{record.SrNum}.</div>
			)
		},
		{
		  	title: 'Source Name',
		  	dataIndex: 'label',
		  	key: 'sourceName',
		  	ellipsis: true,
		},
		{
			title: 'Created By',
			dataIndex: 'created_by',
			ellipsis: true,
			key: 'createdBy',
		},
		{
			dataIndex: -1,
	  		title: 'Actions',
			key: 'actions',
			fixed: 'right',
			width: 100,
	    	render: ( _: any, record: any ) => { 
				// console.log( record.id );
				return (
					<Space size='small'>
						<Tooltip title='Edit'>
							<div className={styles.sourceListingAction} onClick={() => editSource(record)}>
								<EditOutlined style={{opacity: '0.7'}} />
							</div>
						</Tooltip>
						<Tooltip title='Delete'>
							<Popconfirm
								title='Delete the Source'
								description='Are you sure to delete this Source?'
								okText='Yes'
								cancelText='No'
								onConfirm={() => handleDeleteOneSource( record.value )}
  							>
								<div className={styles.sourceListingAction} >
									<DeleteOutlined style={{opacity: '0.7'}}/>
								</div>
							</Popconfirm>
						</Tooltip>
					</Space>
				);
			}
	  	}
	];

	// listSourceColumns.forEach((column: any) => {
	// 	if(sortField && sortOrder && column.dataIndex === sortField){
	// 		column.sortOrder = sortOrder || null;
	// 	}
	// });

	// const rowSelection: TableRowSelection<any> = {
	// 	selectedRowKeys,
	// 	onChange: (selectedRowKeys, selectedRows) => {
	// 		console.log('select1', selectedRows);
	// 		// assign keys to table row
	// 		setSelectedRowKeys(selectedRowKeys);
	// 	},
	// };

	// let timerId: any;
	// const onSearchInputChange = (e: any) => {
	// 	clearTimeout(timerId);
	// 	const searchValueTrimmed = e.target.value.trim();
	// 	timerId = setTimeout(() => {
	// 		setSearchValue(searchValueTrimmed);
	// 		paginationData.searchValue = searchValueTrimmed;

	// 		//set the table page no. to 1
	// 		paginationData.pageNumber = 1;

	// 		setPaginationData(paginationData);
	// 		handleSourceList(paginationData);
	// 	},500);
		
	// };

	// getting source table data
	useEffect(() => {
		handleSourceList(paginationData);
	}, []);

	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<div className={styles.listSourceSreenbody} role='listGroupsSreenbody'>
				<div className={styles.listSourceSreenbodyheader}>
					
					<Button type="primary" onClick={openCreateSource}>
						Create new Source
					</Button>
					
					<SearchField 
						onSearchInputChange={onSearchInputChange}
						isAddLeadModal={false}
						placeholder='Search in source'
					/>
					<SourceDetailsModal
						sourceDetailModalOpen={sourceDetailModalOpen}
						setSourceDetailModalOpen={setSourceDetailModalOpen}
						onSourceDetailModalClose={onSourceDetailModalClose}
						isEdit = {isEdit}
						handleCreateOneSource={handleCreateOneSource}
						oneSourceData={oneSourceData}
						handleEditOneSource={handleEditOneSource}
						setDetailModalBtnLoading={setDetailModalBtnLoading}
						detailModalBtnLoading={detailModalBtnLoading}
						sourceDetailModalSubmitBtnRef={sourceDetailModalSubmitBtnRef}
					/>
				</div>
				<div className={styles.listSourceSreenbodyTable} id='listSourceSreenbodyTable'>
					<TableComponent 
						tableColumns={listSourceColumns}
						listData={dataToShowInTable}
						handleTableChange={handleTableChange}
						isReportPage={false}
						perPageCount={perPageCount}
						totalListDataCount={totalListDataCount}
						loading={tableLoading}
						rowSelection={undefined}
						currentPage={undefined}
					/>
				</div>
			</div>
		</>
	);
};