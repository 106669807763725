import React, { useEffect, useState } from 'react';
import { useAppStore } from '../../stores';


//Custorm Components
import { Header } from '../../components/header';
import { CallerTimeLine } from '../../components/callerTimeLineModel';
//Antd Components
import { Select } from 'antd';

//CSS MODULE
import style from './listCaller-screen.module.css';

const { Option } = Select;
const appStoreSelector = ( state: AppState ) => ({
	getCallerData: state.getCallerData,
	setAppLoading: state.setAppLoading,
	sessionData: state.sessionData,
	leadStages: state.leadStages,
	logoutUser: state.logoutUser,
});

interface CallerDataInterface {
	name: string,
	email: string,
	value: string,
}

interface CallerObj{
	[key: string]: CallerDataInterface
}

export const ListCallerActivity: React.FunctionComponent = () => {
	const {
		sessionData,
		logoutUser,
		getCallerData,
	} = useAppStore( appStoreSelector );
	const [callerData, setCallerData] = useState<CallerObj>({});
	const [selected, setSelected] = useState<string | undefined>();

	const getAllCaller = async () => {
		try{
			const response = await getCallerData();
			if(response?.data){
				setSelected(Object.keys(response?.data)[0]);				
			}
			if(response?.data){
				setCallerData(response.data);
			}
		} catch (error){
			setCallerData({});
		}
	};

	useEffect(() => {
		document.title = 'CallerActivity | CodeQuotient';
	},[]);

	useEffect(() =>{
		getAllCaller();
	},[]);

	const handleChange = (value:string) =>{
		setSelected(value);
	};
	
	return (
		<>
			<Header 
				logoutUser={logoutUser}
				sessionData={sessionData}
			/>
			<div className={style.callerSelect}>
				<Select
					style={{width:'100%',height:'100%'}}
					value={selected}
					onChange={handleChange}
					size='large'
					showSearch
					filterOption={(inputValue: any, option: any) =>
						option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
					}
				>
					{Object.keys(callerData).map((element)=>{
						return (
							<Option key={element} value={element} label={callerData[element].name}>{callerData[element].name}</Option>
						);
					})}
				</Select>
			</div>
			<CallerTimeLine userId={selected}/>
		</>
	);
};