import { CQLeadsClient } from '../clients/cq-leads-clients';
import { APP_CONFIG } from '../config';
import { CreateAppStore } from './app-store';
import { CreateReportStore } from './app-store/reportData';

export * from './app-store';


const cqLeadsClient = new CQLeadsClient( {
	cqServerURL: APP_CONFIG.MainServerURL,
} );

export const useAppStore = CreateAppStore( cqLeadsClient );
export const useReportStore = CreateReportStore(cqLeadsClient);