import { ColumnForReportModelInterface } from '../@types/report';

interface CardConstantInterface{
	[key: number]:{
		name: string,
		color: string,
		value: number,
		stage?: string,
	}
}

interface reverseExportCSVNameInterface{
	[key: number]: {
		label: string,
		value: number,
		columns?: Array<string>
	}
}

interface ExportCSVNameInterface{
	[key: string]:{
		label: string,
		value: number,
		columns?: Array<string>
	}
}

export const CardConstant = {
	['lead']: {
	  name: 'Lead Count',
	  label: 'Lead Count',
	  color: '#1abc9c', // New color: Turquoise
	  value: 0,
	},
	['totalApplications']: {
	  name: 'Total Applications',
	  label: 'Total Applications',
	  color: '#2ecc71', // New color: Emerald
	  value: 217,
	  stage: 'Hot'
	},
	['scheduleTestCount']: {
	  name: 'Total Scheduled Test',
	  label: 'Total Scheduled Test',
	  color: '#3498db', // Color remains the same: Blue
	  value: 211,
	  stage: 'Hot'
	},
	['walkInCount']: {
	  name: 'Total Walkin',
	  label: 'Total Walkin',
	  color: '#9b59b6', // New color: Amethyst
	  value: 213,
	  stage: 'Hot'
	},
	['offerLetterReleasedCount']: {
	  name: 'Total Offer Letter Released',
	  label: 'Total Offer Letter Released',
	  color: '#16a085', // New color: Green Sea
	  value: 215,
	  stage: 'Hot'
	},
	['readyForAdmissionCount']: {
	  name: 'Total Ready For Admission',
	  label: 'Total Ready For Admission',
	  color: '#27ae60', // New color: Nephritis
	  value: 216,
	  stage: 'Hot'
	},
	['registrationDoneCount']: {
	  name: 'Total Registration Done',
	  label: 'Total Registration Done',
	  color: '#f39c12', // New color: Orange
	  value: 227,
	  stage: 'Hot'
	},
	['admissionDoneCount']: {
	  name: 'Total Admission Done',
	  label: 'Total Admission Done',
	  color: '#d35400', // New color: Pumpkin
	  value: 218,
	  stage: 'Hot',
	},
	['linkGeneratedCount']: {
	  name: 'Total Link Generated',
	  label: 'Total Link Generated',
	  color: '#c0392b', // New color: Pomegranate
	  value: 229,
	  stage: 'Hot',
	},
	['paymentFailedCount']: {
	  name: 'Total Payment Failed',
	  label: 'Total Payment Failed',
	  color: '#e74c3c', // New color: Alizarin
	  value: 231,
	  stage: 'Hot',
	},
	['noAnswerCount']: {
	  name: 'No Answer Count',
	  label: 'No Answer Count',
	  color: '#f1c40f', // New color: Sunflower
	  value: 201,
	  stage: 'Cold'
	},
	['interestedCount']: {
	  name: 'Total Interested',
	  label: 'Total Interested',
	  color: '#e67e22', // New color: Carrot
	  value: 207,
	  stage: 'Warm'
	},
	// ['notInterested']: {
	//   name: 'Not Interested',
	//   label: 'Not Interested',
	//   color: '#ffffff', // New color: White
	//   value: 219,
	//   stage: 'Junk'
	// }
};

export const engagementDataType={
	assigned:{label: 'Assigned', value: 0},
	worked:{label: 'Worked', value: 1},
	followup:{label: 'Follow Up', value: 2},
};

export const ReportGraphConstants = {
	leadStatus: {label: 'Lead Status', value: 1, columns:['count','label','stageLabel']},
	leadSource: {label: 'Source Performance', value: 2, columns:['count','label']},
	leadVsCaller: {label: 'Lead Count by Caller', value: 6, columns:['callerName','callerEmail','leadCount']},
	callerPerformace: {label:'Caller Performence', value: 3, columns:['worked', 'payment', 'name', 'email']},
	leadTrend: {label: 'Lead Trend', value: 4, columns:['callerName','callerEmail', 'leadCount']},
	engagementTrend: {label: 'Engagement Chart', value: 5, columns:['Worked','Payment','name','email']},

	cardModel: {label: 'CardData', value: 7}
};

interface reverseReportGraphConstantInterface {
	[key: number] : {
		value: number,
		label: string
	}
}

export const reverseReportGraphConstant = Object.values(ReportGraphConstants).reduce((result: reverseReportGraphConstantInterface, current) => {
	result[current.value] = current;
	return result;
},{});

export const ExportCSVName:ExportCSVNameInterface = {
	callerStatusGraph: {label: 'Caller_Status_Graph', value: 1, columns:['count','label','stageLabel']},
	leadSource: {label: 'Lead_Source', value: 2, columns:['count','label']},
	paymentRecieveVsWorkedOnLead: {label:'PaymentRecieved_VS_CallerWorkedOnLead', value: 3, columns:['worked', 'payment', 'name', 'email']},
	dailyLeadTrend: {label: 'Daily_Lead_Trend', value: 4},
	engagementChart: {label: 'Engagement_Data', value: 5},
	leadVsCaller: {label: 'Lead_VS_Caller', value: 6, columns:['callerName','callerEmail','leadCount']},
	leadCount: {label: CardConstant.lead.name, value: CardConstant.lead.value},
	totalApplications: {label: CardConstant.totalApplications.name, value: CardConstant.totalApplications.value},
	scheduledTest: {label: CardConstant.scheduleTestCount.name, value: CardConstant.scheduleTestCount.value},
	walkin:{label: CardConstant.walkInCount.name, value: CardConstant.walkInCount.value},
	offerLetterRelease: {label: CardConstant.offerLetterReleasedCount.name, value: CardConstant.offerLetterReleasedCount.value},
	readyForAdmission: {label: CardConstant.readyForAdmissionCount.name, value: CardConstant.readyForAdmissionCount.value},
	registrationDone: {label: CardConstant.registrationDoneCount.name, value: CardConstant.registrationDoneCount.value},
	admissionDone: {label: CardConstant.admissionDoneCount.name, value: CardConstant.admissionDoneCount.value},
	linkGenerated: {label: CardConstant.linkGeneratedCount.name, value: CardConstant.linkGeneratedCount.value},
	paymentFailed: {label: CardConstant.paymentFailedCount.name, value: CardConstant.paymentFailedCount.value},
	noAnswerCount: {label: CardConstant.noAnswerCount.name, value: CardConstant.noAnswerCount.value},
	interested: {label: CardConstant.interestedCount.name, value: CardConstant.interestedCount.value},
};


export const reverseExportCSVName:reverseExportCSVNameInterface = {
	1: ExportCSVName.callerStatusGraph,
	2: ExportCSVName.leadSource,
	3: ExportCSVName.paymentRecieveVsWorkedOnLead,
	4: ExportCSVName.dailyLeadTrend,
	5: ExportCSVName.engagementChart,
	6: ExportCSVName.leadVsCaller,
};
